import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { SubSink } from "subsink";
import { EventSelectV2Component } from "../event-select-v2/event-select-v2.component";
import { jobToolFeature } from "../job-tool.reducer";
import { Discount, EstimatesJobFragment } from "graphql.generated";
import { cloneDeep, isNil } from "lodash";
import { FreyaHelperService } from "src/app/services/freya-helper.service";
import { workOrdersSelectors } from "../job-state/workorders-state/workorders.selectors";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SharedModule } from "src/app/shared/shared.module";
import { LibModule } from "src/app/lib.ts/lib.module";
import { convertCentsToDollars, convertDollarsToCents } from "src/app/lib.ts/currency.util";
import { getDisabledStatus } from '../../jobsv2/jobsv2-charges-helpers';
import { WorkOrdersActions } from "../job-state/workorders-state/workorders.actions";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { MutateDiscountV2Component } from "../mutate-discount-v2/mutate-discount-v2.component";
import { CalendarEventWithLockedAndInvoicedFlag } from "../jobv2-create/jobv2-interfaces";

@Component({
    selector: 'app-discounts-modal-v2',
    standalone: true,
    imports: [
        SharedModule,
        LibModule,
        ReactiveFormsModule,
        FreyaCommonModule,
        EventSelectV2Component,
        MutateDiscountV2Component,
    ],
    templateUrl: './add-discounts-modal-v2.component.html',
    styleUrl: './add-discounts-modal-v2.component.scss',
})

export class AddDiscountsModalV2Component implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('mutate') mutateRef: MutateDiscountV2Component;

    @Input() event: CalendarEventWithLockedAndInvoicedFlag;

    constructor(
        private store: Store,
        private freyaHelper: FreyaHelperService,
        private cd: ChangeDetectorRef,
    ) { }

    private subs = new SubSink();
    public getDisabledStatus = getDisabledStatus;

    availableDiscounts$ = this.store.select(jobToolFeature.selectAvailableDiscounts);
    jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
    job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);

    availableDiscounts: Partial<Discount>[] = [];
    job: EstimatesJobFragment;

    selectedDiscount: Discount;

    amountInputAtts: {
        min: number | undefined;
        max: number | undefined;
    };

    // DISCOUNTS
    applyDiscountForm: UntypedFormGroup = new UntypedFormGroup({
        discount: new UntypedFormControl(null, [Validators.required]),
        amount: new UntypedFormControl(null),
        events: new UntypedFormControl(null, [Validators.required]),
    });

    showApplyDiscountDialog = false;
    jobLoading = false;
    defaultCurrency: string;

    ngOnInit(): void {
        this.subs.sink = this.job$.subscribe((job) => {
            if (job) {
                this.job = cloneDeep(job);
            }
        });

        this.subs.sink = this.availableDiscounts$.subscribe((availableDiscounts) => {
            this.availableDiscounts = availableDiscounts;
        });

        this.subs.sink = this.jobLoading$.subscribe((jobLoading) => {
            this.jobLoading = cloneDeep(jobLoading);
        });

        this.defaultCurrency = this.freyaHelper.getCurrency();
        this.setAmountInputAtts();
        this.manageApplyDiscountForm();

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    //single use
    openCreateDiscountDialog() {
        this.showApplyDiscountDialog = false;
        this.mutateRef.mutateType = 'create';
        this.mutateRef.openDialog();
    }

    addDiscountToEvents() {
        const formValues = this.applyDiscountForm.value;

        const discounts = formValues?.events?.map(event => ({
            eventId: event?.id,
            discountId: formValues?.discount?.id,
            customAmount: this.getCustomAmount(),
        }));

        if (discounts?.length) {
            this.store.dispatch(WorkOrdersActions.addDiscount({ discounts }));
        }

        this.applyDiscountForm.reset();
        this.showApplyDiscountDialog = false;
    }

    openDiscountsModal() {
        this.showApplyDiscountDialog = true;
    }

    setAmountInputAtts(min?: number, max?: number) {
        const isPercentage = this.selectedDiscount?.discountType === 'percentage';

        const newMax = isPercentage ? Math.min(max, 100) : max;

        this.amountInputAtts = {
            min,
            max: newMax,
        };
    }

    getCustomAmount() {
        const { amount } = this.applyDiscountForm.value;

        if (!amount) { return; }

        const customAmount = this.selectedDiscount.discountType === 'amount' ? convertDollarsToCents(amount) : amount;

        return customAmount === this.selectedDiscount.amount ? undefined : customAmount;
    }

    manageApplyDiscountForm() {
        this.subs.sink = this.applyDiscountForm.controls.discount
            .valueChanges.subscribe((selectedDiscount: Discount | undefined) => {

                this.selectedDiscount = selectedDiscount;

                const { amount: amountCtrl } = this.applyDiscountForm.controls;

                if (!selectedDiscount) {
                    amountCtrl.reset();
                    this.resetAmountValidators();
                    amountCtrl.disable();
                    this.setAmountInputAtts();
                    return;
                }

                const amount = selectedDiscount.discountType === 'percentage' ?
                    selectedDiscount.amount :
                    convertCentsToDollars(selectedDiscount.amount);

                if (!selectedDiscount.customAmountRange) {
                    amountCtrl.setValue(amount);
                    this.resetAmountValidators();
                    amountCtrl.disable();
                    this.setAmountInputAtts();
                    return;
                }

                amountCtrl.enable();

                amountCtrl.setValue(amount);

                let [min, max] = selectedDiscount.customAmountRange;

                if (selectedDiscount.discountType === 'amount') {
                    min = convertCentsToDollars(min, true);
                    max = convertCentsToDollars(max, true);
                }

                this.setAmountValidators(min, max);
                this.setAmountInputAtts(min, max);
            });;
    }

    resetAmountValidators() {
        const { amount: amountCtrl } = this.applyDiscountForm.controls;
        amountCtrl.clearValidators();
        amountCtrl.updateValueAndValidity();
    }

    setAmountValidators(min: number | undefined, max: number | undefined) {

        const { amount: amountCtrl } = this.applyDiscountForm.controls;

        amountCtrl.clearValidators();

        if (min !== undefined) {
            amountCtrl.addValidators(Validators.min(min));
        }

        if (max !== undefined) {

            const isPercentage = this.selectedDiscount?.discountType === 'percentage';

            const newMax = isPercentage ? Math.min(max, 100) : max;

            amountCtrl.addValidators(Validators.max(newMax));
        }

        amountCtrl.updateValueAndValidity();
    }
}