import dayjs from "dayjs";
import { BaseChargeFragment, Charge, EstimatesJobFragment, Job_LocationsFragment } from "graphql.generated";

import { cloneDeep } from "lodash";

import { EventTypeInfo, JobEventType, eventTypeInfoMapV2, virtualEstimateDefaultTime } from "../global.constants";
import { safeParseJSON } from "../js";
import { ChargeProductType, EventInfo, EventInfoLocation, LocationMap } from "../services/estimate-helper.service";
import { ProductMetadataValue } from "../services/product-rules.service";

import { FullJobFragmentWithFields } from "./job-tool.reducer";
import { DistanceCalculations } from "./jobv2-create/jobv2-interfaces";

export type TimeFromConfig = {
    estimating: number | undefined;
    virtualEstimate: number | undefined;
};

/**
   * Used to precalculate event information in selectors
   *
   * @param eventType the event type to calculate
   * @param locationTypeMap a map of location types to system location ID's
   * @returns an object with the event info
   */
export function precalculateEventInfo(
    eventType: JobEventType,
    distances: DistanceCalculations,
    // map of ids by location type
    locationTypeMap: { [locationType: string]: string } = {},
    includeStartDock = true,
    includeEndDock = true,
): EventInfo {

    const eventTypeInfo = eventTypeInfoMapV2[eventType];

    const baseLocationTypes = eventTypeInfo?.virtualTime ? [] : calculateBaseLocationTypes(
        includeStartDock,
        true,
        eventType === 'moving',
        includeEndDock,
    );

    const locations: EventInfoLocation[] = [];

    let order = 0;
    for (const baseLocationType of baseLocationTypes) {
        const locationType = `${eventType}-${baseLocationType}`;
        const estimatedTimeAtLocation = 0;

        let travelTimeToNextLocation = 0;
        let distanceToNextLocation = 0;
        const nextLocationType = baseLocationTypes[order + 1];
        if (nextLocationType) {
            const distance = getDistanceBetweenLocationTypes(baseLocationType, nextLocationType, distances);
            if (distance) {
                travelTimeToNextLocation = distance.estimatedTime;
                distanceToNextLocation = distance.totalDistance;
            }
        }

        locations.push({
            locationId: locationTypeMap[baseLocationType],
            fullType: locationType,
            type: baseLocationType,
            estimatedTimeAtLocation,
            travelTimeToNextLocation,
            distanceToNextLocation,
            travelTimeToNextLocationOffset: 0,
            order: order++,
        });
    }

    const eventInfoTotals = calculateEventInfoTotals(locations);
    // add virtual time to total location time
    eventInfoTotals.totalLocationTime += eventTypeInfo?.virtualTime || 0;
    eventInfoTotals.totalTime += eventTypeInfo?.virtualTime || 0;

    return {
        eventType,
        eventTypeInfo,
        baseLocationTypes,
        locations,
        includeStartDock,
        includeEndDock,
        ...eventInfoTotals,
    };
}

export function calculateBaseLocationTypes(
    includeStartDock = true,
    includeStart = true,
    includeEnd = true,
    includeEndDock = true,
) {
    const baseLocationTypes: string[] = [];

    if (includeStartDock) {
        baseLocationTypes.push('basestart');
    }
    if (includeStart) {
        baseLocationTypes.push('start');
    }
    if (includeEnd) {
        baseLocationTypes.push('end');
    }
    if (includeEndDock) {
        baseLocationTypes.push('baseend');
    }

    return baseLocationTypes;
}

export function calculateEventInfoTotals(locations: EventInfoLocation[]) {

    let totalLocationTime = 0;
    let totalTravelTime = 0;
    let totalDistanceMeters = 0;

    for (const l of locations) {
        totalLocationTime += l.estimatedTimeAtLocation || 0;
        totalTravelTime += l.travelTimeToNextLocation || 0;
        totalTravelTime += l.travelTimeToNextLocationOffset || 0;
        totalDistanceMeters += l.distanceToNextLocation || 0;
    }
    return {
        totalTime: totalLocationTime + totalTravelTime,
        totalLocationTime,
        totalTravelTime,
        totalDistanceMeters,
    };

}

/**
   * Get distances between two event location types
   *
   * @param startEventType
   * @param endEventType
   * @param locationDistanceTypeMap
   */
export function getDistanceBetweenLocationTypes(
    startEventType: string,
    endEventType: string,
    distances: DistanceCalculations,

    locationDistanceTypeMap = {
        basestart: 'dock',
        baseend: 'dock',
        start: 'start',
        end: 'end',
    },
) {

    const from = locationDistanceTypeMap[startEventType] || startEventType;
    const to = locationDistanceTypeMap[endEventType] || endEventType;
    const distanceKey = `${from}_${to}`;
    const reversedDistanceKey = `${to}_${from}`;

    return distances[distanceKey] || distances[reversedDistanceKey];
}

/**
   * Update estimated time at each location and total time from charges.
   *
   * Includes time at location and estimated travel time
   *EventInfo
   * @param eventInfo Event info, probably calculated from `precalculateEventInfo`
   * @param eventCharges charges on a job to modify the estimated times based on changes in hours/travel
   */
export function getDurationsFromCharges(
    eventInfo: EventInfo,
    eventCharges: BaseChargeFragment[],
    eventTimeFromConfig: TimeFromConfig,
) {
    const eventTypeInfo = eventTypeInfoMapV2[eventInfo.eventType];

    let updatedLocations = cloneDeep(eventInfo.locations);

    const determineCalculationForProduct = (
        productType: ChargeProductType,
        eventTime: number,
    ): {
        timeMultiplier: number;
        timeToAdd: number;
    } => {

        const {
            quantitySum: chargeTimeHours,
            charges: summedCharges,
        } = calculateChargesRelatedToEvent(eventCharges, productType);
        const chargeTime = chargeTimeHours * 3600;

        if (
            // charge time and event time are both zero
            chargeTime === 0 && eventTime === 0
            // there are no summed charges
            && !summedCharges?.length
            // we are calculating the location time
            && productType === 'locationtime'
            // the total time at each location is zero
            && sumTimeAtEventLocations(eventInfo.locations) === 0
            // event type has a default location time
            && eventTypeInfo?.defaultLocationTimes
        ) {
            // missing both rules and charges, set location time from constants
            updatedLocations  = [];
            for (const location of eventInfo.locations) {
                updatedLocations = [...updatedLocations, setDefaultTimeAtLocation(location, eventTypeInfo)];
            }

            return {
                timeMultiplier: 1,
                timeToAdd: 0,
            };
        }
        if (summedCharges.length === 0) {
            // use rule time because no charges were added.
            // console.warn(`Not multiplying ${productType} for ${eventInfo.eventType}, no charges. Event time: ${eventTime}`);

            return {
                timeMultiplier: 1,
                timeToAdd: 0,
            };
        }

        let addTime = 0;
        let multiple = chargeTime / eventTime;
        if (chargeTime === 0 && eventTime === 0) {
            // houston we have a problem
            console.warn(`cant calculate ${eventInfo.eventType} ${productType} `
                + `duration from charges, charge time and event time are 0`);

            return {
                timeMultiplier: 1,
                timeToAdd: 0,
            };
        } else if (eventTime === 0) {
            // use charge time by default
            addTime = chargeTime;
            multiple = 1;
        }

        return {
            timeMultiplier: multiple,
            timeToAdd: addTime,
        };
    };

    const {
        timeMultiplier: locationTimeMultiple,
        timeToAdd: locationTimeAdd,
    } = determineCalculationForProduct('locationtime', eventInfo.totalLocationTime);
    const {
        timeMultiplier: travelTimeMultiple,
        timeToAdd: travelTimeAdd,
    } = determineCalculationForProduct('traveltime', eventInfo.totalTravelTime);

    const nonBaseLocations = updatedLocations.filter((l) => !isBaseLocation(l));

    updatedLocations = updatedLocations.map(l => {

        return {
            ...l,
            // Calculate estimated time at location
            estimatedTimeAtLocation: Math.ceil(
                l.estimatedTimeAtLocation * locationTimeMultiple +
                (isBaseLocation(l) ? 0 : locationTimeAdd / nonBaseLocations.length)
            ),
            // Calculate travel time to next location offset
            travelTimeToNextLocationOffset: Math.ceil(
                l.travelTimeToNextLocation * travelTimeMultiple -
                l.travelTimeToNextLocation +
                (isBaseLocation(l) ? 0 : travelTimeAdd / nonBaseLocations.length)
            ),
        };
    });

    const res = calculateEventInfoTotals(updatedLocations);

    const updatedEventInfo = {
        ...eventInfo,
        locations: updatedLocations,
        totalTime: res.totalTime + (eventTypeInfo.virtualTime || 0),
        totalLocationTime: res.totalLocationTime + (eventTypeInfo.virtualTime || 0),
        totalTravelTime: res.totalTravelTime,
        totalDistanceMeters: res.totalDistanceMeters,
    };

    // Explicitly override VOSE and OSE time from config if it is provided
    return overrideEventTimeFromConfig(
        updatedEventInfo.eventTypeInfo,
        updatedEventInfo,
        eventTimeFromConfig,
        virtualEstimateDefaultTime
    );

}

export function calculateChargesRelatedToEvent(
    eventCharges: BaseChargeFragment[],
    productType: ChargeProductType,
) {

    const charges = [];
    let valueSum = 0;
    let quantitySum = 0;

    for (const charge of eventCharges) {
        const chargeProductType = determineChargeProductType(charge);

        if (chargeProductType === productType) {
            charges.push(charge);
            quantitySum += charge.quantity;
            valueSum += charge.amount;
        }

    }

    return {
        charges,
        quantitySum,
        valueSum,
    };
}

export function /**
* Determines charge product type
*
* @param charge
* @returns
*/
determineChargeProductType(
    charge: BaseChargeFragment,
): ChargeProductType {

    // Authomatic Charges/Rules
    const strAutoInfo = getAutoInfoFromCharge(charge as any);
    if (strAutoInfo) {
        const autoInfo = safeParseJSON<ProductMetadataValue>(strAutoInfo);
        if (!autoInfo) {
        } else if (autoInfo.quantity === 'event-total-traveltime') {
            return 'traveltime';
        } else if (autoInfo.quantity === 'event-total-locationtime') {
            return 'locationtime';
        }
    }

    // Custom Charges (w/out products) don't apply
    if (!charge.product?.category) {
        return undefined;
    }

    // Check the category first as this is the cleaner way
    switch (charge.product?.category) {
        case 'Truck & Travel':
            return 'traveltime';
        case 'Labor':
            return 'locationtime';
    }


    // Check the name if the category is not set, this way is deprecated and will be removed in the future
    const productName = (charge.product?.name || charge.productName)?.toLowerCase();

    if (charge.attributes?.includes('productType::traveltime')) {
        return 'traveltime';
    } else if (charge.attributes?.includes('productType::locationtime')) {
        return 'locationtime';
    } else if (charge.product?.attributes?.includes('productType::traveltime')) {
        return 'traveltime';
    } else if (charge.product?.attributes?.includes('productType::locationtime')) {
        return 'locationtime';
    } else if (productName.includes('man hour')) {
        return 'locationtime';
    } else if (productName.includes('person hour')) {
        return 'locationtime';
    } else if (productName.includes('crew hour')) {
        return 'locationtime';
    } else if (productName.includes('truck') && (productName.includes('travel'))) {
        return 'traveltime';
    }

    return undefined;
}
export function getAutoInfoFromCharge(charge: Charge) {
    return getAttributeValueByPrefix(charge.attributes, 'auto-info');

}

export function getAttributeValueByPrefix(
    attributes: string[],
    prefix: string,
    deliminator = '::',
) {
    if (!attributes) { return undefined; }
    const attr = attributes.find((_attr) => _attr.startsWith(prefix + deliminator));

    if (!attr) { return undefined; }

    const [_prefix, value] = attr.split(deliminator);
    return value;

}

export function /**
* Sums the estimatedTimeAtLocation property on a location
*/
    sumTimeAtEventLocations(
        locations: EventInfoLocation[],
    ) {

    return locations.reduce((p, l) => l.estimatedTimeAtLocation, 0);
}

export function setDefaultTimeAtLocation(
    location: EventInfoLocation,
    eventTypeInfo: EventTypeInfo,
): EventInfoLocation {
    const defaultLocationTypeDuration = eventTypeInfo.defaultLocationTimes[location.type];

    return {
        ...location,
        estimatedTimeAtLocation: defaultLocationTypeDuration || location.estimatedTimeAtLocation,
    };
}

export function isBaseLocation(
    l: EventInfoLocation
) {
    return l?.type === 'basestart' || l?.type === 'baseend' || l?.type === 'base';
}

export function overrideEventTimeFromConfig(
    eventTypeInfo: EventTypeInfo,
    eventInfo: EventInfo,
    eventTimeFromConfig: TimeFromConfig,
    virtualEstimateDefaultTime: number,
): EventInfo {
    const updatedEventInfo = cloneDeep(eventInfo);

    if (eventTypeInfo.value === 'estimating' && eventTimeFromConfig?.estimating) {
        updatedEventInfo.totalLocationTime = eventTimeFromConfig?.estimating;
        updatedEventInfo.totalTime = eventTimeFromConfig?.estimating;
    }

    if (eventTypeInfo.value === 'virtualEstimate' && eventTimeFromConfig?.virtualEstimate) {
        updatedEventInfo.totalLocationTime = eventTimeFromConfig?.virtualEstimate;
        updatedEventInfo.totalTime = eventTimeFromConfig?.virtualEstimate;
    }

    if (eventTypeInfo.value === 'virtualEstimate' && !eventTimeFromConfig?.virtualEstimate) {
        updatedEventInfo.totalLocationTime = virtualEstimateDefaultTime;
        updatedEventInfo.totalTime = virtualEstimateDefaultTime;
    }

    return updatedEventInfo;
}

export function generateLocationTypeMap(job: EstimatesJobFragment) {
    const locationMap: LocationMap = {};

    const dock = getJobLocation(job, 'dock');

    locationMap.basestart = dock?.id;
    locationMap.baseend = dock?.id;

    locationMap.start = getJobLocation(job, 'start')?.id;
    locationMap.end = getJobLocation(job, 'end')?.id;


    return locationMap;

}

export function getJobLocation(job: Job_LocationsFragment, type: string) {
    if (!job?.locations) {
        return;
    }

    const location = job.locations.find((l) => l.locationType === type);

    return location?.location;
}

export const calculateEventTime = (
    job: FullJobFragmentWithFields,
    eventId: string,
    dockToStart: boolean,
    endToDock: boolean,
    eventTimeFromConfig: TimeFromConfig,
) => {
    const event = job?.events?.find(e => e.id === eventId);
    const eventType = event?.type;

    const locationMap = generateLocationTypeMap(job);

    const baseTime = precalculateEventInfo(
        eventType, job?.distances, locationMap,
        dockToStart, endToDock,
    );

    const finalTimeWithCharges = getDurationsFromCharges(
        baseTime,
        event?.charges || [],
        eventTimeFromConfig
    );

    return finalTimeWithCharges;
}

export function getNowAsTime(timezone: string): dayjs.Dayjs {
    let nowTime = dayjs().tz(timezone);
    const currentMinutes = nowTime.get('minutes');

    nowTime = nowTime.set('minutes', currentMinutes - (currentMinutes % 15)).set('seconds', 0);

    return nowTime;
}

export function getCurrentTimeWindows(timezone: string, possibleWindows) {
    const nowTime = getNowAsTime(timezone);
    return possibleWindows.filter(
        (window) => window.start < nowTime.unix() && window.end > nowTime.unix());
}

export function getStartUnix(time: number | Date | string, timezone: string): number {
    // Default, with restrictions ENABLED
    if (typeof time === 'number') {
      return time;
    } else if (typeof time === 'string'){
      return getNowAsTime(timezone).unix();
    }

    // Otherwise, we have restrictions DISABLED and we have to convert the calendar "time," which is
    // a Date() type

    // Convert formatted to dayJs and provide a timezone
    const dayJsStart = dayjs.tz(time, timezone);

    // return unix seconds
    return dayJsStart.unix();
}

/**
*
* @param eventInfo the event info
* @param initialStart the unix time to start the event
* @returns start and end in unix time, and the duration in seconds
*/
export function getTimingForQuery(
 eventInfo: EventInfo,
 initialStart = 0,
) {
 let start = initialStart;

 let duration = 0;
 if (eventInfo?.eventType === 'virtualEstimate') {
   duration = eventInfo?.totalLocationTime;
 } else {

   const startIndex = eventInfo.locations.findIndex((v) => v.type === 'start');
   if (startIndex > 0) {
     // we have stops before the start, eg the dock
     const prelocations = eventInfo.locations.slice(0, startIndex);
     for (const loc of prelocations) {
       const offset = loc.estimatedTimeAtLocation + loc.travelTimeToNextLocation;
       start -= offset;
     }
   }

   duration = eventInfo.totalTime;
 }

 return {
   start,
   end: start + duration,
   duration,
 };
}

export function calculateDateRange(start: Date, end: Date) {
    
    // TODO: Taken from schedules.component.ts; optimise later

    const calendarStart = dayjs(start || new Date()).startOf('day');
    const calendarEnd = dayjs(end|| new Date()).endOf('day');

    const duration = 0;
    const min = dayjs(start || new Date()).startOf('day').subtract(duration, 'day').unix();
    const max = dayjs(end || new Date()).endOf('day').add(duration, 'day').unix();
    
    return {
        startDate: calendarStart.format('YYYY-MM-DD'),
        endDate: calendarEnd.format('YYYY-MM-DD'),
        min,
        max
    };
  };

export function calculateMinMaxFromTimestamp(timestamp: number) {
    const parsedDate = dayjs(timestamp * 1000);
    const min = parsedDate.startOf('day').unix();
    const max = parsedDate.endOf('day').unix();

    return {
        min,
        max,
    };
}

export function getCalendarDate(dateString: string): Date {
    const parsedDate = dayjs(dateString, 'MM/DD/YYYY');
    return parsedDate.toDate();
}