import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { SharedModule } from "src/app/shared/shared.module";
import { workOrdersSelectors } from "../../job-state/workorders-state/workorders.selectors";
import { EstimatesJobFragment, Invoice } from "graphql.generated";
import { CalendarEventWithLockedAndInvoicedFlag } from "../../jobv2-create/jobv2-interfaces";
import { calculateJobTotals } from "../../job-calculator";
import { SubSink } from "subsink";
import { FreyaMutateService } from "src/app/services/freya-mutate.service";
import { FullJobFragmentWithFields, jobToolFeature } from "../../job-tool.reducer";
import { withLatestFrom } from "rxjs";


@Component({
  selector: 'app-financials-table-v2',
  standalone: true,
  imports: [
    SharedModule,
    FreyaCommonModule,
  ],
  templateUrl: './financials-table-v2.component.html',
  styleUrl: './financials-table-v2.component.scss',
})
export class FinancialsTableV2Component implements OnInit, OnDestroy {

  @Input() eventId: string;

  job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);
  customer$ = this.store.select(jobToolFeature.selectCustomer);

  job: EstimatesJobFragment;
  customer: FullJobFragmentWithFields['users'][number]['user'];
  event: CalendarEventWithLockedAndInvoicedFlag;
  invoice: Invoice;
  showConfidentialInfo = false;

  constructor(
    public store: Store,
    public mutateService: FreyaMutateService,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {
    this.subs.sink = this.job$.pipe(
      withLatestFrom(this.customer$)
    ).subscribe(([job, customer]) => {
      if (job) {
        this.job = calculateJobTotals(job);
      }

      this.customer = customer;
    });

    this.subs.sink = this.store
      .select(workOrdersSelectors.selectEventById(this.eventId))
      .pipe(withLatestFrom(this.store.select(workOrdersSelectors.selectEventInvoice(this.eventId))))
      .subscribe(([event, invoice]: [CalendarEventWithLockedAndInvoicedFlag, Invoice]) => {
        this.event = event;
        this.invoice = invoice;
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onPayButtonClick() {

    this.mutateService.openMutateObject({
      objectType: 'transaction',
      mutateType: 'create',
      additionalValues: [
        {
          property: 'job',
          value: this.job,
        },
        {
          property: 'user',
          value: this.customer,
        },
        ...(this.invoice
          ? [{ property: 'invoice', value: this.invoice }]
          : []),
      ],
    });
  }

  getJobGrossProfitMargin(data: any): number {
    if (!data || !data.discountedSubTotal || !data.expenseTotal) {
      return 0;
    }
    return ((data.discountedSubTotal - data.expenseTotal) / data.discountedSubTotal) * 100;
  }
}