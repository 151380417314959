<div>
  <div class="label-edit-container">
    <div>
      <label for="customer">{{ this.label }}</label>
    </div>
  </div>
  <ng-container *ngIf="viewModel$ | async as viewModel">
    <p-autoComplete
      *ngIf="!viewModel.isCustomerEditMode"
      [tabindex]="tabIndex"
      [(ngModel)]="value"
      (completeMethod)="search($event)"
      (onClear)="onClearField()"
      (onSelect)="onSelect($event)"
      (onFocus)="onFocus()"
      (onBlur)="onBlur()"
      [optionLabel]="field"
      [disabled]="!viewModel.isCustomerEditMode && isExistingCustomer"
      [pTooltip]="isExistingCustomer
      ? 'Existing customer is selected. To proceed with editing click Edit Customer'
      : null"
      [suggestions]="viewModel.suggestedCustomers"
      [placeholder]="placeholder"
      [style]="{ width: '100%' }"
      [inputStyle]="{ width: '100%', 'margin-top': '0.5rem' }"
      [ngClass]="{
      'ng-invalid': validationErrors[field],
      'readonly-cursor': !viewModel.isCustomerEditMode && isExistingCustomer
      }"
      >
      <ng-template
        let-customer
        pTemplate="item"
        >
        <div class="customer-item">
          <div class="customer-field name">
            {{ customer.givenName }} {{ customer.familyName }}
          </div>
        <div class="customer-field email" *ngIf="viewModel.activeColumns.email">{{ customer.email }}</div>
        <div class="customer-field phone" *ngIf="viewModel.activeColumns.phone">{{ customer.phone }}</div>
        <div class="customer-field company" *ngIf="viewModel.activeColumns.company">{{ customer.company }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
    <input
    *ngIf="viewModel.isCustomerEditMode"
    type="text"
    pInputText
    [tabindex]="tabIndex"
    [(ngModel)]="value[field]"
    (ngModelChange)="edit($event)"
    [placeholder]="placeholder"
    [style]="{ width: '100%', 'margin-top': '0.5rem' }"
    [ngClass]="{
    'ng-invalid': validationErrors[field],
    'readonly-cursor': !viewModel.isCustomerEditMode && isExistingCustomer
    }"
    >
    <small>{{ validationErrors[field] }}</small>
  </ng-container>
</div>
