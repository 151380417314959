import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { quickAccessFeature } from '../base/menu/quick-access/quick-access-reducers';
import * as quickAccessEffects from '../base/menu/quick-access/quick-access.effects';
import { authFeature } from '../core/auth/auth.reducer';

import * as scheduleEffects from '../schedules/store/schedule.effects';
import { scheduleFeature } from '../schedules/store/schedules.reducer';


import { brandingFeature } from './branding.store';
import { featureFlagsFeature } from './featureFlags.store';

export const rootState = [
	provideState(authFeature),
	provideState(brandingFeature),
	provideState(featureFlagsFeature),
	provideState(scheduleFeature),
	provideEffects(scheduleEffects),

	provideState(quickAccessFeature),
	provideEffects(quickAccessEffects),
];
