
import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Renderer2, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';

import { JOB_CREATE_INDEXES } from 'src/app/global.constants';
import { BrandingService } from 'src/app/services/branding.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubSink } from 'subsink';

import { Modes, jobToolFeature } from '../job-tool.reducer';
import { JobEditActions } from '../jobv2-edit/jobv2-edit-state/jobv2-edit.actions';

import { DiscardJobComponent } from './discard-job-component/discard-job.component';
import { Jobv2CreateCustomerComponent } from './jobv2-create-customer/jobv2-create-customer.component';
import { Jobv2CreateHeaderComponent } from './jobv2-create-header/jobv2-create-header.component';
import { Jobv2InventoryComponent } from './jobv2-create-inventory/jobv2-create-inventory.component';
import { Jobv2LocationsComponent } from './jobv2-create-locations/jobv2-create-locations.component';
import { JobCreateActions } from './jobv2-create-state/jobv2-create.actions';
import { Jobv2SummaryComponent } from './jobv2-summary/jobv2-summary.component';
import { Jobv2TimelineAvailabilityComponent } from './jobv2-timeline-availability/jobv2-timeline-availability.component';

@Component({
    selector: 'app-jobv2-create',
    standalone: true,
    imports: [
      FreyaCommonModule,
      Jobv2CreateCustomerComponent,
      Jobv2LocationsComponent,
      Jobv2InventoryComponent,
      Jobv2TimelineAvailabilityComponent,
      Jobv2SummaryComponent,
      Jobv2CreateHeaderComponent,
      SharedModule,
    ],
    templateUrl: './jobv2-create.component.html',
    styleUrls: ['./jobv2-create.component.scss']
  })
  export class Jobv2CreateComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('locationsComponent') locationsComponent!: Jobv2LocationsComponent;
    @ViewChild('inventoryComponent') inventoryComponent!: Jobv2InventoryComponent;
    @ViewChild('timelineComponent') timelineComponent!: Jobv2TimelineAvailabilityComponent;
    @ViewChild('summaryComponent') summaryComponent!: Jobv2SummaryComponent;

    @ViewChild('startLocation') startLocationInput: ElementRef<HTMLInputElement>;
    @ViewChild('addRoomBtn') addRoomBtn: ElementRef<HTMLInputElement>;
    @ViewChild('timelineMovingDate') timelineMovingDateInput: any;
    @ViewChild('jobCreatePageHeader') jobCreatePageHeader!: ElementRef;

    @HostListener('window:keydown.control.r', ['$event'])
      onControlR(event: KeyboardEvent) {
        event.preventDefault();
          if (this.discardBtnAvailable) {
            this.openDiscardDialog();
          }
    }

    subs = new SubSink();

    jobCreateTabIndexes = JOB_CREATE_INDEXES;
    currentZoneId: string;

    discardBtnAvailable = false;

    mode: Modes;
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);

    constructor(
      private store: Store,
      private brandingService: BrandingService,
      private dialogService: DialogService,
      private localNotify: FreyaNotificationsService,
      private renderer: Renderer2,
      private elRef: ElementRef,
      private route: ActivatedRoute,
      private router: Router,
    ) { }

    ngOnInit(): void {

      this.route?.paramMap?.subscribe(params => {
        const jobId = params?.get('jobId');
        if (jobId) {
            this.store.dispatch(JobEditActions.editJobToolOpened({ jobId }));
        } else {
          this.store.dispatch(JobCreateActions.createJobToolOpened());
        }
    });

      this.store.dispatch(JobCreateActions.initCreateJobTool());

      this.subs.sink = this.brandingService.currentZone().subscribe((zone) => {
        if (!isUndefined(this.currentZoneId) && (this.currentZoneId !== zone.id)) {
          this.store.dispatch(JobCreateActions.changeZone());
          this.localNotify.addToast.next({
            severity: 'warn', summary: 'Referral source, locations and start time are reset after changing zone.'
          });
        }
        this.currentZoneId = zone.id;
      });

      this.subs.sink = this.jobFormMode$
        .subscribe((jobFormMode) => {
          this.mode = jobFormMode;
      });

      this.subs.sink = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe((event: NavigationEnd) => {
        // Ensure focus is set to the appropriate element after navigation
        setTimeout(() => {
            if (this.jobCreatePageHeader && this.jobCreatePageHeader.nativeElement) {
                const element = this.jobCreatePageHeader.nativeElement;
                element.focus();
            }
        }, 500);
    });
    }

    ngAfterViewInit() {
      this.initTabNavigation();
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }

    initTabNavigation() {

      this.discardBtnAvailable = !!this.elRef.nativeElement.querySelector('.discard-btn-container button');

      this.renderer.listen('document', 'keydown', (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          const activeElement = document.activeElement as HTMLElement;
          const tabindex = parseInt(activeElement.getAttribute('tabindex') || '0', 10);

          if (tabindex === this.jobCreateTabIndexes['joOrigin']) {
            if (this.locationsComponent) {
              this.locationsComponent.collapseCard();

              setTimeout(() => {
                if (this.locationsComponent.startLocationInput) {
                  this.locationsComponent.startLocationInput.nativeElement.focus();
                }
              }, 0);
            }
          }

          if (tabindex === this.jobCreateTabIndexes['addStop']) {
            if (this.inventoryComponent) {
              this.inventoryComponent.collapseCard();
            }

            setTimeout(() => {
              if (this.inventoryComponent.addRoomBtn) {
                this.inventoryComponent.addRoomBtn.nativeElement.focus();
              }
            }, 0);
          }

          if (tabindex === this.jobCreateTabIndexes['addRoom']) {
            if (this.timelineComponent) {
              this.timelineComponent.collapseCard();
            }

            setTimeout(() => {
              if (this.timelineComponent.timelineMovingDateInput) {
                this.timelineComponent.timelineMovingDateInput.inputfieldViewChild.nativeElement.focus();
              }
            }, 0);
          }
        }
      });
    }

    openDiscardDialog() {
      return this.dialogService.open(DiscardJobComponent, {
        header: 'Are you sure you want to discard this opportunity?',
        width: '46rem',
      });
    }
  }
