import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { SharedModule } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DocumentHelperService } from '../../services/document-helper.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import * as uploadFileComponent from '../../shared/upload-file/upload-file.component';
import { JobEstimateComponent } from '../job-estimate/job-estimate.component';
import { FullJobFragmentWithFields, jobToolFeature } from '../job-tool.reducer';

@Component({
  selector: 'app-job-files',
  standalone: true,
  imports: [
    CommonModule,
    FreyaCommonModule,
    SharedModule,
    JobEstimateComponent,
  ],
  templateUrl: './job-files.component.html',
  styleUrls: [
    './job-files.component.scss',
    '../job-financials/document-container-style.scss',
  ],
})
export class JobFilesComponent implements OnInit {

  documents$ = this.store.select(jobToolFeature.selectDocuments);

  documentsEmpty$ = this.store.select(createSelector(
    jobToolFeature.selectDocuments,
    jobToolFeature.jobLoaded,
    (documents, jobLoaded) => jobLoaded && documents.length === 0,
  ))

  jobLoading$ = this.store.select(jobToolFeature.jobLoading);

  job$ = this.store.select(jobToolFeature.selectJob);

  job: FullJobFragmentWithFields;

  constructor(
    private store: Store,
    private documentHelper: DocumentHelperService,
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
  ) {}

  ngOnInit(): void {
    this.job$.subscribe((job) => {
      this.job = job;
    });
  }

  addDocument() {
    this.documentHelper.openDocumentsDialog({
      jobId: this.job.id,
      jobCode: this.job.code,
    });
  }

  uploadDocument() {
    this.dialogService.open(uploadFileComponent.UploadFileComponent,{
      header: 'Upload Document',
      data: { relatedObjects: [ this.job.id ], jobId: this.job.id },
      width: this.responsiveHelper.dialogWidth,
      contentStyle: {
        'padding-bottom': 0,
      },
    });
  }
}
