import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ListArtifactsGQL, SendDocumentGQL, ZoneDir } from 'graphql.generated';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { PandaDocService } from '../../features/public-api';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { DocumentStatus, SendableDocument } from '../send-documents/send-documents.component';
import { Store } from '@ngrx/store';
import { JobToolActions } from '../../jobsv2/job-tool.actions';

@Component({
  selector: 'app-sendable-document',
  templateUrl: './sendable-document.component.html',
  styleUrls: ['./sendable-document.component.scss']
})
export class SendableDocumentComponent implements OnInit, OnDestroy, OnChanges {

  @Input() document: SendableDocument;
  @Output() statusChanged = new EventEmitter<DocumentStatus>();
  subs = new SubSink();

  sendButtonDisabled = true;

  message = '';

  viewAction: MenuItem = {
    label: 'View',
    icon: 'pi pi-eye',
    command: () => this.freyaHelper.openInDialog(this.document.artifact)
  };

  downloadAction: MenuItem = {
    label: 'Download',
    icon: 'pi pi-download',
    command: () => this.downloadDocument()
  };

  signAsEmployee: MenuItem = {
    label: 'Sign as Employee',
    icon: 'pi pi-pen',
    command: () => this.openInPandadocIframe('employee')
  };

  signAsCustomer: MenuItem = {
    label: 'Sign as Customer',
    icon: 'pi pi-pen',
    command: () => this.openInPandadocIframe('customer')
  };

  signAsDriver: MenuItem = {
    label: 'Sign as Driver',
    icon: 'pi pi-pen',
    command: () => this.openInPandadocIframe('driver'),
  };

  signAsFinalClient: MenuItem = {
    label: 'Sign as Customer (On Delivery)',
    icon: 'pi pi-pen',
    command: () => this.openInPandadocIframe('finalClient'),
  };

  actions: MenuItem[];

  constructor(
    public freyaHelper: FreyaHelperService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private sendDocumentGQL: SendDocumentGQL,
    private pandadocService: PandaDocService,
    private listArtifactsGQL: ListArtifactsGQL,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.setActions();
    this.setMessage();
    this.statusChanged.next(this.document.status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('document' in changes) {
      this.sendButtonDisabled = this.isSendButtonDisabled();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateDocumentStatus(newStatus: DocumentStatus) {
    this.document.status = newStatus;
    this.statusChanged.next(newStatus);
  }

  sendDocument() {
    const { artifact } = this.document;
    if (!artifact) { return; }

    const { status: currentStatus } = this.document;

    this.updateDocumentStatus('pending');

    const message = this.message?.trim().length ? this.message : undefined;

    this.sendDocumentGQL.mutate({
      artifactId: artifact.id,
      recipientRole: 'customer',
      message,
    }).subscribe(() => {
      this.updateDocumentStatus('sent');

      this.detailsHelper.pushUpdate({
        id: artifact.id,
        type: 'Artifacts',
        action: 'update'
      });

      if (artifact?.attributes?.includes('Estimate')) {
        this.store.dispatch(JobToolActions.sendEstimateSuccess({ estimate: artifact }));
      }

      this.localNotify.success('Document sent to customer');
    }, (err) => {
      this.updateDocumentStatus(currentStatus);

      this.localNotify.apolloError(`Failed to send document`,err);
      console.error(err);
    });
  }

  downloadDocument() {
    const { artifact } = this.document;
    this.freyaHelper.downloadFile(artifact.signedUrl || artifact.url, artifact.name);
  }

  openInPandadocIframe(role: 'employee' | 'customer' | 'driver' | 'finalClient') {
    const { artifact } = this.document;
    const initialStatus = this.document.status;

    this.updateDocumentStatus('pending');

    this.subs.sink = this.pandadocService.getDocumentLink({ artifactId: artifact.id, role })
      .subscribe((res) => {
        this.updateDocumentStatus(initialStatus);
        this.freyaHelper.openInDialog(artifact, res.data.getPandadocDocumentLink);
      }, (err) => {
        this.updateDocumentStatus(initialStatus);
        this.localNotify.apolloError(`Could not open document`,err);
        console.error(err);
      });
  }

  setActions() {
    let availableActions = [ this.viewAction, this.downloadAction ];

    if (this.isSignable) {
      availableActions = [ ...availableActions, this.signAsEmployee, this.signAsCustomer ];

      if (this.document.artifact.attributes.includes('Bill of Lading')){
        availableActions = [ ... availableActions, this.signAsDriver, this.signAsFinalClient];
      }
    }

    this.actions = availableActions;
  }

  get isSignable() {
    return this.document.artifact?.attributes?.includes('signable');
  }

  /**
   * Checks the artifact's metadata for a message that was sent along with the document.
   * When you first send a document, the artifact won't have the message you sent in its metadata,
   * as the parent component *does not* automatically refetch when a document is sent.
   * For that reason, if we cannot find a message, we need to fetch the artifact to make sure we have the latest data.
   */
  async setMessage() {
    // Documents in draft or pending status have no message as they haven't been sent yet
    if (this.document.status !== 'sent') { return; }

    // No way to resolve the message if the document is somehow missing an associated artifact
    if (!this.document.artifact) { return; }

    const existingMessage = this.document.artifact?.metadata?.message;

    if (existingMessage) {
      this.message = existingMessage;
      return;
    }

    // If we couldn't find a message, refetch to make sure we are working with the latest data
    const { data: {
      artifacts: {
        artifacts: [ artifact ],
      },
    }} = await this.listArtifactsGQL.fetch({
      filter: {
        ids: [ this.document.artifact.id ],
        zoneDir: ZoneDir.Any,
      },
      getPrivate: true,
    }).toPromise();

    this.message = artifact?.metadata?.message;
  }

  get hasCustomerEmail() {
    const placeholderEmail = this.document.artifact?.metadata?.placeholderEmail;
    return !placeholderEmail;
  }

  isSendButtonDisabled() {
    if (this.document.status === 'pending') {
      return true;
    }

    if (this.document.artifact?.metadata?.placeholderEmail) {
      return true;
    }

    return false;
  }

}
