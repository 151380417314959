import { AddJobDiscount, Charge, CreateCalendarEventInput, CreateChargeInput, CreateDiscountInput, Discount, DiscountsQueryVariables, EstimatesJobFragment, FullProductFragment, Job_ZoneWithParentAndTimezoneFragment, ListProductsForEstimatingQueryVariables, NewChargesInput, Price, Product, SetFieldValueInput, Tag, ZoneDir } from 'graphql.generated';

import { FIELD_CONFIG, JobEventStatus, eventTypeInfoMapV2 } from '../global.constants';

import { FullJobFragmentWithFields } from './job-tool.reducer';
import {
  CalendarEventWithLockedAndInvoicedFlag,
  ChargesUpdate,
} from './jobv2-create/jobv2-interfaces';
import { compact, filter, flatMap, includes, map, minBy } from 'lodash';

export function addChargeSubtotals(charges: Charge[]): number {
  if (!charges || !charges.length) {
    return 0;
  }

  let total = 0;
  for (const charge of charges) {
    total += getChargeSubtotal(charge);
  }

  return total;
}

export function getChargeSubtotal(
  charge: Charge,
  quantity: number = charge.quantity,
  product: Product = charge.product
): number {
  if (charge.chargeSubTotal !== undefined) {
    return charge.chargeSubTotal;
  }

  if (charge.price) {
    return charge.price.amount * quantity;
  } else if (charge.amount) {
    return charge.amount * quantity;
  }

  if (!quantity || !product || !product?.prices) {
    return 0;
  }

  return product.prices[0].amount * quantity;
}

export function getDisabledStatus(
  event: CalendarEventWithLockedAndInvoicedFlag,
  job: EstimatesJobFragment
): DisabledStatus {
  let disabledStatus = false;
  let disabledToolTip = '';
  let disabledWarning = '';

  if (job?.zone?.type !== 'area') {
    disabledStatus = true;
    disabledToolTip = 'Your job needs to be in an area to apply charges.';
    disabledWarning = disabledToolTip;
  }

  if (event?.locked) {
    disabledStatus = true;
    disabledToolTip = 'This event is locked.';
    disabledWarning = `This event takes place before the lock date, so it cannot be modified.
    Please create a new event if you need to charge the customer additional charges.`;
  }

  if (event?.invoiced) {
    disabledStatus = true;
    disabledToolTip = `This event has been invoiced and cannot be modified.
     Please void the invoice or create a new event if you need to charge the customer additional charges.`;
    disabledWarning = disabledToolTip;
  }

  return {
    disabledStatus,
    disabledToolTip,
    disabledWarning,
  };
}

export const handlePercentageQuantity = (input: number | boolean): number => {
  if (typeof input === 'number') {
    return input;
  } else if (typeof input === 'boolean') {
    return input ? 1 : 0;
  }
};

export function trackWorkordersChanges(
  changes: ChargesUpdate[],
  change: ChargesUpdate
) {
  const updatedChanges = [change, ...changes];
  return updatedChanges;
}

//when products are being selected in modal, keep only one latest amount
//when product was already submitted twice, store two separate records
export function trackAddingCharges(
  changes: ChargesUpdate[],
  change: ChargesUpdate
) {
  const updatedChanges = [
    change,
    ...changes.filter(
      (existingChange) =>
        existingChange.productId !== change.productId ||
        !(existingChange.submitted === false && change.submitted === false)
    ),
  ];
  return updatedChanges;
}

export function trackUpdatingDiscounts(
  changes: ChargesUpdate[],
  change: ChargesUpdate): ChargesUpdate[] {
    return [...changes, change];
}

export function trackDuplicatingEvents(
  changes: ChargesUpdate[],
  change: ChargesUpdate): ChargesUpdate[] {
    return [...changes, change];
}

export function trackUpdatingEventStatus(
  changes: ChargesUpdate[],
  change: ChargesUpdate): ChargesUpdate[] {
    //if event cancelled or removed, filter out all changes related to that event
    const filteredChanges = changes
      ?.filter(c => c?.eventId !== change?.eventId)
      ?.map(c => ({
        ...c,
        eventsWithNewOrder: c?.eventsWithNewOrder?.filter(e => e.eventId !== change?.eventId)
      }));
    return [...filteredChanges, change];
}

export function trackModifyingCharges(
  changes: ChargesUpdate[],
  change: ChargesUpdate
): ChargesUpdate[] {
  const existingChange = changes.find(existing => existing.chargeId === change.chargeId);

  const mergedChange = existingChange
    ? {
        ...existingChange,
        ...change,
      }
    : change;

  const updatedChanges = [
    mergedChange,
    ...changes.filter(existing => existing.chargeId !== change.chargeId),
  ];

  return updatedChanges;
}

export function trackModifyingUnsavedCharges(
  changes: ChargesUpdate[],
  change: ChargesUpdate
): ChargesUpdate[] {
  const existingChange = changes.find(existing =>
    existing.changeType === 'unsaved-charge-updated' && existing.chargeId === change.chargeId
  );

  const mergedChange = existingChange
    ? {
        ...existingChange,
        ...change,
      }
    : change;

    const changesWithMerged = [
      mergedChange,
      ...changes,
    ];

    const filteredChanges = changesWithMerged.filter((item, index, self) =>
      item.changeType !== 'unsaved-charge-updated' ||
      self.findIndex(
        other => other.changeType === 'unsaved-charge-updated' && other.chargeId === item.chargeId
      ) === index
    );

    return filteredChanges;
}

export function trackEventsReordering(
  changes: ChargesUpdate[],
  change: ChargesUpdate
): ChargesUpdate[] {
  const filteredChanges = changes.filter(existing => existing.changeType !== 'events-reordered');

  const updatedChanges = [
    ...filteredChanges,
    change
  ];

  return updatedChanges;
}

interface DisabledStatus {
  disabledStatus: boolean;
  disabledToolTip: string;
  disabledWarning: string;
}

//already created charges that should be updated
export function getExistingChargesWithUnsavedChanges(
  chargesUpdates: ChargesUpdate[], job: FullJobFragmentWithFields) {
  const existingChargesNeedUpdate = [];

  const unsavedChanges = chargesUpdates?.filter(
    update => update?.changeType === 'charge-updated' && !update?.submitted
  );

  if (unsavedChanges?.length) {
    unsavedChanges.forEach(chargeUpdate => {

      existingChargesNeedUpdate.push({
        eventId: chargeUpdate.eventId,
        id: chargeUpdate.chargeId,
        ...(chargeUpdate.quantity !== undefined ? { quantity: chargeUpdate.quantity } : {}),
        ...(chargeUpdate.amount !== undefined ? { amount: chargeUpdate.amount } : {}),
        ...(chargeUpdate.order !== undefined ? { order: chargeUpdate.order } : {}),
      });
    });
  }

  return existingChargesNeedUpdate;
}

export const generateUpdateChargesInput = (charges: any[]) => {
  const chargesToUpdate = charges.map(chargeUpdate => {
    return {
      eventId: chargeUpdate.eventId,
      id: chargeUpdate.id,
      ...(chargeUpdate.quantity !== undefined ? { quantity: chargeUpdate.quantity } : {}),
      ...(chargeUpdate.amount !== undefined ? { amount: chargeUpdate.amount } : {}),
      ...(chargeUpdate.order !== undefined ? { order: chargeUpdate.order } : {}),
    };
  });

  return { charges: chargesToUpdate };
};

export const generateAddDiscountsInput = (chargesUpdates: ChargesUpdate[]) => {
  const newDiscountsToCreate = chargesUpdates
  ?.filter(u => u?.changeType === 'discount-added' && u?.discountInput?.singleUse)
  .reduce((acc, u) => {
    const { appliedId, appliedAt, singleUse, ...restDiscountInput } = u?.discountInput || {};

    // Ensure the required properties are included in restDiscountInput
    if (!restDiscountInput.amount || !restDiscountInput.code || !restDiscountInput.discountType || !restDiscountInput.name) {
      throw new Error('Missing required discount fields');
    }

    // Get the eventId for the current update and add it to the accumulator
    if (!acc.eventsIds) acc.eventsIds = [];
    const currentEventId = u?.eventId;
    if (currentEventId && !acc.eventsIds.includes(currentEventId)) {
      acc.eventsIds.push(currentEventId);
    }

    // Update the discountInput
    acc.discountInput = {
      ...restDiscountInput,
    } as CreateDiscountInput;

    return acc;
  }, { eventsIds: [], discountInput: {} as CreateDiscountInput });

  const existingDiscountsToAdd: AddJobDiscount[] = chargesUpdates
    ?.filter(u => u?.changeType === 'discount-added' && !u?.discountInput?.singleUse)
    .map(u => {
      const discount = {
        discountId: u?.discountId,
        eventId: u?.eventId,
        ...(u?.discountInput?.customAmount !== undefined
          ? { customAmount: u.discountInput.customAmount as number }
          : {}),
      };

      return discount;
    });

  return {
    existingDiscountsToAdd,
    newDiscountsToCreate,
  };
};

export const generateRemoveEventsInput = (chargesUpdates: ChargesUpdate[]): string[] => {
  const eventsDeletedUpdates = chargesUpdates?.filter(
    update => update?.changeType === 'event-deleted'
  );

  const deletedEventsIds = eventsDeletedUpdates?.map(c => c?.eventId);

  return deletedEventsIds;
}

export const generateFieldsInput = (chargesUpdates: ChargesUpdate[]): SetFieldValueInput[] => {
  const inventoryUpdatedChanges = chargesUpdates?.filter(
    update => update?.changeType === 'inventory-updated'
  );

  if (!inventoryUpdatedChanges?.length) {
    return [];
  }

  const change = inventoryUpdatedChanges[inventoryUpdatedChanges.length - 1];
  
  return [{
    fieldName: FIELD_CONFIG.jobs.inventory.name,
    value: JSON.stringify(change.inventory),
  }];
};


export const generateCreateEventsInput = (chargesUpdates: ChargesUpdate[]): CreateCalendarEventInput[] => {
  const eventAddedUpdatesChanges = chargesUpdates?.filter(
    update => update?.changeType === 'event-added'
  );

  const eventDuplicatedUpdatesChanges = chargesUpdates?.filter(
    update => update?.changeType === 'event-duplicated'
  );

  const createEventsInput = eventAddedUpdatesChanges?.map(c => {
    const { jobId, ...restOfEventInput } = c.eventInput || {};
    return {
      temporaryId: c.eventId,
      ...restOfEventInput,
    };
  });

  const createDuplicatedEventsInput = eventDuplicatedUpdatesChanges?.map(c => {;
    return {
      temporaryId: c.eventInput?.id,
      title: c?.eventInput?.title,
      type: c?.eventInput?.type,
      sequentialOrder: c?.eventInput?.sequentialOrder,
      status: c?.eventInput?.status,
    };
  });

  const allEventsInput = [...createEventsInput, ...createDuplicatedEventsInput];

  return allEventsInput as CreateCalendarEventInput[];
};

export const generateEditEventsInput = (chargesUpdates: ChargesUpdate[]) => {
  const eventsEdited = chargesUpdates?.filter(
    update => update?.changeType === 'events-reordered' || update?.changeType === 'event-cancelled'
  );

  const edits = eventsEdited?.reduce((acc, update) => {
    if (update.changeType === 'events-reordered' && update.eventsWithNewOrder) {
      const reorderedEdits = update.eventsWithNewOrder.map(e => ({
        id: e.eventId,
        edit: { sequentialOrder: e.newOrder },
      }));
      acc.push(...reorderedEdits);
    } else if (update.changeType === 'event-cancelled' && update.eventId) {
      acc.push({
        id: update.eventId,
        edit: { status: 'cancelled' },
      });
    }
    return acc;
  }, [] as { id: string; edit: { sequentialOrder?: number; status?: string } }[]);

  return { edits };
};

//not created yet charges with latest updates for them
export const getUnsavedChargesWithUpdates = (chargesUpdates: ChargesUpdate[]): ChargesUpdate[] => {
  return chargesUpdates
    ?.filter(update =>
      !update?.removed &&
      update.changeType === 'unsaved-charge-updated' ||
      (update.changeType === 'product-selected-for-adding' && update?.submitted)
    )
    .reduce((acc, current) => {
      const existingIndex = acc.findIndex(
        item => item.chargeId === current.chargeId
      );

      if (existingIndex !== -1) {
        const existingChange = acc[existingIndex];

        // Determine the merged properties based on priority rules
        const mergedChange = {
          ...existingChange,
          ...current,
          ...(existingChange.changeType === 'unsaved-charge-updated'
            ? {
                amount: existingChange.amount ?? current.amount,
                quantity: existingChange.quantity ?? current.quantity,
                order: existingChange.order ?? current.order,
              }
            : {}),
        };

        acc[existingIndex] = mergedChange;
      } else {
        acc.push(current);
      }

      return acc;
    }, []);
};

export const generateRemoveChargesInput = (chargesUpdates: ChargesUpdate[]): string[] =>
  chargesUpdates
    ?.filter(u => u.removed && u.changeType === 'charge-updated')
    .map(u => u.chargeId) || [];

export const generateRemoveDiscountsInput = (chargesUpdates: ChargesUpdate[]): string[] =>
  chargesUpdates
    ?.filter(u => u.changeType === 'discount-removed')
    .map(u => u.discountInput?.appliedId as string) || [];

export const generateNewChargesInput = (
  userId: string,
  products: FullProductFragment[],
  changes: ChargesUpdate[],
  currency: string,
): NewChargesInput =>
  {
    const chargesToAdd = changes.map(chargeUpdate => {
        const matchingProduct = products.find(product => product.id === chargeUpdate.productId);

        return {
          eventId: chargeUpdate.eventId,
          productId: chargeUpdate.productId,
          productName: chargeUpdate.productName,
          quantity: chargeUpdate.quantity,
          amount: chargeUpdate.amount,
          attributes: matchingProduct?.attributes || [],
          ...( chargeUpdate?.order && { order: chargeUpdate?.order }),
          ...( chargeUpdate?.taxIds && { taxIds: chargeUpdate?.taxIds }),
          //add currency to the query input only when create custom charge, not connected to product
          ...(chargeUpdate.productName !== undefined ? { currency: currency } : {}),
        } as CreateChargeInput;
      });

      return  {
        charges: chargesToAdd,
        userId: userId,
      };
}

export function removeUnsavedChangesForCancelledRemovedEvent(
  chargesUpdates: ChargesUpdate[],
  eventId: string) {
    const filteredChanges = chargesUpdates?.filter(change => change?.eventId !== eventId);

    return filteredChanges;
}

export function calculateChargeOffset(
  eventId: string,
  eventType: string,
  allEvents: Partial<CalendarEventWithLockedAndInvoicedFlag>[]
) {
  let eventTypeOffset: number;
  if (eventTypeInfoMapV2[eventType]) {
    eventTypeOffset = eventTypeInfoMapV2[eventType].order;
  } else {
    eventTypeOffset = Object.keys(eventTypeInfoMapV2).length + 1;
  }

  const eventTypeBase = 1000;
  eventTypeOffset *= eventTypeBase;
  let offset = eventTypeOffset;

  const sameTypeEvents = allEvents?.filter((e) => e.type === eventType);
  sameTypeEvents.sort((a, b) => {
    const aValue = a.start || a.createdAt;
    const bValue = b.start || b.createdAt;
    return aValue - bValue;
  });

  const eventBase = 100;
  const event = sameTypeEvents.find((e) => e.id === eventId);
  const eventOffset = (sameTypeEvents.indexOf(event) + 1) * eventBase;
  offset += eventOffset + 50;

  return offset;
}

export function setOrderToIndexV2(array: any[], offset = 0) {
  return array.map((item, index) => ({
      ...item,
      order: index + offset
  }));
};

export const getEditableAmount = (priceType: string, editableAmount: number) => {
  return priceType === 'percentage' ? editableAmount : Math.round(editableAmount * 100);
}

export function getDiscountFromUpdates(
  matchingDiscount: Partial<Discount>,
  change: ChargesUpdate,
  singleUse: boolean) {
  if (matchingDiscount && !singleUse) {
    return matchingDiscount;
  } else if (!matchingDiscount && singleUse) {
    return {
      ...change?.discountInput,
    };
  } else {
    return {};
  }
}

export const deletableEventStatuses: JobEventStatus[] = [
  'required',
  'cancelled',
];

export enum EventReordered {
  up = 'up',
  down = 'down',
  to_top = 'to_top',
  to_bottom = 'to_bottom',
}

export function isJobTaxExempt(jobTags: Partial<Tag>[]): boolean {
  return jobTags.some(tag => tag.attributes?.includes('tax-exempt'));
}

export const generateListAvailableProductsQueryVariables = (zoneId: string) => {
  const listProductQueryVariables: ListProductsForEstimatingQueryVariables = {
    limit: 200,
    skip: 0,
    filter: {
      hasActivePrice: true,
      zoneDir: ZoneDir.Any,
    },
    sort: 'name:ASC',
    pricesFilter: {
      isActive: true,
    },
  };

  const headers = {
    context: {
      headers: {
        'x-zone': zoneId,
      },
    },
  };

  return {
    listProductQueryVariables,
    headers
  }
}

export const generateListDiscountsQueryVariables = (zoneId: string) => {
  const listDiscountsQueryVariables: DiscountsQueryVariables = {
    filter: {
      applyable: true,
      singleUse: false,
    },
    limit: -1,
  };

  const headers = {
    context: {
      headers: {
        'x-zone': zoneId,
      },
    },
  };

  return {
    listDiscountsQueryVariables,
    headers
  }
}

export function filterOutProductsWithInactivePrices(products: FullProductFragment[]): FullProductFragment[] {
  return products.filter((p) => p.prices?.length && p.prices.find((price) => price.active));
}

export function sortProductsByCategory(products: FullProductFragment[]): FullProductFragment[] {
  return products.sort((prodA, prodB) => {
    if (!prodA.category) return 1;
    if (!prodB.category) return -1;
    return prodA.category.localeCompare(prodB.category);
  });
}

export function getProductsWithClosestPricesByJobZone(
  products: FullProductFragment[],
  jobZone: Job_ZoneWithParentAndTimezoneFragment['zone']
): FullProductFragment[] {
  // Extract jobZone and all parent zone IDs
  const jobZoneAndAllParentsIds = compact([
    jobZone?.id,
    ...flatMap(jobZone?.parents, parent => parent?.id)
  ]);

  // Filter out prices from zones lower than job zone
  const productsWithGTEPrices = map(products, (product: Product) => ({
    ...product,
    prices: filter(product.prices, (price: Price) => includes(jobZoneAndAllParentsIds, price.zone.id))
  })).filter(product => product.prices.length > 0);

  // Assign proximity and keep only the lowest proximity price
  const productsWithLowestProximityPrice = map(productsWithGTEPrices, (product: Product) => ({
    ...product,
    prices: [minBy(product.prices, price => {
      const parentIndex = jobZoneAndAllParentsIds.findIndex(id => id === price.zone.id);
      return parentIndex !== -1 ? parentIndex : Infinity;
    })]
  })).filter(product => product.prices.length > 0);

  return productsWithLowestProximityPrice;
}