import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import {
    BulkEditCalendarEventGQL,
    BulkEditCalendarEventMutationVariables, CalendarEvent, SingleEditInput
} from '../../generated/graphql.generated';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { JobToolActions } from './job-tool.actions';


export const UpdateEvents = createEffect(
    (
        actions$ = inject(Actions),
        bulkEditCalendarEventGQL = inject(BulkEditCalendarEventGQL),
        notify = inject(FreyaNotificationsService)
    ) => {
        return actions$.pipe(
            ofType(JobToolActions.eventUpdateRequested),
            exhaustMap(({ edits }) => {

                const createEditInput = (editObject): SingleEditInput => {

                    const singleEdit = editObject.edit;
                    const { addAttendees = [], removeAttendees = [] } = singleEdit?.setAttendees || {};
                    const { addAssets = [], removeAssets = [] } = singleEdit?.setAssets || {};
                    const { addLocations = [], editLocations = [], removeLocations = [] } = singleEdit?.setLocations || {};

                    return {
                        id: editObject.id,
                        edit: {
                            ...(singleEdit.type && { type: singleEdit.type }),
                            ...(singleEdit.title && { title: singleEdit.title }),
                            ...(singleEdit.status && { status: singleEdit.status }),

                            setAttendees: {
                                addAttendees,
                                removeAttendees,
                            },
                            setAssets: {
                                addAssets,
                                removeAssets,
                            },
                            setLocations: {
                                addLocations,
                                editLocations,
                                removeLocations,
                            }
                        },
                    };
                };

                const input: BulkEditCalendarEventMutationVariables = {
                    edits: edits.map(createEditInput),
                };

                return bulkEditCalendarEventGQL.mutate(input).pipe(
                    map((result) => {
                        const { total, events } = result.data?.bulkEditCalendarEvent;


                        if (total === edits.length) {
                            notify.success('Event updated successfully');
                            return JobToolActions.eventUpdateSuccess({
                                updatedEvents: events as CalendarEvent[],
                            });
                        } else {
                            const error = new Error('Error updating event');
                            notify.error('Error updating event');
                            console.error('Error updating event:', error);
                            return JobToolActions.eventUpdateError({
                                error,
                            });
                        }
                    }),
                    catchError((error) => {
                        notify.apolloError('Error updating job', error);
                        console.error('Error updating job:', error);
                        return of(
                            JobToolActions.eventUpdateError({
                                error,
                            })
                        );
                    })
                );
            })
        );
    },
    { functional: true, dispatch: true }
);

