import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of, Subject, switchMap, take } from 'rxjs';

import { Jobsv2Component } from './jobsv2.component';
import { LeaveJobsv2Component } from './leave-jobsv2-confirm/leave-jobsv2-confirm.component';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class LeaveJobsV2Guard {

  public constructor(
    public dialogService: DialogService
  ) {}

  canDeactivate(
    component: Jobsv2Component,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.hasUnsavedChanges$.pipe(
      take(1),
      switchMap((hasUnsavedChanges) => {

        if (!hasUnsavedChanges) {
          return of (true);
        }
        const sub = new Subject<boolean>();

        this.dialogService.open(LeaveJobsv2Component, {
          width: '46rem',
          data: { sub },
        });

        return sub.asObservable();
      }),
    );
  }
}
