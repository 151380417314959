import { on } from '@ngrx/store';

import { JOB_FORM_FIELDS } from 'src/app/global.constants';

import { JobSummaryActions } from '../../job-state/summary-state/summary.actions';
import { JobToolState } from '../../job-tool.reducer';

import { trackChanges } from '../../jobsv2-helpers';


export const jobSummaryReducers = [
    on(
        JobSummaryActions.updateSummary,
        (state: JobToolState, res): JobToolState => {
            const changes = trackChanges(state.changes, {
                fieldName: JOB_FORM_FIELDS[res.key],
                namespace: 'jobInput',
                value: {
                    text: res.text,
                    contents: res.contents,
                }
            }, true);

            const summaryUpdates = [ ...(state?.summaryUpdates || []) ];

            const matchingSummaryIndex = summaryUpdates.findIndex(s => s.key === res.key);

            if (matchingSummaryIndex > -1) {
                summaryUpdates[matchingSummaryIndex] = res;
            } else {
                summaryUpdates.push(res);
            }

            return {
                ...state,
                summaryUpdates,
                changes,
            };
        }
    ),
] as const;


