import { createFeature, createReducer, on } from '@ngrx/store';


import { cloneDeep } from 'lodash';

import { Mutable } from '../../../typescript';
import { DEFAULT_ROOM_OPTIONS } from '../../global.constants';
import { generateUUID } from '../../utilities/state.util';

import { InventoryActions } from './inventory.actions';
import { addCustomItemToRoom, addItemDefinitionToRoom, recalculateInventory } from './inventory.util';

export interface InventoryItemDefinition extends Omit<InventoryItem, 'id' | 'quantity'> {}

export interface InventoryRoom {
	id: string;

	name: string;
	packing?: any;
	level?: string;
	flights?: string;
	notes?: string;
	moverNotes?: string;
	notMoving?: string;

	totalVolume: number;
	totalWeight: number;
	totalItems: number;
	totalBoxes: number;

	inventory: InventoryItem[];
}

export interface InventoryItem {
	id: string;
	isBulky?: boolean;
	isValuable?: boolean;
	isSpecialEquipment?: boolean;
	is3rdPartyServicing?: boolean;
	isProGear?: boolean;
	isAssembleDisassemble?: boolean;
	isCarrierPack?: boolean;
	isoCode1?: string;
	isoCode2?: string;
	isoCode3?: string;
	reviewerName?: string;
	name: string;
	label?: string;
	packing?: string;
	quantity: number;

	volume?: number;
	weight?: number;
	totalVolume?: number;
	totalWeight?: number;

	generated?: boolean;
}

export interface InventoryItemOption {
	// reviewerName: string;
	label: string;
	value: string;
}

export const defaultInventory = {
	totalVolume: 0 as number,
	totalItems: 0 as number,
	totalWeight: 0 as number,

	totalBoxes: 0 as number,

	rooms: [] as InventoryRoom[],
	specialtyItems: [] as string[],

	weightUnit: 'lbs',
	volumeUnit: 'ft³',
} as const;

export type JobInventory = Mutable<typeof defaultInventory>;

export const inventoryInitialState = {
	...defaultInventory,

	changes: [] as { type: string }[],
	hasChanges: false as boolean,
	loading: false as boolean,
	// changes

	// old inventory notes system
	notes: undefined as string,

	search: '' as string,
	searchResults: [] as InventoryItemDefinition[],
	searchLoading: false as boolean,

	focus: undefined as string,
	addRoomDialogVisible: false as boolean,
	addRoomOptions: DEFAULT_ROOM_OPTIONS.map((name) => ({
		name,
		icon: '',
		exists: false,
		focus: false,
	})),

	editItemDialogVisible: false as boolean,
	editItem: undefined as InventoryItem,
	editItemRoom: undefined as string,

} as const;

export type InventoryState = typeof inventoryInitialState;

export const inventoryFeature = createFeature({
	name: 'inventory',
	reducer: createReducer(
		inventoryInitialState,
		on(InventoryActions.inventoryLoading, (state, {}): InventoryState => {

			return {
				...state,
				...defaultInventory,
				loading: true,
				notes: undefined,
				hasChanges: false,
				search: '',
				searchLoading: false,
				searchResults: [],
			};
		}),
		on(InventoryActions.inventoryLoaded, (state, { inventory, notes }): InventoryState => {

			const _inventory = recalculateInventory(
				inventory.rooms,
				inventory.weightUnit || defaultInventory.weightUnit,
				inventory.volumeUnit || defaultInventory.volumeUnit
			);

			// TODO: check if there is any change state we need to check

			return {
				...state,
				..._inventory,
				notes,
				loading: false,
				hasChanges: false,
				search: '',
				searchLoading: false,
				searchResults: [],
			};
		}),
		on(InventoryActions.inventoryChanged, (state, action): InventoryState => {

			const changes = [ ...state.changes, action ];
			return {
				...state,
				...action.inventory,
				focus: action.doFocus || undefined,
				hasChanges: changes.length > 0,
				changes,
			};
		}),
		on(InventoryActions.addRoomButtonClicked, (state, {}): InventoryState => {
			const addRoomOptions = cloneDeep(state.addRoomOptions);
			let focused = false;
			for (const room of addRoomOptions) {
				room.exists = Boolean(state.rooms.find((r) => r.name === room.name));

				// set the first item to be focused
				room.focus = !focused && !room.exists;
				focused = focused || room.focus;
			}
			
			return {
				...state,
				addRoomOptions,
				addRoomDialogVisible: true,
			}
		}),
		on(InventoryActions.addRoomDialogHidden, (state, action): InventoryState => {
			return {
				...state,
				addRoomDialogVisible: false,
			}
		}),
		on(InventoryActions.addRoomRoomSelected, (state, { room }): InventoryState => {
			// mark that a change occured somehow to jobs
			return {
				...state,
				rooms: [
					...state.rooms,
					{
						id: generateUUID(),
						name: room,
						inventory: [],
						totalItems: 0,
						totalVolume: 0,
						totalWeight: 0,
						totalBoxes: 0,

						// flights: undefined,
					}
				],
				addRoomDialogVisible: false,
			}
		}),
		on(InventoryActions.itemSearchCompleteRequest, (state, { search }): InventoryState => {
			return {
				...state,
				searchLoading: true,
				search,
				searchResults: [],
			};
		}),
		on(InventoryActions.itemSearchCompleteResult, (state, { items }): InventoryState => {
			return {
				...state,
				searchLoading: false,
				searchResults: items,
			};
		}),
		on(InventoryActions.itemSearchSelected, (state, { item, roomId }): InventoryState => {
			
			const rooms = addItemDefinitionToRoom(state.rooms, item, roomId);
			return {
				...state,
				rooms,
				search: '',
				searchLoading: false,
				searchResults: [],
			};
		}),
		on(InventoryActions.itemSearchSave, (state, { roomId }): InventoryState => {
			const rooms = addCustomItemToRoom(state.rooms, state.search, roomId);
			return {
				...state,
				rooms,
				search: '',
				searchLoading: false,
				searchResults: [],
			};
		}),
		on(InventoryActions.itemSearchEnterPressed, (state, { key, roomId }) => {
			if (key.key === 'Enter') {
				const firstItem = state.searchResults[0];
				if (firstItem) {
					const rooms = addItemDefinitionToRoom(state.rooms, firstItem, roomId);
					return {
						...state,
						rooms,
					};
				} else {
					const rooms = addCustomItemToRoom(state.rooms, state.search, roomId);
					return {
						...state,
						rooms,
					};
				}
			}

			return {
				...state,
			};
		}),
		on(InventoryActions.removeRoomClick, (state, { roomId }) => {
			const rooms = cloneDeep(state.rooms);
			const roomIndex = rooms.findIndex((r) => r.id === roomId);
			if (roomIndex >= 0) {
				rooms?.splice(roomIndex, 1);
			}

			return {
				...state,
				rooms,
			};
		}),
		on(InventoryActions.removeItemClick, (state, { item, roomId }) => {
			const rooms = cloneDeep(state.rooms);
			const room = rooms.find((r) => r.id === roomId);

			const itemIndex = room?.inventory?.findIndex((i) => i.id === item.id);
			if (itemIndex >= 0) {
				room?.inventory?.splice(itemIndex, 1);
			}

			return {
				...state,
				rooms,
			};
		}),
		on(InventoryActions.quantityUpdated, (state, { item, roomId, quantity }) => {
			const rooms = cloneDeep(state.rooms);
			const room = rooms.find((r) => r.id === roomId);

			const foundItem = room?.inventory?.find((i) => i.id === item.id);
			if (foundItem) {
				foundItem.quantity = quantity;
			}

			return {
				...state,
				rooms,
			};
		}),

		on(InventoryActions.editItemClick, (state, { item, roomId }): InventoryState => {
			return {
				...state,
				editItem: { ...item },
				editItemDialogVisible: true,
				editItemRoom: roomId,
			};
		}),

		on(InventoryActions.editItemDialogToggleVisible, (state, { visible }): InventoryState => {
			
			const editItemDialogVisible = visible === undefined ? !state.editItemDialogVisible : visible;
			
			return {
				...state,
				editItemDialogVisible,
				editItem: editItemDialogVisible ? state.editItem : undefined,
				editItemRoom: editItemDialogVisible ? state.editItemRoom : undefined,
			};
		}),
		on(InventoryActions.editItemUpdated, (state, { key, value }): InventoryState => {
			if (!state.editItem || !state.editItemRoom) { return state; }

			const item = {
				...state.editItem,
				[ key ]: value,
			};

			return {
				...state,
				editItem: item,
			};
		}),
		on(InventoryActions.editItemSaved, (state, {}): InventoryState => {
			if (!state.editItem || !state.editItemRoom) { return state; }

			const rooms = cloneDeep(state.rooms);
			const room = rooms.find((r) => r.id === state.editItemRoom);

			const foundItemIndex = room?.inventory?.findIndex((i) => i.id === state.editItem.id);
			if (foundItemIndex >= 0) {
				room.inventory.splice(foundItemIndex, 1, state.editItem);
			}

			return {
				...state,
				rooms,
				editItemDialogVisible: false,
				editItem: undefined,
				editItemRoom: undefined,
			};
		}),
		
	)
});

