<section class="job-documents">
    <div>
        <header class="p-my-2">
            <h2>Documents</h2>
            <div class="button-container">
              <p-button
                label="Create Document"
                icon="pi pi-plus"
                outlined="true"
                (onClick)="addDocument()"
                ></p-button>
              <p-button
                label="Upload Document"
                icon="pi pi-upload"
                (onClick)="uploadDocument()"
                outlined="true"
                ></p-button>
            </div>
        </header>
        <div class="document-container">
            <p-skeleton *ngIf="jobLoading$ | async"
                class="flex-1"
                borderRadius="0.571rem"
                width="100%"
                height="6.25rem"
                ></p-skeleton>
            <div class="empty-state" *ngIf="documentsEmpty$ | async">
              <i class="pi pi-file"></i>
              <span>No documents for this job.</span>
            </div>
            <app-job-estimate
              *ngFor="let document of documents$ | async"
              [estimate]="document"
              ></app-job-estimate>
        </div>
    </div>
  </section>
