/* eslint-disable @typescript-eslint/naming-convention */
import { ViewportScroller } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router, Scroll } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FeedbackService, GraphQLModule, PlusAuthenticationService, dayjs } from '@karve.it/core';

import { Store } from '@ngrx/store';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { concat, interval } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { SubSink } from 'subsink';


import { MenuService } from './base/menu/app.menu.service';
import { VERSION_UPDATE_POLLING_FREQUENCY_IN_MS } from './global.constants';
import { jobToolFeature } from './jobsv2/job-tool.reducer';
import { UnsavedChangesToastInfo } from './jobsv2/jobv2-create/jobv2-interfaces';
import { OnlineStatusService } from './online-status.service';
import { AppLoadingService } from './services/app-loading.service';
import { AvailableZonesService } from './services/available-zones.service';
import { BrandingService } from './services/branding.service';

import { DetailsHelperService } from './services/details-helper.service';
import { FreyaHelperService } from './services/freya-helper.service';
import { FreyaNotificationsService } from './services/freya-notifications.service';
import { PageTitleService } from './services/page-title.service';
import { TimezoneHelperService } from './services/timezone-helper.service';


import { DatadogService } from './src/app/services/datadog.service';


export interface ValidationItem {
  touched?: boolean;
  valid?: boolean;
  message?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  menuMode = 'sidebar';

  inputStyle = 'filled';

  ripple: boolean;

  mutateContainerEnabled = true;

  defaultToastlifetime = 3500;

  readyToTryAgain = true;

  subs = new SubSink();

  lastConnectionStatus: string;

  // PWA/Service Workers
  isOnline: boolean;
  isNewVersion: boolean;

  unsavedChangesReminders$ = this.store.select(jobToolFeature.selectUnsavedChangesReminders);
  unsavedChangesReminders: UnsavedChangesToastInfo[] = [];

  contactAccountingButtonText = 'Contact Accounting';

  private authCheckInterval;

  constructor(
    public store: Store,
    public onlineStatusService: OnlineStatusService,
    public plusAuth: PlusAuthenticationService,
    public appLoadingService: AppLoadingService,
    private primengConfig: PrimeNGConfig,
    private notifications: FreyaNotificationsService,
    private messages: MessageService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private menuService: MenuService,
    private brandingService: BrandingService,
    private pageTitleService: PageTitleService,
    private graphqlModule: GraphQLModule,
    private router: Router,
    private availableZones: AvailableZonesService,
    private datadogSvc: DatadogService,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private timeZoneHelper: TimezoneHelperService,
    private feedbackService: FeedbackService,
    private viewportScroller: ViewportScroller,
    private cd: ChangeDetectorRef,
  ) {
    this.isOnline = false;
    this.isNewVersion = false;

    router.events.pipe(filter((event): event is Scroll => event instanceof Scroll)).subscribe((event) => {

    });
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilityChanged() {
    this.freyaHelper.visibilityChanged.next(document.visibilityState === 'visible');
  }

  async ngOnInit() {
    /*this.subs.sink = this.unsavedChangesReminders$.subscribe((unsavedChangesReminders) => {
      if (unsavedChangesReminders?.length) {

        this.unsavedChangesReminders = cloneDeep(unsavedChangesReminders);

        unsavedChangesReminders.forEach((reminder) => {
          const jobCode = reminder.jobCode;
          const jobCustomer = reminder.jobCustomer;

          this.notifications.permanentReminder(
            `${jobCode} for ${jobCustomer} has unsaved changes | Visit the job to save your changes`
          );
        });
      }
    });*/

    this.onlineStatusService.isOnline$.subscribe(status => {
      this.isOnline = status;
    });

    await this.startAuthChecker();

    // PWA / ServiceWorkers
    this.watchForUpdates();

    this.datadogSvc.init();

    this.setDayjsLocale();
    this.timeZoneHelper.watchTimezone();
    this.primengConfig.ripple = true;

    this.subs.sink = this.notifications.addToast.subscribe((input) => {
      if (!input.life) { input.life = this.defaultToastlifetime; }
      this.messages.add(input);
    });

    this.subs.sink = this.notifications.clearToasts.subscribe(() => {
      this.messages.clear();
    });

    this.subs.sink = this.brandingService.currentZone().subscribe((currentZone) => {
      this.datadogSvc.identify();

      if (currentZone) {
        this.menuService.setSidebar();
      }
      // Clear the details pane to prevent showing information that is no longer in context
      this.detailsHelper.detailsItem.next(null);
      this.pageTitleService.init();

      if (currentZone && this.appLoadingService.appLoading.value) {
        this.appLoadingService.appLoading.next(false);
      }
    });

    // Notify if we are having connection issues
    this.subscribeToReconnectedEvent();
    this.subscribeToDisconnectedEvent();

    this.initZoneInQueryParam();

    this.subs.sink = this.plusAuth.authState
      .pipe(filter(state => state !== 'deauthenticating'))
      .subscribe(() => {
        this.brandingService.reloadCurrentZone();
        this.brandingService.reloadBranding();
        this.availableZones.reload();
    });


    this.brandingService.watchCurrentZone();

    this.freyaHelper.manageSubscriptions();

    this.freyaHelper.lockDateSupportInfo$.subscribe((info) => {
      this.contactAccountingButtonText = info?.contactAccountingButtonText || 'Contact Accounting';
    });
  }

  subscribeToReconnectedEvent() {

    this.lastConnectionStatus = this.plusAuth.connectionStatus.value;
    this.subs.sink = this.plusAuth.connectionStatus.subscribe((status) => {
      const reconnected = status === 'connected' && this.lastConnectionStatus === 'disconnected';

       if (reconnected) {
        this.messages.clear();
        this.messages.add({
          severity: 'success',
          summary: 'Reconnected',
          detail: `Network Status: ${ status }`,
          sticky: false,
          life: 3000,
        });
        console.error(`Reconnected`, status);
      }

      if (reconnected && this.appLoadingService.appLoading.value) {
        location.reload();
      }

      this.lastConnectionStatus = this.plusAuth.connectionStatus.value;
    });
  }

  subscribeToDisconnectedEvent() {
    this.subs.sink = this.plusAuth.connectionStatus.pipe(
      distinctUntilChanged(),
    ).subscribe((status) => {
      if (status === 'disconnected') {
        this.messages.clear();
        this.messages.add({
          severity: 'error',
          summary: 'No connection',
          detail: `Network Status: ${ status }`,
          sticky: false,
          life: this.plusAuth.pollingInterval - 500,
        });
        console.error(`No Connection`, status);
      }
    });
  }

  /**
   * Initialize subscriptions to set the zone
   * in the URL query parameter for all pages.
   * Zone will be added to the page search params
   */
  async initZoneInQueryParam() {
    // The angular query params are not yet populated so
    // we must set them here.
    const url = new URL(location as any);
    const queryParamZone = url.searchParams.get('zone');
    const ssZone = sessionStorage.getItem('zone');
    const ssRole = sessionStorage.getItem('role');
    const lsZone = localStorage.getItem('zone');
    const lsRole = localStorage.getItem('role');
    const zone = queryParamZone || ssZone || lsZone;
    const role = ssRole || lsRole;
    this.graphqlModule.zone.next(zone);
    this.graphqlModule.role.next(role);
    // console.log(url, queryParamZone, zone, role);

    this.subs.sink = this.router.events.subscribe((ev) => {
      if (ev instanceof ActivationEnd) {
        this.plusAuth.updateZoneInNavigation();
      }
    });

    this.subs.sink = this.graphqlModule.zone.subscribe((res) => {
      // set last zone set in session storage + local storage
      // so new tabs will be correctly resolved.
      // wait for end of the event loop for role to also update
      setTimeout(() => {
        this.setStoredContext();
      }, 1);
    });
  }

  setStoredContext() {
    const gqlZone = this.graphqlModule.zone.value;
    const gqlRole = this.graphqlModule.role.value;
    // console.log(`Stored context updated`, gqlZone, gqlRole);
    if (gqlZone) {
      sessionStorage.setItem('zone', gqlZone);
      localStorage.setItem('zone', gqlZone);
    }

    if (gqlRole) {
      sessionStorage.setItem('role', gqlRole);
      localStorage.setItem('role', gqlRole);
    }
  }

  setDayjsLocale() {
    dayjs.updateLocale('en', {
      relativeTime : {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
      }
  });
  }

  /**
   * Initializes an interval to check authentication every 5 seconds
   * and immediately performs an auth check if the user is currently
   * authenticated.
   *
   * An auth check will check the JWT. If it is expired/invalid then
   * it will try to use the current refresh token to request a new JWT.
   * If the refresh token is invalid the user will be de-authenticated.
   */
  async startAuthChecker() {
    this.subs.sink = this.appRef.isStable
      .pipe(first((stable) => stable))
      .subscribe(() => {
        this.authCheckInterval = setInterval(() => {
          this.plusAuth.checkAuthStatus().catch((err) => {
            console.error(`Could not check auth status`, err);
          });
        }, 5000);
      });

    if (this.plusAuth.user) {
      await this.plusAuth.checkAuthStatus();

      if (this.plusAuth.user) {
        console.log(`User validated:`, this.plusAuth.user.id);
      } else {
        console.log(`Session invalidated`);
      }
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    clearInterval(this.authCheckInterval);
  }

  refreshMutateContainer(){
    this.mutateContainerEnabled = false;
    setTimeout(() => {
      this.mutateContainerEnabled = true;
    }, 2500);
  }

  /**
   * Watch for updates to the angular application in two ways
   * 1) On load check if there is a new version
   * 2) Poll every 15 minutes to check if there is a new version
   */
  watchForUpdates(){

    // Subscribe to updates in the online status
    if (this.swUpdate.isEnabled) {
      this.subs.sink = this.swUpdate.versionUpdates.subscribe((update) => {
        if (update.type === 'VERSION_READY'){
          this.isNewVersion = true;
        }
      });
    }

    // Poll for updates every 15 minutes after waiting for the app to stabalize
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(VERSION_UPDATE_POLLING_FREQUENCY_IN_MS);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    this.subs.sink = everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        this.isNewVersion = await this.swUpdate.checkForUpdate();
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  updateVersion(): void {
    this.isNewVersion = false;
    window.location.reload();
  }

  closeVersion(): void {
    this.isNewVersion = false;
  }

  reportBug(input: Message) {

    const maxSubjectLength = 100;

    const subject = input.summary.substring(0, maxSubjectLength);

    const message = input.summary + ': ' + input.detail;

    this.feedbackService.openFeedbackDialog({
      subject,
      message,
      feedbackType: 'bug',
    });
  }

  contactAccounting(message: Message) {

    const { eventId } = message?.data;

    if (!eventId) {
      console.error('No eventId found in message data');
      return;
    }

    this.freyaHelper.contactAccounting(eventId);
  }

  getSupportType(message: Message) {
    if (!this.freyaHelper.lockDateSupportInfo$.value) {
      return 'support';
    }
    if (message?.data?.code === 'LOCK_DATE_ERROR') {
      return 'accounting';
    }
    return 'support';
  }
}
