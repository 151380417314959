<div class="page-header-container">
    <div>
        <div class="header-container">
            <p-breadcrumb
                [home]="home"
                [model]="breadcrumb"
                styleClass="p-mb-3"
            >
            </p-breadcrumb>
        </div>
        <div class="buttons-container">
            <app-form-action-button
                *ngIf="!editMode && !responsiveHelper.isSmallScreen"
                action="create-close"
            ></app-form-action-button>
            <app-form-action-button
                *ngIf="!editMode"
                action="create"
            ></app-form-action-button>
            <app-form-action-button
                *ngIf="editMode"
                action="update-close"
                ></app-form-action-button>
            <app-form-action-button
                *ngIf="editMode"
                action="update"
            ></app-form-action-button>
        </div>
    </div>


    <div
        class="errors-container"
        *ngFor="let errorMessage of jobSavingErrors$ | async"
        >
        <p-messages severity="error">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle"></i>
                <p [style]="{ flex: 1, marginBottom: 0 }">{{errorMessage}}</p>
                <button
                    pButton
                    label="Report Bug"
                    icon="pi pi-send"
                    class="p-button-danger"
                    (click)="reportBug(errorMessage)"
                    ></button>
            </ng-template>
        </p-messages>
    </div>

    <div
        class="errors-container"
        *ngFor="let errorMessage of jobUpdatingErrors$ | async"
        >
        <p-messages severity="error">
            <ng-template pTemplate>
                <div class="error-message">
                    <i class="pi pi-exclamation-triangle"></i>
                    <p>{{errorMessage}}</p>
                </div>
            </ng-template>
        </p-messages>
    </div>
</div>
