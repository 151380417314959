import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { FeatureName } from '../services/features';

import { BrandingActions } from './branding.store';

const featureFlagsInitialState = {
	features: [] as FeatureName[],
};

export type FeatureFlagsState = typeof featureFlagsInitialState;

export const featureFlagsFeature = createFeature({
	name: 'featureFlags',
	reducer: createReducer(
		featureFlagsInitialState,
		on(BrandingActions.brandingUpdated, (state, { features }): FeatureFlagsState => {
			return {
				...state,
				features: features as FeatureName[],
			}
		}),
	),
});

export const selectFeature = (feature: FeatureName) => 
	createSelector(
		featureFlagsFeature.selectFeatures,
		(features) => {
			return features.includes(feature)
		},
	);
