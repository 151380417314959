import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Params } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { Tag } from '@karve.it/interfaces/tag';

import { FilterByNumericalValueInput, JobsFilter, UserSearch } from '../../generated/graphql.generated';

import { FreyaHelperService } from '../services/freya-helper.service';
import { TimezoneHelperService } from '../services/timezone-helper.service';
import { ensureUnixSeconds } from '../time';

export interface FormatFormValuesOptions {
  dateRangeControlKeys?: string[];
}

const delimiter = ';';

export type ParamType = 'str' | 'num' | 'arr' | 'dateArr' | 'tagArr' | 'bool' | 'numArr' | 'hour' | 'startEnd' | 'unix';
export type ParamTypes = Partial<Record<ParamType, { params: string[]; findIn?: any[] }>>;

export interface JobParams {
  search?: string;
  stage?: string;
  // Format: category-name,category-name
  tags?: string;
  // Format: 01-10-2023,01-18-2023
  created?: string;
  lastUpdated?: string;
  bookingDates?: string;
  closedAt?: string;
  archivedAt?: string;
  // Format: delivery,packing
  bookedEvents?: string;
  // Format: 123.50
  transactionMin?: string;
  transactionMax?: string;
  state?: string;
  custom?: boolean;
}

export interface UserParams {
  search?: string;
  roles?: string;
}

// TODO: as we create new interfaces (e.g. `UserParams`), bundle them into a union type below
export type AnyParams = JobsFilter | UserSearch;

export interface FilterConfiguration<T extends AnyParams> {
  name: string;
  filter?: T;
  custom?: boolean;
}

export interface CustomControls {
  dateRange?: {
    controls: string[];
  };
  tagArray?: {
    controls: string[];
    tags: Tag[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  constructor(
    private freyaHelper: FreyaHelperService,
    private timezoneHelper: TimezoneHelperService,
    private datePipe: DatePipe
  ) { }

  // Embedding values INTO the URL as query params

  /**
   * Formats the values of an Angular form so they can be embedded in the URL as query params.
   *
   * @param formValues An object containing the values of an Angular formGrop.
   */
  formatSearchFormValues<T extends AnyParams>(formValues: AbstractControl['value'], customControls: CustomControls): T {
    const copy = {...formValues};

    Object.keys(copy).forEach((key) => {

      const controlValue = copy[key];

      const controlType = this.getControlType(copy, key, customControls);

      switch(controlType) {
        case undefined:
          delete copy[key];
          break;
        case 'tagArray':
          if (!customControls.tagArray) { break; }

          const tagQueryParamValues = this.formatTagIds(controlValue, customControls.tagArray.tags);

          delete copy[key];

          copy.tags = tagQueryParamValues;
          break;
        case 'dateRange':
          copy[key] = this.formatDateRange(controlValue as Date[]);
          break;
        case 'array':
          copy[key] = controlValue.join(delimiter);
          break;
        case 'boolean':
          copy[key] = String(controlValue);
          break;
        case 'number':
          copy[key] = String(controlValue);
      }
    });

    return copy;
  }
  getControlType(formValues: AbstractControl['value'], key: string, customControls?: CustomControls) {
    const value = formValues[key];
    const isStringOrArray = Array.isArray(value) || typeof value === 'string';

    if (value === undefined || value === null || (isStringOrArray && !value.length)) { return; }

    const customControlType = Object.entries(customControls)
      .find((cc) => {
        const customControlTypeKeys = cc[1]?.controls;
        return customControlTypeKeys.includes(key);
      });

    if (customControlType) {
      return customControlType[0];
    }

    if (Array.isArray(value)) {
      return 'array';
    }

    if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
      return typeof value;
    }
  }

  formatDateRange(dateRange: Date[]) {
    return dateRange
      .filter(Boolean)
      .map((date) => dayjs(date).format('MM-DD-YYYY'))
      .filter(Boolean)
      .join(delimiter);
  }

  formatTagIds(tagIds: string[], tags: Tag[]) {
    return tagIds.map((tagId) => this.getTagIdentifier(tagId, tags)).join(delimiter);
  }

  /**
   * Finds a tag by ID in a given array of tags,
   * and returns an identifier that can be embedded in the URl as a query param.
   *
   * @param tagId the ID of a tag we want to embed in the URL
   * @param tags an array of tags where we want to look for the tag in question
   * @returns An identifier in the format 'category-name', e.g. 'invoice-open'
   */
  getTagIdentifier(tagId: string, tags: Tag[]) {
    const tag = tags.find((t) => t.id === tagId);

    if (!tag) { return; }

    return [tag.category, tag.name]
      .filter(Boolean)
      .map((tagInfo) => tagInfo.toLowerCase())
      .join('-');
  }

  /**
   * Converts an array of two date objects into an object of type FilterByNumericalValueInput,
   * which represents a range between two numbers.
   *
   * The first number corresponds to the first hour of the first date expressed as a UNIX timestamp.
   * The second number corresponds to hour 24 of the second date expressed as a UNIX timestamp.
   *
   * @returns The resulting object of type FilterByNumericalValueInput.
   */
  convertDatesToNumValInput(dates: Date[]): FilterByNumericalValueInput {
    if ( !dates || !dates.length ) { return; };
    const [ startDate, endDate ] = dates;
    const startTime = startDate? new Date(startDate).setHours(0) : undefined;
    const endTime = endDate? new Date(endDate).setHours(24) : undefined;
    const input: FilterByNumericalValueInput = this.getNumValInput(ensureUnixSeconds(startTime), ensureUnixSeconds(endTime), true, false);
    return input;
  }

  /**
   * Convers two numbers to an object of type FilterByNumericalValueInput,
   * which represents a range of numbers greater than the first and lesser than the second.
   *
   * @param start The start of the range.
   * @param end The end of the the range.
   * @param startInclusive Whether to include the first number in the range. Defaults to true.
   * @param endInclusive Whether to include the second number in the range. Defaults to true.
   * @returns An object of type FilterByNumericalValueInput.
   */
  getNumValInput(start: number, end: number, startInclusive = true, endInclusive = true ): FilterByNumericalValueInput {
    if ( !(start || end) ) { return; };
    if (startInclusive && endInclusive) {
      return {
        greaterThanOrEqualTo: start || undefined,
        lesserThanOrEqualTo: end || undefined
      };
    };
    if (startInclusive) {
      return {
        greaterThanOrEqualTo: start || undefined,
        lesserThan: end || undefined
      };
    };
    if (endInclusive) {
      return {
        greaterThan: start || undefined,
        lesserThanOrEqualTo: end || undefined
      };
    };
    return {
      lesserThan: start || undefined,
      greaterThan: start || undefined
    };
  }
  // Parsing query params OUT of the URl

  /**
   * Strips a params object of any empty properties,
   * e.g. `{ search: 'John', stage: '' }` becomes `{ search: 'John'} `
   *
   * @param params The query params extracted from the current URL.
   */
  stripEmptyParams<K extends AnyParams>(params: K) {
    for (const key of Object.keys(params)) {
      const value = params[key];

      if (!value || (typeof value === 'string' && !value.length)) {
        delete params[key];
      }
    }
  }

  parseQueryParams(params: Params, paramTypes: ParamTypes) {
    for (const paramKey of Object.keys(params)) {

      const paramValue = params[paramKey];

      const passedParamType = Object.entries(paramTypes)
        .find(([ , values]) => values.params.includes(paramKey));

      const paramType = passedParamType? passedParamType[0]: 'str';

      switch(paramType) {
        case 'num':
          params[paramKey] = this.parseNumberQueryParam(paramValue);
          break;
        case 'arr':
          params[paramKey] = this.parseArrayQueryParam(paramValue);
          break;
        case 'dateArr':
          params[paramKey] = this.parseDateArrayQueryParam(paramValue);
          break;
        case 'startEnd':
          params[paramKey] = this.parseStartEndQueryParam(paramValue);
          break;
        case 'numArr':
          params[paramKey] = this.parseArrayQueryParam(paramValue).map(Number);
          break;
        case 'bool':
          params[paramKey] = this.parseBooleanQueryParam(paramValue);
          break;
        case 'hour':
          params[paramKey] = this.parseHour(paramValue);
          break;
        case 'unix':
          params[paramKey] = this.timezoneHelper.unixToDate(paramValue);
          break;
      }
    }

    return params;

  }

  parseNumberQueryParam(queryParam: string | undefined): number | undefined {
    if (!queryParam) { return; }
    const asNumber = Number(queryParam);
    if (Number.isNaN(asNumber)) { return; }
    return asNumber;
  }

  parseArrayQueryParam(queryParam: string | undefined) {
    if (!queryParam) { return; }
    return queryParam.split(delimiter);
  }

  parseDateArrayQueryParam(queryParam: string | undefined) {
    const dateArray = this.parseArrayQueryParam(queryParam);
    if (!dateArray?.length) { return; }
    return dateArray
      .map((dateString) => {
        if (!dateString) { return; }
        return new Date(dateString);
      });
  }

  parseStartEndQueryParam(queryParam: string | undefined) {
    const dateArray = this.parseArrayQueryParam(queryParam);
    if (dateArray?.length !== 2) { return; }
    const [ startStr, endStr ] = dateArray;
    const start = dayjs(startStr).startOf('day').toDate();
    const end = dayjs(endStr).endOf('day').toDate();
    return [ start, end ];
  }

  parseBooleanQueryParam(queryParam: string | undefined): boolean | undefined {
    if (!queryParam) { return; }
    if (queryParam === 'true') {
      return true;
    }

    return false;
  }

  parseTagQueryParam(tagsQueryParam: string, tags: Tag[]) {
    const tagArray = this.parseArrayQueryParam(tagsQueryParam);
    if (!tagArray?.length) { return; }

    const foundTags = tagArray.map((passedTag) => {
      const [ category, name ] = passedTag.split('-');

      if (category && name) {
        return this.findTwoIdentifierTag(category, name, tags);
      }
      if (category || name) {
        return this.findOneIdentifierTag(category || name, tags);
      }
    });

    return foundTags.filter(Boolean).map((t) => t.id);
  }

  findOneIdentifierTag(categoryOrName: string, tags: Tag[]): Tag {
    return tags
      .find((t) => t.name?.toLowerCase() === categoryOrName?.toLowerCase() || t.category?.toLowerCase() === categoryOrName?.toLowerCase());
  }

  findTwoIdentifierTag(category: string, name: string, tags: Tag[]): Tag {
    return tags.find((t) => t.name?.toLowerCase() === name?.toLowerCase() && t.category?.toLowerCase() === category?.toLowerCase());
  }

  parseHour(embeddedTime: string): Date {
    const parsedTime = decodeURIComponent(embeddedTime);
    const [ hourString, minuteString ] = parsedTime.split(':');
    return dayjs().hour(Number(hourString)).minute(Number(minuteString)).toDate();
  }
}
