<div
  *ngIf="loaded$ | async; else loading"
  class="header-wrapper"
>

  <header *ngIf="job">
    <div class="customerDetails">
      <div class="firstRow">
        <h2>
          {{ customerName$ | async }}
        </h2>

        <app-job-status-dropdown></app-job-status-dropdown>
      </div>
      <div class="secondRow">
        {{ job.createdAt * 1000 | date: 'MMM dd yyyy' }}
        |
        <a
          [routerLink]="['/jobs/', job.id, 'overview']"
          [queryParams]="{ zone: authSvc.contextedZoneId }"
          (click)="$event.preventDefault(); detailsHelper.open('job', job)"
        >
          {{ job.code }}
        </a>
        |
        {{
          job?.discountedSubTotal / 100
              | currency : job?.currency || "USD"
        }}
      </div>
    </div>
    <div class="jobActions">
      <p-button
            *ngIf="unsavedChanges"
            label="Save"
            icon="pi pi-save"
            (click)="onSaveButtonClick()"
            [pTooltip]="getSaveTooltipText()"
            tooltipPosition="left"
            [disabled]="(jobBeingUpdated$ | async) || (jobLoading$ | async) || !isOnline"
      ></p-button>
      <p-button
        class="editJobButton"
        label="Edit Job"
        icon="pi pi-receipt"
        (click)="onEditClick(job.id)"
        [outlined]="true"
      ></p-button>
      <p-button
        icon="pi pi-ellipsis-v"
        [text]="true"
        (onClick)="menu.toggle($event)"
      />
    </div>
  </header>

  <app-job-tags></app-job-tags>
</div>

<p-menu
  #menu
  [model]="actionMenuItems"
  [popup]="true"
/>
<!-- Footer for mobile view -->
<footer
  *ngIf="job && !(chargesUpdates$ | async)?.length"
  class="mobile-footer"
  (click)="onEditClick(job.id)"
>
  <div class="footer-button {{ job.closedAt ? 'closed' : 'open' }} {{ job.stage }}">
    <i class="pi pi-receipt"></i>
    <span> {{ job.stage === 'lead' ? 'Review Lead' : 'Edit Job' }} </span>
  </div>
</footer>

<footer
  *ngIf="job && (chargesUpdates$ | async)?.length"
  class="mobile-footer"
  (click)="saveJob()"
>
  <div class="footer-button open">
    <i class="pi pi-receipt"></i>
    <span> Save Job </span>
  </div>
</footer>



<ng-template #loading>
  <div>
    <header>
      <div class="customerDetails">
        <div class="firstRow">
          <p-skeleton
            width="12rem"
            height="3rem"
          ></p-skeleton>
        </div>
        <div class="secondRow">
          <p-skeleton
            width="20rem"
            height="1rem"
          ></p-skeleton>
        </div>
      </div>
      <div class="jobActions">
        <p-skeleton
          width="7rem"
          height="3rem"
        ></p-skeleton>
      </div>
    </header>
  </div>
</ng-template>