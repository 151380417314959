import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { stringifyLocation } from '@karve.it/interfaces/locations';
import { Zone } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { distinctUntilChanged } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseZoneWithAreasFragment, ListZonesFilter, ListZonesWithAreasAndChildrenGQL, ListZonesWithAreasQuery } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';
import { pagination } from '../global.constants';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaMutateService } from '../services/freya-mutate.service';
import { PermissionService } from '../services/permission.service';

@Component({
  selector: 'app-business-units',
  templateUrl: './business-units.component.html',
  styleUrls: ['./business-units.component.scss']
})
export class BusinessUnitsComponent implements OnInit, OnDestroy {

  @ViewChild('franchiseSearchInput', { static: true }) franchiseSearchInput!: ElementRef;

  subs = new SubSink();

  zones: BaseZoneWithAreasFragment[];

  canCreateFranchise = false;

  // Lazy Loading Variables
  franchisesQueryRef: ReturnType<typeof this.zonesGQL.watch>;
  franchisesQueryLoading = true;
  franchisesQueryLimit = pagination.defaultNumberOfItems;
  franchisesQuerySearch = '';
  total = 0;

  stringifyLocation = stringifyLocation;

  franchisesEmptyMessage = pagination.emptyMessage;


  constructor(
    public detailsHelper: DetailsHelperService,
    private freyaMutate: FreyaMutateService,
    private permissionsHandler: PermissionService,
    private zonesGQL: ListZonesWithAreasAndChildrenGQL,
    private auth: PlusAuthenticationService,
  ) {
  }

  ngOnInit() {

    this.retrieveFranchises();

    this.subs.sink = this.detailsHelper.getObjectUpdates('Zones').subscribe((update) => {
        if (update.action === 'create') {
            this.retrieveFranchises();
        }
    });

    this.permissionsHandler.watchPermissionsAndRestrictions([ {
        permission: 'zones.create',
        restriction: { createFranchise: true },
    } ])
        .pipe(distinctUntilChanged((a, b) => a[0] === b[0]))
        .subscribe((res) => {
            this.canCreateFranchise = res[0];
        });

    // this.subs.sink = this.detailsHelper.franchisesUpdated.subscribe(() => {
    //   this.franchisesQueryRef.refetch();
    // });

    // this.subs.sink = fromEvent(this.franchiseSearchInput.nativeElement, 'keyup').pipe(
    //   map((event: any) => event.target.value),
    //   debounceTime(750),
    // ).subscribe((text: string) => {
    //   this.franchisesQueryLoading = true;
    //   this.searchForFranchises();
    // });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  // Populate information in the Side Panel
  viewFranchiseDetails(franchise: Zone){

    franchise = cloneDeep(franchise);

    this.detailsHelper.detailsItem.next({type: 'franchise', item: franchise});
  }

  retrieveFranchises() {

    if (this.franchisesQueryRef) {
        this.franchisesQueryRef.refetch();
        return;
    }

    this.franchisesQueryRef = this.zonesGQL.watch({
      limit: this.franchisesQueryLimit,
      skip: 0,
      filter: this.getFilter(),
    }, {
      fetchPolicy: 'cache-and-network',
    });

    this.subs.sink = this.franchisesQueryRef.valueChanges.subscribe((res) => {
      if (res.networkStatus === 7) {
        this.zones = res.data.zones.nodes;
        this.franchisesQueryLoading = false;
        this.total = res.data.zones.total;
      }
    });

  }

  retrieveMoreFranchises(event: LazyLoadEvent) {
    this.franchisesQueryLoading = true;

    this.franchisesQueryRef.setVariables({
      filter: this.getFilter(),
      limit: event.rows,
      skip: event.first
    });
  }

  getFilter(): ListZonesFilter {
    return {
      parent: this.auth.contextedZoneId,
    };
  }

  searchForFranchises() {
    // this.franchisesQueryRef.resetLastResults();
    // this.franchisesQueryRef.refetch({
    //   // filter: this.getFilter(),
    //   limit: this.franchisesQueryLimit,
    // });
  }

  openMutateBusinessUnit() {
    this.freyaMutate.openMutateObject({
        mutateType: 'create',
        objectType: 'businessUnit',
    });
  }
}
