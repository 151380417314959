import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { BaseInvoiceFragment, TransactionsAvailableForInvoiceGQL, TransactionsAvailableForInvoiceQuery, TransactionsAvailableForInvoiceQueryVariables, UpdateTransactionGQL } from '../../../generated/graphql.generated';
import { remainingBalance } from '../../jobs/jobs.util';
import { DetailsHelperService } from '../../services/details-helper.service';

import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService, TransactionWithAvailability } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { Store } from '@ngrx/store';
import { JobToolActions } from '../../jobsv2/job-tool.actions';

@Component({
  selector: 'app-apply-transaction',
  templateUrl: './apply-transaction.component.html',
  styleUrls: ['./apply-transaction.component.scss', '../../dynamic-dialog-styling.scss']
})
export class ApplyTransactionComponent implements OnInit {

  invoice: BaseInvoiceFragment;

  transactions: TransactionWithAvailability[] = [];

  selectedTransaction: TransactionWithAvailability;

  saving = false;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private documentHelper: DocumentHelperService,
    private updateTransactionGQL: UpdateTransactionGQL,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private transactionsAvailableForInvoiceGQL: TransactionsAvailableForInvoiceGQL,
    private freyaHelper: FreyaHelperService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.transactions = this.config.data?.transactionsWithAvailability;
    this.invoice = this.config.data?.invoice;
  }

  closeDialog() {
    this.ref.close();
  }

  openMutateTransactionDialog() {
    this.ref.close();
    this.documentHelper.openCreateTransactionsDialogForInvoice(this.invoice);
  }

  applyTransaction() {

    if (!this.selectedTransaction) { return; }

    this.saving = true;

    this.updateTransactionGQL.mutate({
      transactionId: this.selectedTransaction.id,
      invoiceId: this.invoice.id,
    }).subscribe(async () => {

      // Store ID as selected transaction may be cleared
      const updatedTransactionId = this.selectedTransaction.id;

      await this.checkIfInvoicePaid(this.selectedTransaction);

      this.saving = false;

      this.localNotify.success('Transaction applied');

      this.detailsHelper.pushUpdate({
        type: 'Transactions',
        action: 'update',
        id: updatedTransactionId,
      });

      this.detailsHelper.pushUpdate({
        type: 'Invoice',
        action: 'update',
        id: this.invoice.id,
      });

      this.store.dispatch(JobToolActions.paymentAppliedToInvoice({ jobId: this.invoice.job.id }));

    }, (err) => {
      this.localNotify.apolloError('Failed to apply transaction', err);
      this.saving = false;
    });
  }

  async checkIfInvoicePaid(appliedTransaction: TransactionWithAvailability) {

    const remainingInvoiceBalance = remainingBalance(this.invoice, false);

    const remainingBalanceAfterTransaction = remainingInvoiceBalance - appliedTransaction.amount;

    const invoiceFullyPaid = remainingBalanceAfterTransaction === 0;

    // If the invoice is fully paid, we are done here...
    if (invoiceFullyPaid) {
      this.closeDialog();
      return;
    }

    // Otherwise, refetch invoice and transactions so we have latest totals

    const vars: TransactionsAvailableForInvoiceQueryVariables = { invoiceId: this.invoice.id, invoiceJobId: this.invoice.job.id };

    const {
      data: {
        invoices: { invoices: [ invoice ] },
        transactions: { transactions },
      },
    } = await this.transactionsAvailableForInvoiceGQL.fetch(vars, { fetchPolicy: 'network-only' }).toPromise();

    const {
      transactionsAvailable,
      transactionsWithAvailability,
    } = this.freyaHelper.getTransactionsAvailableForInvoice(invoice, transactions);

    this.invoice = invoice;
    this.transactions = transactionsWithAvailability;

    // If there are no more available transactions, close dialog
    if (!transactionsAvailable) {
      this.closeDialog();
      return;
    }

    // Otherwise, reset component so they can keep apply existing transactions
    this.selectedTransaction = undefined;
  }
}
