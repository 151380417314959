import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlusAuthenticationService } from '@karve.it/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseZoneFragment, CreateNewJobGQL, CreateNewJobMutationVariables, CreateUserInputWithRole, EditProfileGQL, FindGQL } from 'graphql.generated';
import { isEmpty } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, filter, from, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { MenuService } from 'src/app/base/menu/app.menu.service';
import { DEFAULT_EVENT_TYPE, JOB_FORM_FIELDS, JOB_ROLE_MAP, eventTypeInfoMapV2 } from 'src/app/global.constants';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';

import { isErrorState } from 'src/app/utilities/state.util';

import { environment } from '../../../../environments/environment';
import { updateRecentJobs } from '../../../base/menu/quick-access/quick-access.util';
import { safeParseJSON } from '../../../js';
import { BrandingService } from '../../../services/branding.service';
import { brandingFeature } from '../../../state/branding.store';
import { JobSummaryActions } from '../../job-state/summary-state/summary.actions';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import { checkAndUpdateEmptyRoomName, generateCreateCustomerInput, generateFieldsInputFromLatestChanges, generateInventoryInputFromLatestChanges, generateJobCreateVariables, generateLocationsInputsFromLatestChanges, generateUpdateCustomerInput, getFindQueryVariablesV2 } from '../../jobsv2-helpers';
import { getLastEditedJobsFromLocalStorage, saveLastEditedJobsToLocalStorage } from '../../jobsv2-local-storage.utils';
import { JobEditActions } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions';
import { CloseJobDialogState, CloseJobV2Component } from '../close-job-componentV2/close-job-componentV2.component';
import { JobCreateCustomerActions } from '../jobv2-create-customer-state/jobv2-create-customer.actions';
import { JobCreateInventoryActions } from '../jobv2-create-inventory-state/jobv2-create-inventory-state.actions';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { JobTimelineActions } from '../jobv2-timeline-availability-state/jobv2-timeline-availability-state.actions';


import { JobCreateActions } from './jobv2-create.actions';
import { selectCreateJobCallState, selectCustomerRole, selectDataToSaveInLS, JobDataToSaveInLS, selectTotalTimeForFind, selectYemboStatus, selectZoneIdForFind } from './jobv2-create.selectors';

export const createJobToolOpenedEffect = createEffect(() => {
	const actions$ = inject(Actions);

	return actions$.pipe(
		ofType(JobCreateActions.createJobToolOpened),
		map(() => {
			const storedState = localStorage.getItem(environment.lskeys.createJobState);
			const retrievedState = storedState ? JSON.parse(storedState) : undefined;

			return JobCreateActions.stateForCreateJobGenerated({
				retrievedState
			});
		})
	);
}, { functional: true, dispatch: true });

//current user
export const initEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const authService = inject(PlusAuthenticationService);

	return actions$.pipe(
		ofType(ROOT_EFFECTS_INIT),
		switchMap(() => {
			const user = authService.user;
			const actions = [];

			if (user) {
				actions.push(JobCreateActions.getCurrentUserSuccess({ user }));
			}

			return of(...actions);
		})
	);
}, { functional: true, dispatch: true });


export const currencyGotEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const freyaHelper = inject(FreyaHelperService);

	return actions$.pipe(
		ofType(JobCreateActions.getCurrency),
		switchMap(async () => {
			const currency = await freyaHelper.getCurrency();
			return JobCreateActions.getCurrencySuccess({ currency });
		})
	);
}, { functional: true, dispatch: true });


//recalculations after zone changed
export const resetZoneSensitiveInputsEffect = createEffect(() => {
	const actions$ = inject(Actions);

	return actions$.pipe(
		ofType(JobCreateActions.changeZone),
		map(() =>
			JobCreateActions.resetZoneSensitiveInputs()
		)
	);
}, { functional: true, dispatch: true });

export const availableTimesFoundWhenCreateJobEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const findGQL = inject(FindGQL);

	return actions$.pipe(
		ofType(
			JobCreateCustomerActions.selectMovingDate,
			JobCreateLocationsActions.locationResolveServiceAreaSuccess,
			JobCreateLocationsActions.locationSelectAreaManually,
			// reretrieve times after locations set as they affect travelTime and totalTime
			JobCreateLocationsActions.locationSetAutocomplete,
			JobCreateLocationsActions.locationSetManually,
		),
		withLatestFrom(
			store.select(selectTotalTimeForFind),
			store.select(selectZoneIdForFind),
			store.select(jobToolFeature.selectJobInput),
			store.select(jobToolFeature.selectJobFormMode),
		),
		filter(([action, _, __, ___, mode]) => mode === 'create'), // Proceed only if mode is 'create'
		filter(([_, __, zoneId, jobInput]) => !!zoneId && !!jobInput?.timeline), // Proceed only if both zoneId and timeline are defined
		switchMap(([action, totalTime, zoneId, jobInput, mode]) => {
			const timeline = jobInput.timeline;
			const variables = getFindQueryVariablesV2(timeline, totalTime, DEFAULT_EVENT_TYPE, zoneId);

			return from(findGQL.fetch(variables)).pipe(
				map(response => JobCreateActions.findTimesSuccess({
					result: response
				})),
				catchError(error => of(JobCreateActions.findTimesError({ error })))
			);
		})
	);
}, { functional: true, dispatch: true });

//Save the changes, calculated and loaded based on inputs data to localStorage
//on any action when user make changes
export const saveJobToolStateToLocalStorageEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	return actions$.pipe(
		ofType(
			JobCreateActions.resetZoneSensitiveInputs,

			JobCreateCustomerActions.customerValueInput,
			JobCreateCustomerActions.existingCustomerSelected,
			JobCreateCustomerActions.selectMovingDate,
			JobCreateCustomerActions.selectHowHeard,
			JobCreateCustomerActions.selectCustomerType,
			JobCreateCustomerActions.selectJobOrigin,
			JobCreateCustomerActions.discardCustomerChanges,
			JobCreateCustomerActions.deselectCustomer,
			JobCreateCustomerActions.editCustomerWhenCreateJob,

			JobCreateActions.findTimesSuccess,
			JobCreateActions.selectTimeSlot,
			JobCreateActions.updateCustomerSuccess,
			JobCreateActions.createJobSuccess,
			JobCreateActions.discardJob,

			JobEditActions.updateJobSuccess,
			JobEditActions.discardChanges,

			JobCreateInventoryActions.inventoryAddRoom,
			JobCreateInventoryActions.inventoryUpdateRoomName,
			JobCreateInventoryActions.inventoryDeleteRoom,
			JobCreateInventoryActions.inventoryItemAddedToRoom,
			JobCreateInventoryActions.inventoryItemRemovedFromRoom,
			JobCreateInventoryActions.inventoryItemQuantityChanged,
			JobCreateInventoryActions.selectEstimateMethod,

			JobCreateLocationsActions.locationSetAutocomplete,
			JobCreateLocationsActions.locationSetManually,
			JobCreateLocationsActions.setLocationFields,
			JobCreateLocationsActions.locationResolveServiceAreaSuccess,
			JobCreateLocationsActions.locationSelectAreaManually,
			JobCreateLocationsActions.updateZoneInContext,
			JobCreateLocationsActions.locationsCreateSuccess,
			JobCreateLocationsActions.additionalLocationSelected,
			JobCreateLocationsActions.additionalLocationRemoved,
			JobCreateLocationsActions.additionalLocationChangedType,
			JobCreateLocationsActions.additionalLocationRemoved,
			JobCreateLocationsActions.locationChangedForJobWithCharges,

			JobSummaryActions.updateSummary,

			JobTimelineActions.calculateDistancesSuccess,
		),
		withLatestFrom(
			store.select(jobToolFeature.selectJobFormMode),
			store.select(selectDataToSaveInLS)
		),
		tap(([_, mode, appState]) => {
			if (mode === 'create') {
				localStorage.setItem(environment.lskeys.createJobState, JSON.stringify(appState));
			} else if (mode === 'edit') {
				const lastEditedJobsFromLS = getLastEditedJobsFromLocalStorage();

				const updatedLastEditedJobs = updateRecentJobs<JobDataToSaveInLS>(lastEditedJobsFromLS, appState);

				saveLastEditedJobsToLocalStorage(updatedLastEditedJobs);
			}
		})
	);
}, { functional: true, dispatch: false });

//SAVE FORM EFFECTS

// If existing customer was edited, perform update customer
export const updateExistingCustomerEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(JobCreateActions.saveJobForm, JobEditActions.updateFormSaved),
		withLatestFrom(
			store.select(jobToolFeature.selectChanges)
		),
		map(([action, changes]) => {
			const initialCustomerCopy = changes.find(item => item.fieldName === JOB_FORM_FIELDS.initialCustomerCopy);
			if (initialCustomerCopy) {
				const updateCustomerVariables = generateUpdateCustomerInput(changes, initialCustomerCopy.value.id);
				return JobCreateActions.updateCustomer({customerEditInput: updateCustomerVariables});
			}
			return null;
		}),
		filter(action => action !== null)
	);
}, { functional: true, dispatch: true });

export const customerBeingUpdated = createEffect(() => {
	const actions$ = inject(Actions);;
	const editProfileGQL = inject(EditProfileGQL);

	return actions$.pipe(
		ofType(JobCreateActions.updateCustomer),
		switchMap((action) => {
			return from(editProfileGQL.mutate(action.customerEditInput)).pipe(
				map(response => {
					return JobCreateActions.updateCustomerSuccess();
				}),
				catchError(error => of(JobCreateActions.updateCustomerError({ error })))
			);
		})
	);
}, { functional: true, dispatch: true });

//Create job
//create new customer, set fields, tags and close job if needed in the same query
export const createJobEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const branding = inject(BrandingService);
	const createNewJobGQL = inject(CreateNewJobGQL);
	const authService = inject(PlusAuthenticationService);

	return actions$.pipe(
		ofType(JobCreateActions.saveJobForm, JobToolActions.closeJob),
		filter((action) => {
			// this was closed from jobs v2
			// if we implement close job on job creation tool for existing jobs
			// this needs to be changed
			if (action.type === '[Job Tool] Close Job' && action.jobId) {
				return false;
			}

			return true;
		}),
		withLatestFrom(
			store.select(jobToolFeature.selectChanges),
			store.select(brandingFeature.selectCurrency),
			store.select(jobToolFeature.selectCustomerInput),
			store.select(selectCustomerRole),
			store.select(selectYemboStatus),
		),
		switchMap(([action, changes, currency, customer, customerRoleId, yemboStatus]) => {
			const selectedExistingCustomer = changes.find(item => item.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer);
			const selectedTimeSlot = changes.find(item => item.fieldName === JOB_FORM_FIELDS.selectedTimeSlot);
			const yemboStatusForSaving = yemboStatus.yemboStatusForSaving;
			const {
				jobType,
				jobOrigin,
				resolvedServiceArea,
				...recentChanges } = generateJobCreateVariables(changes);
			const locations = generateLocationsInputsFromLatestChanges(changes);
			const locationsToAdd = Object.values(locations).map(item => ({
				locationId: item.id,
				locationType: item.locationType
			}));
			const createCustomerVariables = generateCreateCustomerInput(changes);
			const fieldsChanges = generateFieldsInputFromLatestChanges(changes);
			const { inventory } = generateInventoryInputFromLatestChanges(changes);

			const zoneId = resolvedServiceArea?.id || branding.currentZone().value.id;
			const createJobVariables: CreateNewJobMutationVariables = {
				stage: 'lead',
				zoneId,
				currency,
				metadata: {
					jobType,
					jobOrigin,
				},
				locations: locationsToAdd,
				users: [],
				events: [],
				newCustomers: [],
				fields: {},
				...recentChanges,
			}

			if (!Array.isArray(createJobVariables.users)) {
				createJobVariables.users = [];
			}

			// Deprecated: The sales agent is now set in the backend.
			// createJobVariables.users.push({
			// 	role: JOB_ROLE_MAP.salesAgentRole,
			// 	userId: authService.user.id,
			// });

			if (selectedExistingCustomer || customer?.id) {
				createJobVariables.users.push({
					role: JOB_ROLE_MAP.customerRole,
					userId: customer?.id,
				});
			}

			if (!selectedExistingCustomer) {
				const customerWithRole = {
					...createCustomerVariables,
					role: customerRoleId,
				}
				createJobVariables.newCustomers = [customerWithRole as CreateUserInputWithRole];
			}

			if (yemboStatusForSaving.smartConsult) {
				const eventVariables = {
					sequentialOrder: 0.5,
					status: 'required',
					type: eventTypeInfoMapV2.virtualEstimate.value,
					title: eventTypeInfoMapV2.virtualEstimate.name,
				};

				// Ensure events is an array before adding new events
				createJobVariables.events = Array.isArray(createJobVariables.events)
					? [...createJobVariables.events, eventVariables]
					: [eventVariables];
			}

			if (yemboStatusForSaving.selfSurvey) {
				createJobVariables.sendSelfSurvey = true;
			}

			if (selectedTimeSlot) {
				const eventVariables = {
					sequentialOrder: 1,
					status: 'required',
					type: eventTypeInfoMapV2.moving.value,
					title: eventTypeInfoMapV2.moving.name,
					start: selectedTimeSlot?.value,
				}
				createJobVariables.events = Array.isArray(createJobVariables.events)
					? [...createJobVariables.events, eventVariables]
					: [eventVariables];
			}

			if (action.type === '[Job Tool] Close Job' && action.jobId === undefined) {
				createJobVariables.closedReason = action.closedReason;
			}

			let fieldsForQuery = [];

			if (!isEmpty(fieldsChanges)) {
				fieldsForQuery = Object.keys(fieldsChanges).map(key => ({
					fieldName: key,
					value: fieldsChanges[key]
				}));
			}

			if (!isEmpty(inventory)) {
				const withFilledRoomNames = checkAndUpdateEmptyRoomName(inventory);
				const inventoryForQuery = {
					fieldName: "jobs.inventory",
					value: JSON.stringify(withFilledRoomNames)
				}

				fieldsForQuery.push(inventoryForQuery);
			}

			if (fieldsForQuery?.length) {
				createJobVariables.fields = {
					objectLabel: "Job",
					fields: fieldsForQuery,
				};
			}

			return from(createNewJobGQL.mutate(createJobVariables)).pipe(
				map(response => {
					const job = response?.data?.createJob;
					if (!job) {
						throw new Error(`No job returned`);
					}

					if (!job.zone) {
						throw new Error(`Job is not in a zone.`);
					}

					// hacky workaround to get the first zone that's not an area
					let zone: BaseZoneFragment = job.zone;
					if (zone.type === 'area') {
						zone = job.zone.parents.find((p) => p.type !== 'area');
					}

					return JobCreateActions.createJobSuccess({ jobId: job.id, zoneId: zone.id });
				}),
				catchError(error => of(JobCreateActions.createJobError({ error })))
			);
		}),
	);
}, { functional: true, dispatch: true });

export const handleUserFromAnotherZoneEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const confirmService = inject(ConfirmationService);

	return actions$.pipe(
		ofType(JobCreateActions.createJobError),
		withLatestFrom(store.select(selectCreateJobCallState)),
		// Filter for a specific error in callState.createJob
		filter(([action, createJobCallState]) =>
			isErrorState(createJobCallState) &&
			typeof createJobCallState.error === 'string' &&
			createJobCallState.error.includes('Insufficent permissions to assign job to user')
		),
		tap(([action, createJobCallState]) => {
			const message = 'Would you like to create new customer with same name, email, phone and company information?';

			confirmService.confirm({
				header: 'The selected customer might be from another zone',
				message,
				acceptLabel: 'Yes, create new customer',
				acceptIcon: 'pi pi-arrow-right',
				acceptButtonStyleClass: 'p-button-warning',
				rejectLabel: 'Select another customer',
				rejectIcon: 'pi pi-times',
				dismissableMask: true,
				accept: () => store.dispatch(JobCreateCustomerActions.createNewCustomerBasedOnExisting()),
				reject: () => store.dispatch(JobCreateCustomerActions.deselectCustomer()),
			});
		})
	);
}, { functional: true, dispatch: false });

//If closed reason was selected proceed with closing created job
export const initializeCreateAndCloseJobEffect = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
	dialogService = inject(DialogService),
) => { return actions$.pipe(
		ofType(JobCreateActions.createAndCloseJob),
		withLatestFrom(
			store.select(jobToolFeature.selectJob)
		),
		map(([action, job]) => {

			// when job is closed through this dialog it will emit
			// JobFeature.cloesJob with job as undefined
			const ref = dialogService.open(CloseJobV2Component, {
				header: 'Create job and close it right away',
				width: '20rem',
				data: {
					job,
				} as CloseJobDialogState,
			});
		}),
) }, { functional: true, dispatch: false });

// clean state after all changes saved
export const removeJobFromLsAfterAllJobInfoSaved = createEffect(() => {
	const actions$ = inject(Actions);

	return actions$.pipe(
		ofType(
			JobCreateActions.createJobSuccess,
			JobEditActions.updateJobSuccess,
		),
		map(() => JobCreateActions.discardJob())
	);
}, { functional: true, dispatch: true });

//redirect to jobv2 page
export const redirectAfterJobCreatedSuccess = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const router = inject(Router);
	const route = inject(ActivatedRoute);
	const menuService = inject(MenuService)

	return actions$.pipe(
		ofType(JobCreateActions.createJobSuccess),
		withLatestFrom(store.select(jobToolFeature.selectJobInput)),
		tap(async ([action, jobInput]) => {


			if (menuService.isJobV2Enabled) {
				const redirectTo = `/jobs/${jobInput.id}/overview`;
				router.navigate([redirectTo], {
					relativeTo: route,
					queryParams: {
						zone: action.zoneId,
					},
				});
			} else {
				const redirectTo = `/estimating/${jobInput.id}`;
				router.navigate([redirectTo], {
					relativeTo: route,
					queryParams: {
						step: 'products-services',
						zone: action.zoneId,
					},
				});
			}
		})
	);
}, { functional: true, dispatch: false });

export const logErrors = createEffect((
	actions$ = inject(Actions)
) => {
	return actions$.pipe(
		ofType(
			JobCreateActions.createJobError,
			JobCreateActions.createCustomerError,
			JobCreateActions.updateCustomerError,
			JobCreateActions.setFieldsError,
			JobCreateActions.setTagsError,
			JobCreateActions.createRequiredEventError,
		),
		tap(({ error }) => {
			console.error(error);
		}),
	);
}, { functional: true, dispatch: false});

