import { M } from '@fullcalendar/resource/internal-common';
import { capitalize } from 'lodash';

import { Inventory } from './estimates/estimate.interfaces';
import { FIELD_TYPE } from './interfaces/fields';
import { JobClosureReason } from './jobs/jobs.component';

import { FilterConfiguration, JobParams, UserParams } from './shared/query-params.service';

export const FREYA_ROUTES = {
    login: 'auth/login',
    resetPassword: ['/auth', 'reset-password'],
    signup: 'auth/signup',

    notFound: 'notfound',
    error: 'error',
    accessDenied: 'access',
};

export const VERSION_UPDATE_POLLING_FREQUENCY_IN_MS = 900000;

export const PRESET_FILTERS: {
    jobs: FilterConfiguration<JobParams>[];
    users: FilterConfiguration<UserParams>[];
} = {
    jobs: [
        {
            name: 'All',
            filter: {
                stage: 'any',
                state: 'any',
            }
        },
        {
            name: 'Leads',
            filter: {
                stage: 'lead',
                state: 'open',
            }
        },
        {
            name: 'Estimates',
            filter: {
                stage: 'estimate',
                state: 'open',
            }
        },
        {
            name: 'Booking',
            filter: {
                stage: 'booking',
                state: 'open',
            }
        },
        {
            name: 'Invoices',
            filter: {
                stage: 'invoice',
                state: 'open',
            }
        },
        {
            name: 'Pending',
            filter: {
                tags: 'approval required',
                state: 'open',
            }
        },
        {
            name: 'Open',
            filter: { state: 'open' }
        },
        {
            name: 'Closed',
            filter: { state: 'closed' }
        },
    ],
    users: []
};

export type FilterType = 'job' | 'user';

export const ATTRIBUTES = {
    singleUse: 'single-use', // Specifies discounts that are job specific
    manualDuration: 'manual-duration', // Specifies events that shouldb't have end autocalculate
    inventory: 'manual-inventory',
    generatedInventory: 'generated-inventory',
};

export const SHARED_TO_ATTRIBUTE_PREFIX = 'shared-to:';

export const UNREAD_NOTIFICATIONS_COUNT_MAX = 99;

export const pagination = {
    defaultNumberOfItems: 10,
    emptyMessage: 'No Items Found Matching Your Criteria',
    rowPerPageOptions: [5, 10, 25, 50, 100, 250],
};

export const text = {
    companyName: 'Karve IT',
    projectName: ''
};

export const JOB_ROLE_MAP = {
    customerRole: 'customer',
    estimatorRole: 'Estimator',
    salesAgentRole: 'Sales Agent',
    authorRole: '',
};

export const roleNames = {
    customer: 'Toledo Customer',
};

export const ROLES = {
    customers: ['Customer', 'Toledo Customer', 'Cincinnati Customer', 'Phoenix Customer']
};

export enum EventAttendeeRoles {
    crewLead = 'Crew Lead',
    assistantCrewLead = 'Assistant Crew Lead',
    crewMember = 'Crew Member',
    author = 'Author'
}

// Job roles that are edittable by the user
export const userJobRoles = [
    JOB_ROLE_MAP.customerRole,
    JOB_ROLE_MAP.estimatorRole,
    JOB_ROLE_MAP.salesAgentRole
];

export const eventAttendeeRolesArray = [
    EventAttendeeRoles.crewLead,
    EventAttendeeRoles.assistantCrewLead,
    EventAttendeeRoles.crewMember
];

export interface JobStage {
    name: string;
    // In the format `Mark ${verb}`
    pastTenseVerb: string;
    order?: number;

    /**
     * Set to true we should be calculating auto charges for this stage
     * Default is false
     */
    autoChargesEnabled?: boolean;

    /**
     * If auto charges is true then this will disable auto charges
     * for events that are booked.
     *
     * Defaults to false (enabled)
     */
    disableAutoChargesForBookedEvents?: boolean;

    /**
     * Set to true to apply the "estimate: required" tag
     * if the require-estimate trigger is triggered.
     */
    applyEstimateRequired?: boolean;
}

export const JOB_STAGES: { [id: string]: JobStage } = {
    lead: {
        name: 'lead',
        pastTenseVerb: 'lead',
        order: 0,
        autoChargesEnabled: false,
        applyEstimateRequired: true,
    },
    estimate: {
        name: 'estimate',
        pastTenseVerb: 'estimate',
        order: 1,
        autoChargesEnabled: true,
        applyEstimateRequired: true,
    },
    booking: {
        name: 'booking',
        pastTenseVerb: 'booked',
        order: 2,
        autoChargesEnabled: true,
        disableAutoChargesForBookedEvents: true,
    },
    invoice: {
        name: 'invoice',
        pastTenseVerb: 'invoiced',
        order: 3,
        // autoChargesEnabled: false,
        disableAutoChargesForBookedEvents: true,
    },
    cancelled: {
        name: 'cancelled',
        pastTenseVerb: 'cancelled',
        // autoChargesEnabled: false,
        disableAutoChargesForBookedEvents: true,
    },
};

export const JOB_STAGES_BY_ORDER = Object
    .entries(JOB_STAGES)
    .filter(([id, val]) => val.order !== undefined)
    .sort(([_, a], [__, b]) => {
        if (a.order < b.order) { return -1; }
        if (a.order > b.order) { return 1; }
        return 0;
    })
    .map(([id, val]) => id);

//HERE: Change to objects, store colors?
export const JOB_STATES = [
    'open',
    'closed',
    'archived',
    'deleted',
] as const;

export const TAGS = {
    estimateBooked: 'Estimate Booked',
    approvalRequired: 'Approval Required',
};

export const TAG_CATEGORIES = {
    lead: 'Lead',
};

export const BOOK_OFF_EVENT_TYPE = 'book-off';

export const CANCEL_JOB_REASONS = [
    'Went With Competitor',
    'Attended - No Customer',
    'Decided To Move Themselves',
    'Move Date No Longer Available',
    'No Longer Moving',
    'Tentative Booking',
    'Customer Needed Storage',
    'Long Distance Move',
    'Duplicate',
    'Other'
];

// export const eventTypeTagCategories = {
//     estimating: 'estimating',

// };

export const eventTypeTagStatuses = {
    required: 'Required',
    pending: 'Pending',
    booked: 'Booked',
    cancelled: 'Cancelled',
    completed: 'Completed',
    confirmed: 'Confirmed',
};

//data about origin event that we need to pass from frontend to create duplicate
export interface OriginEventBasicData {
    duplicateFromOriginId: string;
    title: string;
    type: string;
}

export interface EventTypeInfo {
    name: string;
    value: string;
    tagCategory?: string;

    requiredLocations?: string[];

    selectedByDefaultInEstimator?: boolean;

    /**
     * Only scheduling a revenue generating event will
     * promote the job to the booking stage.
     */
    revenueGenerating?: boolean;

    // The background color on the schedule
    backgroundColor?: string;

    /**
     * The text color to use on the schedule. If background color
     * is a standard color then it will use the color from the standard
     * color, otherwise it will default to this color, otherwise it will
     * default to black
     */
    textColor?: string;

    // The default length of the event type if there are no rules for that event type
    // defaultLength?: number;
    assetTypes?: string[];

    /**
     * No locations will be added to the event if this is set
     * The duration of the event will be set to this value (in seconds)
     */
    virtualTime?: number;

    /**
     * Message to display during the booking process
     */
    bookingMessage?: string;

    enableDock?: boolean;

    /**
     * If there are no charges and no rules which define the length of an
     * event then this event will default its location times to the
     * specified times
     */
    defaultLocationTimes?: {
        [locationkey: string]: number;
    };
    //for default ordering events based on their types
    order?: number;
    //order of new added event that depends on the amount of events previously added to job
    sequentialOrder?: number;
}

export const ADDABLE_LOCATION_TYPES = ['dock', 'storage', 'office', '2nd stop', '3rd stop', 'other'];

//This const is being used only in currently commented pieces of code in component mutate-calendar-event.component
export const LOCATION_TYPES = [...ADDABLE_LOCATION_TYPES, 'start', 'end', 'basestart', 'baseend'];

// https://www.color-hex.com/color-palette/10664

//being mutated by estimateHelperService: updateDurationsFromCharges and precalculateEventInfo
export const virtualEstimateDefaultTime = 1800;

//being mutated by estimateHelperService: updateDurationsFromCharges and precalculateEventInfo. Hence, for JobsV2, use eventTypeInfoMapV2
export const eventTypeInfoMap: {
    [key: string]: EventTypeInfo;
} = {

    estimating: {
        name: 'OSEstimate',
        value: 'estimating',
        // this is estimating in production but may be ose on some machines
        tagCategory: 'estimating',
        backgroundColor: 'orange',
        assetTypes: ['Estimator'],
        revenueGenerating: false,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],

        order: 1
    },
    virtualEstimate: {
        name: 'Virtual Estimate',
        value: 'virtualEstimate',
        tagCategory: 'Virtual Estimate',
        assetTypes: ['Estimator'],
        backgroundColor: 'orange-yellow',
        revenueGenerating: false,
        enableDock: false,
        virtualTime: virtualEstimateDefaultTime,
        order: 1.5,
        bookingMessage: 'Virtual Estimates cannot be booked in the past',
        requiredLocations: ['start'],
    },
    moving: {
        name: 'Moving',
        value: 'moving',
        tagCategory: 'moving',
        selectedByDefaultInEstimator: true,
        backgroundColor: 'blue',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
            end: 3600,
        },
        requiredLocations: ['start', 'end'],
        order: 2
    },
    delivery: {
        name: 'Delivery',
        value: 'delivery',
        tagCategory: 'delivery',
        backgroundColor: 'brown',
        assetTypes: ['Delivery'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],
        order: 3
    },
    packing: {
        name: 'Packing',
        value: 'packing',
        tagCategory: 'packing',
        backgroundColor: 'navy',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],
        order: 4
    },
    unpacking: {
        name: 'Unpacking',
        value: 'unpacking',
        tagCategory: 'unpacking',
        backgroundColor: 'navy',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        requiredLocations: ['start'],
        defaultLocationTimes: {
            start: 3600,
        },
        order: 5
    },
    // generic: {
    //     name: 'Generic',
    //     value: 'generic',
    //     backgroundColor: 'grey',
    //     revenueGenerating: true,
    //     assetTypes: ['Truck', 'Estimator', 'Delivery'],
    //     order: 6
    // }

};

export const JOB_EVENT_STATUSES = ['cancelled', 'required', 'pending', 'booked', 'confirmed', 'completed'] as const;
export type JobEventStatus = (typeof JOB_EVENT_STATUSES)[number];

export type JobEventType = (typeof JOB_EVENT_TYPES)[number];

export type DistanceUnit = 'imperial' | 'metric';

export const JOB_EVENT_TYPES = Object.keys(eventTypeInfoMap);
export const CHARGE_EVENT_PREFIX = 'event';
export const CHARGE_EVENT_TYPES = JOB_EVENT_TYPES.map((v) => `${CHARGE_EVENT_PREFIX}::${v}`);

export const CHARGE_EVENT_TYPE_FILTER = [
    ...JOB_EVENT_TYPES.map((type) => (
        {
            attribute: `${CHARGE_EVENT_PREFIX}::${type}`,
            name: capitalize(type)
        })),
    {
        attribute: `${CHARGE_EVENT_PREFIX}::none`,
        name: 'None',
    }
];

// In Reverse order accodmdate for additonal location types
export const JOB_LOCATION_ORDER = ['dock', 'end', 'start',];

// eslint-disable-next-line no-shadow
export enum EventLocationTypes {
    dockStart = 'basestart',
    start = 'start',
    end = 'end',
    dockEnd = 'baseend'
}

export const locationNameMap = {
    basestart: 'dock',
    start: 'start',
    end: 'end',
    baseend: 'dock',
};

// How much grouped calendar events are offset (in seconds) to ensure they don't push eachother down (the minimum timeslot)
export const CALENDAR_EVENT_OFFSET = 60;

// All events will render as this long or greater, set in seconds
export const MIN_EVENT_RENDER_TIME = 900;

export const MOVING_EVENT_CALCULATIONS = ['moving-basestart', 'moving-start', 'moving-end', 'moving-baseend'];
export const PACKING_EVENT_CALCULATIONS = ['packing-basestart', 'packing-start', 'packing-end', 'packing-baseend'];
export const UNPACKING_EVENT_CALCULATIONS = ['unpacking-basestart', 'unpacking-start', 'unpacking-end', 'unpacking-baseend'];
export const DELIVERY_EVENT_CALCULATIONS = ['delivery-basestart', 'delivery-start', 'delivery-end', 'delivery-baseend'];
export const ESTIMATING_EVENT_CALCULATIONS = ['estimating-basestart', 'estimating-start', 'estimating-end', 'estimating-baseend'];

export const ZONE_TYPES = {
    area: 'area',
    franchise: 'franchise',
    corporate: 'corporate',
    root: 'root'
} as const;

export const ZONE_ORDER = [...Object.values(ZONE_TYPES)] as const;

export const passwords = {
    regex: /.{10,}/,
};

export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const taxTypes = ['Goverment Tax', 'Provincial Tax', 'State Tax', 'Federal Tax', 'Enviromental Tax', 'General Tax'];



// Values based on a country, if modifying ensure the index order matches
export const countries = ['Canada', 'USA'] as const;
export const currencies = ['CAD', 'USD'] as const;
export const CANADA = countries[0];
export const USA = countries[1];

export const ADDITIONAL_EVENT_TYPES = [
    {
        name: 'Travel Time',
        value: 'travel-time'
    },
    // {
    //     name: 'Availability',
    //     value: 'availability'
    // },
    {
        name: 'Calendar Event',
        value: 'calendar-event'
    },
];

export const CUSTOMER_TYPES = [
    'Residential',
    'Commercial - Move',
    'Commercial - Delivery',
    'Long Distance'
];

export const DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS = [
    'Angie\'s list',
    'Billboard',
    'Box Drop',
    'Direct Mail (Target Data, Door Hangers, Postcards)',
    'Google Search',
    'Home Advisor',
    'Home stars',
    'Lawn Signs',
    'Local Networking Groups (BNI, Associations, etc)',
    'Local Service Ad (LSA)',
    'Moving Waldo',
    'Radio',
    'Referrals (Friends, Family, Neighbor, etc)',
    'Referrals (Realtors, Storage, Trade Parners, etc)',
    'Repeat',
    'Saw The Trucks',
    'Social Media (Facebook, Instagram, etc)',
    'THGR',
    'TV commercial',
    'Yelp!',
    'MoveSnap',
    'Other'
];

export const JOB_ORIGINS = [
    'CSC',
    'OBE',
    'FP',
    'OTR'
];

export const accessLevels = ['any', 'viewer', 'commenter', 'editor', 'manager', 'owner'] as const;

export const transactions = {
    stages: ['pending', 'paid', 'cancelled'],
    types: ['cash', 'stripe'],
};

export const discounts = {
    types: ['percentage', 'amount'],
};

export const PRICE_TYPES = [
    {
        display: '$ - Fixed',
        value: 'fixed'
    }, {
        display: '% - Percentage',
        value: 'percentage',
    }
];

export const estimating = {
    bedroomDropdownOptions: [
        { value: 0, name: 'Bachelor/Studio' },
        { value: 1, name: 1 },
        { value: 2, name: 2 },
        { value: 3, name: 3 },
        { value: 4, name: 4 },
        { value: 5, name: '5+' },
    ],
    dwellingTypes: ['Apartment', 'Condo', 'House', 'Commercial', 'Other'],
    stairsDropDownOptions: [
        { value: 0, name: '1st/Ground Floor' },
        { value: 1, name: '2nd Floor' },
        { value: 2, name: '3rd Floor' },
        { value: 3, name: '4th Floor' },
        { value: 4, name: '5th Floor' },
        { value: 5, name: '6th+ Floor' },
        { value: -0.5, name: 'Half down' },
        { value: 0.5, name: 'Half up' },
    ],
    storeysDropDownOptions: [
        { value: 0, name: '1' },
        { value: 1, name: '2' },
        { value: 2, name: '3' },
        { value: 3, name: '4' },
        { value: 4, name: '5' },
        { value: 5, name: '6' },
    ],
    accessOptions: [
        { value: 'frontDoor', name: 'Front Door' },
        { value: 'sideDoor', name: 'Side Door' },
        { value: 'backDoor', name: 'Back Door' },
        { value: 'garage', name: 'Garage' },
        { value: 'parking', name: 'Parking' },
        { value: 'driveway', name: 'Driveway' },
        { value: 'stairs', name: 'Stairs' },
        { value: 'elevator', name: 'Elevator' }
    ],
    elevatorDropDownOptions: ['None', 'Passenger', ' Freight'],
    inventoryTemplate:
        `LIVING ROOM:

MASTER:

BEDROOMS:

KITCHEN/DINING:

BATHROOM:


Heavy Or Hard To Move Items:

Boxes:

TV's:

Antiques/Unique:

Storage (shed, garage, storage unit):

Patio:

Appliances:
    `,
};



export const dayOfWeekOptions = [
    {
        label: 'Sunday',
        value: 0,
    },
    {
        label: 'Monday',
        value: 1,
    },
    {
        label: 'Tuesday',
        value: 2,
    },
    {
        label: 'Wednesday',
        value: 3,
    },
    {
        label: 'Thursday',
        value: 4,
    },
    {
        label: 'Friday',
        value: 5,
    },
    {
        label: 'Saturday',
        value: 6,
    },
];

export const schedule = {
    rerenderTimeout: 200,
};

export const defaultJobIcon = 'pi pi-briefcase';
export const jobStageIcons = {
    lead: 'pi pi-folder-open',
    estimate: 'pi pi-paperclip',
    booking: 'pi pi-book',
    invoice: 'pi pi-file',
};

export const OBJECT_ICON_MAP = {
    asset: 'pi pi-tablet',
    users: 'pi pi-user',
    job: defaultJobIcon,

    product: 'pi pi-box',
    price: 'pi pi-tag',
    charge: 'pi pi-money-bill',

    franchise: 'pi pi-table',
    'calendar-event': 'pi pi-calendar-times',
};

export const ATTENTION_ACTION_NAMES = {
    'process-job': 'Process Job',
    'book-event': 'Book Event',
    'generate-invoice': 'Generate Invoice',
    'complete-event': 'Complete Event',
    call: 'Call',
};

export const ATTENTION_ACTION_ICONS = {
    'process-job': 'pi pi-arrow-right',
    'book-event': 'pi pi-calendar',
    'generate-invoice': 'pi pi-file',
    'complete-event': 'pi pi-check',
    call: 'pi pi-phone',
};

export const METERS_PER_KM = 1000;
export const FEET_PER_MILE = 5280;
export const METERS_PER_MILE = 1609.34;
export const METERS_PER_FOOT = 0.3048;

export interface Country {
    country: string;
    units: 'metric' | 'imperial';
    currency: string;
    jurisdictions: string[];
}

export const SM_BREAKPOINT = 520;
export const MD_BREAKPOINT = 992;

export const jurisdictions: Country[] = [
    {
        country: 'Canada',
        units: 'metric',
        currency: 'CAD',
        jurisdictions: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
            'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec',
            'Saskatchewan', 'Yukon Territory']

    },
    {
        country: 'USA',
        units: 'imperial',
        currency: 'USD',
        jurisdictions: ['Alaska', 'Alabama', 'Arkansas',
            'American Samoa',
            'Arizona',
            'California',
            'Colorado',
            'Connecticut',
            'District of Columbia',
            'Delaware',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Iowa',
            'Idaho',
            'Illinois',
            'Indiana',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Massachusetts',
            'Maryland',
            'Maine',
            'Michigan',
            'Minnesota',
            'Missouri',
            'Mississippi',
            'Montana',
            'North Carolina',
            'North Dakota',
            'Nebraska',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'Nevada',
            'New York',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Virginia',
            'Virgin Islands',
            'Vermont',
            'Washington',
            'Wisconsin',
            'West Virginia',
            'Wyoming']
    }
];


export const defaultCountry = jurisdictions[1];

export const objectTypes = ['text', 'array', 'color'];

export const regex = {
    areaCodeCanada: '^([A-Y, a-y][0-9]?[A-Z, a-z]?)([0-9]?[A-Z, a-z]?[0-9]?)?$',
    areaCodeUSA: '^[0-9]{1,5}$',
    // eslint-disable-next-line max-len
    email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
    phone: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g,
    // eslint-disable-next-line max-len
    secureURL: '^(?!mailto:)(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
};

export const BRANDING_ICON_SIZES = [
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    48, 64, 128, 196, 256, 512, 1024, 'Wide',
] as const;

export type BrandingIconSize = typeof BRANDING_ICON_SIZES[number];

// List of all currency codes
export const currencyCodes = [
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BOV',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYR',
    'BZD',
    'CAD',
    'CDF',
    'CHE',
    'CHF',
    'CHW',
    'CLF',
    'CLP',
    'CNY',
    'COP',
    'COU',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LTL',
    'LVL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MXV',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'USN',
    'USS',
    'UYI',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XAG',
    'XAU',
    'XBA',
    'XBB',
    'XBC',
    'XBD',
    'XCD',
    'XDR',
    'XFU',
    'XOF',
    'XPD',
    'XPF',
    'XPT',
    'XTS',
    'XXX',
    'YER',
    'ZAR',
    'ZMW'
];

export const DEFAULT_JOB_CLOSED_REASONS: JobClosureReason[] = [
    {
        id: 'complete',
        title: 'Complete',
        stages: ['invoice'],
    },
    {
        id: 'accidental',
        title: 'Accidental',
        stages: [],
    },
    {
        id: 'cannot_fulfill',
        title: 'Cannot fulfill',
        stages: [],
    },
];

export const MAX_32_BIT_INT = 2147483647;

export const JOB_CATEGORIES = ['Moving'];
export const DEFAULT_EVENT_TYPE = 'moving';

export const FIELD_CONFIG = {
    customer: {
        howHeard: {
            name: 'customer.howDidTheyHearAboutUs',
            type: FIELD_TYPE.STRING,
        },
    },
    jobs: {
        inventory: {
            name: 'jobs.inventory',
            type: FIELD_TYPE.OBJECT,
        },
        inventoryNotes: {
            name: 'jobs.inventory-notes',
            type: FIELD_TYPE.STRING,
        }
    },
    end: {
        buildingType: {
            name: 'endLocation.dwellingType',
            type: FIELD_TYPE.STRING,
        },
        sqft: {
            name: 'endLocation.sqft',
            type: FIELD_TYPE.NUMBER,
        },
        stairs: {
            name: 'endLocation.stairs',
            type: FIELD_TYPE.NUMBER,
        },
        bedrooms: {
            name: 'endLocation.bedrooms',
            type: FIELD_TYPE.NUMBER,
        },
        accessNotes: {
            name: 'endLocation.parkingInformation',
            type: FIELD_TYPE.STRING,
        },
        accessOptions: {
            name: 'endLocation.accessOptions',
            type: FIELD_TYPE.OBJECT,
        },
    },
    start: {
        buildingType: {
            name: 'startLocation.dwellingType',
            type: FIELD_TYPE.STRING,
        },
        sqft: {
            name: 'startLocation.sqft',
            type: FIELD_TYPE.NUMBER,
        },
        stairs: {
            name: 'startLocation.stairs',
            type: FIELD_TYPE.NUMBER,
        },
        bedrooms: {
            name: 'startLocation.bedrooms',
            type: FIELD_TYPE.NUMBER,
        },
        accessNotes: {
            name: 'startLocation.parkingInformation',
            type: FIELD_TYPE.STRING,
        },
        accessOptions: {
            name: 'startLocation.accessOptions',
            type: FIELD_TYPE.OBJECT,
        },
    },
}

export const CONFIGS_KEYS = {
    howHeardOptions: 'jobs.howHeardOptions',
    howHeardMandatory: 'jobs.howHeardMandatory',
    dwellingTypeMandatory: 'jobs.dwellingTypeMandatory',
    bedroomsMandatory: 'jobs.bedroomsMandatory',
    inventoryItems: 'inventory.items',
    closedReasons: 'jobs.closedReasons',
    roleCustomer: 'role.customer',
    yemboEnabled: 'yembo-integration.enabled',
    lockDate: 'rolling-lock-date.currentDate',
    virtualEstimateLength: 'calendarEvents.virtualEstimateLength',
    estimatingLength: 'calendarEvents.estimateLength'
}

export type AddLocationType = 'start' | 'end' | 'dock';
export type AdditionalLocationType = 'office' | 'storage' | '2ndStop' | '3rdStor' | 'other';

export const DEFAULT_ROOM_OPTIONS = [
    'Living Room',
    'Dining Room',
    'Kitchen',
    'Bedroom',
    'Bathroom',
    'Office',
    'Garage',
    'Basement',
    'Attic',
];

export const DEFAULT_INVENTORY: Inventory = {
    rooms: [],
    specialtyItems: [],
    totalItems: 0,
    totalVolume: 0,
    totalWeight: 0
};

export const distancesToShow = [
    { key: 'dock_start', label: 'Dock ➞ Start' },
    { key: 'start_end', label: 'Start ➞ End' },
    { key: 'end_dock', label: 'End ➞ Dock' },
    { key: 'dock_start_end_dock', label: 'Total Travel', total: true },
];

export const jobSummaryDefaultToolbar = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': "check" }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],

    // ['clean'],                                         // remove formatting button
];

export const ADDABLE_LOCATIONS_V2 = [
    {
        key: 'office',
        label: 'office',
    },
    {
        key: 'storage',
        label: 'storage',
    },
    {
        key: '2ndStop',
        label: '2nd stop',
    },
    {
        key: '3rdStop',
        label: '3rd stop',
    },
    {
        key: 'other',
        label: 'other',
    },
]

export const distancesToShowV2 = [
    { key: 'dock_start', from: 'Dock', to: 'Start', labelFrom: 'pi pi-warehouse', labelTo: 'pi pi-map-marker' },
    { key: 'start_end' },
    { key: 'end_dock', from: 'End', to: 'Dock', labelFrom: 'pi pi-map-marker', labelTo: 'pi pi-warehouse' },
    { key: 'dock_start_end_dock', label: 'Total Travel', total: true },
];

export const eventTypeInfoMapV2: {
    [key: string]: EventTypeInfo;
} = {

    estimating: {
        name: 'OSEstimate',
        value: 'estimating',
        // this is estimating in production but may be ose on some machines
        tagCategory: 'estimating',
        backgroundColor: 'orange',
        assetTypes: ['Estimator'],
        revenueGenerating: false,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],

        order: 1
    },
    virtualEstimate: {
        name: 'Virtual Estimate',
        value: 'virtualEstimate',
        tagCategory: 'Virtual Estimate',
        assetTypes: ['Estimator'],
        backgroundColor: 'orange-yellow',
        revenueGenerating: false,
        enableDock: false,
        virtualTime: 1800,
        order: 1.5,
        bookingMessage: 'Virtual Estimates cannot be booked in the past',
        requiredLocations: ['start'],
    },
    moving: {
        name: 'Moving',
        value: 'moving',
        tagCategory: 'moving',
        selectedByDefaultInEstimator: true,
        backgroundColor: 'blue',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
            end: 3600,
        },
        requiredLocations: ['start', 'end'],
        order: 2
    },
    delivery: {
        name: 'Delivery',
        value: 'delivery',
        tagCategory: 'delivery',
        backgroundColor: 'brown',
        assetTypes: ['Delivery'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],
        order: 3
    },
    packing: {
        name: 'Packing',
        value: 'packing',
        tagCategory: 'packing',
        backgroundColor: 'navy',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        defaultLocationTimes: {
            start: 3600,
        },
        requiredLocations: ['start'],
        order: 4
    },
    unpacking: {
        name: 'Unpacking',
        value: 'unpacking',
        tagCategory: 'unpacking',
        backgroundColor: 'navy',
        assetTypes: ['Truck'],
        revenueGenerating: true,
        enableDock: true,
        requiredLocations: ['start'],
        defaultLocationTimes: {
            start: 3600,
        },
        order: 5
    },
    // generic: {
    //     name: 'Generic',
    //     value: 'generic',
    //     backgroundColor: 'grey',
    //     revenueGenerating: true,
    //     assetTypes: ['Truck', 'Estimator', 'Delivery'],
    //     order: 6
    // }

};

export const FULL_DISTANCE_FOR_TIMING = 'dock_start_end_dock';

export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PHONE_PATTERN = /^\d{10}$/;

export const JOB_CREATE_INDEXES = {
    fullName: 1,
    email: 2,
    phone: 3,
    movingDate: 4,
    company: 5,
    jobType: 6,
    customerType: 7,
    referralSource: 8,
    joOrigin: 9,
    startLocation: 10,
    endLocation: 11,
    startBuildingType: 12,
    endBuildingType: 13,
    start: {
        bedrooms: 14,
        area: 15,
        floor: 16,
        notes: 17,
    },
    end: {
        bedrooms: 18,
        area: 19,
        floor: 20,
        notes: 21,
    },
    office: {
        address: 31,
        type: 32,
    },
    storage: {
        address: 41,
        type: 42,
    },
    '2ndStop': {
        address: 51,
        type: 52,
    },
    '3rdStop': {
        address: 61,
        type: 62,
    },
    other: {
        address: 71,
        type: 72,
    },
    addStop: 30,
    addRoom: 200, // as lower numbers might be taken by additional stops inputs
    timelineMovingDate: 20000, //as lower indexes might be taken by inventory items
    timelineStartTime: 20001,
    crewSummary: 20002,
    adminSummary: 20003,
    customerSummary: 20004,
};

export const JOB_SUMMARIES = [
    {
        editorId: 'crewEditor',
        key: 'crewSummary',
        name: 'Crew Summary',
        description: 'This summary will be visible for crew and office staff',
    },
    {
        editorId: 'adminEditor',
        key: 'adminSummary',
        name: 'Admin Summary',
        description: 'This summary will be visible for admins',
    },
    {
        editorId: 'customerEditor',
        key: 'customerSummary',
        name: 'Customer Summary',
        description: 'This summary will be visible for customer',
    },
];

const addableLocationsFields = ADDABLE_LOCATIONS_V2.reduce((acc, { key }) => {
    acc[key] = key;
    return acc;
}, {} as Record<string, string>);

const fieldsConfig = Object.entries(FIELD_CONFIG).reduce(
    (acc, [location, fields]) => {
        Object.entries(fields).forEach(([key, fieldConfig]) => {
            acc[`${location}Location.${key}`] = `${location}Location.${key}`;
        });
        return acc;
    },
    {} as Record<string, string>
);

export const locationsFieldsArray = Object.entries(FIELD_CONFIG).reduce<string[]>((acc, [location, fields]) => {
    Object.entries(fields).forEach(([key, fieldConfig]) => {
        acc.push(fieldConfig.name);
    });
    return acc;
}, []);

export const JOB_FORM_FIELDS = {
    howHeard: 'customer.howDidTheyHearAboutUs',
    timeline: 'timeline',
    jobType: 'jobType',
    jobOrigin: 'jobOrigin',
    crewSummary: 'crewSummary',
    adminSummary: 'adminSummary',
    customerSummary: 'customerSummary',
    resolvedServiceArea: 'resolvedServiceArea',
    selectedTimeSlot: 'selectedTimeSlot',
    selectedExistingCustomer: 'selectedExistingCustomer',
    initialCustomerCopy: 'initialCustomerCopy',
    email: 'email',
    phone: 'phone',
    company: 'company',
    fullName: 'fullName',
    inventory: 'inventory',
    dock: 'dock',
    start: 'start',
    end: 'end',
    estimateMethod: 'estimateMethod',
    ...addableLocationsFields,
    ...fieldsConfig,
};

export const zoneSensitiveChanges = ['start', 'end', ...ADDABLE_LOCATION_TYPES, 'resolvedServiceArea',
    'selectedTimeSlot',
    'customer.howDidTheyHearAboutUs', 'selectedExistingCustomer', 'initialCustomerCopy',
    'email', 'fullName', 'phone', 'company',
];

export const CUSTOMER_ROLE_ATTRIBUTE = 'customer';
export const CREW_ROLE_ATTRIBUTE = 'crew';
export const EMPLOYEE_ROLE_ATTRIBUTE = 'employee';

export const requiredFieldsValidationMessages = {
    bedrooms: 'Bedrooms is required.',
    dwellingType: 'Dwelling type is required.',
};

export const statuses = {
    notInitiated: 'not_initiated',
    initiated: 'initiated',
    notBooked: 'not_booked',
};

export const yemboStatuses = {
    selfSurvey: {
        [statuses.notInitiated]: 'Link will be sent after job is saved.',
        [statuses.initiated]: 'Link has been sent.'
    },
    smartConsult: {
        [statuses.notInitiated]: 'Event scheduling will be available after job is saved.',
        [statuses.notBooked]: 'VOSE event is created and needs to be booked.',
        [statuses.initiated]: 'Consult scheduled.'
    }
};

export type JobCreationFormAction = 'create' | 'create-close' | 'update' | 'update-close';

export const PERCENT = 100;
