import { Component,  OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { SharedModule } from '../../../shared/shared.module';
import { jobToolFeature } from '../../job-tool.reducer';

type SelectCustomerReturnType = ReturnType<typeof jobToolFeature.selectCustomer>;

export interface CreateSelfSurveyDialogData {
  customer: SelectCustomerReturnType;
}

@Component({
  selector: 'app-send-self-survey-dialog',
  standalone: true,
  imports: [
    ButtonModule,
    SharedModule
  ],
  providers: [DialogService],
  templateUrl: './create-self-survey-link-dialog.component.html',
  styleUrl: './create-self-survey-link-dialog.component.scss'
})
export class CreateSelfSurveyLinkDialogComponent implements OnInit{
  customer: SelectCustomerReturnType = null;

  constructor(  private config: DynamicDialogConfig<CreateSelfSurveyDialogData>, private ref: DynamicDialogRef) {}

    ngOnInit(): void {
      this.customer = this.config.data?.customer;
    }
  createSelfSurvey() {
    this.ref.close({createSelfSurvey: true});
  }
}
