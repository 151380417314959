import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterContentChecked, ChangeDetectorRef, Component,
  Input, OnDestroy, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserService } from '@karve.it/core';
import { UserRole } from '@karve.it/interfaces/auth';
import { RoleInfo } from '@karve.it/interfaces/roles';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { first, withLatestFrom } from 'rxjs/operators';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';

import { AssignRolesGQL, AuthMethodType, BaseLocationFragment, BaseRoleFragment, BaseZoneFragment, EditProfileGQL, EditProfileMutationVariables, FullUserFragment, ListAuthMethodsGQL, ListUsersGQL, ListUsersQuery, ListUsersQueryVariables, RolesGQL, RolesQuery, RolesQueryVariables, ZoneDir } from '../../../generated/graphql.generated';
import { Zone } from '../../interfaces/zones';

import { jobToolFeature } from '../../jobsv2/job-tool.reducer';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { Address, GoogleHelperService } from '../../services/google-helper.service';

import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { EMAIL_VALIDATION_PATTERN } from '../pattern.validator';
import { JobToolActions } from '../../jobsv2/job-tool.actions';


@Component({
  selector: 'app-mutate-user',
  templateUrl: './mutate-user.component.html',
  styleUrls: ['./mutate-user.component.scss']
})
export class MutateUserComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('firstName') firstNameRef: TemplateRef<any>;
  @ViewChild('lastName') lastNameRef: TemplateRef<any>;
  @ViewChild('company') companyRef: TemplateRef<any>;
  @ViewChild('billingAddress') billingAddressRef: TemplateRef<any>;
  @ViewChild('phone') phoneRef: TemplateRef<any>;
  @ViewChild('email') emailRef: TemplateRef<any>;
  @ViewChild('roles') rolesRef: TemplateRef<any>;
  @ViewChild('sendInvite') sendInviteRef: TemplateRef<any>;
  @ViewChild('inviteOrPassword') inviteOrPasswordRef: TemplateRef<any>;
  @ViewChild('zones') zonesRef: TemplateRef<any>;


  @Input() mutateType: 'update' | 'create';
  @Input() user: FullUserFragment;

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  rolesQueryRef: QueryRef<RolesQuery, RolesQueryVariables>;
  zoneRoles: {
    name: string;
    subName: string;
    id: string;
    search: string;
    zone: BaseZoneFragment;
    role: BaseRoleFragment;
  }[] = [];

  userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]),
    company: new UntypedFormControl(''),
    phone: new UntypedFormControl(undefined, [Validators.minLength(7)]),
    billingAddress: new UntypedFormControl(''),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(EMAIL_VALIDATION_PATTERN)]),
    roles: new UntypedFormControl([], [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(environment.passwordMinLength)]),
    sendInvite: new UntypedFormControl(false, []),
    authMethod: new UntypedFormControl(false),
    zones: new UntypedFormControl([], []),
  });

  userFormValues = this.userForm.value;

  userQueryRef: QueryRef<ListUsersQuery, ListUsersQueryVariables>;

  billingAddress: Address;

  constructor(
    private userService: UserService,
    private listUsersGQL: ListUsersGQL,
    private assignRolesGQL: AssignRolesGQL,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private cd: ChangeDetectorRef,
    private clipboard: Clipboard,
    private freyaHelper: FreyaHelperService,
    public responsiveHelper: ResponsiveHelperService,
    private rolesGQL: RolesGQL,
    private editProfileGQL: EditProfileGQL,
    public listAuthMethods: ListAuthMethodsGQL,
    public googleHelper: GoogleHelperService,
    private store: Store,
  ) {
    this.isValidBillingAddress = this.isValidBillingAddress.bind(this);
  }

  ngOnInit(): void {
    this.user = cloneDeep(this.user);

    this.subs.sink = this.userForm.controls.sendInvite.valueChanges.subscribe((value) => {
      if (this.mutateType !== 'create' || !this.mutateRef.steps) { return; }
      if (value) {
        this.userForm.controls.password.setValue(undefined);
        this.userForm.controls.password.setErrors(null);
      };
      this.validateAuthMethod();
    });

    this.subs.sink = this.userForm.controls.email.valueChanges.subscribe((value) => {
      if (value && value.trim().length) {
        this.userForm.controls.sendInvite.enable();
        return;
      }
      this.userForm.controls.sendInvite.setValue(false);
      this.userForm.controls.sendInvite.disable();
      this.userForm.controls.password.enable();
    });

    this.subs.sink = this.userForm.controls.password.statusChanges.subscribe((value) => {
      this.validateAuthMethod();
    });

    this.subs.sink = this.listAuthMethods.fetch({}).subscribe((res) => {
      if (!res?.data?.authMethods?.length) { return; }

      const minLength = res.data.authMethods.find((a) => a.authMethodType === AuthMethodType.Password)?.password_minLength;

      if (!minLength || minLength < environment.passwordMinLength) { return; }

      const passwordControl = this.userForm.get('password');
      passwordControl.clearValidators();
      passwordControl.setValidators([
        Validators.required,
        Validators.minLength(minLength),
      ]);
    });

    this.userForm.get('billingAddress').setValidators(this.isValidBillingAddress);

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createUser();
    } else if (this.mutateType === 'update') {

      if (this.userForm.controls.roles.dirty) {
        this.editUserRoles();
        this.userForm.controls.roles.markAsPristine();
      }

      if (this.userForm.dirty) {
        this.editUser();
      } else {
        this.mutateRef.closeDialog();
        this.localNotify.success('User already up to date');
      }
    }
  }

  refetchUser() {
    if (this.userQueryRef) {
      this.userQueryRef.setVariables({});
    } else {
      this.userQueryRef = this.listUsersGQL.watch({ filter: { userIds: [this.user.id] } });

      this.subs.sink = this.userQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.user = cloneDeep(res.data.usersv2.users[0]);
        }
      });
    }
  }

  openDialog() {
    this.watchRoles();

    this.steps = [
      { name: 'First Name', ref: this.firstNameRef, control: 'firstName', type: 'text' },
      { name: 'Last Name', ref: this.lastNameRef, control: 'lastName', type: 'text' },
      { name: 'Company', ref: this.companyRef, control: 'company', type: 'text' },
      { name: 'Billing Address', ref: this.billingAddressRef, control: 'billingAddress', type: 'text' },
      { name: 'Phone', ref: this.phoneRef, control: 'phone', type: 'phone' },
      { name: 'Roles', ref: this.rolesRef, control: 'roles', type: 'array' },
      { name: 'Email', ref: this.emailRef, control: 'email', type: 'text' },
    ];

    if (this.mutateType === 'update') {
      this.steps.push({ name: 'Zones', ref: this.zonesRef, control: 'zones', type: 'array' });
    }

    if (this.mutateType === 'create') {
      // Assign Steps
      this.steps.push(
        {
          name: 'Authentication', ref: this.inviteOrPasswordRef, control: 'authMethod', type: 'func', reviewFunc: (val, step) => {
            if (this.userForm.controls.sendInvite.value) {
              return 'Invite will be sent';
            }
            if (this.userForm.controls.password.value) {
              return '*******';
            }
            return 'No password';
          }
        }
        // { name: 'Send Invite', ref: this.sendInviteRef, control: 'sendInvite', type: 'boolean' },
        // { name: 'Password', ref: this.passwordRef, control: 'password', type: 'password' },
      );
      this.reset();
      this.userForm.controls.password.enable();

    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();

    console.log('User form: ', this.userForm);
  }

  setFormValues() {

    if (this.user.billingAddress) {
      this.billingAddress = this.googleHelper.convertLocationToGoogleAddress(this.user.billingAddress as BaseLocationFragment);
    }

    this.userForm.reset({
      firstName: this.user.givenName,
      lastName: this.user.familyName,
      company: this.user.company,
      phone: this.user.phone,
      email: this.user.email,
      roles: this.mapRolesToRetrievedRoles(this.user.roles as UserRole[]),
      password: 'PLACEHOLDER_FOR_VALIDITY',
      sendInvite: new UntypedFormControl({ value: false, disabled: true }, []),
      zones: this.user.zones?.nodes || [],
      billingAddress: this.user.billingAddress?.addressLineOne,
    });
  }

  getRolesVariables(): RolesQueryVariables {
    return {
      search: {
        zoneDir: ZoneDir.Lte,
        limit: -1,
      }
    };
  }

  /**
   * Watch system roles (not user roles)
   * Call again to repull cached value
   */
  watchRoles() {
    if (this.rolesQueryRef) {
      this.rolesQueryRef.setVariables(this.getRolesVariables());
      return;
    }

    // TODO: paginate roles
    this.rolesQueryRef = this.rolesGQL.watch(this.getRolesVariables());

    this.subs.sink = this.rolesQueryRef.valueChanges.subscribe((res) => {
      if (res.data.roles) {
        this.zoneRoles = res.data.roles.map((role) => {
          const [zone] = role.zones.nodes;

          return {
            id: role.id,
            // search across role and zone names
            search: [
              role.name,
              role.zones.nodes.map((z) => z?.name).join('\t'),
              role.zones.nodes.map((z) => z?.id).join('\t'),
            ].join('\t'),
            name: role?.name || 'Unknown Role',
            subName: zone?.name || 'Unknown Zone',
            role,
            zone,
          };
        });

        const roles = this.userForm.get('roles');
        roles.setValue(this.mapRolesToRetrievedRoles(this.user?.roles as UserRole[] || []));
      }
    });
  }

  createUser() {
    const val = this.userForm.value;

    const user = {
      givenName: val.firstName,
      familyName: val.lastName,
      company: val.company,
      phone: val.phone ? val.phone : undefined,
      email: val.email ? val.email : undefined,
      password: val.sendInvite || !val.password ? undefined : val.password,
      billingAddress: this.billingAddress ? this.googleHelper.convertGoogleLocationToCreateLocationInput(this.billingAddress) : undefined,
    };

    this.subs.sink = this.userService.createUsers({
      users: [user],
      roles: this.userForm.controls.roles.value.map((role) => role.id),
      inheritRoleZones: true,
      sendInvite: this.userForm.controls.sendInvite.value,
    }).subscribe((res) => {
      if (res.failedUsers.length > 0) {
        this.mutateRef.loading = false;
        this.localNotify.addToast.next({ severity: 'error', summary: 'Failed to create user', detail: res.failedUsers[0].error });
        return;
      }
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'User created' });

      this.reset();
      this.detailsHelper.pushUpdate({
        id: res.users[0].id,
        type: 'User',
        action: 'create',
        update: 'create'
      });

      this.detailsHelper.detailsItem.next({ type: 'users', item: { id: res.users[0].id } });
    });
  }

  reset() {
    this.userForm.reset(this.userFormValues);
    this.billingAddress = undefined;
  }

  async editUser() {
    const formValue = this.userForm.value;

    const billingAddresCtrl = this.userForm.get('billingAddress');

    const billingAddressUpdated = billingAddresCtrl.dirty && this.billingAddress;

    const billingAddressCleared = billingAddresCtrl.dirty && !this.billingAddress;

    const editUserInput: EditProfileMutationVariables = {
      id: this.user.id,
      givenName: formValue.firstName,
      familyName: formValue.lastName,
      company: formValue.company ? formValue.company : undefined,
      phone: formValue.phone ? formValue.phone : undefined,
      email: this.userForm.controls.email.dirty ? formValue.email : undefined,
    };

    if (billingAddressUpdated) {
      editUserInput.billingAddress = this.googleHelper.convertGoogleLocationToCreateLocationInput(this.billingAddress);
    } else if (billingAddressCleared) {
      editUserInput.billingAddress = null;
    }

    // Save changes to the users zones
    await this.freyaHelper.saveZoneChanges(this.user.zones?.nodes as Zone[] || [], formValue.zones, [this.user.id]);

    this.subs.sink = this.editProfileGQL.mutate(editUserInput).subscribe((res) => {
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'User Edited' });
      this.updateLocalUser();

      console.log('Dispatch user updated');

      this.store.dispatch(JobToolActions.userUpdated({ userId: this.user.id }));

      this.detailsHelper.pushUpdate({
        id: this.user.id,
        type: 'User',
        action: 'update',
        update: 'update'
      });

    }, (err) => {
      console.error(err);
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to edit user`, err);
    });
  }

  // Confirm the Changes
  updateLocalUser() {
    this.user.phone = this.userForm.controls.phone.value;
    this.user.company = this.userForm.controls.company.value;
    this.user.givenName = this.userForm.controls.firstName.value;
    this.user.familyName = this.userForm.controls.lastName.value;
  }

  editUserRoles() {
    const roleIds = this.userForm.value.roles.map((r) => r.id);
    this.subs.sink = this.assignRolesGQL.mutate({
      roles: roleIds,
      users: [this.user.id],
      inheritZones: true,
      unassignMissing: true,
    }).subscribe((res) => {
      const update = res.data?.assignRoles;
      if (!update) { return; }

      const detail: string[] = [];
      if (update.rolesAssigned.length) {
        detail.push(`${update.rolesAssigned.length} role${update.rolesAssigned.length === 1 ? '' : 's'} assigned`);
      }
      if (update.rolesRemoved.length) {
        detail.push(`${update.rolesRemoved.length} role${update.rolesRemoved.length === 1 ? '' : 's'} removed`);
      }
      // if (update.zonesAssigned.length) {
      // detail.push(`${ update.zonesAssigned.length } zone${ update.zonesAssigned.length === 1 ? '' : 's' } assigned`);
      // }

      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({
        severity: 'success',
        summary: `Roles updated`,
        detail: detail.join(' and '),
      });

      this.detailsHelper.pushUpdate({
        id: this.user.id,
        type: 'User',
        action: 'update',
        update,
      });
    }, (err) => {
      this.mutateRef.loading = false;
      console.error(err);
      this.userForm.controls.roles.setValue(this.user.roles);
      this.localNotify.apolloError(`Could not assign roles`, err);
    });
  }

  mapRolesToRetrievedRoles(roles: RoleInfo[]): RoleInfo[] {
    const mappedRoles = [];

    for (const role of roles) {
      const matchedRole = this.zoneRoles.find((ur) => ur.id === role.id);
      if (!matchedRole) {
        continue;
      }
      mappedRoles.push(matchedRole);
    }

    return mappedRoles;
  }

  copyPassword() {
    this.clipboard.copy(this.userForm.value.password);
    this.localNotify.success('Copied to clipboard');
  }

  unselectRole(role: UserRole) {
    const filteredRoles = this.userForm.value.roles.filter((r) => r.id !== role.id);
    this.userForm.controls.roles.setValue(filteredRoles);
    this.userForm.controls.roles.markAsDirty();
  }

  /**
   * Sets authMethod control as valid or invalid based on sendInvite and password controls
   * Used to set the right color for authentication step in review listbox
   */
  validateAuthMethod() {
    if (this.userForm.controls.sendInvite.value || this.userForm.controls.password.valid) {
      this.userForm.controls.authMethod.setErrors(null);
      return;
    }
    this.userForm.controls.authMethod.setErrors({ incorrect: true });
  }

  handleAddressChange(billingAddress: Address) {
    this.billingAddress = billingAddress;
    const control = this.userForm.get('billingAddress');
    control.setValue(billingAddress.formatted_address);
    control.updateValueAndValidity();
  }

  clearBillingAddress() {
    this.billingAddress = undefined;
    const control = this.userForm.get('billingAddress');
    control.updateValueAndValidity();
  }

  clearBillingAddressControl() {
    const control = this.userForm.get('billingAddress');

    const currentVal = control.value;

    control.setValue('');

    if (currentVal) {
      control.markAsDirty();
    }

    this.clearBillingAddress();
  }

  isValidBillingAddress(control: AbstractControl): ValidationErrors | null {

    if (this.billingAddress && control.value.length) {
      return null;
    }

    if (!this.billingAddress && !control.value?.length) {
      return null;
    }

    return {
      invalidLocation: 'Please select a location from the autocomplete menu',
    };
  }

}
