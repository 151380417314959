import { Component } from '@angular/core';

import { formatCurrency } from '../../lib.ts/currency.util';
import { DashboardStore } from '../dashboardv2.store';

@Component({
  selector: 'app-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './event-summary.component.scss',
  ],
})
export class EventSummaryComponent {

  events$ = this.dashboardStore.eventsViewModel$;

  constructor(
    private readonly dashboardStore: DashboardStore,
  ) {}

}
