/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, EventDropArg, EventInput } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg, ThirdPartyDraggable } from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { dayJsFullCalendarTimeZonePlugin, dayjs } from '@karve.it/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { capitalize, intersection } from 'lodash';
import { SharedModule } from 'primeng/api';
import { debounceTime, withLatestFrom } from 'rxjs';

import { CalendarHelperService } from 'src/app/calendar-helper.service';

import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { isFinalizedInvoice } from 'src/app/invoices/invoices.utils';
import { RESOURCE_AREA_HEADER } from 'src/app/schedules/schedule.constants';
import { ScheduleView, ScheduleViewSimple, scheduleFeature, simpleScheduleViewToFullCalendarView } from 'src/app/schedules/store/schedules.reducer';
import { BrandingService } from 'src/app/services/branding.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { ExtendedCalendarEventForScheduleFragment, FullCalendarHelperService } from 'src/app/services/full-calendar-helper.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { dateToDateString } from 'src/app/time';

import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { AvailabilityOutput, BaseAssetFragment, BaseCalendarEventFragment, CalendarEvent, CalendarEventForScheduleFragment, FullAssetFragment } from '../../../generated/graphql.generated';
import { MIN_EVENT_RENDER_TIME, eventTypeInfoMap } from '../../global.constants';
import { ScheduleEventsActions } from '../../jobsv2/job-state/event-schedule-state/event-schedule.actions';
import { selectEventsWithChanges } from '../../jobsv2/job-state/event-schedule-state/event-schedule.selectors';
import { jobToolFeature } from '../../jobsv2/job-tool.reducer';
import { ScheduleActions } from '../store/schedule.actions';


export type EventChange = (EventResizeDoneArg | EventDropArg) & {
  notifyAttendees: boolean;
  status: 'Pending' | 'Saving' | 'Saved' | 'Failed';
  hasConflict: boolean;
};

@Component({
  selector: 'app-schedule-v2',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
    FullCalendarModule,
  ],
  templateUrl: './schedule-v2.component.html',
  styleUrls: ['./schedule-v2.component.scss'],
})
export class ScheduleV2Component implements OnInit, OnDestroy, AfterViewInit {

  // Calendar
  @ViewChild('fc', { static: true }) calendarComponent: FullCalendarComponent;
  // @ViewChild('scheduleOverlay') scheduleOverlay: OverlayPanel;

  @Input() height = '85vh';
  // Change the height based on the number of assets
  @Input() dynamicHeight = true;

  // The area whose events/availability should be shown, defaults to all
  // @Input() area: string;

  // @Input() jobInDifferentTimezoneWarning: string;
  // @Input() timezone: string;
  // @Input() event: CalendarEvent;

  // If false shows the totals for day, If true shows all events for day
  showMonthEvents = false;

  get calendar() {
    return this.calendarComponent?.getApi();
  }
  subs = new SubSink();
  timeOuts: NodeJS.Timeout[] = [];
  events: CalendarEventForScheduleFragment[];
  assets: FullAssetFragment[];

  //observables
  // events$ = this.store.select(scheduleFeature.selectEvents);
  title$ = this.store.select(scheduleFeature.selectTitle).pipe(debounceTime(1));
  loading$ = this.store.select(scheduleFeature.selectLoading);
  view$ = this.store.select(scheduleFeature.selectView);
  // $ = this.store.select(scheduleFeature.selectAvailability);
  // eventDataErrors$ = this.store.select(scheduleFeature.selectEventDataErrors);

  // TODO: Break this out into a service value or a seperate file
  calendarOptions: CalendarOptions = {
    headerToolbar: false,
    plugins: [
      resourceTimelinePlugin,
      interactionPlugin,
      dayGridPlugin,
      dayJsFullCalendarTimeZonePlugin
    ],
    timeZone: this.timeZoneHelper.getCurrentTimezone(), // will be overriden in OnChanges with job timezone
    eventMinWidth: 0.001,
    height: 'auto',
    initialView: 'resourceTimelineDay',
    schedulerLicenseKey: environment.fullCalendarKey,
    eventDisplay: 'block', // Without this dayGrid events don't have backgrounds
    resourceAreaHeaderContent: RESOURCE_AREA_HEADER,
    resourceAreaWidth: this.fcHelper.getResourceAreaWidth(),
    stickyFooterScrollbar: true,
    titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'short'
    },
    eventResizableFromStart: false,
    eventDurationEditable: false,
    // Sets the granularity for moving events
    snapDuration: '00:05:00',
    // Sets the frequency of the lines
    slotDuration: '01:00:00',
    slotLabelInterval: '02:00:00',
    // Sets the start and end times for the day
    slotMinTime: '06:00:00',
    slotMaxTime: '22:00:00',
    navLinks: false,
    dayMaxEvents: 0,
    views: {
      dayGridMonth: { // name of view
        titleFormat: { month: 'long', year: 'numeric' },
      },
      resourceTimelineWeek: {
        titleFormat: { month: 'long', day: 'numeric', year: 'numeric' }
      }
    },
    resources: [
      {
        id: 'loading',
        title: 'Loading ...',
      }
    ],
    resourceOrder: 'order',
    events: [],
    eventOrder: 'start',
    // Do not automatically scroll the calendar when dragging an event on mobile (makes dragging awkward)
    dragScroll: !this.responsiveHelper.isSmallScreen,
    longPressDelay: this.responsiveHelper.isSmallScreen ? this.responsiveHelper.dragDelay : undefined,
    nowIndicator: true,

    moreLinkDidMount: (arg) => {
      // @ts-ignore
      const date = arg.el.closest('.fc-day').dataset.date;
      const eventsForDay = this.events
        .filter((ev) => dayjs(ev.start * 1000).format('YYYY-MM-DD') === date);

      const content = document.createElement('div');
      content.classList.add('content');

      // TODO:  FIX
      const assetTypes = [];
      const filteredEventsForDay = eventsForDay.filter(event => !assetTypes.length || event.assets.some((asset) => assetTypes.includes(asset.type)));
      const totalEl = document.createElement('div');
      if (filteredEventsForDay.length > 0) {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        totalEl.classList.add('total');
        totalEl.style.color = 'var(--text-color)';
        totalEl.title = `Discounted Subtotal for ${formattedDate}`;
        const totalAmount = (filteredEventsForDay.reduce((total, current) => total + current.discountedSubTotal, 0) / 100).toFixed(2);
        totalEl.innerText = `$${totalAmount}`;
      }

      const eventBadgesEl = document.createElement('div');
      eventBadgesEl.classList.add('badges-container');
      const eventTypesToDisplay = {};

      for (const event of filteredEventsForDay) {
        if (event.type === 'book-off') {
          continue;
        }
        if (eventTypesToDisplay[event.type]) {
          eventTypesToDisplay[event.type].push(event);
          continue;
        }
        eventTypesToDisplay[event.type] = [event];
      }
      // Create a badge for every event type present on the day
      for (const eventType of Object.keys(eventTypesToDisplay)) {
        const badge = document.createElement('div');
        badge.title = `${eventTypesToDisplay[eventType]?.length} ${capitalize(eventType)} events`;
        badge.innerText = `${eventTypesToDisplay[eventType]?.length}`;

        const backgroundColor = eventTypeInfoMap[eventType]?.backgroundColor || 'primary-color';

        badge.style.backgroundColor = `var(--${backgroundColor})`;
        eventBadgesEl.append(badge);
      }

      content.append(totalEl, eventBadgesEl);

      arg.el.append(content);
    },
    moreLinkClick: (info) => {
      const tzDate = dayjs(dateToDateString(info.date), 'YYYY/MM/DD').add(1, 'day').toDate();
      this.calendar.gotoDate(tzDate);
      this.calendar.changeView('resourceTimelineDay');
    },
    moreLinkContent: (args) => ({ domNodes: [document.createElement('div')] }),
    resourceLabelContent: (info) => (
      this.fcHelper.generateResourceLabel(info, this.assets)
    ),
    eventContent: (event) => {

      const calendarEvent = event.event.extendedProps as CalendarEventForScheduleFragment;
      // const calendarEvent = this.calendarEvents.find((ce) => ce.id === event.event._def.groupId);

      let eventEl;

      if (event.event.extendedProps?.type === 'availability') {
        eventEl = this.fcHelper.createFreyaAvailability(false);
      } else if (calendarEvent?.type === 'book-off') {
        eventEl = this.fcHelper.createBookedOffEvent(calendarEvent, this.calendar.view.type);
      } else {

        let disabled = false;

        if (this.freyaHelper.lockDate > calendarEvent.end) {
          disabled = true;
        }

        if (calendarEvent.invoices?.some(isFinalizedInvoice)) {
          disabled = true;
        }

        eventEl = this.fcHelper.createFreyaEvent(
          event,
          calendarEvent as ExtendedCalendarEventForScheduleFragment,
          this.calendar,
          false,
          false,
          false,
          disabled);
      }

      return { domNodes: [eventEl] };
    },
    datesSet: (info) => {
      if(!this.calendar)  return;
      this.calendarHelper.displayedDateChanged(info.startStr, info.view.type);
      
      const timezone = this.calendar.getOption('timeZone');
      const date = dayjs.tz(this.calendar.getDate(), timezone);
      const view = this.calendar.view;
      
      this.store.dispatch(ScheduleActions.fcViewChanged({
        timezone,
        date: date.format('YYYY-MM-DD'),
        start: dayjs(view.currentStart).unix(),
        end: dayjs(view.currentEnd).unix(),
        view: view.type as ScheduleView,
        title: this.getTitle(),
      }));
    },
    dateClick: (info) => {
      this.store.dispatch(ScheduleActions.fcDateClicked({
        date: info.dateStr,
        view: this.calendar.view.type as ScheduleView,
      }));
    },
    navLinkWeekClick: (date, event) => {
      event.preventDefault();
    },
    eventResize: (info: EventResizeDoneArg) => {
      // Pending events do not send notifications
      const isPending = info.event?._def.extendedProps?.event?.status === 'pending';
    },
  };

  ScheduleActions = ScheduleActions;

  constructor(
    // Angular
    public store: Store,
    private actions: Actions,
    // Helpers
    public responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private fcHelper: FullCalendarHelperService,
    private calendarHelper: CalendarHelperService,
    private brandingService: BrandingService,
    private timeZoneHelper: TimezoneHelperService,
    public permissions: PermissionService,
  ) { }

  ngOnInit(): void {
    // This ensures that the calendar is rerendered if the container size changes to match that size.
    this.subs.sink = this.freyaHelper.containerSizeChanged.subscribe(() => {
      this.renderAfterDelay();
    });

    // Update the calendar to reflect any changes based on authentication status
    this.subs.sink = this.brandingService.currentZone().subscribe(() => {

      // reload the component it needs to reinitialize
      this.store.dispatch(ScheduleActions.componentInitialized({
        title: this.getTitle(),
      }));
    });

    // subscribe to any actions that effect the state here
    this.subs.add(
      this.actions.pipe(ofType(ScheduleActions.viewSelected)).subscribe((action) => {
        const { date, view } = action;
        this.setDateAndView(date, view);
      }),
      this.actions.pipe(ofType(ScheduleActions.timezoneChanged)).subscribe((action) => {
        const { timezone } = action;
        if (!timezone || !this.calendar) {
          return;
        }
        this.calendar.setOption('timeZone', timezone);
      }),
      this.actions.pipe(ofType(ScheduleActions.storeInitialized)).subscribe((action) => {
        const { timezone, date, view } = action;
        if (!timezone || !this.calendar) {
          return;
        }
    
        this.calendar.setOption('timeZone', timezone);
        this.calendar.removeAllEvents();
        this.events = [];
        this.setDateAndView(date, view);
      }),
      this.actions.pipe(ofType(ScheduleActions.eventsLoaded)).subscribe((action) => {
        this.events = action.events;
      }),
      this.actions.pipe(
        ofType(
          ScheduleActions.assetsLoaded,
          ScheduleActions.availabilityLoaded,
          ScheduleActions.eventsLoaded,
          ScheduleEventsActions.assetsSelected,
          ScheduleEventsActions.dateSelected,
          ScheduleEventsActions.timeSelected,
          ScheduleEventsActions.bookEventError,
          ScheduleEventsActions.bookEventSuccess,
          ScheduleEventsActions.rescheduleButtonClicked,
        ),
        // these may all load at once and cause a bunch of lag
        debounceTime(150),
        withLatestFrom(
          this.store.select(scheduleFeature.selectAssets),
          this.store.select(scheduleFeature.selectAvailability),
          this.store.select(selectEventsWithChanges),
          this.store.select(jobToolFeature.selectEventToBook),
        ),
      ).subscribe(([ action, assets, availability, events, eventToBook ]) => {
        this.renderData(assets, availability, events, eventToBook);
      }),
    );
  }

  ngAfterViewInit() {

    // this.subs.sink = this.calendarAssets$.subscribe((calendarAssets) => {
    //   this.assets = cloneDeep(calendarAssets);

    //   this.calendar?.batchRendering(() => {
    //     this.convertAssetsIntoResources();
    //   });

    //   if (!this.assets.length) {
    //     this.noAvailableAssetsWarning = 'There are no available assets in the area where current job is being placed.';
    //   }
    // });

    // this.subs.sink = this.calendarEventDataErrors$.subscribe((calendarEventDataErrors) => {
    //   this.calendarEventDataErrors = cloneDeep(calendarEventDataErrors);
    // });

    // this.changeDate('today');

    const container = document.getElementById('droppable-area');

    new ThirdPartyDraggable(container);

    this.store.dispatch(ScheduleActions.componentInitialized({
      // view
    }));
  }

  ngOnDestroy() {
    this.clearOverlays();
    this.timeOuts.forEach((t) => clearTimeout(t));
    this.subs.unsubscribe();
    this.freyaHelper.scheduleEditModeEnabled.next(false);
  }


  setDateAndView(
    date?: string,
    view?: ScheduleViewSimple,
  ) {
    if (!this.calendar) {
      return;
    }

    return this.calendar.batchRendering(() => {
      if (date) {
        // Assuming `date` is already formatted as 'YYYY-MM-DD'
        this.calendar.gotoDate(date);
      }

      const fcView = simpleScheduleViewToFullCalendarView(view);

      if (fcView) {
        this.calendar.changeView(fcView);
      }
    });
  }

  renderData(
    assets: FullAssetFragment[] | undefined,
    availability: AvailabilityOutput[] | undefined,
    events: CalendarEventForScheduleFragment[] | undefined,
    eventToBook?: CalendarEventForScheduleFragment,
  ) {
    this.calendar?.batchRendering(() => {
      this.calendar.removeAllEvents();

      if (assets) {
        this.addAssetsAsResources(assets, eventToBook);
      }

      if (availability) {
        // this.removeEventsOfTypes(['availability']);
        this.addAvailabilityEvents(availability);
      }

      if (events) {
        // this.removeEventsOfTypes(['calendar-event']);
        this.addCalendarEvents(events, assets);
      }
      // this.renderAfterDelay();

      const loadingResource = this.calendar.getResourceById('loading');
      loadingResource?.remove();
    });
  }

  // CALENDAR HELPERS

  setCalendarDate(date: Date) {
    this.fcHelper.changeCalendarDate.next(date);
  }

  isMonthView() {
    return this.calendar.view.type === 'dayGridMonth';
  }

  getTitle() {
    return this.calendar?.getCurrentData()?.viewTitle || 'Schedule';
  }

  /**
   * Changes the view based on the view type, throws a warning in edit mode
   *
   * @param viewType The type of you you want to render
   */
  changeView(viewType: string) {
    this.calendar.changeView(viewType);
    this.setCalendarHeight();
  }

  changeDate(dateType: 'prev' | 'next' | 'today' | Date) {

    switch (dateType) {
      case 'prev':
        this.calendar?.prev();
        break;
      case 'next':
        this.calendar?.next();
        break;
      case 'today':
        this.calendar?.today();
        break;
      default:
        if(dateType instanceof Date) {
          this.calendar?.gotoDate(dateType);
        }
        break;
    }
  }

  setCalendarHeight(height?: string) {
    if (this.calendar.view.type === 'dayGridMonth') {
      height = 'auto';
    }


    if (!height) { return; }
    this.calendar.setOption('contentHeight', height);

  }

  clearOverlays() {
    const overlays = document.querySelectorAll('.freya-fc-hover');

    for (let i = 0; i < overlays.length; i++) {
      overlays.item(i).remove();
    }
  }

  // Calendar is drawn not dynamic, as such for events not watched by FC (container size) we must call this.
  renderAfterDelay() {
    this.calendar.setOption('resourceAreaWidth', this.fcHelper.getResourceAreaWidth());
    const timeout = setTimeout(() => { // Timeout ensures that the DOM has been updated before we try to redraw
      this.calendar.render();

      // This logic handles setting the top property for the times to never overlap with the dates
      const sourceElement = document.getElementsByClassName('calendar-header-wrapper').item(0);
      const targetElement = document.getElementsByClassName('fc-scrollgrid').item(0).getElementsByTagName('thead')[0];

      // Get the height of the source element
      const sourceElementHeight = sourceElement.clientHeight;

      // Set the top property of the target element based on the height of the source element
      targetElement.style.top = sourceElementHeight + 'px';
    }, 225);

    this.timeOuts.push(timeout);
  }

  getCalendarActionVariables() {
    const start = dayjs(this.calendar.view.currentStart || new Date()).startOf('day');
    const end = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');

    const duration = 0;

    const min = dayjs(start).startOf('day').subtract(duration, 'day').unix();
    const max = dayjs(end).endOf('day').add(duration, 'day').unix();

    return {
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
      min,
      max
    }
  }

  getDateForDatePicker() {
    const currentDate = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');
    return currentDate.format('MM/DD/YYYY');
  }

  /**
   * Remove events of a certain type from the calendar
   *
   * @param eventTypes The types to remove
   */
  removeEventsOfTypes(eventTypes: string[]) {
    const eventsToRemove = this.calendar.getEvents().filter((e) => eventTypes.includes(e?.extendedProps?.type));

    for (const event of eventsToRemove) {
      event.remove();
    }

  }

  // QUERY HELPERS
  // getScheduleVariables(): { availabilty: AvailabilityQueryVariables; events: ScheduleEventsQueryVariables } {
  //   const start = dayjs(this.calendar.view.currentStart || new Date()).startOf('day');
  //   const end = dayjs(this.calendar.view.currentEnd || new Date()).endOf('day');

  //   // add / subtract one period so previous/next loads quickly
  //   // TODO: REDO this logic to run as supporting requests
  //   const duration = 0; // end.diff(start, 'day');
  //   const min = dayjs(start).startOf('day').subtract(duration, 'day').unix();
  //   const max = dayjs(end).endOf('day').add(duration, 'day').unix();

  //   return {
  //     availabilty: {
  //       objectIds: this.getDisplayedAssets().map((a) => a.id),
  //       startDate: start.format('YYYY-MM-DD'),
  //       endDate: end.format('YYYY-MM-DD'),
  //       zone: this.area || undefined,
  //     },
  //     events: {
  //       filter: {
  //         min,
  //         max,
  //       },
  //       limit: 2000,
  //     }
  //   };
  // }

  // generateAssetInput() {
  //   return {
  //     filter: {
  //       types: this.scheduleFilters.get('assetTypes').value.length ? this.scheduleFilters.get('assetTypes').value : undefined,
  //       zoneDir: ZoneDir.any,
  //     },
  //     limit: -1
  //   };
  // }

  // PARSE OBJECTS TO Full Calendar
  addCalendarEvents(
    events: ExtendedCalendarEventForScheduleFragment[],
    assets: BaseAssetFragment[],
  ) {

    for (const ev of events) {
      const start = ev.start;
      const end = ev.end;

      // This may contain the id of deleted assets...
      const allAssetIds = ev.assets?.map((a) => a.id) || [];

      // ...therefore, make sure allAssetIds contains the id of at least one existing asset...
      const hasExistingAssets = ev?.assets?.length
        && intersection(allAssetIds, (assets || []).map((a) => a.id)).length;

      // ...otherwise assign 'none' so the event is assigned to the 'Unassigned' resource
      const assetIds = hasExistingAssets ? allAssetIds : ['none'];

      let endForRendering = end;

      if ((end - start) < MIN_EVENT_RENDER_TIME) {
        endForRendering += MIN_EVENT_RENDER_TIME - (end - start);
      }

      const {
        backgroundColor,
        textColor,
      } = this.fcHelper.determineColorsFromEvent(ev);

      // Create Actual Event
      const event: EventInput = {
        title: ev.title,
        groupId: ev.id,
        start: start * 1000,
        end: endForRendering * 1000,
        id: ev.id,
        extendedProps: {
          ...ev,
          type: 'calendar-event',
          eventId: ev.id,
          event: ev,
          eventType: ev.type,
        },
        resourceIds: assetIds,
        color: backgroundColor,
        textColor,
        editable: false,
        resourceEditable: false,
      };
      this.calendar.addEvent(event);

    }
  }

  // Turn System Assets into Calendar Resources
  addAssetsAsResources(
    assets: FullAssetFragment[],
    eventToBook: BaseCalendarEventFragment,
  ) {
    this.assets = assets;
    this.calendar.refetchResources();

    if (eventToBook) {
      const assetTypes = eventTypeInfoMap[eventToBook.type]?.assetTypes;
      if (assetTypes?.length) {
        assets = assets.filter((a) => assetTypes.includes(a.type));
      }
    }

    let order = 0;

    this.calendar.addResource({ // Add a resource for events that don't have any assets
      id: 'none',
      title: 'Unassigned',
      eventColor: 'red',
      type: 'Unassigned',
      order,
    });
    order++;

    for (const asset of assets) {
      this.calendar.addResource({
        id: asset.id,
        title: `${asset.name} (${asset.type})`,
        eventColor: asset.zones[0]?.color,
        extendedProps: {
          id: asset.id,
          type: asset.type,
          name: asset.name,
        },
        order,
      });

      // increment order
      order++;
    }

    if (this.dynamicHeight) {
      this.setCalendarHeight(`${80 + ((this.calendar.getResources().length) * 52)}px`);
      // this.calendar.setOption('contentHeight', ``);
    }
  }

  addAvailabilityEvents(
    availabilities: AvailabilityOutput[] | undefined,
  ) {
    for (const object of availabilities) {

      for (const day of object.dayAvailability) {

        // If the asset has no availability, we need to put a dummy availability to show the inverse of
        if (!day.availability?.length) {
          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start: `${day.date}T00:00:00`,
            end: `${day.date}T00:00:1`,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });

          continue;
        }

        for (const avail of day.availability) {
          const start = avail.start * 1000;
          const end = avail.end * 1000;

          this.calendar.addEvent({
            title: 'Unavailable',
            groupId: `${object.objectId}-availability`,
            start,
            end,
            display: 'inverse-background',
            // The Asset that this availability belongs to
            resourceIds: [object.objectId],
            color: '#666666',
            extendedProps: {
              type: 'availability',
            },
          });
        }
      }
    }
  }

  setMaxEvents(showAllEvents: boolean) {
    if (showAllEvents) {
      this.calendar.setOption('dayMaxEvents', false);
    } else {
      this.calendar.setOption('dayMaxEvents', 0);
    }
  }
}
