import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SubSink } from 'subsink';

import { ChargeWithJobFragment, ListChargesGQL, ListChargesQueryVariables } from '../../../generated/graphql.generated';
import { OBJECT_ICON_MAP } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';

@Component({
  selector: 'app-charge-details',
  templateUrl: './charge-details.component.html',
  styleUrls: ['./charge-details.component.scss']
})
export class ChargeDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() charge: ChargeWithJobFragment;

  icon = `${OBJECT_ICON_MAP.charge} large-icon`;
  subs = new SubSink();

  // editPriceItem = {
  //   label: 'Edit',
  //   icon: 'pi pi-pencil',
  //   visible: false,
  //   command: () => {
  //     this.freyaMutateSvc.openMutateObject({
  //       mutateType: 'update',
  //       objectType: 'charge',
  //       object: this.charge,
  //       additionalValues: [{
  //         property: 'job',
  //         value: this.job
  //       },
  //       {
  //         property: 'isDamage',
  //         value: this.isDamages,
  //       }]
  //     });
  //   },
  // };

  // deletePriceItem = {
  //   label: 'Delete',
  //   icon: 'pi pi-trash',
  //   visible: false,
  //   command: () => {
  //     this.freyaMutateSvc.openDeleteObject({
  //       objectId: this.price.id,
  //       objectName: this.price.name,
  //       objectType: 'charge',
  //     });
  //   }
  // };

  priceActions = [
    {
      label: 'Actions',
      visible: false,
      items: [
      ]
    }
  ] as MenuItem[];

  queryRef: ReturnType<typeof this.listChargesGQL['watch']>;

  loading = false;

  eventLocked = false;

  eventLockedTooltip: string;

  get promisedChargeEvent() {
    return this.freyaHelper.getAttributeValueByPrefix(this.charge.attributes, 'event', '::') || 'None';
  }

  constructor(
    public detailsHelperSvc: DetailsHelperService,
    private freyaMutateSvc: FreyaMutateService,
    private listChargesGQL: ListChargesGQL,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit(): void {
    this.subs.sink = this.detailsHelperSvc.getObjectUpdates('Charges').subscribe(()=>{
      this.refetch();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.charge?.job) {
      // this.refetch();
    }

    if (changes.charge && this.charge) {
      this.eventLocked = this.freyaHelper.lockDate > this.charge.calendarEvent?.end;

      if (this.eventLocked) {
        this.eventLockedTooltip = `Cannot modify charges on events ending before ${this.freyaHelper.getFormattedLockDate()}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getFetchVariables() {
    return {
      filter: {
        ids: [ this.charge.id ],
      },
      limit: 1,
    } as ListChargesQueryVariables;
  }

  refetch() {
    if (this.queryRef) {
      this.queryRef.refetch(this.getFetchVariables());
      return;
    };

    this.initQuery();

  }

  initQuery() {

    this.queryRef = this.listChargesGQL.watch(this.getFetchVariables(), {});

    this.subs.sink = this.queryRef.valueChanges.subscribe((res) => {
      this.loading = res.loading;
      const [ resItem ] = res.data?.charges?.charges || [];
      if (!resItem) { return; }
      this.charge = resItem;
    });
  }

  editCharge(charge: ChargeWithJobFragment){
    this.freyaMutateSvc.openMutateObject({
      mutateType: 'update',
      objectType: 'charge',
      object: charge,
    });
  }

}
