import { environment } from '../../environments/environment';
import { safeParseJSON } from '../js';

import { JobDataToSaveInLS } from './jobv2-create/jobv2-create-state/jobv2-create.selectors';

interface LastEditedJobsData {
	version: number;
	jobs: JobDataToSaveInLS[];
}

/*
 * We have updated the data structure of the lastEditedJobs in the local storage.
 * To avoid conflicts with the old data structure, we are using versioning.
 * For older versions, we will discard the data and start fresh.
 */
const LAST_EDITTED_JOB_FROM_LOCAL_STORAGE_VERSION = 1;

export function saveLastEditedJobsToLocalStorage(updatedRecentJobs: JobDataToSaveInLS[]) {
    try {
        
		const dataToSave: LastEditedJobsData = {
			version: LAST_EDITTED_JOB_FROM_LOCAL_STORAGE_VERSION,
			jobs: updatedRecentJobs
		};
		localStorage.setItem(
            environment.lskeys.lastEditedJobState,
            JSON.stringify(dataToSave)
        );
    
	} catch (error) {
        console.error('Error saving last edited jobs to local storage:', error);
    }
}

export function getLastEditedJobsFromLocalStorage(): JobDataToSaveInLS[] {
    try {
        
		const storedData = safeParseJSON<LastEditedJobsData>(
            localStorage.getItem(environment.lskeys.lastEditedJobState), { version: 0, jobs: [] }
        );

        if (storedData?.version !== LAST_EDITTED_JOB_FROM_LOCAL_STORAGE_VERSION) {
            console.warn(`Mismatched version: expected ${LAST_EDITTED_JOB_FROM_LOCAL_STORAGE_VERSION}, got ${storedData?.version}`);
            return [];
        }

        return storedData.jobs;
    } catch (error) {
        console.error('Error retrieving last edited jobs from local storage:', error);
        return [];
    }
}