import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Store, createSelector } from "@ngrx/store";
import { cloneDeep, isEqual } from 'lodash';
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';

import { TabPanel } from 'primeng/tabview';
import Quill, { Delta } from 'quill/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, take } from 'rxjs';
import { DistanceService } from "src/app/estimates/distance.service";
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { JOB_CREATE_INDEXES, JOB_SUMMARIES, jobSummaryDefaultToolbar } from 'src/app/global.constants';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SharedModule } from 'src/app/shared/shared.module';

import { SubSink } from "subsink";

import { brandingFeature } from '../../../state/branding.store';
import { selectFeature } from '../../../state/featureFlags.store';
import { JobSummaryActions, SummaryType } from '../../job-state/summary-state/summary.actions';
import { JobChange, Modes, Summary, jobToolFeature } from '../../job-tool.reducer';

import { selectJobUpdatingErrors } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { selectJobSavingErrors, selectSavingDisabled } from '../jobv2-create-state/jobv2-create.selectors';
import { JobV2FormActionButtonComponent } from '../jobv2-form-action-button/jobv2-form-action-button';


const handleUndefined = (summary: Summary): Delta => {
  if (summary?.text) {
    return summary.contents;
  } else {
    return new Delta([{ insert: '' }]);
  }
}
const setEditorContents = (editor: Quill, summary: Summary) => {
  const contents = handleUndefined(structuredClone(summary));
  editor.setContents(contents, 'api');
};


@Component({
  selector: 'app-jobv2-summary',
  standalone: true,
  imports: [
    FreyaCommonModule,
    LibModule,
    SharedModule,
    QuillEditorComponent,
    JobV2FormActionButtonComponent
  ],
  templateUrl: './jobv2-summary.component.html',
  styleUrls: ['./jobv2-summary.component.scss']
})
export class Jobv2SummaryComponent implements OnInit, OnDestroy {

  @Input() renderHeadless = false;
  @Input() defaultActiveTabIndex = 0;

  constructor(
    private store: Store,
    public distanceService: DistanceService,
    public localNotify: FreyaNotificationsService,
  ) { }

  private subs = new SubSink();

  // Editors
  crewEditor: Quill;
  adminEditor: Quill;
  customerEditor: Quill;

  configs$ = this.store.select(brandingFeature.selectConfigs);
  selectSavingDisabled$ = this.store.select(selectSavingDisabled);

  //error handling
  savingDisabledMessage = 'A customer name is required to create this job.';
  jobSavingErrors$ = this.store.select(selectJobSavingErrors);
  errorMessage = '';

  jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
  errorUpdateMessage = '';
  creatingDisabled = false;

  //modes
  mode: Modes;
  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  jobsV2Enabled$ = this.store.select(selectFeature('jobs-v2'));

  jobInput$ = this.store.select(jobToolFeature.selectJobInput);

  editModules: QuillModules = {
    toolbar: jobSummaryDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.moveBackToSummaryTab();
          },
        },
      },
    },
  };

  jobId: string;
  initialContentLoaded = false;

  jobSummaries = JOB_SUMMARIES;

  jobCreateIndexes = JOB_CREATE_INDEXES;
  activeTabIndex: number = 0;

  ngOnInit(): void {

    this.activeTabIndex = this.defaultActiveTabIndex;

    this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
      this.mode = jobFormMode;
    });

    this.subs.sink = this.selectSavingDisabled$.subscribe((selectSavingDisabled) => {
      this.creatingDisabled = selectSavingDisabled;
    });

    this.subs.sink = this.configs$.subscribe((configs) => {
      if (configs['jobs.howHeardMandatory']) {
        this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
      }
    })

    this.subs.sink = this.jobSavingErrors$.subscribe((jobSavingErrors) => {

      if (!jobSavingErrors.length) { return; }

      if (jobSavingErrors.length === 1) {
        this.errorMessage = jobSavingErrors[0] + ' Please resolve this error and try again.';
        return;
      }

      let errorMessage = 'Errors occurred: ';

      for (let i = 0; i < jobSavingErrors.length; i++) {
        errorMessage += `(${i + 1}) ${jobSavingErrors[i]} `;
      }

      this.errorMessage = errorMessage + ' Please resolve these errors and try again.';

    });

    this.subs.sink = this.jobUpdatingErrors$.subscribe((jobUpdatingErrors) => {

      if (!jobUpdatingErrors.length) { return; }

      if (jobUpdatingErrors.length === 1) {
        this.errorUpdateMessage = jobUpdatingErrors[0] + ' Please resolve this error and try again.';
        return;
      }

      let errorMessage = 'Errors occurred: ';

      for (let i = 0; i < jobUpdatingErrors.length; i++) {
        errorMessage += `(${i + 1}) ${jobUpdatingErrors[i]} `;
      }

      this.errorUpdateMessage = errorMessage + ' Please resolve these errors and try again.';
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  editorCreated(editor: Quill, key: SummaryType) {
    switch (key) {
      case 'crewSummary':
        this.crewEditor = editor;
        break;
      case 'adminSummary':
        this.adminEditor = editor;
        break;
      case 'customerSummary':
        this.customerEditor = editor;
        break;
    }

    this.watchJobSummaries();
  }

  watchJobSummaries(): void {

    // Wait untill all editors are ready
    if (!this.crewEditor || !this.adminEditor || !this.customerEditor) return;

    this.subs.sink = this.jobInput$
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe((jobInput) => {
        const { id, crewSummary, adminSummary, customerSummary } = jobInput || {};
        this.jobId = id;
        setEditorContents(this.crewEditor, crewSummary);
        setEditorContents(this.adminEditor, adminSummary);
        setEditorContents(this.customerEditor, customerSummary);
      });
  }

  contentChanged(event: ContentChange, key: SummaryType) {

    const {source, text, content} = event || {};

    if (source === 'api') return;

    this.store.dispatch(JobSummaryActions.updateSummary({
      text,
      contents: content,
      key,
    }));
  }

  moveBackToSummaryTab() {
    const focusableElements = Array.from(document.querySelectorAll<HTMLElement>('div[tabindex]'))
      .filter(el => el.tabIndex >= 0);

    focusableElements.sort((a, b) => a.tabIndex - b.tabIndex);

    const nextElement = focusableElements[0];
    nextElement.focus();
  }
}
