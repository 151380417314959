


import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, withLatestFrom } from "rxjs";

import { FreyaNotificationsService } from '../../services/freya-notifications.service';

import { jobToolFeature } from '../job-tool.reducer';

import { JobToolSubscriptionActions } from './job-tool-subscription.actions';

export const remoteJobUpdatedEffect = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
    notify = inject(FreyaNotificationsService),
) => {

	return actions$.pipe(
		ofType(
		  JobToolSubscriptionActions.remoteJobUpdated,
		),
		withLatestFrom(
		  store.select(jobToolFeature.selectJob),
		),
		map(([ action, job ]) => {
			if (action.output?.stageChanged) {
				const name = action.userName || 'Someone';
				const stage = action.input.stage || action.output?.job?.stage;
				const summary = `${ name } promoted job to ${ stage }`;
				const detail = action.input.promoteReason || undefined;

				notify.success(
					summary,
					detail,
				);
			}
		}),
	);
}, { functional: true, dispatch: false });
