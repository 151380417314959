import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { dayjs } from '@karve.it/core';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { MenuItem } from 'primeng/api';

import { Observable, filter, first, switchMap } from 'rxjs';

import { InvoiceWithArtifactsFragment } from '../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { isDraftInvoice, isFinalizedInvoice } from '../../invoices/invoices.utils';
import { remainingBalance } from '../../jobs/jobs.util';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { SharedModule } from "../../shared/shared.module";
import { JobToolActions } from '../job-tool.actions';
import { jobToolFeature } from '../job-tool.reducer';

export type TagInfo = {
  text: string;
  color: string;
  backgroundColor: string;
}

@Component({
    selector: 'app-job-invoice',
    standalone: true,
    templateUrl: './job-invoice.component.html',
    styleUrls: [
        '../job-financials/document-style.scss',
        './job-invoice.component.scss',
    ],
    imports: [
        FreyaCommonModule,
        SharedModule
    ]
})
export class JobInvoiceComponent implements OnChanges {

  @Input() invoice: InvoiceWithArtifactsFragment;

  job$ = this.store.select(jobToolFeature.selectJob);

  tags: TagInfo[] = [];

  actionsMenu: MenuItem[] = [];

  sending = false;

  sendInvoiceDisabled = true;

  constructor(
    private store: Store,
    private actions: Actions,
    private documentHelper: DocumentHelperService,
    private freyaHelper: FreyaHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.invoice) {
      this.setTags();
      this.setActionsMenu();
      this.setSendInvoiceDisabled();
    }
  }

  setTags() {
    const tags: TagInfo[] = []; 

    if (this.documentGenerating && !this.invoice.deletedAt) {
      tags.push({
        text: 'Generating',
        ...this.freyaHelper.getTagColors('warning'),
      });
      this.tags = tags;
      return;
    }

    if (this.invoice.sentAt) {
      tags.push({
        text: 'Sent',
        ...this.freyaHelper.getTagColors('success'),
      });
    } else {
      tags.push({
        text: 'Not Sent',
        ...this.freyaHelper.getTagColors('neutral'),
      });
    }

    if (this.invoice.openedAt) {
      tags.push({
        text: 'Opened',
        ...this.freyaHelper.getTagColors('success'),
      });
    }

    if (this.invoice.voidedAt) {
      tags.push({
        text: 'Voided',
        ...this.freyaHelper.getTagColors('danger'),
      });
      this.tags = tags;
      return;
    }

    if (this.invoice.deletedAt) {
      tags.push({
        text: 'Deleted',
        ...this.freyaHelper.getTagColors('danger'),
      });
      this.tags = tags;
      return;
    }

    if (remainingBalance(this.invoice) > 0) {

      tags.push({
        text: 'Not paid',
        ...this.freyaHelper.getTagColors('warning'),
      });

      const dueDate = dayjs.unix(this.invoice.dueDate);

      tags.push({
        text: `Due on ${dueDate.format('MMM D, YYYY')}`,
        ...this.freyaHelper.getTagColors('neutral'),
      });
    } else {
      tags.push({
        text: 'Paid',
        ...this.freyaHelper.getTagColors('success'),
      });
    }

    this.tags = tags;
  }

  setActionsMenu() {

    const actions: MenuItem[] = [
      {
        id: 'pay',
        label: 'Pay',
        icon: 'pi pi-wallet',
        command: () => this.documentHelper.payInvoice(this.invoice),
        ...this.getDisabled({
          invoiceVoided: true,
          invoiceDeleted: true,
          invoicePaid: true,
        }),
      },
      {
        id: 'preview',
        label: 'Preview',
        icon: 'pi pi-eye',
        command: () => this.freyaHelper.openInDialog(this.document),
        ...this.getDisabled({
          documentGenerating: true,
        }),
      },
      {
        id: 'download',
        label: 'Download',
        icon: 'pi pi-download',
        command: () => this.documentHelper.downloadDocument(this.document),
        ...this.getDisabled({
          documentGenerating: true,
          invoiceDeleted: true,
        }),
      },
      {
        id: 'employee',
        label: 'View as Employee',
        icon: 'pi pi-external-link',
        command: () => this.confirmOpenInvoice('employee'),
        disabled: true,
        ...this.getDisabled({
          documentGenerating: true,
          invoiceOutdated: true,
          invoiceVoided: true,
          invoiceDeleted: true,
        }),
      },
      {
        id: 'finalize',
        label: 'Finalize',
        icon: 'pi pi-check',
        command: () => this.finalizeInvoice(),
          disabled: true,
          ...this.getDisabled({
            documentGenerating: true,
            invoiceFinalized: true,
            invoiceOutdated: true,
            invoiceVoided: true,
            invoiceDeleted: true,
          }),
      },
      {
        id: 'customer',
        label: 'View as Customer',
        icon: 'pi pi-external-link',
        command: () => this.confirmOpenInvoice('customer'),
        ...this.getDisabled({
          documentGenerating: true,
          invoiceOutdated: true,
          invoiceVoided: true,
          invoiceDeleted: true,
        }),
      },
      {
        id: 'void',
        label: 'Void',
        icon: 'pi pi-times',
        command: () => this.voidInvoice(),
        ...this.getDisabled({
          invoiceVoided: true,
          invoiceDraft: true,
          invoiceDeleted: true,
        }),
        // visible: false,
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.deleteInvoice(),
        ...this.getDisabled({
          invoiceDeleted: true,
          invoiceVoided: true,
          invoiceFinalized: true,
        }),
        // visible: false,
      },
      {
        id: 'export-to-quickbooks',
        label: 'Export to Quickbooks',
        icon: 'pi pi-share-alt',
        command: () => this.exportToQuickbooks(),
        // visible: false,
        ...this.getDisabled({
          invoiceExportedToQuickbooks: true,
          invoiceVoided: true,
          invoiceDraft: true,
          documentGenerating: true,
          invoiceDeleted: true,
        }),
      },
      {
        id: 'force-update',
        label: 'Force Update Invoice',
        icon: 'pi pi-sync',
        command: () => this.forceUpdateInvoice(),
        disabled: true,
        ...this.getDisabled({
          documentGenerating: true,
          invoiceVoided: true,
          invoiceDeleted: true,
          invoiceFinalized: true,
        }),
      },
    ];

    this.actionsMenu = actions;
  }

  get document() {
    return this.invoice?.artifacts?.[0];
  }

  get documentGenerating() {
    return !(this.document?.url || this.document?.signedUrl);
  }

  get invoiceOutdated() {
    return this.document?.attributes?.includes('outdated');
  }

  get invoiceDeleted() {
    return Boolean(this.invoice?.deletedAt);
  }

  get invoiceVoided() {
    return Boolean(this.invoice?.voidedAt);
  }

  get invoicePaid() {
    return remainingBalance(this.invoice) === 0;
  }

  get invoiceExportedToQuickbooks() {
    return Boolean(this.invoice?.metadata?.quickbooksId);
  }

  get invoiceDraft() {
    return isDraftInvoice(this.invoice);
  }

  get placeholderEmail() {
    return this.document?.metadata?.placeholderEmail;
  }

  get invoiceFinalized() {
    return Boolean(this.invoice?.sentAt) || Boolean(this.invoice?.openedAt);
  }

  getDisabled(input: {
    documentGenerating?: boolean;
    invoiceVoided?: boolean;
    invoicePaid?: boolean;
    invoiceDraft?: boolean;
    invoiceDeleted?: boolean;
    invoiceOutdated?: boolean;
    invoiceFinalized?: boolean;
    invoiceExportedToQuickbooks?: boolean;
    hasPlaceholderEmail?: boolean;
  }) {

    const labels: Record<keyof typeof input, string> = {
      documentGenerating: 'Document is generating',
      invoiceVoided: 'Invoice is voided',
      invoicePaid: 'Invoice is paid',
      invoiceDraft: 'Invoice is a draft',
      invoiceDeleted: 'Invoice is deleted',
      invoiceOutdated: 'Invoice is outdated',
      invoiceFinalized: 'Invoice is finalized',
      invoiceExportedToQuickbooks: 'Invoice is exported to Quickbooks',
      hasPlaceholderEmail: 'There is no email associated with this invoice',
    };

    for (const key of Object.keys(input)) {
      if (input[key] && this[key]) {
        return {
          disabled: true,
          tooltipOptions: {
            tooltipPosition: 'left' as const,
            tooltipLabel: labels[key],
          },
        };
      }
    }

    return {
      disabled: false,
    }
  }

  sendInvoice() {
    this.sending = true;

    this.store.dispatch(JobToolActions.sendInvoiceButtonClicked({ invoice: this.invoice }));

    this.actions.pipe(
      ofType(JobToolActions.sendInvoiceSuccess, JobToolActions.sendInvoiceError),
      filter(({ invoice }) => invoice.id === this.invoice.id),
      first(),
    ).subscribe(() => this.sending = false);
  }

  setSendInvoiceDisabled() {
    this.sendInvoiceDisabled = this.documentGenerating ||
      this.invoiceOutdated ||
      this.invoiceVoided ||
      this.invoiceDeleted ||
      this.placeholderEmail;
  }

  confirmOpenInvoice(role: 'customer' | 'employee') {

    if (isFinalizedInvoice(this.invoice)) {
      this.store.dispatch(JobToolActions.openInvoiceAsButtonClicked({ invoice: this.invoice, role }));
      return;
    }

    const message = `Opening an invoice will finalize it. `
    + `You will not be able to make any further charges on any invoiced events. `
    + `If you just want to view the document and do not need to collect signatures, `
      + `consider using the "Preview" option instead.`;

    const dispatchAction$ = new Observable((observer) => {
      this.store.dispatch(JobToolActions.openInvoiceAsButtonClicked({ invoice: this.invoice, role }));
      observer.next();
      observer.complete();
    });

    const waitForResponse$ = this.actions.pipe(
      ofType(JobToolActions.openInvoiceAsSuccess, JobToolActions.openInvoiceAsError),
      filter(({ invoice }) => invoice.id === this.invoice.id),
      first(),
    );

    this.freyaHelper.confirmWithLoading({
      header: 'Open Invoice?',
      message,
      acceptLabel: 'Open and Finalize',
      acceptIcon: 'pi pi-external-link',
      rejectLabel: 'Preview',
      rejectIcon: 'pi pi-eye',
      acceptObservable: dispatchAction$.pipe(switchMap(() => waitForResponse$)),
      reject: () => this.freyaHelper.openInDialog(this.document),
      onAcceptSuccess: () => {},
    });
  }

  finalizeInvoice() {
    this.store.dispatch(JobToolActions.finalizeInvoiceButtonClicked({ invoice: this.invoice }));
  }

  forceUpdateInvoice() {
    this.store.dispatch(JobToolActions.forcedUpdateInvoiceButtonClicked({ invoice: this.invoice }));
  }

  voidInvoice() {
    this.store.dispatch(JobToolActions.voidInvoiceButtonPressed({ invoice: this.invoice }));
  }

  deleteInvoice() {
    this.store.dispatch(JobToolActions.deleteInvoiceButtonPressed({ invoice: this.invoice }));
  }

  exportToQuickbooks() {
    this.store.dispatch(JobToolActions.exportInvoiceToQuickbooksButtonPressed({ invoice: this.invoice }));
  }

}
