<div *ngIf="job$ | async as job">
  <span
    class="job-status {{ job.closedAt ? 'closed' : 'open' }} {{ job.stage }}"
    (click)="toggleMenu($event, menu)"
  >
    <span class="content">
      {{ job.state | titlecase }}
      {{ job.stage | titlecase }}
      <ng-container *ngIf="(jobClosedReason$ | async) as closedReason">
        - {{ closedReason }}
      </ng-container>
    </span>
    <i
      class="pi {{
        isJobStatusMenuVisible ? 'pi-chevron-up' : 'pi-chevron-down'
      }}"
    ></i>
  </span>
  <p-tieredMenu #menu
    [model]="jobStatusItems"
    [popup]="true"
    appendTo="body"
  />
</div>
