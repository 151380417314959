import { createSelector, on } from "@ngrx/store"

import { createConfigSelector } from '../../state/branding.store';
import { jobToolFeature } from '../job-tool.reducer';


export const selectJobClosedReason = createSelector(
	createConfigSelector('jobs.closedReasons'),
	jobToolFeature.selectJob,
	(closedReasons, job) => {
		const reason = closedReasons.find((c) => c.id === job?.closedReason);

		return reason?.title || job?.closedReason;
	},
);
