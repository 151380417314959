import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Store } from '@ngrx/store';
import { BaseZoneWithParentFragment, ServiceAreaQueryMatch } from 'graphql.generated';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputText } from 'primeng/inputtext';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { ADDABLE_LOCATIONS_V2, AddLocationType, FIELD_CONFIG, JOB_CREATE_INDEXES, estimating } from 'src/app/global.constants';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';


import { showGoogleMapsRouteV2 } from 'src/app/utilities/locations.util';
import { cmdFlags } from 'src/cmd';
import { SubSink } from 'subsink';

import { brandingFeature } from '../../../state/branding.store';
import { JobToolFieldsV2, LocationWithHowSetIndicator, Modes, jobToolFeature } from '../../job-tool.reducer';
import { Jobv2AdditionalLocationsComponent } from '../jobv2-additional-locations/jobv2-additional-locations.component';
import { Jobv2LocationsDetailsComponent } from '../jobv2-create-locations-details/jobv2-create-locations-details.component';
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { AddableLocations } from '../jobv2-interfaces';
import { Jobv2ManuallyAddLocationComponent } from '../jobv2-manually-add-location/jobv2-manually-add-location.component';


import { Jobv2SelectAreaManuallyComponent, SelectAreaManuallyDialogDataComponent, SelectAreaManuallyDialogResult } from '../jobv2-select-area-manually/jobv2-select-area-manually.component';
import { selectCanChangeArea, selectServerCopyAreaId } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';




@Component({
    selector: 'app-jobv2-create-locations',
    standalone: true,
    imports: [
        FreyaCommonModule,
        NgxGpAutocompleteModule,
        Jobv2ManuallyAddLocationComponent,
        Jobv2LocationsDetailsComponent,
        Jobv2SelectAreaManuallyComponent,
        Jobv2AdditionalLocationsComponent,
    ],
    templateUrl: './jobv2-create-locations.component.html',
    styleUrls: ['./jobv2-create-locations.component.scss']
})
export class Jobv2LocationsComponent implements OnInit, OnDestroy {
    @Input() renderHeadless: boolean = false;

    @ViewChild('googleAutocomplete') autocompleteRef: NgxGpAutocompleteDirective;
    @ViewChild('addLocation') addLocationManuallyRef: Jobv2ManuallyAddLocationComponent;
    @ViewChild(InputText) locationInputRef: InputText;

    @ViewChild('startLocation', { static: false }) startLocationInput!: ElementRef<HTMLInputElement>;

    constructor(
        private store: Store,
        public googleHelper: GoogleHelperService,
        public dialogService: DialogService,
        private freyaHelper: FreyaHelperService,
        private localNotify: FreyaNotificationsService,
        private confirmService: ConfirmationService,
    ) { }

    private subs = new SubSink();

    mode: Modes;
    jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);

    fieldsInput$ = this.store.select(jobToolFeature.selectFieldsInput);
    locationsInput$ = this.store.select(jobToolFeature.selectLocationsInputs);
    changes$ = this.store.select(jobToolFeature.selectChanges);
    configs$ = this.store.select(brandingFeature.selectConfigs);

    addableAdditionalLocations$ = this.store.select(jobToolFeature.selectAddableAdditionalLocations);
    resolvedServiceArea$ = this.store.select(jobToolFeature.selectResolvedServiceArea);
    canChangeArea$ = this.store.select(selectCanChangeArea);
    serverCopyAreaId$ = this.store.select(selectServerCopyAreaId);

    locationTypeToOpenInManual: AddLocationType;

    dwellingDropdownOptions = estimating?.dwellingTypes;

    startBuildingTypeSelectedOption: string;
    endBuildingTypeSelectedOption: string;

    fields: JobToolFieldsV2 | object;
    locations: LocationWithHowSetIndicator;
    startAddress: string;
    endAddress: string;
    viewRouteHidden: boolean;

    resolvedAreaName: string;
    canChangeArea: boolean;
    serverCopyAreaId: string | undefined;

    mainLocationsMap = {
        start: {
            key: 'start',
            label: 'Starting Location',
        },
        end: {
            key: 'end',
            label: 'Ending Location',
        },
        dock: {
            key: 'dock',
            label: 'Dock',
        },
        ...ADDABLE_LOCATIONS_V2.reduce((acc, loc) => {
            acc[loc.key] = loc;
            return acc;
        }, {} as { [key: string]: { key: string, label: string } }),
    };

    addableAdditionalLocations: AddableLocations;
    addedAdditionalLocations: { key: string, value: any }[] = [];
    //always stays null to prevent issue with Add Stop dropdown placeholder
    selectedLocation: string | null = null;

    isCardCollapsed = false;
    jobCreateIndexes = JOB_CREATE_INDEXES;
    idToSetFocusOn: string;

    inputState = {
        start: { userTyping: false, addressSelected: false, showError: false },
        end: { userTyping: false, addressSelected: false, showError: false }
    };

    startDwellingTypeRequired = false;
    endDwellingTypeRequired = false;

    ngOnInit(): void {
        this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
            this.mode = jobFormMode;
        });

        this.subs.sink = this.canChangeArea$.subscribe((canChangeArea) => {
            this.canChangeArea = canChangeArea;
        });

        this.subs.sink = this.serverCopyAreaId$.subscribe((serverCopyAreaId) => {
            this.serverCopyAreaId = serverCopyAreaId;
        });

        this.subs.sink = this.fieldsInput$
            .pipe(distinctUntilChanged())
            .subscribe(fields => {
                this.fields = cloneDeep(fields);
                this.startBuildingTypeSelectedOption = this.fields?.[FIELD_CONFIG?.start?.buildingType?.name];
                this.endBuildingTypeSelectedOption = this.fields?.[FIELD_CONFIG?.end?.buildingType?.name];
            });

        this.subs.sink = this.locationsInput$
            .subscribe(locationsInput => {
                this.locations = cloneDeep(locationsInput);
                if (typeof this.locations === 'object' && this.locations !== null && Object.keys(this.locations).length > 1) {
                    this.isCardCollapsed = false;
                }

                if (locationsInput?.start?.addressLineOne) {
                    this.startAddress = locationsInput.start.addressLineOne;
                } else if (!locationsInput?.start) {
                    this.startAddress = undefined;
                }

                if (locationsInput?.end?.addressLineOne) {
                    this.endAddress = locationsInput.end.addressLineOne;
                } else if (!locationsInput?.end) {
                    this.endAddress = undefined;
                }

                this.viewRouteHidden = Boolean(!locationsInput?.start && !locationsInput?.end);

                if (this.locations?.start?.setManually) {
                    this.setArea();
                }

                if (locationsInput?.start) {
                    this.inputState = {
                        ...this.inputState,
                        start: {
                            ...this.inputState.start,
                            showError: false
                        }
                    };
                }

                if (locationsInput?.end) {
                    this.inputState = {
                        ...this.inputState,
                        end: {
                            ...this.inputState.end,
                            showError: false
                        }
                    };
                }
            });

        this.subs.sink = this.addableAdditionalLocations$
            .subscribe(addableAdditionalLocations => {
                this.addableAdditionalLocations = cloneDeep(addableAdditionalLocations);
                this.addedAdditionalLocations = this.getAddedAdditionalLocations();
            });

        this.subs.sink = this.resolvedServiceArea$.subscribe((resolvedServiceArea) => {
            this.resolvedAreaName = resolvedServiceArea?.name;

            if (resolvedServiceArea && !resolvedServiceArea?.id) {
                // this.setArea();
            }

            //check that resolvedServiceArea is not undefined, to prevent issue
            //with popup appearing after editing job and redirect to job page
            if (resolvedServiceArea && this.mode === 'edit') {
                if (!this.canChangeArea && this.serverCopyAreaId
                    && (this.serverCopyAreaId == resolvedServiceArea?.id)) {
                    this.store.dispatch(JobCreateLocationsActions.locationChangedForJobWithCharges());
                }

                if (!this.canChangeArea && this.serverCopyAreaId
                    && (this.serverCopyAreaId !== resolvedServiceArea?.id)) {
                    this.confirmLocationChangeWithoutAreaChange();
                }
            }
        });

        this.subs.sink = combineLatest([
            this.changes$,
            this.configs$
          ])
          .pipe(distinctUntilChanged())
          .subscribe(([changes, configs]) => {

            const startChanged = changes?.find(c => c?.fieldName?.includes('start'));
            const endChanged = changes?.find(c => c?.fieldName?.includes('end'));

            if (configs['jobs.dwellingTypeMandatory'] && startChanged) {
                this.startDwellingTypeRequired = true;
            } else {
                this.startDwellingTypeRequired = false;
            }

            if (configs['jobs.dwellingTypeMandatory'] && endChanged) {
                this.endDwellingTypeRequired = true;
            } else {
                this.endDwellingTypeRequired = false;
            }
          });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    confirmLocationChangeWithoutAreaChange() {

        // This akward definition is required to properly display message across multiple lines
        const message = 'You can change this job\'s starting location. ' +
          'However, this will not change its area ' +
          'as the job already has charges, transactions or discounts\n\n' +
          'If you are trying to change the area for this job, ' +
          'you will first have to remove any charges, transactions or discounts.\n\n' +
          'Are you sure you want to change the starting location without changing the area?';

        this.confirmService.confirm({
          header: 'This location does not match the job\'s current area',
          message,
          acceptLabel: 'Yes, change starting location',
          acceptIcon: 'pi pi-arrow-right',
          rejectButtonStyleClass: 'p-button-text discard-button',
          rejectLabel: 'Cancel',
          rejectIcon: 'pi pi-times',
          dismissableMask: true,
          accept: () => this.store.dispatch(JobCreateLocationsActions.locationChangedForJobWithCharges()),
          reject: () => this.store.dispatch(JobCreateLocationsActions.restoreServerCopyLocationAndArea()),
        });
      }
      onInput(inputType: 'start' | 'end') {
        this.inputState[inputType].userTyping = true;
        this.inputState[inputType].addressSelected = false;
        this.inputState[inputType].showError = false;
      }

      onBlur(inputType: 'start' | 'end') {
        const { userTyping, addressSelected } = this.inputState[inputType];
        if (userTyping && !addressSelected) {
          this.inputState[inputType].showError = true;
        }
      }

    /**
   * Take the input of the location autocomplete and assign the values for our location forms accordingly
   *
   * @param address Google Address https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
   * @param formType The location form to use
   */
    public handleAddressChange(
        address: google.maps.places.AutocompletePrediction,
        locationType: AddLocationType
    ) {
        if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
            this.addLocationManuallyRef.formType = locationType;
            this.addLocationManuallyRef.open(address);
            return;
        }

        this.inputState[locationType].addressSelected = true;
        this.inputState[locationType].userTyping = false;
        this.inputState[locationType].showError = false;

        const location = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);

        this.store.dispatch(JobCreateLocationsActions.locationSetAutocomplete({
            locationType: locationType,
            location: {
                ...location,
                public: false,
            },
        }));

        const queryToDispatch = this.locations['start']?.addressLineOne;
        const areaCodeToDispatch = this.locations['start']?.areaCode;

        if (locationType === 'start' && queryToDispatch && areaCodeToDispatch) {
            this.store.dispatch(JobCreateLocationsActions.locationResolveServiceArea({
                query: queryToDispatch,
                areaCode: areaCodeToDispatch,
            }));
        }
    }

    openManualLocation(locationType: AddLocationType) {
        this.locationTypeToOpenInManual = locationType;
        this.addLocationManuallyRef.open();
        this.addLocationManuallyRef.formType = locationType;
    }

    onBuildingTypeSelect(option: DropdownChangeEvent, locationType: AddLocationType) {
        this.store.dispatch(JobCreateLocationsActions.setLocationFields({
            fieldName: FIELD_CONFIG[locationType].buildingType.name,
            fieldType: FIELD_CONFIG[locationType].buildingType.type,
            fieldValue: option.value,
        }));
    }

    async setArea() {
        const res = await this.freyaHelper.openSelectAreaDialog({
            actionRequired: true,
            onlyShowCurrentSubzones: false,
        });

        this.store.dispatch(JobCreateLocationsActions.locationSelectAreaManually({
            zone: res,
        }));
    }

    showGoogleMapsRoute() {
        return window.open(
            showGoogleMapsRouteV2(
                this.locations?.start?.addressLineOne,
                this.locations?.end?.addressLineOne)
            , '_blank')
    }

    getAddedAdditionalLocations(): { key: string, value: any }[] {
        if (!this.locations) {
            return [];
        }

        const mainLocations = ['start', 'dock', 'end'];
        return Object.keys(this.locations)
            .filter(key => !mainLocations.includes(key))
            .map(key => ({ key, value: this.locations[key] }));
    }

    /*addAdditionalLocation(option) {

        const selectedLocation = this.addableAdditionalLocations.find(loc => loc.key === option);

        if (selectedLocation) {
            this.store.dispatch(JobCreateLocationsActions.additionalLocationSelected({
                locationType: selectedLocation.key,
            }))
        }
    }*/

    addAdditionalLocation() {

        const selectedLocation = this.addableAdditionalLocations[0];

        if (selectedLocation) {
            this.store.dispatch(JobCreateLocationsActions.additionalLocationSelected({
                locationType: selectedLocation.key,
            }))
        }
    }

    collapseCard() {
        this.isCardCollapsed = !this.isCardCollapsed
    }

}
