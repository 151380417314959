<div class="card calendar-holder no-border-radius">
  <div class="p-grid calendar-header-wrapper">
    <div class="p-md-4 p-pr-0 p-mr-auto">
      <span class="button-group">
        <button
          pButton
          icon="pi pi-chevron-left"
          (click)="changeDate('prev')"
        ></button>
        <button pButton label="Today" (click)="changeDate('today')"></button>
        <button
          pButton
          icon="pi pi-chevron-right"
          (click)="changeDate('next')"
        ></button>
      </span>
      <span
        *ngIf="(view$ | async) === 'month'"
        class="switch-label-group p-ml-2 p-mt-1"
      >
        <p-inputSwitch
          [(ngModel)]="showMonthEvents"
          (onChange)="setMaxEvents($event.checked)"
        ></p-inputSwitch>
        <label>Show Events</label>
      </span>
    </div>
    <div class="p-col-12 p-md-4 fc calendar-title-wrapper">
      <h2>{{ title$ | async }}</h2>
    </div>

    <div class="p-md-4 p-d-inline-flex p-jc-md-end p-ai-start">
      <button
        class="p-mr-2"
        pButton
        [disabled]="loading$ | async"
        [icon]="(loading$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
        data-cy="reload-calendar-button"
        (click)="store.dispatch(ScheduleActions.reloadButtonClicked())"
      ></button>
      <div class="button-group">
        <button
          pButton
          label="Day"
          (click)="store.dispatch(ScheduleActions.viewSelected({ view: 'day' }))"
          data-by="calendar-day-button"
        ></button>
        <button
          pButton
          label="Week"
          (click)="store.dispatch(ScheduleActions.viewSelected({ view: 'week' }))"
          data-by="calendar-week-button"
        ></button>
        <button
          pButton
          label="Month"
          (click)="store.dispatch(ScheduleActions.viewSelected({ view: 'month' }))"
          data-by="calendar-month-button"
        ></button>
      </div>
    </div>


  </div>
  <div class="calendar-holder" style="position: relative">
    <full-calendar [options]="calendarOptions" #fc></full-calendar>
  </div>
</div>
