<div class="close-job-dialog">
    <label>Reason (required)</label>
    <br>
    <p-dropdown
        appendTo="body"
        placeholder="Select Closure Reason"
        [options]="closureReasons$ | async"
        optionLabel="title"
        optionValue="id"
        [autofocus]="true"
        [ngModel]="selectedCloseReason$ | async"
        (ngModelChange)="closeReasonChanged($event)"
        >
    </p-dropdown>
        <div class="footer">
            <button
                pButton
                label="Exit Dialog"
                icon="pi pi-times"
                [outlined]="true" severity="secondary"
                (click)="closeDialog(false)"
            ></button>
            <button
                class="p-button-success"
                pButton
                label="Close Job"
                icon="pi pi-circle-fill"
                (click)="closeJob()"
                [disabled]="(selectedCloseReason$ | async) === undefined"
            ></button>
        </div>
</div>
