import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { environment } from '../../../environments/environment';
import { GetConfigValuesGQL } from '../../../generated/graphql.generated';
import { ScheduleEventsActions } from '../../jobsv2/job-state/event-schedule-state/event-schedule.actions';
import { EstimateHelperService } from '../../services/estimate-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-disable-restrictions',
  templateUrl: './disable-restrictions.component.html',
  styleUrls: ['./disable-restrictions.component.scss']
})
export class DisableRestrictionsComponent implements OnInit, OnDestroy {

  subs = new SubSink();

  // RESTRICTIONS
  disableRestrictionsForm: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required]),
  });

  // True if the user must enter the code because they lack the specific permissions to ignore it
  requiresCode = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public estimateHelper: EstimateHelperService,
    private localNotify: FreyaNotificationsService,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private permissionHandler: PermissionService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    const permissionsInput = [{permission: 'frontend.estimating', restriction: {disableRestrictions: true}}];

    this.subs.sink = this.permissionHandler.watchPermissionsAndRestrictions(permissionsInput).subscribe(((res) => {
      this.requiresCode = !res[0];
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Validates the code and performs the requsite actions if valid/invalid
   */
  async validateRestrictionsCode() {
    if (this.requiresCode){
      if (!await this.checkCodeValid() && this.estimateHelper.restrictionsEnabled.value) {
        this.disableRestrictionsForm.controls.code.setErrors({ incorrectCode: true });
        return;
      }
    }

    this.disableRestrictionsForm.controls.code.setErrors(null);
    this.disableRestrictionsForm.reset();
    this.estimateHelper.restrictionsEnabled.next(!this.estimateHelper.restrictionsEnabled.value);
    this.store.dispatch(ScheduleEventsActions.bookingRestrictionsChanged({
      isBookingRestrictionDisabled: !this.estimateHelper.restrictionsEnabled.value,
    }));
    this.localNotify.success(`Booking Restrictions ${this.estimateHelper.restrictionsEnabled.value ? 'Enabled' : 'Disabled'}`);
    this.closeDialog();
  }

  /**
   * Verifies that the code is valid
   */
  async checkCodeValid() {
    const configValues = await this.getConfigValuesGQL.fetch({keys: ['estimator.restrictionCode']}).toPromise();

    const restrictionCode = configValues.data?.getConfigValues[0]?.value || environment.overrideCode;
    const codeToCheck = this.disableRestrictionsForm.value.code;

    return codeToCheck === restrictionCode;
  }

  closeDialog(){
    this.ref.close();
  }



}
