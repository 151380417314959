import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NormalizedCacheObject } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';


import { cloneDeep } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, lastValueFrom, Observable, Subject } from 'rxjs';

import { SubSink } from 'subsink';

import { AuthenticateGQL, AuthenticateMutationVariables, AuthenticateOutput, ConfirmSecretGQL, ConfirmSecretMutationVariables, DeauthenticateGQL, Disable2FaGQL, Disable2FaMutationVariables, GenerateSecretGQL } from '../../../generated/graphql.generated';
import { JWTPayload, UserProfile } from '../../interfaces/auth';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { currentTimeSeconds, MS_TEN_SECONDS, resolveMilliseconds, S_ONE_HOUR } from '../../time';

import { AuthActions } from './auth.actions';
import { GraphQLModule } from './graphql.module';
import { jwtDecode } from './jwtDecode';

export type AuthState = typeof authStates[number];
export const authStates = [
  // user has been successfully authenticated
  'authenticated',
  // user has been successfully reauthenticated
  'reauthenticated',
  // user deauthenticated, cache cleared
  'deauthenticated',
  // warning that cache will be cleared very soon & queries refired
  'deauthenticating',
] as const;

export type ConnectionStatus = typeof connectionStatuses[number];
export const connectionStatuses = [
  // initial state
  'preparing',
  // called when query succeeds and not in connected state
  'connected',
  // called when we are confirmed disconnected by polling
  'disconnected',
  // called when polling is currently active
  'disconnected-polling',
] as const;


@Injectable({
  providedIn: 'root'
})
export class PlusAuthenticationService implements OnDestroy {

  subs = new SubSink();

  public homeURL = '/';
  public loginURL = '/login';
  public autoAccessTokenRenewalOffset = 0;

  /**
   * Whether this service should handle navigation after login
   * If the localstorage value with key pre_login_url is set
   * it will use that and then clear that localstorage key
   * Otherwise it will go to homeURL
   */
  public shouldNavigateAfterLogin = true;

  // Local storage keys
  public lsKeys = {
    preLoginURL: 'pre_login_url',
    userInfo: 'user_info',
    accessTokenExpiration: 'access_token_expiration',
    refreshTokenExpiration: 'refresh_token_expiration',
  };

  // session storage keys (valid for that tab)
  public ssKeys = {
    desiredZoneId: 'desired_zone_id',
  };

  public polling = false;
  public pollingInterval = MS_TEN_SECONDS;

  public connectionStatus = new BehaviorSubject<ConnectionStatus>('preparing');
  public authState = new BehaviorSubject<AuthState>(this.user ? 'authenticated' : 'deauthenticated');

  private userProfile?: UserProfile;

  /**
   * Set by updateAccessToken when an authentication is in progress.
   * If set, the application is currently trying to authenticate
   */
  private accessTokenUpdatePromise: Promise<AuthState>;

  /**
   * Key value store of context cache serialized state
   * Key is in the format zone:role (see getCurrentContextId)
   * this is set by the restoreCurrentContextCache method
   * and read by the restoreContextCache method.
   */
  private cacheStorage: { [key: string]: NormalizedCacheObject } = {};

  constructor(
    private apollo: Apollo,
    private authGql: AuthenticateGQL,
    private deauthGql: DeauthenticateGQL,
    private confirmSecretGql: ConfirmSecretGQL,
    private disable2FAGql: Disable2FaGQL,
    private generateSecretGql: GenerateSecretGQL,
    private graphqlModule: GraphQLModule,
    private router: Router,
    private http: HttpClient,

    // gotta be careful with circular dependencies here
    // private perms: PermissionsService,
    private localNotify: FreyaNotificationsService,
    private store: Store,
  ) {
    this.subs.sink = this.authState.subscribe((authState) => {
      console.log(`Auth state updated`, authState);
      this.store.dispatch(AuthActions.authUpdated({
        user: this.user,
      }));
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * The currently contexted zone id, alias of GraphqlModule.zone.value
   */
  public get contextedZoneId() {
    return this.graphqlModule.zone.value;
  }

  /**
   * The currently contexted role id, alias of GraphqlModule.role.value
   */
  public get contextedRoleId() {
    return this.graphqlModule.role.value;
  }

  /**
   * Returns the users profile, based from the current JWT.
   * Returns undefined if Refresh Token is invalid or expired.
   * Will still return if access token is invalid or expired
   */
  public get user(): UserProfile {
    const payload = this.jwtPayload;
    if (!payload) { return undefined; }

    const expiration = this.refreshTokenExpiration;
    if (!expiration || expiration < Date.now() / 1000) {
      return undefined;
    }

    if (this.userProfile && this.userProfile.id === payload.id) {
      return this.userProfile;
    }

    return {
      ...payload,
      createdAt: payload.createdAt ? new Date(payload.createdAt * 1000) : undefined,
      updatedAt: payload.updatedAt ? new Date(payload.updatedAt * 1000) : undefined,
      deletedOn: payload.deletedOn ? new Date(payload.deletedOn * 1000) : undefined,
    };
  }

  /**
   * Sets the expiration time for the json web token in unix seconds
   *
   * @param time is the expiration time before formatted to string
   */
  public set jwtPayload(payload: JWTPayload) {
    if (payload) {
      localStorage.setItem(this.lsKeys.userInfo, JSON.stringify(payload));
    } else {
      localStorage.removeItem(this.lsKeys.userInfo);
    }

  }

  /**
   * Retrieves the expiration time for the json web token in unix seconds
   */
  public get jwtPayload(): JWTPayload {
    const strPayload = localStorage.getItem(this.lsKeys.userInfo);

    return strPayload ? JSON.parse(strPayload) : undefined;
  }

  /**
   * Sets the expiration time for the json web token in unix seconds
   *
   * @param time is the expiration time before formatted to string
   */
  public set accessTokenExpiration(time: number) {
    if (time) {
      localStorage.setItem(this.lsKeys.accessTokenExpiration, time.toString(10));
    } else {
      localStorage.removeItem(this.lsKeys.accessTokenExpiration);
    }
  }

  /**
   * Retrieves the expiration time for the json web token in unix seconds
   */
  public get accessTokenExpiration(): number {
    return +localStorage.getItem(this.lsKeys.accessTokenExpiration);
  }
  /**
   * Sets the expiration time for the json web token in unix seconds
   *
   * @param time is the expiration time before formatted to string
   */
  public set refreshTokenExpiration(time: number) {
    if (time) {
      localStorage.setItem(this.lsKeys.refreshTokenExpiration, time.toString(10));
    } else {
      localStorage.removeItem(this.lsKeys.refreshTokenExpiration);
    }
  }

  /**
   * Retrieves the expiration time for the refresh token in unix seconds
   */
  public get refreshTokenExpiration(): number {
    return +localStorage.getItem(this.lsKeys.refreshTokenExpiration);
  }

  /**
   * @returns true if authenticated
   */
  public isAuthenticated(): boolean {
    return Boolean(this.user);
  }

  /**
   * Toast for session expired, sign in again
   */
  public notifySessionExpired() {
    this.localNotify.addToast.next({
      summary: 'Session Expired',
      detail: 'Please sign in again.',
      closable: true,
      life: S_ONE_HOUR,
      severity: 'warn',
    });
  }

  /**
   * Checks the refresh token and access token for validity
   *
   * If refresh token has expired, will log the user out
   * If access token has expired, will try to update the access token.
   *
   * @returns current auth state after updates
   */
  public async checkAuthStatus(
    updateAccessToken = false,
    logoutIfRefreshTokenInvalid = true,
  ) {

    const currentTimeMS = Date.now() / 1000;

    if (
      this.refreshTokenExpiration
      && this.refreshTokenExpiration < currentTimeMS
      && logoutIfRefreshTokenInvalid
    ) {
      this.notifySessionExpired();
      await this.logout();
    } else if (this.accessTokenExpiration) {
      // If our access token has expired then renew it
      const expiresIn = this.accessTokenExpiration - currentTimeSeconds();
      const accessTokenExpired = expiresIn <= 0;
      const signedIn = this.user;
      if (accessTokenExpired && signedIn && updateAccessToken) {
        await this.updateAccessToken();
      }
    }

    return this.authState.value;
  }

  /**
   * Set access token expirations
   * If our refresh token is expired, log out.
   *
   * If there was a specific error where the user must
   * require more information then skip setting state
   */
  public async updateAuthStatus(res: AuthenticateOutput = {}): Promise<void> {
    const prestate: Partial<JWTPayload> = this.jwtPayload;

    // reset if no user id was returned
    if (!res.userId) {
      this.accessTokenExpiration = undefined;
      this.refreshTokenExpiration = undefined;
      this.jwtPayload = undefined;
    }

    if (res.jwt && res.userId) {
      this.jwtPayload = jwtDecode(res.jwt);
    } else if (res.validJWT === false) {
      this.accessTokenExpiration = undefined;
    }

    if (res.jwtExpires) {
      this.accessTokenExpiration = res.jwtExpires;
    }

    if (res.refreshTokenExpires) {
      this.refreshTokenExpiration = res.refreshTokenExpires;
    }

    if (res.validRefreshToken === false) {
      this.refreshTokenExpiration = undefined;
    }

    const currentState: Partial<JWTPayload> = this.jwtPayload;

    if (prestate && !this.jwtPayload) {
      await this.logout(true);
    } else if (!prestate && this.jwtPayload) {
      this.graphqlModule.reinitWebsocket();
      this.authState.next('authenticated');
    } else if (prestate && this.jwtPayload) {
      this.graphqlModule.reinitWebsocket();
      this.authState.next('reauthenticated');
    }
  }

  /**
   * deauthenticates by calling backend
   * then calls onDeauthenticated to clear the state and
   * optionally navigate to login
   */
  public async logout(doNavigate = true) {
    await lastValueFrom(this.deauthGql.mutate({}, {
      context: {
        headers: {
          'x-no-auth': 'true',
        },
      },
    }))
    .catch((err) => {
      const errors = err.networkError?.error?.errors;
      if (errors) {
        const [error] = errors;
        console.error(errors);
        if (error.message.includes('Cannot resolve zone from domain')) {
          this.localNotify.warning(
            `Cannot deauthenticate - has the system been setup?`,
            error.message,
          );
        }
      } else {
        console.error('Error deauthenticating', err);
      }
    });

    this.store.dispatch(AuthActions.deauthenticated());

    return this.onDeauthenticated(doNavigate);
  }

  /**
   * Fired directly after we have deauthenticated
   *
   * Clears storage, resets cache, resets permissions, resets current zones,
   * clears the store, and optionally navigates to login.
   *
   * Fires the deauthenticating and deauthenticated events.
   *
   * @param doNavigate whether we should navigate after we've deauthenticated.
   * @returns current auth state
   */
  public async onDeauthenticated(doNavigate = true) {

    // warn impending de-auth so queries can be unsubbed from
    this.authState.next('deauthenticating');

    // HTTP Only cookies have now been cleared.
    localStorage.clear();

    // clear session storage on deauth for now
    // In the future we may leave the zone here
    // so the user can log back into the same zone + receive extra info
    sessionStorage.clear();
    await this.resetCache();
    // this.perms.reset();
    this.graphqlModule.zone.next(undefined);
    this.graphqlModule.role.next(undefined);
    // this.apollo.client.

    this.graphqlModule.reinitWebsocket();
    await this.apollo.client.clearStore()
      .catch();

    // If we want to route to login page automatically
    if (doNavigate) {
      this.goToLogin({});
    }

    // Notify apllication that the user has been logged out
    this.authState.next('deauthenticated');

    return this.authState.value;
  }

  /**
   * Whether we are in the process of deauthenticating or are currently deauthenticated.
   *
   * @returns true if deauthenticating/deauthenticated
   */
  isDeauthed() {
    return this.authState.value.startsWith('deauth');
  }

  /**
   * @returns Returns true if the application is in the process of authenticating
   * or deauthenticating. Returns false if authenticated or deauthenticated but not query is
   * in flight
   */
  isDeauthenticating() {
    return this.authState.value === 'deauthenticating';
  }

  /**
   * @returns the current context in zone:role format
   */
  public getCurrentContextId() {
    return `${this.graphqlModule.zone.value}:${this.graphqlModule.role.value}`;

  }

  /**
   * Store the current cache in a variable associated with the current context
   */
  public storeCurrentContextCache() {
    const ctxId = this.getCurrentContextId();
    // https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#extract
    this.cacheStorage[ctxId] = cloneDeep(this.apollo.client.cache.extract(false));
  }

  /**
   * Restore a specific context's cache
   *
   * @param ctxId Context ID, in zone:role format.
   */
  public async restoreContextCache(ctxId: string) {
    const cache = this.apollo.client.cache;
    if (this.cacheStorage[ctxId]) {
      // console.log('cache restoring', cache, this.cacheStorage[ctxId]);
      // console.log(this.cacheStorage[ctxId]);
      // cache must be reset beforehand because restore doesn't
      // completely clear the cache.
      await cache.reset();
      cache.restore(this.cacheStorage[ctxId]);
    } else {
      await cache.reset();
    }
  }

  /**
   * Completely clear the cache and cache storage.
   */
  public async resetCache() {
    this.cacheStorage = {};
    await this.apollo.client.cache.reset();
  }

  /**
   * Sets the current context in terms of zone:role combos.
   *
   * Stores the current cache before it is set and restores the new zone cache.
   * Emits provided graphqlModule.zone and graphqlModule.role states.
   * Resets permissions and refeteches observable queries for a specified context.
   *
   * @param zone Zone to context into
   * @param role Optional, role will be resolved by backend if not provided.
   */
  async setContext(
    zone: string,
    role?: string,
  ) {
    this.storeCurrentContextCache();
    await this.restoreContextCache(`${zone}:${role}`);
    this.graphqlModule.zone.next(zone);
    this.graphqlModule.role.next(role);
    // this.perms.reset();

    // Incase of an issue with the observables return after 5 seconds
    await Promise.race([this.apollo.client.reFetchObservableQueries().catch(), resolveMilliseconds(5000)]);

    // Update the URL after we have confirmed the zone change
    this.updateZoneInNavigation();
  }

  /**
   * Calls PlusAuthenticationService.authenticate to retrieve a new JWT into the cookie.
   * Guarantees this is only called once at a time, if a query is in flight you can
   * await the returned promise and only one authenticate will be called.
   *
   * Used in graphql.module
   *
   * @returns a promise that resolves when the access token has been updated
   */
  updateAccessToken() {
    if (this.accessTokenUpdatePromise) {
      return this.accessTokenUpdatePromise;
    }

    this.accessTokenUpdatePromise = new Promise((resolve, reject) => {
      const sub = this.authenticate({}).subscribe(() => {
        sub.unsubscribe();
        delete this.accessTokenUpdatePromise;
        resolve(this.authState.value);
      }, (err) => {
        sub.unsubscribe();
        delete this.accessTokenUpdatePromise;
        reject(err);
      });
    });

    return this.accessTokenUpdatePromise;
  }

  /**
   * Authenticate into the system with a username, password,
   * refresh token, or any other auth method.
   *
   * System will then set the appropriate cookies, localStorage, and sessionStorage.
   *
   * @param variables credentials to authenticate with
   * @param updateAuthStatus Whether you want to set the appropriate state afterwards
   * and emit events. Set to false to just check auth credentials (eg when setting 2fa)
   * @returns observable that completes when fully authenticated or when there is an error
   */
  public authenticate(
    variables: AuthenticateMutationVariables = {},
  ): Observable<AuthenticateOutput> {

    const s = new Subject<AuthenticateOutput>();

    // If we don't have a refresh token, let the system set our zone
    // instead of enforcing it
    if (variables.refreshToken) {
      variables.zone = variables.zone || undefined;
    }

    // rememberLength: true=30 days, false=24 hours
    this.authGql.mutate(variables, {
      context: {
        headers: {},
      },
    }).subscribe(async (res) => {
      if (!res?.data) { return; }
      const authRes = res.data.authenticate;
      if (!authRes.invalidCode) {
        /**
         * Only update the auth status if we successfully authenticated
         * The deauthentication headers will handle what to do
         * if we have encountered an error that requires us to deauth,
         * such as the access token expiring.
         */
        await this.updateAuthStatus(authRes);
      }

      s.next(res.data?.authenticate);
      s.complete();
    }, (e) => {
      console.error('Authentication Error', e);
      s.next({
        invalidCode: 'ERR',
        invalidReason: e.message ? e.message.replace('GraphQL error: ', '') : 'Auth error',
      });
      s.complete();
    });

    return s.asObservable();
  }

  /**
   * Redirects to the login page.
   */
  public goToLogin(
    queryParams: any = {},
    url?: string,
  ): void {

    if (url) {
      console.log(`Redirecting to login - will redirect to ${url}`);

      // Capture current page in localstorage
      localStorage.setItem(this.lsKeys.preLoginURL, url);
    }

    // navigate to login
    this.router.navigate([this.loginURL], { queryParams });

    // When the user logs in they will be redirected to this.router.url
  }

  /**
   * Redirects to the home page.
   */
  public navigateAfterLogin(): false | string {
    if (!this.shouldNavigateAfterLogin) { return false; }

    let url = localStorage.getItem(this.lsKeys.preLoginURL);

    if (!url) {
      url = this.homeURL;
    }

    this.router.navigateByUrl(url);

    return url;
  }

  async checkConnection() {

    const data = await this.http.get(`/api/ready`, {
      headers: {},
      responseType: 'text',
    }).toPromise().catch(() => '');

    const ok = data === 'OK';
    return ok;
  }

  public async pollConnection() {
    if (this.polling) { return; }
    this.polling = true;
    let lastCheck = 0;

    const interval = setInterval(() => {
      this.connectionStatus.next('disconnected-polling');
    }, this.pollingInterval);

    const stop = () => {
      sub.unsubscribe();
      clearInterval(interval);
      this.polling = false;
      //when user loses internet connection ang goes back online, reFetchObservableQueries
      //causes 20+ queries being refetched on estimator page, that causes long loading
      //when user works on field and has unstable connection
      //this.apollo.client.reFetchObservableQueries();
    };

    const sub = this.connectionStatus.subscribe(async (status) => {
      console.log(`Polling Status`, status);
      if (status === 'connected') {
        return stop();
      }

      // skip if we just checked for extra
      // loop protection and to prevent duplicates
      if (Date.now() - lastCheck < 500) {
        return;
      }

      const ok = await this.checkConnection();
      lastCheck = Date.now();
      if (ok) {
        this.connectionStatus.next('connected');
        return stop();
      } else if (this.connectionStatus.value !== 'disconnected') {
        this.connectionStatus.next('disconnected');
      }
    });
  }

  /**
   * Generate a secret and assign that secret to a user, secret isn't activated unti confirmed.
   *
   * @returns Secret used for generating 2FA codes
   */
  public generateSecret() {
    return this.generateSecretGql.mutate({});
  }

  /**
   * Use a token to confirm that the generated secret has been saved by the user.
   *
   * @param input Token Value generated by the secret
   * @returns
   */
  public confirmSecret(input: ConfirmSecretMutationVariables) {
    return this.confirmSecretGql.mutate(input);
  }

  /**
   * Remove the 2FA level of authentication for this user.
   *
   * @param input Valid 2FA Token Generated
   * @returns
   */
  public disable2FA(input: Disable2FaMutationVariables) {
    return this.disable2FAGql.mutate(input);
  }

  /**
   * Sets the current zone in the location search/query params
   * using native angular navigation.
   *
   * Keeps existing search parameters, just adds zone.
   */
  updateZoneInNavigation() {

    // const queryParamMap = this.router.routerState.snapshot.root.queryParamMap;
    // const queryParamZone = queryParamMap.get('zone');

    const url = new URL(location.toString());
    const queryParamZone = url.searchParams.get('zone');
    const zone = this.graphqlModule.zone.value;

    // If the queryParamsZone is different than the current zone
    // and the user is authenticated,
    // then set the current zone in the url params
    if (queryParamZone !== zone && this.user) {
      const queryParams = {};
      url.searchParams.set('zone', zone);
      url.searchParams.forEach((v, k) => queryParams[k] = v);
      // console.log(url.pathname, zone, queryParams);
      this.router.navigate([url.pathname], {
        replaceUrl: true,
        queryParams,
        queryParamsHandling: 'merge',
        preserveFragment: true,
        // skipLocationChange: true,
      });
    }

  }


}
