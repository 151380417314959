import { error } from 'console';

import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { DialogService } from 'primeng/dynamicdialog';
import { SubSink } from 'subsink';

import { CreateInvoiceGQL, CreateInvoiceMutationVariables, GetConfigValuesQuery, GetConfigValuesQueryVariables } from '../../../generated/graphql.generated';
import { EventSelectComponent } from '../../custom-inputs/event-select/event-select.component';
import { CalendarEvent } from '../../interfaces/calendarEvents';
import { JobToolActions } from '../../jobsv2/job-tool.actions';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { brandingFeature, createConfigSelector } from '../../state/branding.store';
import { ApplyTransactionComponent } from '../../transactions/apply-transaction/apply-transaction.component';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-invoice',
  templateUrl: './mutate-invoice.component.html',
  styleUrls: ['./mutate-invoice.component.scss']
})
export class MutateInvoiceComponent implements OnDestroy {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('events') eventsRef: TemplateRef<any>;

  @ViewChild(EventSelectComponent) eventSelectRef: EventSelectComponent;

  @Input() mutateType: 'update' | 'create';

  // ADDITIONAL INPUTS
  @Input() jobId: string;

  configQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;

  steps: MutateObjectElement[];

  disableMultipleEvents$ = this.store.select(createConfigSelector('invoice.disableMultipleEvents'));

  invoiceForm = new FormGroup({
    events: new FormControl([], [ Validators.required ]),
  });

  subs = new SubSink();

  constructor(
    private createInvoiceGQL: CreateInvoiceGQL,
    private localNotify: FreyaNotificationsService,
    private detaislHelper: DetailsHelperService,
    private dialogService: DialogService,
    private responsiveHelper: ResponsiveHelperService,
    private freyaHelper: FreyaHelperService,
    private documentHelper: DocumentHelperService,
    private store: Store,
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openDialog() {

    this.steps = [
      { name: 'Events', ref: this.eventsRef, control: 'events', type: 'array' },
    ];

    this.invoiceForm.reset({
      events: [],
    });

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();

    if (this.eventSelectRef) {
      this.eventSelectRef.searchEvents();
    }
  }

  mutateInvoice() {
    if (this.mutateType === 'update') {
      throw new Error('Update invoice not implemented');
    } else if (this.mutateType === 'create') {
      this.createInvoice();
    }
  }

  createInvoice() {

    const { value } = this.invoiceForm;

    const input: CreateInvoiceMutationVariables = {
      invoices: [
        {
          eventIds: value.events.map((e: CalendarEvent) => e.id),
        },
      ],
    };

    this.createInvoiceGQL.mutate(input).subscribe(async (res) => {

      const [ invoice ] = res.data.createInvoice.invoices;

      if (invoice) {

        this.mutateRef.closeDialog();

        this.localNotify.success('Generating invoice', 'You can apply payments while it generates');

        this.store.dispatch(JobToolActions.createInvoiceSuccess({ invoice }));

        this.detaislHelper.pushUpdate({
          id: this.jobId,
          type: 'Invoice',
          action: 'create',
        });

        this.detaislHelper.detailsItem.next({ type: 'invoice', item: { id: invoice.id } });

        const {
          transactionsWithAvailability,
          transactionsAvailable,
        } = await this.documentHelper.getTransactionsAvailableForInvoice(invoice);

        if (transactionsAvailable) {
          this.dialogService.open(ApplyTransactionComponent, {
            header: 'Apply Existing Transaction to Invoice?',
            width: this.responsiveHelper.dialogWidth,
            data: { transactionsWithAvailability, invoice },
            contentStyle: this.freyaHelper.getDialogContentStyle('1.5rem'),
          });
        }
      } else {
        this.store.dispatch(JobToolActions.createInvoiceError({ error: new Error('Failed to create invoice') }));
      }
    }, (error) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError('Failed to create invoice', error);
      this.store.dispatch(JobToolActions.createInvoiceError({ error }));
    });
  }

}
