import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { CalendarEvent  } from '@karve.it/interfaces/calendarEvents';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { SubSink } from 'subsink';

import { ListCalendarEventsForSelectEventGQL, CalendarEventsFilter, ListCalendarEventsForSelectEventQuery, ListCalendarEventsForSelectEventQueryVariables, CalendarEventForSelectEventFragment } from '../../../generated/graphql.generated';
import { isDraftInvoice, isFinalizedInvoice } from '../../invoices/invoices.utils';

import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';

export interface EventSuggestion {
  event: CalendarEventForSelectEventFragment;
  disabled?: boolean;
  label: string;
  invoiceStatus?: 'finalized' | 'draft';
}

@Component({
  selector: 'app-event-select',
  templateUrl: './event-select.component.html',
  styleUrls: ['./event-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: EventSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EventSelectComponent,
    }
  ]
})
export class EventSelectComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy, OnChanges {

  @Output() eventsSelected = new EventEmitter();

  @Input() filter: CalendarEventsFilter;
  // If true the list of selected events will be defaulted to what is returned from the query
  @Input() selectAllByDefault = false;
  // Allows you to override the placeholder for the component
  @Input() placeholder = 'Select Events';

  @Input() limit = undefined;

  @Input() preSelectEventType;

  @Input() preselectEventId = undefined;

  @Input() preselectFirstAvailableEvent = true;

  @Input() disabledEvents = {
    locked: false,
    incomplete: false,
    hasInvoice: false,
    invoiced: false,
  };

  @Input() showInvoiceInfo = false;

  subs = new SubSink();

  // Users Variables
  eventsQueryRef: QueryRef<ListCalendarEventsForSelectEventQuery, ListCalendarEventsForSelectEventQueryVariables>;
  eventSuggestions: EventSuggestion[] = [];

  // FORM CONTROL VARIABLES
  selectedEvents: CalendarEventForSelectEventFragment[] = [];
  touched = false;
  disabled = false;

  constructor(
    private listCalendarEventsForSelectEventGQL: ListCalendarEventsForSelectEventGQL,
    public responsiveHelper: ResponsiveHelperService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
  ) { }

  ngOnInit(): void {
    this.searchEvents();

    this.subs.sink = this.detailsHelper.getObjectUpdates([ 'Events', 'Invoice' ])
      .subscribe(update => {

        if (update.type === 'Events') {
          this.searchEvents();
          return;
        }

        // If we are supposed to disable invoiced events, refetch events on invoice updates
        // in case the invoiced status of any event changed
        if (this.disabledEvents.hasInvoice) {
          this.searchEvents();
        }
      });
  }

  //here makes sense trigger searchEvents only if we are switching between jobs
  //if we call searchEvents on any change, we will run query listCalendarEventsForSelectEvent when we
  //add event, add charges, remove charges, reorder charges, uncollapse all events and make multiple other actions
  //that has nothing to do with running that query
  ngOnChanges(changes: SimpleChanges) {

    const currentJobId = changes?.filter?.currentValue?.jobId;
    const previousJobId = changes?.filter?.previousValue?.jobId;

    if (currentJobId && previousJobId && (currentJobId !== previousJobId)) {
      this.searchEvents();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchEvents(){
    if (this.eventsQueryRef){
      this.eventsQueryRef.refetch({
        filter: this.filter,
      });
      return;
    }

    this.eventsQueryRef = this.listCalendarEventsForSelectEventGQL.watch({
      filter: this.filter
    }, {
      fetchPolicy: 'network-only',
    });

    this.subs.sink = this.eventsQueryRef.valueChanges.subscribe((res) => {
      if(!res.data?.calendarEvents?.events?.length) { return; }
      this.setEventSuggestions(res.data.calendarEvents.events);
      this.preselectEvent();
    });
  }

  preselectEvent(){
    if(!this.eventSuggestions.length) {return;}

    // Prioritize selectAllByDefault
    if (this.selectAllByDefault) {
      this.selectedEvents = this.eventSuggestions
        .filter((s) => !s.disabled)
        .map((s) => s.event);
    } else if (this.preSelectEventType) {
      this.selectedEvents = this.eventSuggestions
        .filter((s) => !s.disabled && s.event.type === this.preSelectEventType)
        .map((s) => s.event);
    } else if (this.preselectEventId) {
      this.selectedEvents = this.eventSuggestions
        .filter((s) => !s.disabled && s.event.id === this.preselectEventId)
        .map((s) => s.event);
    } else if (this.preselectFirstAvailableEvent) {

      const availableEvents = this.eventSuggestions
        .filter((s) => !s.disabled)
        .map((s) => s.event);

      const [ firstAvailableEvent ] = availableEvents;

      if (firstAvailableEvent) {
        this.selectedEvents = [ firstAvailableEvent ];
      }

    }

    this.emitSelect();

    this.onChange(this.selectedEvents);
  }

  emitSelect() {
    this.eventsSelected.emit(this.selectedEvents);
  }

  // FORM CONTROL FUNCTIONS
  onChange = (selectedEvents) => {};

  onTouched = () => {
    this.touched = true;
  };

  writeValue(events: CalendarEvent[]) {
    this.selectedEvents = events ?? [];
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  setEventSuggestions(events: CalendarEventForSelectEventFragment[]) {

    if (!events) { return; }

    events = cloneDeep(events);

    const suggestions: EventSuggestion[] = [];

    for (const event of events) {

      const eventConditions: typeof this.disabledEvents = {
        locked: event?.end && this.freyaHelper.lockDate > event.end,
        incomplete: event.status !== 'completed',
        hasInvoice: event.invoices.some((i) => !i.deletedAt && !i.voidedAt),
        invoiced: event.invoices.some(isFinalizedInvoice),
      };

      let disabled = false;

      for (const condition of Object.keys(this.disabledEvents)) {

        if (!this.disabledEvents[condition]) { continue; }

        if (eventConditions[condition]) {
          disabled = true;
          break;
        }
      }

      suggestions.push({
        event,
        disabled,
        label: event.title,
        invoiceStatus: this.getInvoiceStatus(event.invoices),
      });
    }

    this.eventSuggestions = suggestions;
  }

  getInvoiceStatus(invoices: CalendarEventForSelectEventFragment['invoices']) {

    if (invoices.some(isFinalizedInvoice)) {
      return 'finalized' as const;
    }

    if (invoices.some(isDraftInvoice)) {
      return 'draft' as const;
    }
  }

}
