
import { BaseLocationFragment, CalendarEventLocation, CalendarEvent_LocationsFragment, FullJobFragment, LocationCreateBase } from '../../generated/graphql.generated';

import { CANADA, EventLocationTypes, USA } from '../global.constants';
import { getJobLocation } from '../jobs/jobs.util';

export function getLocationAreaCode(location: BaseLocationFragment | LocationCreateBase): string {
    if (!location || !location.areaCode || !location.country) { return 'Not Set'; }

    if (location.country === CANADA) {
        const unifiedAreaCode = removeWhiteSpaceFromAreaCode(location.areaCode);
        return `${unifiedAreaCode.substring(0, 3)} ${unifiedAreaCode.substring(3, 6)}`;
    } else if (location.country === USA) {
        return location.areaCode;
    }
}

//when lead comes from OBE, area code field has format "XXX XXX"
//when user fills locations from UI, area code field has format "XXXXXX"
//here we unify format of area code for proper work of getLocationAreaCode
export function removeWhiteSpaceFromAreaCode(areaCode: string): string {
    return areaCode.replace(/\s+/g, '');
}

export function getFirstLocation(
    ce: CalendarEvent_LocationsFragment,
    firstLocationType: EventLocationTypes = EventLocationTypes.start,
    hardMatch = false,
) {
    const locations = ce?.locations || [];
    let loc = hardMatch ? undefined : locations[0];
    for (const ceLocation of locations) {
        if (ceLocation?.type === firstLocationType) {
            loc = ceLocation;
            break;
        }
    }

    return loc;

}

export function getActualTravelTime(location: CalendarEventLocation): number{
    if (!location) { return 0; }
    return location.travelTimeToNextLocation + location.travelTimeToNextLocationOffset;
}

export function viewLocationOnGoogleMaps(location: BaseLocationFragment) {
    const url = `https://www.google.com/maps/place/${encodeURIComponent(convertLocationToFullAddress(location))}`;
    return url;
  }

export function convertLocationToFullAddress(location: BaseLocationFragment): string {
    if (!location) { return; }

    const areaCode = getLocationAreaCode(location);

    if (location.addressLineOne === areaCode) {
        return `${areaCode} ${location.country ? location.country : ''}`;
    }

    return `${location.addressLineOne} ${location.city} ${areaCode}`;
}

export function showGoogleMapsRoute(job:FullJobFragment) {
    // TODO: Update this to work with multiple locations
    const start = convertLocationToFullAddress(getJobLocation(job, 'start'));
    const end = convertLocationToFullAddress(getJobLocation(job, 'end'));

    const url = `https://www.google.ca/maps/dir/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
    return url;
}

export function showGoogleMapsRouteV2(start: string, end: string) {
    const url = `https://www.google.ca/maps/dir/${encodeURIComponent(start)}/${encodeURIComponent(end)}`;
    return url;
}

