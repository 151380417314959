<div class="details" (click)="openInDialog()">
  <i class="pi pi-file"></i>
  <div>
    <div class="document-name">
      <span>{{ estimate.name }}</span>
      <p-tag
        *ngFor="let tag of tags"
        rounded="true"
        [style]="{
          backgroundColor: tag.backgroundColor,
          color: tag.color
        }"
        [value]="tag.text"
        styleClass="p-ml-2"
        ></p-tag>
    </div>
    <div>Created {{ estimate.createdAt * 1000 | date : 'mediumDate' }} | {{ estimate.fileSize | fileSize }} | {{ estimate.public ? 'Public' : 'Private' }}</div>
  </div>
</div>
<div class="buttons">
  <p-button
    *ngIf="isPandadoc"
    (onClick)="sendEstimate()"
    [icon]="sending ? 'pi pi-spinner pi-spin' : 'pi pi-send'"
    [label]="(sending ? 'Sending' : status === 'draft' ? 'Send' : 'Resend') + ' ' + documentType"
    [disabled]="sendEstimateDisabled"
    styleClass="p-mr-2"
    ></p-button>
  <p-button
    icon="pi pi-ellipsis-v"
    text="true"
    (click)="menu.toggle($event)"
    ></p-button>
  <p-menu
    #menu
    [popup]="true"
    appendTo="body"
    [model]="actionsMenu"
    ></p-menu>
</div>

