import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AvailabilityOutput, CalendarEventForScheduleFragment, FullAssetFragment } from '../../../generated/graphql.generated';

import { DataError } from '../../utilities/errors.util';

import { ScheduleView, ScheduleViewSimple } from './schedules.reducer';

export const ScheduleActions = createActionGroup({
	source: 'Schedule',
	events: {
		'Component Initialized': props<{
			// defaults to day
			view?: ScheduleViewSimple;
			// if no initial date is provided we will use the date from localstorage
			date?: string;

			title?: string;
		}>(),
		'Store Initialized': props<{
			timezone: string;
			date: string;
			view: ScheduleViewSimple;
		}>(),

		// calls effects to change the date
		// (doesn't change state - that's done by dateSet)
		'View Selected': props<{
			date?: string;
			view?: ScheduleViewSimple;
		}>(),

		// calls effects to change the view mode
		// (doesn't change state - that's done by view changed)
		// 'Change View Clicked': props<{
		// 	view: ScheduleViewSimple;
		// }>(),

		/**
		 * If the timezone is changed because of a setting
		 * or the zone was changed
		 */
		'Timezone Changed': props<{
			timezone: string;
		}>(),

		'Filters Updated': props<{
			assetTypes: string[];
			eventTypes: string[];
			// products: string[];
		}>(),

		'Reload Button Clicked': emptyProps(),

		/**
		 * Loaded events
		 */
		'Assets Loaded': props<{
			assets: FullAssetFragment[];
		}>(),


		'Availability Loaded': props<{
			availability: AvailabilityOutput[];
		}>(),
		'Availability Load Error': props<{ error: string, }>(),

		'Events Loaded': props<{
			events: CalendarEventForScheduleFragment[];
		}>(),
		'Events Load Error': props<{
			error: string,
			calendarEventDataErrors: DataError[];
		}>(),
		

		//============== FULL CALENDAR EVENTS
		'fc Date Clicked': props<{
			date: string;
			view: ScheduleView;
		}>(),
		'fc View Changed': props<{
			date: string;
			start: number;
			end: number;

			timezone: string;

			// this is the internal full calendar view type
			view: ScheduleView,

			title: string,
		}>(),
	},
});
