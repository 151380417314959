<div>
	<h6>Confirm before sending:</h6>
	<p>Email: {{customer?.email || 'None'}}</p>
	<p>Phone: {{customer | phone: true}}</p>
</div>

<footer class="p-d-flex p-jc-end p-mt-4">
	<button
		pButton
		(click)="createSelfSurvey()"
		icon="pi pi-arrow-right"
		label="Send Invite"
	></button>
</footer>