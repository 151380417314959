import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CreateUserInputWithRole, FindGQL, UpdateJobGQL, UpdateJobInput } from 'graphql.generated';
import { isEmpty } from 'lodash';
import { catchError, filter, from, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { DEFAULT_EVENT_TYPE, JOB_FORM_FIELDS, eventTypeInfoMapV2 } from 'src/app/global.constants';

import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import {
	checkAndUpdateEmptyRoomName,
	filterLocationsForAddAndRemove,
	generateCreateCustomerInput,
	generateFieldsInputFromLatestChanges,
	generateInventoryInputFromLatestChanges,
	generateJobCreateVariables,
	generateLocationsInputsFromLatestChanges,
	getFindQueryVariablesV2
} from '../../jobsv2-helpers';
import { getLastEditedJobsFromLocalStorage } from '../../jobsv2-local-storage.utils';
import { JobCreateCustomerActions } from '../../jobv2-create/jobv2-create-customer-state/jobv2-create-customer.actions';
import { JobCreateLocationsActions } from '../../jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { JobCreateActions } from '../../jobv2-create/jobv2-create-state/jobv2-create.actions';
import { selectCustomerRole, selectTotalTimeForFindWhenEditJob, selectYemboStatus, selectZoneIdForFind } from '../../jobv2-create/jobv2-create-state/jobv2-create.selectors';

import { JobEditActions } from './jobv2-edit.actions';

export const editJobToolOpenedEffect = createEffect((
    actions$ = inject(Actions)
) => {
    return actions$.pipe(
        ofType(JobEditActions.editJobToolOpened),
        map(({ jobId }) => {
			const lastEditedJobs = getLastEditedJobsFromLocalStorage();
			const retrievedJobState = lastEditedJobs.find(job => job.id === jobId);
			return JobEditActions.stateForEditJobGenerated({ retrievedJobState });
		})
    );
}, { functional: true, dispatch: true });

//find available time slots
export const availableTimesFoundWhenEditJob = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const findGQL = inject(FindGQL);

	return actions$.pipe(
	  ofType(
		JobCreateCustomerActions.selectMovingDate,
		JobCreateLocationsActions.locationResolveServiceAreaSuccess,
		JobCreateLocationsActions.locationSelectAreaManually,
		JobCreateLocationsActions.locationSetAutocomplete, // reretrieve times after locations set as they affect travelTime and totalTime
		JobCreateLocationsActions.locationSetManually,
	  ),
	  withLatestFrom(
		store.select(selectTotalTimeForFindWhenEditJob),
		store.select(selectZoneIdForFind),
		store.select(jobToolFeature.selectJobInput),
		store.select(jobToolFeature.selectJobFormMode),
	  ),
	  filter(([action, _, __, ___, mode]) => mode === 'edit'), // Proceed only if mode is 'edit'
	  filter(([_, __, zoneId, jobInput]) => !!zoneId && !!jobInput?.timeline), // Proceed only if both zoneId and timeline are defined
	  switchMap(([action, totalTime, zoneId, jobInput, mode]) => {
		const timeline = jobInput.timeline;
		const variables = getFindQueryVariablesV2(timeline, totalTime, DEFAULT_EVENT_TYPE, zoneId);

		return from(findGQL.fetch(variables)).pipe(
		  map(response => JobCreateActions.findTimesSuccess({
			result: response})),
		  catchError(error => of(JobCreateActions.findTimesError({ error })))
		);
	  })
	);
  }, { functional: true, dispatch: true });

//update customer
//handled in updateExistingCustomerEffect in jobv2-create.effects.ts

//Update job
export const updateJobEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(
		  JobEditActions.updateFormSaved,
			JobToolActions.closeJob,
		),
		filter((action) => {
			if (action.type === JobToolActions.closeJob.type && !action.saveFormChanges) {
				return false;
			}
			return true;
		}),
		withLatestFrom(
			store.select(jobToolFeature.selectChanges),
			store.select(jobToolFeature.selectJob),
			store.select(selectCustomerRole),
			store.select(selectYemboStatus),
		),
		map(([result, changes, serverCopy, customerRoleId, yemboStatus]) => {
			const { jobType, jobOrigin, resolvedServiceArea, ...recentChanges } = generateJobCreateVariables(changes);
			const locations = generateLocationsInputsFromLatestChanges(changes);
			const yemboStatusForSaving = yemboStatus.yemboStatusForSaving;
			const selectedTimeSlot = changes.find(item => item.fieldName === JOB_FORM_FIELDS.selectedTimeSlot);

			const { forRemove, forAdd } = filterLocationsForAddAndRemove(locations as any);

			const addLocationsInput = Object.values(forAdd).map(item => ({
				locationId: item.id,
				locationType: item.locationType
			}));

			const removeLocationsInput = Object.values(forRemove).map(item => item?.id);

			const fieldsChanges = generateFieldsInputFromLatestChanges(changes);

			const { inventory } = generateInventoryInputFromLatestChanges(changes);

			const selectedExistingCustomer = changes.find(
				item => item.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer);
			const createCustomerVariables = generateCreateCustomerInput(changes);
			const initialCustomerCopy = changes.find(
				item => item.fieldName === JOB_FORM_FIELDS.initialCustomerCopy);
			const editJobVariables: UpdateJobInput = {
				jobId: undefined, //is added below,
				metadata: {
					jobType,
					jobOrigin,
				},
				fields: {},
				addLocations: addLocationsInput,
				removeLocations: removeLocationsInput,
				...recentChanges,
				closedReason: result.closedReason,
			}

			let fieldsForQuery = [];
			if (resolvedServiceArea) {
				editJobVariables.setZone = resolvedServiceArea?.id;
			}

			if (!isEmpty(fieldsChanges)) {
				fieldsForQuery = Object.keys(fieldsChanges).map(key => ({
					fieldName: key,
					value: fieldsChanges[key]
				}));
			}

			if (!isEmpty(inventory)) {
				const withFilledRoomNames = checkAndUpdateEmptyRoomName(inventory);
				const inventoryForQuery = {
					fieldName: "jobs.inventory",
					value: JSON.stringify(withFilledRoomNames)
				}

				fieldsForQuery.push(inventoryForQuery);
			}

			if (fieldsForQuery?.length) {
				editJobVariables.fields = {
					objectLabel: "Job",
					fields: fieldsForQuery,
				};
			}

			if (selectedExistingCustomer) {
				const previousCustomerId = serverCopy?.users?.find(u => u.role === 'customer')?.user?.id;
				const newCustomerId = selectedExistingCustomer?.value?.id;
				editJobVariables.removeUsers = [
					{
						role: 'customer',
						userId: previousCustomerId,
					}
				];
				editJobVariables.addUsers = [
					{
						role: 'customer',
						userId: newCustomerId,
					}
				]
			}

			//original user was deselected and we create new one
			if(!initialCustomerCopy && !isEmpty(createCustomerVariables)) {
				const previousCustomerId = serverCopy?.users?.find(u => u.role === 'customer')?.user?.id;

				const customerWithRole = {
					...createCustomerVariables,
					role: customerRoleId,
				}
				editJobVariables.newCustomers = [customerWithRole as CreateUserInputWithRole];

				editJobVariables.removeUsers = [
					{
						role: 'customer',
						userId: previousCustomerId,
					}
				];
			}

			if (yemboStatusForSaving.smartConsult) {
				const eventVariables = {
					sequentialOrder: 0.5,
					status: 'required',
					type: eventTypeInfoMapV2.virtualEstimate.value,
					title: eventTypeInfoMapV2.virtualEstimate.name,
				};

				editJobVariables.addEvents = Array.isArray(editJobVariables.addEvents)
					? [...editJobVariables.addEvents, eventVariables]
					: [eventVariables];
			}

			if (yemboStatusForSaving.selfSurvey) {
				editJobVariables.sendSelfSurvey = true;
			}

			if (selectedTimeSlot) {
				const eventVariables = {
					sequentialOrder: 1,
					status: 'required',
					type: eventTypeInfoMapV2.moving.value,
					title: eventTypeInfoMapV2.moving.name,
					start: selectedTimeSlot?.value,
				}
				editJobVariables.addEvents = Array.isArray(editJobVariables.addEvents)
					? [...editJobVariables.addEvents, eventVariables]
					: [eventVariables];
			}

			return JobEditActions.updateJob({ updateJobInput: { ...editJobVariables, jobId: result.jobId } });
		}),
	);
}, { functional: true, dispatch: true });

export const jobBeingUpdated = createEffect(() => {
	const actions$ = inject(Actions);
	const updateJobGQL = inject(UpdateJobGQL);

	return actions$.pipe(
	  ofType(JobEditActions.updateJob),
	  switchMap((action) => {
		return from(updateJobGQL.mutate({
			updateJobs: [action.updateJobInput]
		})).pipe(
		  map(result => {
			const job = result?.data?.updateJobs?.jobs[0];
         	const fields = result?.data?.updateJobs?.fields;
			return JobEditActions.updateJobSuccess({ job, fields });
		  }),
		  catchError(error => of(JobEditActions.updateJobError({ error })))
		);
	  })
	);
  }, { functional: true, dispatch: true });

//redirect to jobv2 page
export const redirectAfterJobCreatedSuccess = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const router = inject(Router);
	const route = inject(ActivatedRoute);

	return actions$.pipe(
		ofType(JobEditActions.updateJobSuccess),
		withLatestFrom(
		  store.select(jobToolFeature.selectJobInput),
			store.select(jobToolFeature.selectZoneToContextInto),
		),
		tap(([ _action, jobInput, zone ]) => {
			const redirectTo = `/jobs/${jobInput.id}`;
			router.navigate([redirectTo], {
			  relativeTo: route,
				queryParams: zone ? { zone } : {},
			});
		})
	);
}, { functional: true, dispatch: false });

