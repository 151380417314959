import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { InjectionToken, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloLink, FetchResult, HttpLink, InMemoryCache, Observable, Operation, ServerError, split } from '@apollo/client/core';
import { NetworkError } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { createPersistedQueryLink, PersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { datadogRum } from '@datadog/browser-rum';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { sha256 } from 'crypto-hash';

import { GraphQLFormattedError, print } from 'graphql';
import { Client, createClient } from 'graphql-ws';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

import { generateUUID } from '../../utilities/state.util';

import { PlusAuthenticationService } from './plus-auth.service';

export interface PlusConnectionOptions {
  graphql: string;
  ws: string;
  websocketsEnabled: boolean;
}

export const PLUS_CONNECTION_OPTIONS = new InjectionToken<PlusConnectionOptions>('plus.connection-options');

export function createApollo(
  injector: Injector,
  plusConnectionOptions: PlusConnectionOptions,
  graphqlModule: GraphQLModule,
) {

  const webLink = ApolloLink.from([
    // createRetryCountTrackerLink(),


    // add nonce header to uri
    createNonceLink(),

    // // retry if status code is 0 or 502
    createRetryLink(),

    createPersistedQueryLinkWithOptions(),


    // // add auth headers and call auth if necessary
    createAuthLink(injector, graphqlModule),

    // // handle errors and let user know of no connections
    createHandleErrorLink(injector),

    // httpLink.create({
    //   uri: '/api/graphql',
    //   withCredentials: true,
    //   includeExtensions: true,
    // }),
    // add zone and role to uri
    addQueryParams(
      graphqlModule, plusConnectionOptions,
    ),

    new HttpLink({
      // uri: (operation) => operation.getContext().uri, // Use the updated URI

      // withCredentials: true,
      includeExtensions: true,
      // includeQuery: false,
    }),

    // httpLink.create({
    // }),

  ]);

  let link: ApolloLink;

  // if we support websockets then add websocket link
  const supportsWebSockets = 'WebSocket' in window || 'MozWebSocket' in window;
  if (plusConnectionOptions.ws && supportsWebSockets && plusConnectionOptions.websocketsEnabled) {

    const wsLink = createWebsocketLink(graphqlModule);

    link = split(
      ({ query }) => {
        const { kind, operation }: any = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      webLink,
    );
  }

  return {
    link,
    cache: makeCache(),
  };
}

export function createNonceLink() {
  return setContext((operation, previousContext) => {
    const nonce = generateUUID();

    // add a nonce header to all queries
    // mutations will error if they are

    // console.log(`NONCE: ${nonce}`, operation);

    return {
      headers: {
        ...previousContext.headers,
        'x-nonce': nonce,
      },
    };
  });
}

export const maxRetryAttempts = 3;
export function createRetryLink() {

  const retryLink = new RetryLink({
    delay: {
      initial: 300,           // Initial delay in milliseconds
      max: 1500,              // Maximum delay in milliseconds
      jitter: true,           // Apply random jitter to delay
    },
    attempts: {
      max: maxRetryAttempts,                 // Maximum number of retry attempts
      // retry if status code is 0 (no connection) or 502 (bad gateway)
      retryIf: (error, _operation) => {
        // Check if the error has a response with status code 0 or 502
        const statusCode = error?.statusCode;
        const doRetry = isNotConnectedError(error)

        // Get the current retry count from context
        const currentRetryCount = _operation.getContext()?.retryCount ?? 0;

        if (doRetry) {
          console.warn(`Retrying ${ _operation.operationName } ${ statusCode }`, error);

          // Increment and update the retry count in context
          _operation.setContext({ retryCount: currentRetryCount + 1 });
        }

        return doRetry;
      },
    },
  });

  return retryLink;
}

export function createAuthLink(
  injector: Injector,
  graphqlModule: GraphQLModule,
) {

  const authLink = new ApolloLink((op, forward) => {
    let promiseResolve: () => void;
    const prom = new Promise<void>((resolve, reject) => {
      promiseResolve = resolve;
    });
    function resolveInFlightPromise() {
      if (promiseResolve) {
        // resolve at the end of the event queue
        setTimeout(() => {
          promiseResolve();
          const index = graphqlModule.inFlightPromises.indexOf(prom);
          if (index >= 0) {
            graphqlModule.inFlightPromises.splice(index, 1);
          }
        }, 1);
      }
    }

    const authService = injector.get(PlusAuthenticationService);

    const isAuthOp = [
      'authenticate',
      'deauthenticate',
    ].includes(op?.operationName);

    const authPromise = authService.checkAuthStatus(!isAuthOp, !isAuthOp);

    graphqlModule.inFlightPromises.push(prom);

    return graphqlModule.waitForReady()
      // wait for the auth observable to resolve before we proceed with the query
      .flatMap(() => new Observable((observer) => {
        authPromise.then((res) => {
          if (!isAuthOp && res === 'deauthenticating'){
            observer.error(res);
            return;
          }

          observer.next(res);
          observer.complete();
        });

        return () => {};
      }))
      .flatMap(
        () => forward(op).map((response) => {
          if (response && authService.connectionStatus.value !== 'connected') {
            authService.connectionStatus.next('connected');
          }

          if (isAuthOp) {
            resolveInFlightPromise();
            return response;
          }

          // Do not set zone if we are in the middle of a zone change
          if (graphqlModule.newZoneLoading) {
            resolveInFlightPromise();
            return response;
          }

          const context = op.getContext();
          const {
            response: { headers }
          } = context;

          let desiredZone = graphqlModule.zone.value;
          let desiredRole = graphqlModule.role.value;
          const opContext = op.getContext();

          opContext.zone = resolveZoneFromContext(opContext);

          if (opContext.zone) {
            desiredZone = opContext.zone;
            desiredRole = opContext.role;
          }

          // get user from header
          if (headers) {

            // TODO: initial login has zone set incorrectly for branding query
            const resolvedZone = headers.get('x-zone') || undefined;
            const resolvedRole = headers.get('x-role') || undefined;
            const backendResolvedDifferentZone = graphqlModule.zone.value === desiredZone && desiredZone !== resolvedZone;
            const backendResolvedDifferentRole = graphqlModule.role.value === desiredRole && desiredRole !== resolvedRole;

            if ((resolvedZone && !graphqlModule.zone.value) || (resolvedZone && backendResolvedDifferentZone)) {
              graphqlModule.zone.next(resolvedZone);
            }
            if (resolvedRole && !graphqlModule.role.value || (resolvedRole && backendResolvedDifferentRole)) {
              graphqlModule.role.next(resolvedRole);
            }

            const user = headers.get('x-user') || undefined;
            const deauthenticated = headers.get('x-deauthenticated');

            if (deauthenticated && op.operationName !== 'deauthenticated') {
              authService.onDeauthenticated(true).then(() => {
                location.reload();
              });
            } else if (!user && authService.user) {
              authService.notifySessionExpired();
              authService.onDeauthenticated(true).then(() => {
                location.reload();
              });
            }
          }

          // setTimeout(() => {
          resolveInFlightPromise();
          // }, 250);
          return response;

        })
      );
    // .map
  });

  return authLink;
}

export function createPersistedQueryLinkWithOptions() {
 
  const options: PersistedQueryLink.Options = {
    // retry: (err) => {
    //   console.error(`PQ RETRY`, err);
    //   return false;
    // },
    // disable: (err) => {
    //   console.error(`PQ DISABLE`, err);
    //   return false;
    // },

    sha256: sha256,
    useGETForHashedQueries: false,
    // generateHash,

  };

  return createPersistedQueryLink(options);

}
export function addQueryParams(
  graphqlModule: GraphQLModule,
  plusConnectionOptions: PlusConnectionOptions,
) {
  return setContext((operation, previousContext) => {
    let zone = graphqlModule.zone.value;
    let role = graphqlModule.role.value;

    previousContext.zone = resolveZoneFromContext(previousContext);
    if (previousContext.zone) {
      zone = previousContext.zone;
      role = previousContext.role;
    }

    const queryParams = new URLSearchParams({
      n: operation.operationName.slice(0, 32),
    });

    if (zone && role) {
      queryParams.set('context', `${zone}:${role}`);
    } else if (zone) {
      queryParams.set('zone', zone);
    }

    const uriWithParams = `${plusConnectionOptions.graphql}?${queryParams.toString()}`;

    return {
      ...previousContext, // Preserve existing context, including extensions
      uri: uriWithParams,
    };
  });
}

export function createHandleErrorLink(injector: Injector) {
  return onError(({
    graphQLErrors,
    networkError,
    operation,
    forward,
  }) => {
    const retryCount = operation.getContext()?.retryCount;
    const authService = injector.get(PlusAuthenticationService);

    const [gqlError] = graphQLErrors || [];

    if (isServerError(networkError) && isAuthenticationError(gqlError)) {
      handleAuthenticationError(authService);
    } else if (isServerError(networkError) && gqlError) {
      handleGraphQLError(graphQLErrors, networkError, operation);
    } else if (isServerError(networkError) || isNotConnectedError(networkError)) {
      handleServerError(networkError, retryCount || 0, authService);
    } else if (networkError) {
      console.error('Unknown network error:', networkError, {networkError});
    } else if (graphQLErrors?.length > 1) {
      console.error('GraphQL Errors:', graphQLErrors);
    } else if (gqlError && gqlError.extensions?.code !== 'PERSISTED_QUERY_NOT_FOUND') {
      console.error('[GQL Error]:', gqlError);
    }
  });
}

// Type guard to check if the error is an instance of HttpErrorResponse
function isServerError(error: any): error is ServerError {
  return error?.name === 'ServerError' && error?.statusCode;
}

// Helper function to check if the error is an authentication error
function isAuthenticationError(gqlError: GraphQLFormattedError): boolean {
  return gqlError?.extensions?.code === 'UNAUTHENTICATED';
}

// Handle authentication errors by deauthenticating and reloading
function handleAuthenticationError(authService: PlusAuthenticationService) {
  if (authService.jwtPayload) {
    authService.onDeauthenticated(true).then(() => {
      location.reload();
    });
  }
}

// Handle GraphQL errors by logging them and optionally breaking on errors
function handleGraphQLError(errors: readonly GraphQLFormattedError[], serverError: ServerError, operation: Operation) {
  if (environment.breakAtGraphqlErrors) {
    debugger;
  }
  console.error(`[GQL Error] ${operation.operationName}:`, {operation, errors, serverError});
}

// Handle network errors by checking retry count and connection status
function handleServerError(
  error: ServerError | Error,
  retryCount: number,
  authService: PlusAuthenticationService
) {
  const status = 'statusCode' in error && error?.statusCode;
  // const isClientError = false;

  // if (networkError.error?.errors) {
  //   for (const err of networkError.error.errors) {
  //     if (err.extensions.code === 'BAD_USER_INPUT') {
  //       console.error('[Error]: Bad Input Supplied to Function');
  //       isClientError = true;
  //     } else if (err.extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
  //       console.error('[Error]: GraphQL Schema is Invalid');
  //       isClientError = true;
  //     } else {
  //       console.error(networkError);
  //     }
  //   }
  // }

  if (isNotConnectedError(error) && retryCount >= maxRetryAttempts - 1) {
    console.error(`Not connected error: `, error, { error })
    authService.connectionStatus.next('disconnected');
    authService.pollConnection();
  }
}

function isNotConnectedError(error: Error | ServerError) {
  if (!error) { return false; }

  const status = 'statusCode' in error && error?.statusCode;
  if ((status >= 502 && status <= 504) || status === 0) {
    return true;
  }

  if (error?.message?.includes('Load failed')) {
    return true;
  }

  return false;
}

export function createWebsocketLink(
  graphqlModule: GraphQLModule,
) {

  const wsProtocol = location.protocol === 'http:' ? 'ws:' : 'wss:';

  const wsUrl = `${ wsProtocol }//${ location.host }/api/subscriptions`;

  console.log(`Connecting to WS:`, wsUrl);

  if (!graphqlModule.wsClient) {
      graphqlModule.wsClient = createClient({
        url: wsUrl,
        retryAttempts: Infinity,
        shouldRetry: () => true,
        keepAlive: 10000,
        

        // eslint-disable-next-line arrow-body-style
        connectionParams: () => {
          // TODO: reconnect on zone change

          return {
            zone: graphqlModule.zone.value,
            role: graphqlModule.role.value,
          };
        },

        on: {
          closed: () => {
            console.log(`WS Closed`);
            graphqlModule.connectionStatus.next('disconnected');

          },
          connected: () => {
            console.log(`WS Connected`);
            graphqlModule.connectionStatus.next('connected');
          },
          error: (err) => {
            console.error(`WS Error`, err)
          },
        },
      });
    }

    const wsLink = new GraphQLWsLink(graphqlModule.wsClient);
    return wsLink;
}

export function makeCache() {
  return new InMemoryCache({
    resultCaching: true,
    // fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
    typePolicies: {
      Query: {
        fields: {
          jobs: {
            // Merge the existing jobs array if incoming does not have it
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                jobs: incoming.jobs ? incoming.jobs : existing.jobs,
              };
            },
          },
          users: {
            // Merge the existing users array if incoming does not have it
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                users: incoming.users ? incoming.users : existing.users,
              };
            },

          }
        },
      },
    }
  });
}

function resolveZoneFromContext(opContext: any): string | undefined {
  if (!opContext) { return undefined; }

  if (opContext.zone) {
    return opContext.zone;
  }

  if (opContext['x-zone']) {
    return opContext['x-zone'];
  }

  if (opContext.headers && opContext.headers['x-zone']) {
    return opContext.headers['x-zone'];
  }

  return undefined;
}



export class PlusApollo extends Apollo { }

export class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    // eslint-disable-next-line arrow-body-style
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      );
    });
  }
}

export type WSConnectionStatus = 'connected' | 'disconnected';

// @dynamic
@NgModule({
  imports: [
    // ApolloModule,
    HttpClientModule,
    // HttpLinkModule
  ],
  exports: [
    // ApolloModule,
    HttpClientModule,
    // HttpLinkModule
  ],
  providers: [],
})
export class GraphQLModule {

  wsClient: Client;
  inFlightPromises: Promise<void>[] = [];

  readyResolve: () => void;
  readyWaiting: Promise<void>;

  /**
   * A behaviour subject of the currently contexted zone
   */
  public zone = new BehaviorSubject<string>(undefined);

  /**
   * A behaviour subject of the currently contexted zone
   */
  public role = new BehaviorSubject<string>(undefined);

  public connectionStatus = new BehaviorSubject<WSConnectionStatus>('disconnected');

  /**
   * String combination of zone:role used to context
   * into the backend
   */
  public get context() {
    return `${this.zone}:${this.role}`;
  }

  public newZoneLoading = false;

  constructor() {

  }

  public static forRoot(config?: PlusConnectionOptions): ModuleWithProviders<GraphQLModule> {
    return {
      ngModule: GraphQLModule,
      providers: [
        {
          provide: PLUS_CONNECTION_OPTIONS,
          useValue: config || {
            graphql: 'localhost:1337/api/graphql',
            ws: 'localhost:1337/api/subscriptions',
          }
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: createApollo,
          deps: [
            Injector,
            PLUS_CONNECTION_OPTIONS,
            GraphQLModule,
          ],
        },
        {
          provide: PlusApollo,
          useExisting: Apollo,
        }
      ]
    };
  }

  reinitWebsocket() {
    if (!this.wsClient) { return; }
    // re-initialize the websocket connection
    // this.wsClient.close(false, false);
    console.log(`Reinitializing the websocket connection`);
  }



  markWaiting() {
    this.markReady();

    this.readyWaiting = new Promise((resolve) => {
      this.readyResolve = resolve;
    });
  }

  async waitForInflightQueries(timeout = 5000) {
    console.log('Changing Zone');
    return new Promise<void>(async (resolve) => {
      const timeoutHandle = setTimeout(() => {
        this.inFlightPromises = [];
        resolve();
      }, timeout);
      console.warn(this.inFlightPromises);
      Promise.all(this.inFlightPromises)
        .catch()
        .then((vals) => {
          this.inFlightPromises = [];
          clearTimeout(timeoutHandle);
          resolve();
        });
    });

  }

  markReady() {
    if (this.readyResolve) {
      this.readyResolve();
      this.readyResolve = undefined;
      this.readyWaiting = undefined;
    }
  }

  waitForReady() {
    return new Observable<boolean>((observer) => {
      if (!this.readyWaiting) {
        observer.next(true);
        observer.complete();
        return;
      } else {
        this.readyWaiting.then(() => {
          observer.next(true);
          observer.complete();
        });
      }

    });

  }
}
