import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { QuillEditorComponent } from 'ngx-quill';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { JOB_SUMMARIES } from '../../../global.constants';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { JobSummaries, jobToolFeature } from '../../job-tool.reducer';
import { Jobv2SummaryComponent } from "../../jobv2-create/jobv2-summary/jobv2-summary.component";

@Component({
  selector: 'app-overview-summary',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
    QuillEditorComponent,
    Jobv2SummaryComponent
  ],
  templateUrl: './overview-summary.component.html',
  styleUrl: './overview-summary.component.scss'
})
export class OverviewSummaryComponent implements OnInit, OnDestroy {

  private jobSummaries$ = this.store.select(jobToolFeature.selectJobSummaries);
  public jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);

  public jobSummaries: JobSummaries;
  public selectedSummaryIndex = 2;
  public summaryOptions = [
    {
      label: 'Crew Summary',
      value: 'crewSummary'
    }, {
      label: 'Admin Summary',
      value: 'adminSummary'
    },
    {
      label: 'Customer Summary',
      value: 'customerSummary'
    }
  ]

  jobSummaryData = JOB_SUMMARIES;

  isEditMode$ = this.store.select(jobToolFeature.selectIsSummaryEditMode);

  private subs = new SubSink();
  private editSubs = new SubSink();

  constructor(
    private store: Store,
    private actions: Actions,
  ) { }

  ngOnInit(): void {
    this.watchJobSummaries();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.editSubs.unsubscribe();
  }

  private watchJobSummaries() {

    this.subs.sink = this.jobSummaries$.subscribe(summaries => {
      if (summaries) {
        this.jobSummaries = summaries;
      }
    });

    this.subs.sink = this.actions.pipe(ofType(JobToolActions.summaryInPlaceEditingCancelled)).subscribe(() => {
      this.editSubs.unsubscribe();
    });
  }
  public editSummary() {
    this.store.dispatch(JobToolActions.summaryInPlaceEditingActivated());
  }

  public cancelEditSummary() {
    this.store.dispatch(JobToolActions.summaryInPlaceEditingCancelled());
  }

  public updateJob() {
    this.store.dispatch(JobToolActions.jobUpdateRequested());

    const updateSuccess$ = this.store.select(jobToolFeature.updateJobLoaded);

    // Subscribe to the success indicator
    this.editSubs.unsubscribe();
    this.editSubs.sink = updateSuccess$.subscribe((success) => {
      if (success) {
        this.cancelEditSummary();
      }
      // If not successful, do nothing and let the user adjust as needed
    });
  }
}
