import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MenuItem, Message } from "primeng/api";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";

import { SubSink } from "subsink";

import { ResponsiveHelperService } from "../../../services/responsive-helper.service";
import { jobToolFeature } from "../../job-tool.reducer";
import { JobEditActions } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions";
import { selectJobUpdatingErrors } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors";
import { selectJobSavingErrors } from "../jobv2-create-state/jobv2-create.selectors";

import { JobV2FormActionButtonComponent } from "../jobv2-form-action-button/jobv2-form-action-button";
import { FeedbackService } from "../../../core/public-api";

@Component({
  selector: 'app-jobv2-create-header',
  standalone: true,
  imports: [
    FreyaCommonModule,
    JobV2FormActionButtonComponent,
  ],
  templateUrl: './jobv2-create-header.component.html',
  styleUrls: ['./jobv2-create-header.component.scss']
})
export class Jobv2CreateHeaderComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store,
    public router: Router,
    private feedbackService: FeedbackService,
    public responsiveHelper: ResponsiveHelperService,
  ) { }

  private subs = new SubSink();

  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  jobInput$ = this.store.select(jobToolFeature.selectJobInput);

  jobCode: string;
  jobId: string;
  editMode: boolean;

  //error handling
  jobSavingErrors$ = this.store.select(selectJobSavingErrors);
  jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);

  breadcrumb: MenuItem[];
  home: MenuItem;

  ngOnInit(): void {
    this.subs.sink = this.jobInput$.subscribe((jobInput) => {
      this.jobCode = jobInput?.code;
      this.jobId = jobInput.id;
    });

    this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
      this.editMode = jobFormMode === 'edit';

      this.setBreadcrumb(this.editMode);
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setBreadcrumb(editMode: boolean) {

    if (editMode) {
      this.breadcrumb = [
        { label: 'Jobs', routerLink: '/jobs'},
        { label: this.jobCode, routerLink: `/jobs/${this.jobId}`},
        { label: 'Edit', routerLink: `/jobs/${this.jobId}/edit` },
      ];
    } else {
      this.breadcrumb = [
        { label: 'Jobs', routerLink: '/jobs'},
        { label: 'New Opportunity', routerLink: `/jobs/new`},
      ];
    }

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  reportBug(subject: string) {
    this.feedbackService.openFeedbackDialog({
      subject,
      feedbackType: 'bug',
    });
  }
}
