<div class="card">
	<header>
		<h3> {{isEditMode ? 'Edit' : 'New'}} Event</h3>
		<div *ngIf="isEditMode && event?.event?.start">
			<span
				class="freya-link-button"
				(click)="reschedule()"
				pTooltip="Reschedule"
			>{{ event.datetime }} </span>
		</div>
	</header>
	<form
		*ngIf="eventForm"
		[formGroup]="eventForm"
		(ngSubmit)="formSubmitted()"
	>
		<div class="p-fluid p-formgrid p-grid">
			<div class="p-field p-col-12 p-md-6">
				<label for="eventType">Event Type *</label>
				<p-dropdown
					id="eventType"
					[options]="eventTypes"
					placeholder="Select event type"
					optionLabel="name"
					optionValue="value"
					formControlName="type"
					appendTo="body"
				>
					<ng-template
						let-item
						pTemplate="item"
					>
						<div class="eventType">
							<span
								class="color"
								[style.backgroundColor]="item.backgroundColor"
							></span>
							<span>{{ item.name }}</span>
						</div>
					</ng-template>
					<ng-template
						let-selectedItem
						pTemplate="selectedItem"
					>
						<div class="eventType">
							<span
								class="color"
								[style.backgroundColor]="selectedItem.backgroundColor"
							></span>
							<span>{{ selectedItem.name }}</span>
						</div>
					</ng-template>
				</p-dropdown>
			</div>
			<div class="p-col-6"></div>
			<div class="p-field p-col-12 p-md-6">
				<label for="eventTitle">Event Title *</label>
				<input
					id="eventTitle"
					type="text"
					pInputText
					formControlName="title"
				/>
			</div>
			<div class="p-field p-col-12 p-md-6">
				<label for="eventStatus">Status *</label>
				<p-dropdown
					id="eventStatus"
					[options]="eventStatus"
					placeholder="Select event status"
					formControlName="status"
					appendTo="body"
				></p-dropdown>
			</div>
			<div class="p-field p-col-12">
				<label for="assets">Assets</label>
				<p-autoComplete
					id="assets"
					[multiple]="true"
					[suggestions]="searchedAssets$ | async"
					(completeMethod)="searchAssets($event)"
					field="name"
					formControlName="assets"
					appendTo="body"
					placeholder="Type asset name"
					appendTo="body"
				>
					<ng-template
						let-selectedItem
						pTemplate="selectedItem"
					>
						<div class="p-d-flex gap-2">
							<i class="pi pi-truck"></i>
							<span>{{ selectedItem.name }}</span>
						</div>
					</ng-template>
					<ng-template
						let-item
						pTemplate="item"
					>
						<div class="p-d-flex gap-2">
							<i class="pi pi-truck"></i>
							<span>{{ item.name }}</span>
						</div>
					</ng-template>
				</p-autoComplete>
			</div>
			<div class="p-field p-col-12">
				<h5>Locations</h5>
				<p-multiSelect
					display="chip"
					formControlName="locations"
					dataKey="addressLabel"
					optionLabel="addressLabel"
					[options]="potentialEventLocations$ | async"
					placeholder="Select locations"
					appendTo="body"
				></p-multiSelect>
			</div>
			<div
				class="p-field p-col-12 p-fluid p-formgrid p-grid"
				cdkDropList
				(cdkDropListDropped)="drop($event)"
			>
				<div
					cdkDrag
					class="p-field p-col-12 p-fluid p-formgrid p-grid locationDetailsForm"
					*ngFor="let location of eventForm.value.locations; let i = index"
				>

					<div class="p-field p-col-6 locationDetails">
						<b> <i class="pi pi-map p-mr-2"></i> {{ location.type}} </b>
						<span> {{location.address }} </span>
					</div>
					<div class="p-field p-col-5">
						<label for="estimatedTimeAtLocation">Estimated time at location</label>
						<app-duration-input [(duration)]="location.estimatedTimeAtLocation"></app-duration-input>
					</div>
					<div class="p-field p-col-1">
						<span class="dragHandle">
							<i class="pi pi-bars"></i>
						</span>
					</div>
				</div>
			</div>

			<div class="p-field p-col-12">
				<h5>Attendees</h5>
				<p-autoComplete
					id="crew"
					[multiple]="true"
					[suggestions]="searchedCrew$ | async"
					(completeMethod)="searchCrew($event)"
					field="name"
					formControlName="crew"
					appendTo="body"
					placeholder="Type crew name"
					appendTo="body"
				>
					<ng-template
						let-item
						pTemplate="item"
					>
						<div class="flex align-items-center gap-2">
							<div class="p-text-capitalize">{{item.name}} </div>
							<i
								class="pi pi-star-fill"
								*ngIf="item.role === 'Crew Lead'"
							></i>
						</div>

					</ng-template>
					<ng-template
						let-selectedItem
						pTemplate="selectedItem"
					>
						<div class="flex align-items-center gap-2">
							<div class="p-text-capitalize">{{selectedItem.name}} </div>
							<i
								class="pi pi-star-fill p-mr-2"
								*ngIf="selectedItem.role === 'Crew Lead'"
							></i>
						</div>
					</ng-template>
				</p-autoComplete>
			</div>
		</div>
		<div class="actionButtons">
			<p-button
				class="cancelButton"
				[outlined]="true"
				label="Cancel"
				icon="pi pi-times"
				(onClick)="onClose()"
			></p-button>
			<!-- <p-button
					[disabled]="true"
					[outlined]="true"
					label="Create & Schedule"
					icon="pi pi-calendar"
					(onClick)="onClose()"
				></p-button> -->
			<p-button
				[loading]="isLoading"
				[disabled]="!eventForm.valid"
				class="submitButton"
				type="submit"
				label="{{isEditMode ? 'Save': 'Create'}} Event"
				icon="pi pi-check"
			></p-button>
		</div>
	</form>
</div>