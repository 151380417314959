import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Asset, BaseAssetFragment, BaseCalendarEventFragment, CalendarEvent, CalendarEventForScheduleFragment, FindQuery } from 'graphql.generated';
import { EventInfo } from 'src/app/services/estimate-helper.service';

export interface PlaceholderEventInfo {
   id: string;
   type: string;
   title: string;
   start: number;
   assets: Asset[];
   end?: number;
   //always empty, used just for correct rendering event placeholder
   locations?: Location[];
   products?: [];
}

export const ScheduleEventsActions = createActionGroup({
   source: 'Schedule Event',
   events: {
      'Open Book Dialog Button Clicked': props<{
         event: BaseCalendarEventFragment,
         reschedule?: boolean,
      }>(),
      'Book Dialog Closed': emptyProps(),

      'Include Dock Travel Checked': props<{
         includeDockTravel: boolean;
         eventId: string;
      }>(),
      'Event Info Updated': props<{ updatedEventInfo: EventInfo }>(),

      'Date Selected': props<{
         // start?: Date,
         // end?: Date,
         date: string | Date,
         fromFullCalendarChange?: boolean,
         // eventType: string,
         // currentEventIdToExclude?: string,
         // preselectedAssets?: Asset[],
      }>(),
      'Find Availability Success': props<{
         startTimes: number[],
         possibleWindows: FindQuery['find']['windows'];
         hasLockedWindows: boolean
      }>(),
      'Find Availability Error': props<{ error: Error }>(),

      'Time Selected': props<{ time: number, eventId: string }>(),

      'Available Assets Set': props<{ availableAssets: BaseAssetFragment[] }>(),

      'Assets Selected': props<{
         assets: string[],
         // eventType: string;
         // placeholderEventInfo: PlaceholderEventInfo
      }>(),
      'Placeholder Timing Calculated': props<{
         placeholderEventInfo: PlaceholderEventInfo,
         selectedAssetsIds: string[],
      }>(),

      'Book Button Clicked': emptyProps(),
      'Book Event Success': props<{ updatedEvent: CalendarEventForScheduleFragment }>(),
      'Book Event Error': props<{ error: string }>(),

      'Reschedule Button Clicked': emptyProps(),
      'Update Event Button Clicked': emptyProps(),
      // 'Reschedule Event Success': props<{ updatedEvent: CalendarEvent }>(),
      // 'Reschedule Event Error': props<{ error: Error }>(),

      'Booking Restrictions Changed': props<{
         isBookingRestrictionDisabled: boolean;
      }>(),


   },
});
