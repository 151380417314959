import { Pipe, PipeTransform } from '@angular/core';

import { JOB_ROLE_MAP } from '../global.constants';


@Pipe({
  name: 'jobCustomer'
})
export class JobCustomerPipe implements PipeTransform {

  transform<
    T extends {
      user?: {
        givenName?: string;
        familyName?: string;
      };
      role?: string;
    },
  >(
    users: T[], asString = true
  ): string | T['user'] {
    if (!users?.length) { return 'None'; }
    const user = users.find((u) => u.role === JOB_ROLE_MAP.customerRole);

    if (asString) {
      return user?.user ? `${user.user.givenName} ${user.user.familyName}` : 'None';
    } else {
      return user?.user;
    }
  }

}
 
