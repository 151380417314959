/* eslint-disable @ngrx/prefix-selectors-with-select */
import { dayjs } from '@karve.it/core';
import { SearchUsersInput } from '@karve.it/interfaces/users';
import { createFeature, createReducer, createSelector, MemoizedSelector, on } from '@ngrx/store';

import { clone, cloneDeep } from 'lodash';

import { Delta } from 'quill/core';

import {
  Asset,
  BaseArtifactFragment,
  BaseAssetFragment,
  BaseCalendarEventFragment,
  BaseFieldFragment,
  BaseUserFragment,
  BaseZoneFragment,
  CalendarEventLocation,
  CommentWithRepliesFragment,
  Discount,
  Expense,
  FindQuery,
  FullCalendarEventFragment,
  FullJobFragment,
  FullTransactionFragment,
  FullUserFragment,
  InvoiceWithArtifactsFragment,
  Job,
  JobsV2PageQuery,
  Location,
  TagsQuery,
  Tax,
  User
} from '../../generated/graphql.generated';

import { getJobFromQuickAccess } from '../base/menu/quick-access/quick-access.util';
import { getFieldValue, splitName } from '../fields/fields.utils';
import {
  ADDABLE_LOCATIONS_V2,
  DEFAULT_INVENTORY,
  EventAttendeeRoles,
  eventTypeInfoMap,
  JOB_CATEGORIES,
  JOB_FORM_FIELDS,
  JOB_ROLE_MAP,
  ZONE_TYPES
} from '../global.constants';
import { UserProfile } from '../interfaces/auth';
import { formatLocationToDisplay, getAccessInformation, getBuildingDetails } from '../jobs/jobs.util';
import { CREW_LEAD_ROLE } from '../schedules/dispatch/store/dispatch.reducer';
import { currentTimeSeconds } from '../time';
import { CallState, LoadingState } from '../utilities/state.util';

import { addCommentToComments, removeCommentFromArray, updateCommentInPlace } from './job-activity/comments.utils';
import { InventoryActions } from './job-inventory/inventory.actions';
import { scheduleEventsReducers } from './job-state/event-schedule-state/event-schedule.reducer';
import { jobToolSubscriptionReducers } from './job-state/job-tool-subscription.reducers';
import { workOrdersReducers } from './job-state/workorders-state/workorders.reducer';
import { JobToolActions } from './job-tool.actions';
import { trackUpdatingEventStatus } from './jobsv2-charges-helpers';
import { filterLocationsForAddAndRemove, formatDateTimeWithTimezone, generateFieldsInputFromLatestChanges, generateJobInputFromLatestChanges, generateJobInputMetadataFromLatestChanges, generateLocationsInputsFromLatestChanges, getServiceAreaFromChanges, populateCustomerInfoFromResponse, populateFieldsFromResponse, populateJobInputFromResponse, populateLocationsInputsFromResponse, populateResolvedServiceAreaFromResponse, stringifyContents } from './jobsv2-helpers';
import { jobCreateCustomerReducers } from './jobv2-create/jobv2-create-customer-state/jobv2-create-customer.reducer';
import { jobCreateInventoryReducers } from './jobv2-create/jobv2-create-inventory-state/jobv2-create-inventory-state.reducer';
import { jobCreateLocationsReducers } from './jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.reducer';
import { jobCreateReducers } from './jobv2-create/jobv2-create-state/jobv2-create.reducer';
import { jobSummaryReducers } from './jobv2-create/jobv2-create-summary-state/jobv2-create-summary.reducer';
import { AddableLocations, CalendarEventWithLockedAndInvoicedFlag, ChargesUpdate, DistanceCalculations, DocumentsArtifacts, JobTiming, SummaryUpdate, UnsavedChangesToastInfo } from './jobv2-create/jobv2-interfaces';
import { jobTimelineReducers } from './jobv2-create/jobv2-timeline-availability-state/jobv2-timeline-availability-state.reducer';
import { jobEditReducers } from './jobv2-edit/jobv2-edit-state/jobv2-edit.reducer';

export type JobToolFieldsV2 = {
  [key: string]: Partial<BaseFieldFragment>;
};

// States that are tracked by the job tool under "callState"
export const JOB_TOOL_CALL_STATES = [
  'job',
  'discounts',
  'eventList',
  'addComment',
  'updateComment',
  'searchCustomer',
  'loadJobConfigs',
  'resolveServiceArea',
  'createLocation',
  'loadDockLocation',
  'calculateDistance',
  'findAvailableTimes',
  'deleteComment',
  'createCustomer',
  'updateCustomer',
  'createJob',
  'updateJob',
  'setFields',
  'setTags',
  'jobState',
  'loadInventory',
  'documents',
  'invoices',
  'transactions',

  // Job Overview
  'roles',
  'jobAgentChange',
  'employees',
  'eventCreation',
  'eventUpdate',
  'eventList',
  'documentArtifact',
  'closeJob',
  'reopenJob',
  'updateJobStage',
  'bookEvent',

  // Job Inventory
  'yemboSelfSurvey'
] as const;
export type JobToolCallState = (typeof JOB_TOOL_CALL_STATES)[number];

export type LocationWithHowSetIndicator = {
  [key: string]: Partial<Location> & { setManually?: boolean };
};

export type EditCustomerInputWithFullName = Partial<FullUserFragment> & {
  fullName?: string;
};

export type JobInputWithSummaries = Partial<Job> & {
  crewSummary?: Summary;
  adminSummary?: Summary;
  customerSummary?: Summary;
};

export interface Summary {
  text?: string;
  contents?: Delta;
}

export interface JobToolComment extends CommentWithRepliesFragment {
  callState?: CallState;
}

export interface ResolvedServiceArea {
  id: string;
  name: string;
}

export interface JobChange<T = any> {
  namespace: string;
  fieldName: string;
  value: T;

  key?: string;
  remove?: boolean;
}

export interface ResolvedServiceArea {
  id: string;
  name: string;
}

export type Modes = 'edit' | 'create';

export type FullJobFragmentWithFields = FullJobFragment & {
  fields: Partial<BaseFieldFragment>[];
  events: Partial<CalendarEventWithLockedAndInvoicedFlag>[];
  distances: DistanceCalculations;
  timezone?: string;
 };

export const jobChangeNamespaces = ['fieldsInput', 'customerInput', 'locationsInputs', 'jobInput', 'inventoryInput'] as const;

export type JobChangeNamespace = (typeof jobChangeNamespaces)[number];

export interface JobChange<T = any> {
  namespace: string;
  fieldName: string;
  value: T;

  key?: string;
  remove?: boolean;
}

export type FullUserFragmentWithFullName = Partial<FullUserFragment> & {
  fullName?: string;
};

export type Tag = {
  id: string;
  name: string;
}

export type EstimateMethod = 'selfSurvey' | 'smartConsult';

/***
 * The initial job tool state, also used to
 * create the job tool type.
 * 
 * PLEASE make sure you place any new states in the respective category
 * or try to create a new category as this state is becoming really large.
 */
export const jobToolInitialState = {

  //======  app info -- to be moved OUT of job tool
  // currently logged in user
  user: undefined as UserProfile | undefined,
  currentAppZone: undefined as BaseZoneFragment | undefined,

  //=== Shared info

  jobFormMode: 'create' as Modes,
  job: undefined as JobsV2PageQuery['jobs']['jobs'][number]
    & FullJobFragmentWithFields | undefined,
  jobId: undefined as string | undefined,

  callState: JOB_TOOL_CALL_STATES.reduce((p, c) => ({ ...p, [c]: LoadingState.INIT }), {} as {
    [ key in JobToolCallState ]: CallState;
  }),

  events: [] as FullCalendarEventFragment[] | undefined,

  zone: undefined as BaseZoneFragment | undefined,

  //=== Job tool general state

  tab: {
    name: 'overview' as string,
    index: 0 as number,
  },

  jobTagsIds: [] as Tag[],

  chargesUpdates: [] as ChargesUpdate[],
  unsavedChangesReminders: [] as UnsavedChangesToastInfo[],

  //=== Job Tool > Overview
  employeeSearch: '' as string,
  employees: [] as User[],
  tagSearch: '' as string,
  tags: [] as TagsQuery['tags']['tags'],
  assetSearch: '' as string,
  assets: [] as Asset[],
  crewSearch: '' as string,
  crew: [] as User[],

  //=== Job Tool > Overview > Timeline > Create/Edit Event
  createEventDialogVisible: false as boolean,
  eventIdToEdit: null as string | null,

  summaryUpdates: [] as SummaryUpdate[],
  isSummaryEditMode: false as boolean,
  closedReason: undefined as string,
  isDockEditMode: false as boolean,

  //=== Job Tool > Inventory
  //=== Job Tool > Workorders
  availableDiscounts: [] as Partial<Discount>[],
  taxes: [] as Tax[],
  expenses: [] as Expense[],

  //=== Job Tool > Billing
  invoices: [] as InvoiceWithArtifactsFragment[],
  transactions: [] as FullTransactionFragment[],

  //=== Job Tool > Documents
  documentsArtifacts: {} as DocumentsArtifacts,
  documents: [] as BaseArtifactFragment[],

  //=== Job Tool > Activity
  totalComments: undefined as number | undefined,
  comments: undefined as JobToolComment[] | undefined,

  //=== Job Creation Tool
  customerInput: undefined as FullUserFragmentWithFullName | undefined,
  jobInput: {
    //as selecting from UI is disabled now, predefine it here
    jobCategory: JOB_CATEGORIES[0],
    metadata: {},
  } as JobInputWithSummaries,
  locationsInputs: undefined as LocationWithHowSetIndicator | undefined,
  inventoryInput: cloneDeep(DEFAULT_INVENTORY),
  fieldsInput: {} as {
    [key: string]: string,
  },

  //track whether user left Create Job and came back
  continueEditing: false as boolean,
  changes: [] as Array<JobChange>,
  validationErrors: {} as { [key: string]: string },


  customerSearchInput: {} as SearchUsersInput | object,
  suggestedCustomers: [] as (BaseUserFragment & {
    fullName: string;
  })[],


  addableAdditionalLocations: [...ADDABLE_LOCATIONS_V2] as AddableLocations,
  zoneToContextInto: undefined as string,
  resolvedServiceArea: undefined as ResolvedServiceArea | undefined,
  distances: {} as DistanceCalculations,

  jobTiming: {
    moving: {
      totalLocationTime: 0,
      totalTime: 0,
      //used when when both start and end locations are set
      totalTravelTime: 0,
      //used when only one location is set
      partialTravelTime: 0,
    },
  } as JobTiming,

  availableTimeSlots: [] as number[],
  selectedTimeSlot: undefined as number | undefined,
  editCustomerWhenCreateJobMode: false as boolean,

  //=== Event Booking Data
  eventToBook: undefined as BaseCalendarEventFragment,
  bookEventDialogVisible: false as boolean,
  includeDockTravel: true as boolean,

  // minWindowLength: undefined as number | undefined,
  selectedDate: undefined as string | undefined,
  selectedStartTime: undefined as number | undefined,
  selectedAssets: [] as string[],

  loadingFindTime: false as boolean,
  possibleWindows: [] as FindQuery['find']['windows'],
  availableTimes: [] as number[],
  availableAssets: [] as BaseAssetFragment[],
  hasLockedWindows: false as boolean,

  // view or schedule
  scheduleMode: 'view' as string,
  alreadyScheduled: false as boolean,

  showAssetsNotAvailableWarning: false as boolean,
  showTimeNotAvailableWarning: false as boolean,
  isBookingRestrictionDisabled: false as boolean,

  // calendarViewData: {
  //   min: undefined as number | undefined,
  //   max: undefined as number | undefined,
  //   startDate: undefined as string | undefined,
  //   endDate: undefined as string | undefined,
  // },
  // calendarEvents: [] as ExtendedCalendarEventForScheduleFragment[],
  // calendarEventDataErrors: [] as DataError[] ,
  // calendarAssets: [] as AssetWithConfig[],
  // calendarAvailability: [] as AvailabilityOutput[],

} as const;

export type JobToolState = typeof jobToolInitialState;

export type LoadingSelectorType = MemoizedSelector<Record<string, any>, boolean, (s1: JobToolState['callState']) => boolean>;

export type LoadingErrorSelectorType = MemoizedSelector<Record<string, any>, boolean, (s1: JobToolState['callState']) => string | null>;

export interface DetailedLocation extends Location {
  addressString: string;
  locationType: string;
  details: string;
  parkingInformation: string;
  accessInformation: string;
}
export interface JobLocations {
  start: DetailedLocation;
  end: DetailedLocation;
  dock: DetailedLocation;
  otherStops: DetailedLocation[];
}

export interface UserWithName extends BaseUserFragment {
  name: string;
  role?: JobRole;
}

export type JobRole = (typeof JOB_ROLE_MAP)[keyof typeof JOB_ROLE_MAP];

export interface UpdateJobsV2Input {
  jobId: string;
  addUsers: UserWithName[];
  removeUsers: UserWithName[];
}

export interface JobsV2EventLocation {
  id: string;
  locationId: string;
  type: string;
  address: string;
  addressLabel: string;
  order: number;
  estimatedTimeAtLocation: number;
  ignoreInDuration: boolean;
}

export interface UpdateJobsV2CalendarEventInput {
  eventId: string;
  title?: string;
  status?: string;
  type?: string;
  setAttendees?: {
    addAttendees: UserWithName[];
    removeAttendees: UserWithName[];
  };
  setAssets?: {
    addAssets: Asset[];
    removeAssets: Asset[];
  };
  setLocations?: {
    addLocations: JobsV2EventLocation[];
    editLocations: JobsV2EventLocation[];
    removeLocations: JobsV2EventLocation[];
  };
};

export interface JobEvent {
  title: string;
  details: string;
  datetime: string;
  style: { borderLeftColor: string };
  locations: JobsV2EventLocation[];
  crew: UserWithName[];
  event: BaseCalendarEventFragment;
}

export const formatPotentialEventLocations = (location: DetailedLocation, index: number): JobsV2EventLocation => ({
  address: location.addressLineOne,
  addressLabel: `${location.locationType}: ${location.addressLineOne}`,
  id: `new-event-location-${index}`,
  locationId: location.id,
  type: location.locationType,
  estimatedTimeAtLocation: 0,
  order: index,
  ignoreInDuration: true,
});

export const formatEventLocations = (location: CalendarEventLocation): JobsV2EventLocation => ({
  address: location.location.addressLineOne,
  addressLabel: `${location.type}: ${location.location.addressLineOne}`,
  id: location.id,
  locationId: location.location.id,
  type: location.type,
  estimatedTimeAtLocation: location.estimatedTimeAtLocation,
  ignoreInDuration: true,
  order: location.order,
});


const formatEventDetails = (event: BaseCalendarEventFragment, timezone: string): JobEvent => {
  const { title: eventTitle, status, assets = [], attendees = [], start, end, locations = [] } = event;

  const title = [eventTitle, status].filter(Boolean).join(' - ');

  let eventDate = 'Not Booked';
  let eventTime = null;
  let duration = null;

  if (start && end) {
    const startDateFormatted = formatDateTimeWithTimezone(start, timezone);
    const endDateFormatted = formatDateTimeWithTimezone(end, timezone);

    eventDate = `${startDateFormatted.date} - ${endDateFormatted.date}`;
    eventTime = `${startDateFormatted.time} - ${endDateFormatted.time}`;

    const dayJsStart = dayjs(start * 1000).tz(timezone);
    const dayJsEnd = dayjs(end * 1000).tz(timezone);
    duration = dayJsEnd.diff(dayJsStart, 'hour');
  }

  const trucks = assets.length ? `${assets.length} Truck${assets.length > 1 ? 's' : ''}` : '';
  const crew = attendees.filter(({ role }) => role !== EventAttendeeRoles.author);
  const crewCount = crew.length;
  const totalCrew = crewCount ? `${crewCount} Crew` : '';

  const datetime = [
    eventDate,
    eventTime,
    duration && `${duration} hrs`,
  ].filter(Boolean).join(' | ');

  const details = [
    eventDate,
    eventTime,
    duration && `${duration} hrs`,
    //timezone && duration ? `(${timezone})` : '',
    trucks,
    totalCrew
  ].filter(Boolean).join(' | ');

  const style = {
    borderLeftColor: eventTypeInfoMap[event.type]?.backgroundColor,
  };

  // Event details for editing an event
  const derivedCrew = crew.map((attendee) => ({
    ...attendee.user,
    role: attendee.role,
    name: [attendee.user.givenName, attendee.user.familyName].filter(Boolean).join(' '),
  }));

  const derivedLocations = locations
    ? locations.map(formatEventLocations).sort((a, b) => a.order - b.order)
    : [];

  return {
    title,
    datetime,
    details,
    style,
    event,
    crew: derivedCrew,
    locations: derivedLocations,
  };
};

const darkenColor = (color: string, percent: number): string => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

const getAttendeeRole = (attendee: User): EventAttendeeRoles => {
  const role = attendee.roles?.[0];
  return role?.attributes.includes(CREW_LEAD_ROLE)
    ? EventAttendeeRoles.crewLead
    : EventAttendeeRoles.crewMember;
}

type JobSummary = {
  summary: Delta;
  format: 'Object';
  isEmpty: boolean;
} |
{
  summary: string;
  format: 'text';
  isEmpty: boolean;
};

export interface JobSummaries {
  crewSummary: JobSummary;
  adminSummary: JobSummary;
  customerSummary: JobSummary;
}

export const parseSummary = (
  summary: { text: string; contents: string },
  summaryType: 'admin' | 'crew' | 'customer',
  readOnly: boolean = false,
): JobSummary => {
  if (!summary) {
    return {
      summary: getEmptySummaryText(summaryType, readOnly),
      format: 'text',
      isEmpty: true,
    };
  }

  const { contents, text } = summary;

  return contents
    ? {
      summary: JSON.parse(contents),
      format: 'Object',
      isEmpty: false,
    }
    : {
      summary: text || getEmptySummaryText(summaryType, readOnly),
      format: 'text',
      isEmpty: !text,
    };
};

function getEmptySummaryText(summaryType: 'admin' | 'crew' | 'customer', readOnly: boolean) {

  if (readOnly) {
    return 'Summary is not set';
  } else {
    let visibleTo = '';

    if (summaryType === 'admin') {
      visibleTo = 'only visible for admins';
    } else if (summaryType === 'crew') {
      visibleTo = 'visible for crew and office staff';
    } else if (summaryType === 'customer') {
      visibleTo = 'visible for customers';
    }

    return `Click here to set the ${summaryType} summary, ${visibleTo}.`;
  }
}

export const jobToolFeature = createFeature({
  name: 'jobTool',
  extraSelectors: ({ selectCallState, selectJob, selectEmployees, selectTags, selectCrew, selectDocuments, 
    selectInvoices, selectEventIdToEdit }) => {
    const loadingSelectors = JOB_TOOL_CALL_STATES.reduce(
      (p, callState) => {
        const selectors = {
          [`${callState}Loading`]: createSelector(
            selectCallState,
            (state) =>
              state[callState] === LoadingState.PENDING ||
              state[callState] === LoadingState.LOADING ||
              state[callState] === LoadingState.MUTATING,
          ),
          [`${callState}Loaded`]: createSelector(
            selectCallState,
            (state) => state[callState] === LoadingState.LOADED || state[callState] === LoadingState.MUTATED,
          ),
        };

        return {
          ...p,
          ...selectors,
        };
      },
      {} as {
        [x in `${JobToolCallState}Loading` | `${JobToolCallState}Loaded`]: LoadingSelectorType;
      },
    );

    const errorSelectors = JOB_TOOL_CALL_STATES.reduce(
      (p, callState) => {
        // eslint-disable-next-line @ngrx/prefix-selectors-with-select
        const selectors = {
          [`${callState}Error`]: createSelector(selectCallState, (state) => {
            const cs = state[callState];
            if (typeof cs === 'object' && 'error' in cs) {
              return cs.error;
            }

            return null;
          }),
        };

        return {
          ...p,
          ...selectors,
        };
      },
      {} as {
        [x in `${JobToolCallState}Error`]: LoadingErrorSelectorType;
      },
    );

    const isAnyLoading = createSelector(selectCallState, (callState) => {
      for (const key in callState) {
        if (callState[key] === LoadingState.LOADING) {
          return true;
        }
        if (callState[key] === LoadingState.MUTATING) {
          return true;
        }
      }

      return false;
    });


    const isJobLoading = createSelector(selectCallState, (callState) => {
      {
        if (callState['job'] === LoadingState.LOADING) {
          return true;
        }
        if (callState['job'] === LoadingState.MUTATING) {
          return true;
        }
      }


      return false;
    });

    const isJobBeingUpdated = createSelector(selectCallState, (callState) => {
      {
        if (callState['updateJob'] === LoadingState.MUTATING) {
          return true;
        }
      }


      return false;
    });

    const isEventBeingScheduled = createSelector(selectCallState, (callState) => {
      {
        if (callState['bookEvent'] === LoadingState.MUTATING) {
          return true;
        }
      }


      return false;
    });

    const selectCustomer = createSelector(selectJob, (job) => {
      const jobCustomer = job?.users?.find((u) => u.role === JOB_ROLE_MAP.customerRole);
      return jobCustomer?.user;
    });

    const selectCustomerName = createSelector(selectCustomer, (customer) => {
      if (!customer) return 'No Customer';

      const customerName = [
        customer.givenName,
        customer.familyName,
      ]
        .map((s) => s.trim())
        .filter(Boolean)
        .join(' ');
      return customerName;
    });

    const selectJobSalesAgent = createSelector(selectJob, (job) => {
      const salesAgent = job?.users?.find((user) => user.role === JOB_ROLE_MAP.salesAgentRole)?.user;

      if (salesAgent) {
        const name = [salesAgent.givenName, salesAgent.familyName].filter(Boolean).join(' ');
        return {
          ...salesAgent,
          name,
        };
      }

      return undefined;
    });

    const selectJobEstimator = createSelector(selectJob, (job) => {
      const estimator = job?.users?.find((user) => user.role === JOB_ROLE_MAP.estimatorRole)?.user;

      if (estimator) {
        const name = [estimator.givenName, estimator.familyName].filter(Boolean).join(' ');
        return {
          ...estimator,
          name,
        };
      }

      return undefined;
    });

    const selectJobSummaries = createSelector(selectJob, (job) => {
      return {
        crewSummary: parseSummary(job?.crewSummary, 'crew'),
        adminSummary: parseSummary(job?.adminSummary, 'admin'),
        customerSummary: parseSummary(job?.customerSummary, 'customer'),
      };
    });

    const selectJobMetadata = createSelector(selectJob, (job) => {
      return {
        jobOrigin: job?.metadata?.jobOrigin || 'Not set',
        customerType: job?.metadata?.jobType || 'Not set',
      };
    });

    const selectJobSource = createSelector(selectJob, (job) => {
      const sourceField = job?.fields?.find((field) => field.name === 'customer.howDidTheyHearAboutUs');
      const source = sourceField?.values?.[0]?.value;

      return source || 'Not set';
    });

    const selectJobLocations = createSelector(selectJob, (job) => {
      //TODO: Only add required in the template properties rather than spreading entire object
      // TODO: Add Types
      const jobLocations = {
        otherStops: [],
      } as JobLocations;

      if (!job) {
        return jobLocations;
      }

      job?.locations?.forEach((locationObject) => {
        const { locationType, location } = locationObject;
        if (['start', 'end', 'dock'].includes(locationType)) {
          const addressString = formatLocationToDisplay(locationObject.location);
          jobLocations[locationType] = {
            ...location,
            locationType,
            addressString,
          };
        } else {
          jobLocations.otherStops.push({
            ...location,
            locationType,
            addressString: formatLocationToDisplay(locationObject.location),
          } as DetailedLocation);
        }
      });

      const locationFields = {
        startLocation: {
          parkingInformation: '',
        },
        endLocation: {
          parkingInformation: '',
        },
      };

      // Process each field and assign to respective location object
      job?.fields?.forEach((field) => {
        if (field.name.startsWith('startLocation.') || field.name.startsWith('endLocation.')) {
          const { namespace, key } = splitName(field as BaseFieldFragment);
          locationFields[namespace][key] = getFieldValue(field);
        }
      });

      if (jobLocations.start) {
        jobLocations.start.details = getBuildingDetails(locationFields.startLocation);
        jobLocations.start.parkingInformation = locationFields.startLocation?.parkingInformation;
        jobLocations.start.accessInformation = getAccessInformation(locationFields.startLocation);
      }

      if (jobLocations.end) {
        jobLocations.end.details = getBuildingDetails(locationFields.endLocation);
        jobLocations.end.parkingInformation = locationFields.endLocation?.parkingInformation;
        jobLocations.end.accessInformation = getAccessInformation(locationFields.endLocation);
      }

      return jobLocations;
    });

    const selectPotentialEventLocations = createSelector(selectJobLocations, (jobLocations) => {
      if (!jobLocations) return [];

      const { dock, start, end, otherStops = [] } = jobLocations;

      const locations = [
        dock && { ...dock, locationType: 'basestart' },
        start && { ...start },
        end && { ...end },
        ...otherStops,
        dock && { ...dock, locationType: 'baseend' },
      ].filter(Boolean).map(formatPotentialEventLocations);

      return locations;
    });

    const selectJobEvents = createSelector(selectJob, (job) => {
      // Create a shallow copy before sorting to avoid mutating the original array
      const events = (job?.events || []).slice();

      return events
          .sort((a, b) => a.sequentialOrder - b.sequentialOrder)
          .map(event => formatEventDetails(event, job.timezone))
    });

    // const selectEmployeeRoleIds = createSelector(selectRoles, (roles) =>
    //   roles.filter((role) => !role.attributes?.includes(CUSTOMER_ROLE_ATTRIBUTE)).map((role) => role.id),
    // );

    const selectEmployeesWithName = createSelector(selectEmployees, (employees) => {
      return employees.map((employee: User) => ({
        ...employee,
        name: [employee.givenName, employee.familyName].filter(Boolean).join(' '),
      }));
    });

    const selectFilteredTags = createSelector(selectTags, (tags) => {
      return tags.map((tag) => {
        const tagName = [tag.category ? `${tag.category}:` : null, tag.name].filter(Boolean).join(' ');
        return {
          ...tag,
          formattedName: tagName,
          backgroundColor: darkenColor(tag.color, 50), // Darken by 50%

        };
      });
    });

    const selectJobTags = createSelector(selectJob, (job) => {
      return job.tags.map((tag) => {
        const tagName = [tag.category ? `${tag.category}:` : null, tag.name].filter(Boolean).join(' ');
        return {
          ...tag,
          formattedName: tagName,
          backgroundColor: darkenColor(tag.color, 50), // Darken by 50%
        };
      });
    });

    const selectJobTravelDistances = createSelector(selectJob, (job) => {
      return job?.distances || {};
    });

    // const selectCrewRoleIds = createSelector(selectRoles, (roles) =>
    //   roles.filter((role) =>
    //     role.attributes.includes(CREW_LEAD_ROLE) ||
    //     role.attributes.includes(CREW_MEMBER_ROLE)
    //   ).map((role) => role.id)
    // );

    const selectSearchedCrew = createSelector(selectCrew, (crew) => {
      return crew.map((staff: User) => ({
        ...staff,
        name: [staff.givenName, staff.familyName].filter(Boolean).join(' '),
        role: getAttendeeRole(staff),
      }));
    });

    // Select current estimates
    const selectLatestAndOlderEstimates = createSelector(selectDocuments, (documents) => {

      const estimates = documents.filter((doc) => doc.attributes?.includes('Estimate'));

      const [ latestEstimate, ...olderEstimates ] = estimates;

      return {
        latestEstimate,
        olderEstimates,
      };
    });

    const selectLatestEstimate = createSelector(selectLatestAndOlderEstimates, (estimates) => estimates.latestEstimate);

    const selectOlderEstimates = createSelector(selectLatestAndOlderEstimates, (estimates) => estimates.olderEstimates);

    const selectLatestAndOlderInvoices = createSelector(selectInvoices, (invoices) => {

      const [ latestInvoice, ...olderInvoices ] = clone(invoices || []).sort((a, b) => b.createdAt - a.createdAt);

      return {
        latestInvoice,
        olderInvoices,
      };
    });

    const selectLatestInvoice = createSelector(selectLatestAndOlderInvoices, (invoices) => invoices.latestInvoice);

    const selectOlderInvoices = createSelector(selectLatestAndOlderInvoices,(invoices) => invoices.olderInvoices);

    const selectTimezone = createSelector(
      selectJob,
      (job) => {
        return job?.zone?.timezone;
      }
    )

    const selectIsYemboMoveCreated = createSelector(selectJob, (job) => {
      const { yemboMoveCreatorId = '', yemboEmployeeLink = '', yemboCustomerLink = '' } = job?.metadata || {};
      return Boolean(yemboMoveCreatorId || (yemboEmployeeLink && yemboCustomerLink));
    });

    const selectEventToEdit = createSelector(selectJobEvents, selectEventIdToEdit, (events, eventIdToEdit) => {
      return events.find((event) => event.event.id === eventIdToEdit);
    });

    const selectors = {
      ...loadingSelectors,
      ...errorSelectors,
      selectCustomer,
      selectCustomerName,
      selectJobSalesAgent,
      selectJobEstimator,
      selectJobSummaries,
      selectJobMetadata,
      selectJobSource,
      selectJobLocations,
      selectJobEvents,
      selectPotentialEventLocations,
      selectEmployeesWithName,
      selectFilteredTags,
      selectSearchedCrew,
      selectJobTags,
      selectJobTravelDistances,
      isAnyLoading,
      selectLatestEstimate,
      selectOlderEstimates,
      selectLatestInvoice,
      selectOlderInvoices,
      isJobLoading,
      isJobBeingUpdated,
      isEventBeingScheduled,
      selectTimezone,
      selectIsYemboMoveCreated,
      selectEventToEdit
    }

    return selectors;
  },
  reducer: createReducer(
    jobToolInitialState as JobToolState,
    on(JobToolActions.paramsSet, (state, res): JobToolState => {
      let newState: JobToolState = {
        ...state,
        user: res.user,
        jobId: res.jobId,
        tab: res.tab,

        callState: {
          ...jobToolInitialState.callState,
          job: LoadingState.LOADING,
        },
      };

      const job = getJobFromQuickAccess(res.jobId);
      if (job) {
        newState = {
          ...newState,
          job: {
            ...job,
            events: job.events as CalendarEventWithLockedAndInvoicedFlag[],
            distances: job?.distances as DistanceCalculations,
            // comments: [],
          },
          documentsArtifacts: {},
          jobId: job.id,
          zone: job.zone,

          comments: job.comments,
          totalComments: job.totalComments,
          // comments: res.comments,
          // totalComments: res.totalComments,

          callState: {
            ...state.callState,
            job: LoadingState.LOADED,
          },
        }
      }

      return newState;
    }),
    on(JobToolActions.tabChanged, (state, res): JobToolState => {
      return {
        ...state,
        tab: {
          index: res.index,
          name: res.name,
        },
      };
    }),
    on(JobToolActions.jobLoading, (state, res): JobToolState => {
      // reset everything about the job state
      // except for a few values
      return {
        ...jobToolInitialState,
        user: state.user,
        jobId: state.jobId,
        tab: state.tab,

        callState: {
          ...jobToolInitialState.callState,
          job: LoadingState.LOADING,
        },
      };
    }),
    on(JobToolActions.jobLoaded, (state, res): JobToolState => {
      const { job, fields } = res;
      return {
        ...state,

        job: {
          ...job,
          fields,
          events: job.events as CalendarEventWithLockedAndInvoicedFlag[],
          discounts: job?.discounts,
          distances: job.distances as DistanceCalculations,
        },
        documentsArtifacts: {},
        jobId: job.id,
        zone: job.zone,

        comments: res.comments,
        totalComments: res.totalComments,
        documents: res.documents,
        invoices: res.invoices,
        transactions: res.transactions,

        callState: {
          ...state.callState,
          job: LoadingState.LOADED,
        },
      };
    }),
    on(JobToolActions.jobTimezoneChanged, (state, res): JobToolState => {
      return {
        ...state,
        job: {
          ...state?.job,
          timezone: res.jobTimeZone,
        }
      };
    }),
    on(
      JobToolActions.eventListSuccess,
      (state, { event: newEvent }): JobToolState => {
        const job = structuredClone(state.job);


        const newEvents = job.events.map(event =>
          newEvent.id === event.id ? newEvent : event
        );


        return {
          ...state,
          job: {
            ...job,
            events: newEvents as CalendarEventWithLockedAndInvoicedFlag[],
          },
          callState: {
            ...state.callState,
            eventList: LoadingState.LOADED,
          }
        };
      },
    ),
    on(JobToolActions.addLockedAndInvoicedFlagToEvents, (state, res): JobToolState => {
      const { events, charges } = res;
      return {
        ...state,
        job: {
          ...state.job,
          events,
          charges,
        },
      };
    }),
    on(
      JobToolActions.eventListSuccess,
      (state, { event: newEvent }): JobToolState => {
        const job = structuredClone(state.job);


        const newEvents = job.events.map(event =>
          newEvent.id === event.id ? newEvent : event
        );


        return {
          ...state,
          job: {
            ...job,
            events: newEvents as CalendarEventWithLockedAndInvoicedFlag[],
          },
          callState: {
            ...state.callState,
            eventList: LoadingState.LOADED,
          }
        };
      },
    ),
    on(JobToolActions.addComment, (state, { input, temporaryCommentId }): JobToolState => {
      const tempThreadId = `thread:${temporaryCommentId}`;

      const comment: JobToolComment = {
        ...input,
        id: temporaryCommentId,
        createdAt: currentTimeSeconds(),
        replies: [],
        author: state.user,
        thread: {
          id: input.threadId || tempThreadId,
        },
        callState: LoadingState.MUTATING,
      };

      const { comments } = addCommentToComments(state.comments, comment);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.addCommentSuccess, (state, res): JobToolState => {
      const comment: JobToolComment = {
        ...res.comment,
        callState: LoadingState.MUTATED,
      };

      const { comments } = updateCommentInPlace(state.comments, res.commentId, comment, false);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.addCommentError, (state, { commentId, error }): JobToolState => {
      const comment: Partial<JobToolComment> = {
        callState: {
          error,
        },
      };

      // TODO: is the error because we disconnected?
      // Then mark this comment as PENDING
      // downside of this is that we can only add one pending comment
      // at a time... though if you have an array of pending
      // changes perhaps not?
      // console.error(`Add Comment Error`, state);
      const { comments } = updateCommentInPlace(state.comments, commentId, comment, true);

      return {
        ...state,
        comments,
        callState: {
          ...state.callState,
        },
      };
    }),
    on(JobToolActions.updateComment, (state, { input }): JobToolState => {
      const update = {
        ...input.update,
        callState: LoadingState.MUTATING,
      };

      const { comments } = updateCommentInPlace(state.comments, input.ids[0], update, true);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.updateCommentSuccess, (state, res): JobToolState => {
      const comment = {
        ...res.comment,
        callState: LoadingState.MUTATED,
      };

      const { comments } = updateCommentInPlace(state.comments, res.comment.id, comment, false);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.updateCommentError, (state, res): JobToolState => {
      const update = {
        callState: {
          error: res.error,
        },
      } as JobToolComment;

      const { comments } = updateCommentInPlace(state.comments, res.input.ids[0], update, true);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.deleteComment, (state, { comment }): JobToolState => {
      const { comments } = removeCommentFromArray(state.comments, comment.id);

      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.deleteCommentSuccess, (state, { comment }): JobToolState => {
      return {
        ...state,
      };
    }),
    on(JobToolActions.deleteCommentError, (state, res): JobToolState => {
      const update = {
        callState: {
          error: res.error,
        },
      } as JobToolComment;

      const { comments } = updateCommentInPlace(state.comments, res.comment.id, update, true);
      return {
        ...state,
        comments,
      };
    }),
    on(JobToolActions.commentLoaded, (state, res): JobToolState => {
      // we can just update the comment in place
      // because this assumes that it has already been
      // added but maybe missing some information (like author)
      const { comments, comment } = updateCommentInPlace(state.comments, res.comment.id, res.comment, false);

      return {
        ...state,
        comments,
      };
    }),
    // Job Overview Tab Reducers
    on(JobToolActions.calculateDistanceSuccess, (state: JobToolState, {distances}): JobToolState => {
      const job = structuredClone(state.job);

      return {
        ...state,
        job: {
          ...job,
          distances: distances as DistanceCalculations,
        },
        callState: {
          ...state.callState,
          calculateDistance: LoadingState.LOADED,
        },
      };
    }),

    on(JobToolActions.calculateDistanceError, (state: JobToolState, { error }): JobToolState => {
      return {
        ...state,
        callState: {
          ...state.callState,
          calculateDistance: {
            error: error.message,
          },
        },
      };
    }),

    on(
      JobToolActions.employeeSearched,
      (state, { employeeSearch }): JobToolState => ({
        ...state,
        employeeSearch,
      }),
    ),

    on(
      JobToolActions.employeesLoaded,
      (state, { employees }): JobToolState => ({
        ...state,
        employees,
        callState: {
          ...state.callState,
          employees: LoadingState.LOADED,
        },
      }),
    ),

    on(
      JobToolActions.tagSearched,
      (state, { tagSearch }): JobToolState => ({
        ...state,
        tagSearch,
      }),
    ),

    on(
      JobToolActions.tagsLoaded,
      (state, { tags }): JobToolState => ({
        ...state,
        tags,
      }),
    ),

    on(JobToolActions.updateJobStage, (state, { stage }): JobToolState => {
      const job = structuredClone(state.job);
      job.stage = stage;
  
      return {
        ...state,
        job,
        callState: {
          ...state.callState,
          updateJobStage: LoadingState.MUTATING,
        }
      };
    }),

    on(
      JobToolActions.updateJobStageSuccess,
      (state): JobToolState => ({
        ...state,
        callState: {
          ...state.callState,
          updateJobStage: LoadingState.MUTATED,
        },
      }),
    ),

    on(JobToolActions.updateJobStageError, (state, { error, currentStage }): JobToolState => {

      return {
        ...state,
        job: {
          ...state.job,
          stage: currentStage,
        },
        callState: {
          ...state.callState,
          updateJobStage: {
            error
          },
        },
      };
    }),


    on(JobToolActions.jobAgentChanged, (state, { updateJobInput }): JobToolState => {
      const job = structuredClone(state.job);
      const { addUsers, removeUsers } = updateJobInput;

      // Set of user-role pairs to remove
      const removeUserRolePairs = new Set(removeUsers.map((user) => `${user.id}-${user.role}`));

      const newUsers = job.users
      .filter((user) => !removeUserRolePairs.has(`${user.user.id}-${user.role}`))
        .concat(
          addUsers.map((addUser) => ({
            role: addUser.role,
            user: addUser,
          })),
        );

      return {
        ...state,
        job: {
          ...job,
          users: newUsers,
        },
        callState: {
          ...state.callState,
          jobAgentChange: LoadingState.MUTATING,
        },
      };
    }),
    on(
      JobToolActions.jobAgentChangeSuccess,
      (state): JobToolState => ({
        ...state,
        callState: {
          ...state.callState,
          jobAgentChange: LoadingState.MUTATED,
        },
      }),
    ),

    on(JobToolActions.jobAgentChangeError, (state, { updateJobInput, error }): JobToolState => {
      const job = structuredClone(state.job);
      const { addUsers, removeUsers } = updateJobInput;

      // Set of user ids to remove
      const addUserIds = new Set(addUsers.map((user) => user.id));

      const newUsers = job.users
        .filter((user) => !addUserIds.has(user.user.id))
        .concat(
          removeUsers.map((addUser) => ({
            role: addUser.role,
            user: addUser,
          })),
        );

      return {
        ...state,
        job: {
          ...job,
          users: newUsers,
        },
        callState: {
          ...state.callState,
          jobAgentChange: {
            error
          },
        },
      };
    }),

    on(JobToolActions.tagAssignedToJob, (state, { tags }): JobToolState => {
      const job = structuredClone(state.job);

      return {
        ...state,
        job: {
          ...job,
          tags,
        },
      };
    }),

    on(
      JobToolActions.tagAssignmentSuccess,
      (state): JobToolState => ({
        ...state,
      }),
    ),

    on(JobToolActions.tagAssignmentError, (state, { originalTags }): JobToolState => {
      const job = structuredClone(state.job);

      return {
        ...state,
        job: {
          ...job,
          tags: originalTags,
        },
      };
    }),

    on(
      JobToolActions.assetSearched,
      (state, { assetSearch }): JobToolState => ({
        ...state,
        assetSearch,
      }),
    ),

    on(
      JobToolActions.assetsSearchLoaded,
      (state, { assets }): JobToolState => ({
        ...state,
        assets,
      }),
    ),

    on(
      JobToolActions.crewSearched,
      (state, { crewSearch }): JobToolState => ({
        ...state,
        crewSearch,
      }),
    ),

    on(
      JobToolActions.searchedCrewLoaded,
      (state, { crew }): JobToolState => ({
        ...state,
        crew,
      }),
    ),

    on(JobToolActions.newEventButtonClicked, (state, {eventId}): JobToolState => ({
      ...state,
      createEventDialogVisible: true,
      eventIdToEdit: eventId,
    })),
    
    on(JobToolActions.newEventDialogClosed, (state): JobToolState => ({
      ...state,
      createEventDialogVisible: false,
      eventIdToEdit: null,
    })),

    on(JobToolActions.eventCreationRequested, (state): JobToolState => {
      return {
        ...state,
        callState: {
          ...state.callState,
          eventCreation: LoadingState.MUTATING,
        },
      };
    }),

    on(
      JobToolActions.eventCreationSuccess,
      (state, { events }): JobToolState => {
        const job = structuredClone(state.job);
        const newEvents = job?.events ? [...job.events, ...events] : events;

        return {
          ...state,
          job: {
            ...job,
            events: newEvents,
          },
          callState: {
            ...state.callState,
            eventCreation: LoadingState.MUTATED,
          },
        }
      },
    ),

    on(
      JobToolActions.eventCreationError,
      (state, { error }): JobToolState => ({
        ...state,
        callState: {
          ...state.callState,
          eventCreation: {
            error
          },
        },
      }),
    ),

    on(JobToolActions.eventUpdateRequested, (state, res): JobToolState => {
      return {
        ...state,
        callState: {
          ...state.callState,
          eventUpdate: LoadingState.MUTATING,
        },
      };
    }),

    on(JobToolActions.eventUpdateSuccess, (state, { updatedEvents }): JobToolState => {
      const job = structuredClone(state.job);

      const updatedEventsMap = new Map(updatedEvents.map(event => [event.id, event]));

      const newEvents = state.job.events.map((event: CalendarEventWithLockedAndInvoicedFlag) => {
        const updatedEvent = updatedEventsMap.get(event.id);
        return updatedEvent
          ? {...updatedEvent,
              charges: event?.charges,
              locked: event?.locked,
              invoiced: event?.invoiced,
            }
          : event;
      });

      return {
        ...state,
        job: {
          ...job,
          events: newEvents as CalendarEventWithLockedAndInvoicedFlag[],
        },
        callState: {
          ...state.callState,
          eventUpdate: LoadingState.MUTATED,
        },
      };
    }),

    on(
      JobToolActions.eventUpdateError,
      (state, { error }): JobToolState => {
        return {
          ...state,
          callState: {
            ...state.callState,
            eventUpdate: {
              error
            },
          }
        };
      },
    ),
    on(JobToolActions.eventDeletionSuccess, (state, { eventId }): JobToolState => {
      const job = structuredClone(state.job);
      const newEvents = job.events.filter(event => event.id !== eventId);

      return {
        ...state,
        job: {
          ...job,
          events: newEvents as CalendarEventWithLockedAndInvoicedFlag[],
        },
      };
    }),

    on(JobToolActions.summaryInPlaceEditingActivated, (state): JobToolState => {

      // TODO: Optimize - Only load/process details related to customer
      const job = structuredClone(state.job);
      const jobInput = populateJobInputFromResponse(job);

      return {
        ...state,
        jobFormMode: 'edit',
        isSummaryEditMode: true,
        jobInput,
      };
    }),
    on(JobToolActions.summaryInPlaceEditingCancelled, (state): JobToolState => {
      return {
        ...state,
        summaryUpdates: [],
        isSummaryEditMode: false,
      };
    }),
    on(JobToolActions.customerDetailsInPlaceEditingActivated, (state): JobToolState => {

      // TODO: Optimize - Only load/process details related to customer
      const job = structuredClone(state.job);
      const fieldsInput = populateFieldsFromResponse(job?.fields);
      const jobInput = populateJobInputFromResponse(job);
      const customerInput = populateCustomerInfoFromResponse(job);

      return {
        ...state,
        jobFormMode: 'edit',
        fieldsInput: fieldsInput as { [key: string]: string },
        jobInput,
        customerInput,
      };
    }),

    on(JobToolActions.locationDetailsInPlaceEditingActivated, (state): JobToolState => {

      // TODO: Optimize - Only load/process details related to locations
      const job = structuredClone(state.job);
      const fieldsInput = populateFieldsFromResponse(job?.fields);
      const jobInput = populateJobInputFromResponse(job);
      const locationsInputs = populateLocationsInputsFromResponse(job);
      const resolvedServiceArea = populateResolvedServiceAreaFromResponse(job);

      return {
        ...state,
        jobFormMode: 'edit',
        fieldsInput: fieldsInput as { [key: string]: string },
        jobInput,
        locationsInputs,
        resolvedServiceArea
      };
    }),

    // JobsV2 Edit Customer
    on(JobToolActions.updateCustomerSuccess, (state, { customerEditInput }): JobToolState => {
      const job = structuredClone(state.job);

      // Find the customer in the job's users array
      const jobCustomerObject = job.users.find(user => user.role === JOB_ROLE_MAP.customerRole);

      if (jobCustomerObject) {
        // Edit the jobCustomer object directly

        const jobCustomer = jobCustomerObject.user;

        // Loop through the properties of customerEditInput and update if they exist in jobCustomer
        Object.keys(customerEditInput).forEach(key => {
          if (key in jobCustomer) {
            jobCustomer[key] = customerEditInput[key];
          }
        });
      }

      return {
        ...state,
        job,
      };
    }),

    //looks like a lot of logic here was implemented when we dind't return updated job from
    //UpdateJobGQL query. Need to check if we still need it or we can just use job from query result
    on(JobToolActions.jobUpdateSuccess, (state, res): JobToolState => {
      const { changes = [], job: currentJob, callState, locationsInputs = {} } = state;

      if (!changes.length) {
        return {
          ...state,
          callState: {
            ...callState,
            updateJob: LoadingState.MUTATED,
          }
        };
      }

      let updatedJob = structuredClone(currentJob);

      const currentCustomerObject = updatedJob.users.find(user => user.role === JOB_ROLE_MAP.customerRole);

      const jobChanges = generateJobInputFromLatestChanges(changes);
      const jobInputMetaChanges = generateJobInputMetadataFromLatestChanges(changes);
      const locationChanges = generateLocationsInputsFromLatestChanges(changes);
      const { forRemove: locationsToRemove, forAdd: locationsToAdd } = filterLocationsForAddAndRemove(locationChanges as any);
      const fieldChanges = generateFieldsInputFromLatestChanges(changes);
      const { resolvedServiceArea } = getServiceAreaFromChanges(changes);

      // Check if customer related to the job has changed
      const selectedExistingCustomer = changes.find(change => change.fieldName === JOB_FORM_FIELDS.selectedExistingCustomer);

      if (selectedExistingCustomer && currentCustomerObject.user.id !== selectedExistingCustomer?.value?.id) {
        // Update the customer in the job's users array
        currentCustomerObject.user = selectedExistingCustomer.value;
      }

      // Update job
      updatedJob.timeline = jobChanges.timeline || updatedJob.timeline;
      updatedJob.adminSummary = stringifyContents(jobChanges.adminSummary) || updatedJob.adminSummary;
      updatedJob.crewSummary = stringifyContents(jobChanges.crewSummary) || updatedJob.crewSummary;
      updatedJob.customerSummary = stringifyContents(jobChanges.customerSummary) || updatedJob.customerSummary;

      // Update job metadata
      updatedJob.metadata.jobOrigin = jobInputMetaChanges.jobOrigin || updatedJob.metadata.jobOrigin;
      updatedJob.metadata.jobType = jobInputMetaChanges.jobType || updatedJob.metadata.jobType;


      // Update job fields
      Object.entries(fieldChanges).forEach(([fieldName, value]) => {
        const jobField = updatedJob.fields.find(f => f.name === fieldName);
        if (jobField) {
          jobField.values[0].value = value;
        }
      });

      // Helper function to update or add job locations
      const updateJobLocation = (locationType, location, locationDetails) => {
        const jobLocationIndex = updatedJob.locations.findIndex(l => l.locationType === locationType);
        const updatedJobLocation = {
          locationType,
          locationId: location.id,
          location: {
            ...structuredClone(locationDetails),
            id: location.id,
            addressLineOne: locationDetails.addressLineOne,
          }
        };

        if (jobLocationIndex !== -1) {
          updatedJob.locations[jobLocationIndex] = updatedJobLocation;
        } else {
          updatedJob.locations.push(updatedJobLocation);
        }
      };

      // Update job locations
      Object.entries(locationsToAdd).forEach(([locationType, location]) => {
        const locationDetails = locationsInputs[locationType];
        if (!locationDetails) return;
        updateJobLocation(locationType, location, locationDetails);
      });

      // Remove job locations
      Object.entries(locationsToRemove).forEach(([locationType]) => {
        updatedJob.locations = updatedJob.locations.filter(l => l.locationType !== locationType);
      });

      // Update job zone if service area was changed
      if (resolvedServiceArea) {
        // TODO: Find a better way to update the zone.
        // Ensure that resolvedServiceArea is always of type 'area'.
        // Note that with this change, updating the parent zone will become incorrect.
        updatedJob.zone.id = resolvedServiceArea.id;
        updatedJob.zone.name = resolvedServiceArea.name;
        updatedJob.zone.type = ZONE_TYPES.area;
      }

      //as we return updated job in updateJobGQL query we can get zone from there
      if(res.zone) {
        updatedJob = {
          ...updatedJob,
          zone: { ...res.zone },
        } as unknown as JobsV2PageQuery['jobs']['jobs'][number] & FullJobFragmentWithFields | undefined;
      }

      //display updated dock when it's changed after change start location
      const dockFromUpdatedJob = res?.updatedJob?.locations?.find(l => l?.locationType === 'dock');
      if (dockFromUpdatedJob?.locationId) {
        updatedJob.locations = [
          ...updatedJob.locations.filter(location => location.locationType !== 'dock'),
          dockFromUpdatedJob
        ];
      }

      return {
        ...state,
        job: updatedJob,
        isDockEditMode: false,
        changes: [],
        callState: {
          ...callState,
          updateJob: LoadingState.MUTATED,
        }
      };
    }),
    on(JobToolActions.getInvoiceDetailsSuccess, (state, res): JobToolState => {

      const { documentType, artifact } = res;

      return {
        ...state,
        documentsArtifacts: {
          ...state.documentsArtifacts,
          [documentType]: artifact,
        },
        callState: {
          ...state.callState,
          documentArtifact: LoadingState.LOADED,
        },
      };
    }),

    on(
      JobToolActions.getInvoiceDetailsError,
      (state, { error }): JobToolState => {
        return {
          ...state,
          callState: {
            ...state.callState,
            documentArtifact: {
              error
            },
          }
        };
      },
    ),

    on(JobToolActions.deleteDocumentSuccess, (state, res): JobToolState => ({
      ...state,
      documents: state.documents.filter(doc => doc.id !== res.documentId),
      }),
    ),

    on(JobToolActions.closeJob, (state): JobToolState => {
      return {
        ...state,
        callState: {
          ...state.callState,
          closeJob: LoadingState.MUTATING,
        },
      };
    }),

    on(JobToolActions.reopenJob, (state): JobToolState => {
      return {
        ...state,
        callState: {
          ...state.callState,
          jobState: LoadingState.MUTATING,
        },
      };
    }),

    on(JobToolActions.jobStateUpdatedSuccess, (state, res): JobToolState => {

      return {
        ...state,
        job: {
          ...state.job,
          ...res,
        },
        callState: {
          ...state.callState,
          jobState: LoadingState.MUTATED,
        },
      };
    }),

    on(
      JobToolActions.jobStateUpdatedError,
      (state, { error }): JobToolState => {
        return {
          ...state,
          callState: {
            ...state.callState,
            closeJob: {
              error
            },
          }
        };
      },
    ),

    on(
      JobToolActions.sendEstimateSuccess, (state, payload): JobToolState => {

        const currentEstimateIndex = state.documents.findIndex(doc => doc.id === payload.estimate.id);

        const currentEstimate = state.documents[currentEstimateIndex];

        if (currentEstimate.attributes.includes('status::sent')) {
          return state;
        }

        const updatedAttributes = currentEstimate.attributes.filter((attr) => !attr.startsWith('status'));

        updatedAttributes.push('status::sent');

        const updatedEstimate = {
          ... currentEstimate,
          attributes: updatedAttributes,
        };

        const updatedDocuments = [ ...state.documents ];

        updatedDocuments[currentEstimateIndex] = updatedEstimate;

        return {
          ...state,
          documents: updatedDocuments,
        };
      },
    ),

    on(
      JobToolActions.sendInvoiceSuccess, (state, payload): JobToolState => {

        const currentInvoiceIndex = state.invoices.findIndex(doc => doc.id === payload.invoice.id);

        const currentInvoice = state.invoices[currentInvoiceIndex];

        if (currentInvoice.sentAt) {
          return state;
        }

        const updatedInvoice = {
          ... currentInvoice,
          sentAt: currentTimeSeconds(),
        }

        const updatedInvoices = [ ...state.invoices ];

        updatedInvoices[currentInvoiceIndex] = updatedInvoice;

        return {
          ...state,
          invoices: updatedInvoices,
        };
      }
    ),

    on(
      JobToolActions.openInvoiceAsSuccess,
      JobToolActions.finalizeInvoiceSuccess,
      (state, payload): JobToolState => {

        const currentInvoiceIndex = state.invoices.findIndex(doc => doc.id === payload.invoice.id);

        const currentInvoice = state.invoices[currentInvoiceIndex];

        if (currentInvoice.openedAt) {
          return state;
        }

        const updatedInvoice = {
          ... currentInvoice,
          openedAt: currentTimeSeconds(),
        }

        const updatedInvoices = [ ...state.invoices ];

        updatedInvoices[currentInvoiceIndex] = updatedInvoice;

        return {
          ...state,
          invoices: updatedInvoices,
        };
      }
    ),

    on(
      JobToolActions.voidInvoiceSuccess, (state, payload): JobToolState => {

        const currentInvoiceIndex = state.invoices.findIndex(doc => doc.id === payload.invoice.id);

        const currentInvoice = state.invoices[currentInvoiceIndex];

        const updatedInvoice = {
          ... currentInvoice,
          voidedAt: currentTimeSeconds(),
        }

        const updatedInvoices = [ ...state.invoices ];

        updatedInvoices[currentInvoiceIndex] = updatedInvoice;

        return {
          ...state,
          invoices: updatedInvoices,
        };
      },
    ),

    on(
      JobToolActions.deleteInvoiceSuccess, (state, payload): JobToolState => {

        const currentInvoiceIndex = state.invoices.findIndex(doc => doc.id === payload.invoice.id);

        const currentInvoice = state.invoices[currentInvoiceIndex];

        const updatedInvoice = {
          ... currentInvoice,
          deletedAt: currentTimeSeconds(),
        }

        const updatedInvoices = [ ...state.invoices ];

        updatedInvoices[currentInvoiceIndex] = updatedInvoice;

        return {
          ...state,
          invoices: updatedInvoices,
        };
      }
    ),

    on(
      JobToolActions.exportInvoiceToQuickbooksSuccess, (state, payload): JobToolState => {

        const currentInvoiceIndex = state.invoices.findIndex(doc => doc.id === payload.invoice.id);

        const currentInvoice = state.invoices[currentInvoiceIndex];

        const updatedInvoice = {
          ... currentInvoice,
          metadata: {
            ...currentInvoice.metadata,
            quickbooksId: payload.quickbooksId,
          }
        }

        const updatedInvoices = [ ...state.invoices ];

        updatedInvoices[currentInvoiceIndex] = updatedInvoice;

        return {
          ...state,
          invoices: updatedInvoices,
        };
      }
    ),

    on(
      JobToolActions.updateTransactionSuccess, (state, payload): JobToolState => {

        const currentTransactionIndex = state.transactions.findIndex(doc => doc.id === payload.transaction.id);

        const currentTransaction = state.transactions[currentTransactionIndex];

        const updatedTransaction = {
          ... currentTransaction,
          ... payload.transaction,
        }

        const updatedTransactions = [ ...state.transactions ];

        updatedTransactions[currentTransactionIndex] = updatedTransaction;

        return {
          ...state,
          transactions: updatedTransactions,
        };
      },
    ),

    on(
      JobToolActions.createInvoiceSuccess, (state, payload): JobToolState => ({
        ...state,
        invoices: [ ...state.invoices, payload.invoice ],
      }),
    ),

    on(
      InventoryActions.inventoryChanged,
      (state, { action, inventory }): JobToolState => {

        const incomingChange: ChargesUpdate = {
            changeType: 'inventory-updated',
            inventory: inventory,
            action: action,
        }

        const latestChanges = trackUpdatingEventStatus(state?.chargesUpdates, incomingChange);
        return {
            ...state,
            chargesUpdates: latestChanges,
        };
      },
    ),
    on(
      JobToolActions.editDockClicked,
      (state): JobToolState => {
        return {
            ...state,
            isDockEditMode: true,
        };
      },
    ),
    on(
      JobToolActions.discardEditingDockClicked,
      (state): JobToolState => {
        const { dock, ...remainingLocationsInputs } = state?.locationsInputs || {};
        return {
            ...state,
            isDockEditMode: false,
            locationsInputs: remainingLocationsInputs,
            changes: (state.changes || []).filter(change => change.fieldName !== 'dock'),
        };
      },
    ),
    on(
      InventoryActions.createSelfSurveyLinkDialogClosed,
      (state, { createSelfSurvey }): JobToolState => {

        const yemboSelfSurvey = createSelfSurvey ? LoadingState.MUTATING : LoadingState.MUTATED;
        return {
            ...state,
            callState: {
              ...state.callState,
              yemboSelfSurvey,
            }
        };
      },
    ),

    on(
      InventoryActions.selfSurveyLinkCreatedSuccessfully,
      (state, { customerLink, employeeLink }): JobToolState => {

        return {
          ...state,
          job: {
            ...state.job,
            metadata: {
              ...state.job?.metadata,
              yemboCustomerLink: customerLink,
              yemboEmployeeLink: employeeLink,
            },
          },
          callState: {
            ...state.callState,
            yemboSelfSurvey: LoadingState.MUTATED,
          }
        };
      }
    ),
    on(
      InventoryActions.selfSurveyLinkCreationFailed,
      (state, { error }): JobToolState => {

        return {
          ...state,
          callState: {
            ...state.callState,
            yemboSelfSurvey: {
              error,
            },
          }
        };
      }
    ),

    ...jobToolSubscriptionReducers,
    ...jobCreateReducers,
    ...jobCreateLocationsReducers,
    ...jobCreateInventoryReducers,
    ...jobTimelineReducers,
    ...jobSummaryReducers,
    ...jobCreateCustomerReducers,
    ...jobEditReducers,
    ...workOrdersReducers,
    ...scheduleEventsReducers,
  ),
});
