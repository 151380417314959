import { Component, OnDestroy, OnInit } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Store } from "@ngrx/store";
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { tap } from 'rxjs';
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";

import { SubSink } from "subsink";

import { BaseJobFragment } from '../../../../generated/graphql.generated';
import { createConfigSelector } from '../../../state/branding.store';
import { JobToolActions } from "../../job-tool.actions";

export interface CloseJobDialogState {
  job?: BaseJobFragment;
  jobId?: string;
  selectedCloseReason?: string;
  saveFormChanges?: boolean;
}

@Component({
    selector: 'app-close-job-component-v2',
    standalone: true,
    imports: [
      FreyaCommonModule,
    ],
    templateUrl: './close-job-componentV2.component.html',
    styleUrls: ['./close-job-componentV2.component.scss']
  })
  export class CloseJobV2Component  extends ComponentStore<CloseJobDialogState> implements OnInit, OnDestroy {

    constructor(
      private store: Store,
      public ref: DynamicDialogRef,
      private dialogConfig: DynamicDialogConfig,
    ) {
      super({});
    }

    private subs = new SubSink();

    selectedCloseReason$ = this.select((state) => state.selectedCloseReason || state.job?.closedReason || undefined);
  
    closureReasons$ = this.select(
      this.store.select(createConfigSelector('jobs.closedReasons')),
      this.select((state) => state.job),
      (reasons, job) => {
        const stage = job?.stage || 'lead';

        return reasons
          .filter((r) => r.stages?.length === 0 || r.stages.includes(stage))
          .map((r) => ({
            label: r.title,
            title: r.title,
            id: r.id,
          } as MenuItem));
      }
    );

    closeReasonChanged = this.updater((state, selectedCloseReason: string): CloseJobDialogState => {
      return {
        ...state,
        selectedCloseReason,
      }
    });

    ngOnInit(): void {
      this.setState(this.dialogConfig.data || {});
    }

    ngOnDestroy(): void {
      this.subs.unsubscribe();
    }

    readonly closeJob = this.effect<void>((trigger$) =>
      trigger$.pipe(
        tap(() => {
          const state = this.get();
  
          if (!state.selectedCloseReason) {
            console.error('Missing close reason');
            return;
          }

          this.store.dispatch(JobToolActions.closeJob({
            jobId: state.job?.id || state.jobId,
            closedReason: state.selectedCloseReason,
            saveFormChanges: state.saveFormChanges,
          }));

          this.closeDialog(true);
        })
      )
    );

    closeDialog(closed: boolean){
      this.ref.close(closed);
    }
  }
