import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { MenuModule } from 'primeng/menu';
import { TieredMenu, TieredMenuModule } from 'primeng/tieredmenu';
import { withLatestFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { JOB_STAGES } from '../../../global.constants';
import { JobClosureReason } from '../../../jobs/jobs.component';
import { strToTitleCase } from '../../../js';
import { FreyaHelperService } from '../../../services/freya-helper.service';
import { createConfigSelector } from '../../../state/branding.store';
import { selectJobClosedReason } from '../../job-state/job-tool.reducer';
import { JobToolActions } from '../../job-tool.actions';
import { FullJobFragmentWithFields, jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-job-status-dropdown',
  standalone: true,
  imports: [
    MenuModule,
    TieredMenuModule,
    TitleCasePipe,
    AsyncPipe,
    CommonModule,
  ],
  templateUrl: './job-status-dropdown.component.html',
  styleUrl: './job-status-dropdown.component.scss',
})
export class JobStatusDropdownComponent implements OnInit, OnDestroy {

  private wasClickedInside = false;
  @HostListener('click')
  clickInside() {
    this.wasClickedInside = true;
  }

  @HostListener('document:click', ['$event.target'])
  clickedOut(targetElement: HTMLElement) {
    if (this.isJobStatusMenuVisible) {
      // Only run when the menu is visible
      const menuElement = document.querySelector('.p-menu-overlay');

      const isInsideMenu = menuElement?.contains(targetElement);
      if (!this.wasClickedInside && !isInsideMenu) {
        this.isJobStatusMenuVisible = false;
      }
    }
    this.wasClickedInside = false;
  }

  subs = new SubSink();

  public job$ = this.store.select(jobToolFeature.selectJob);

  closedReasons$ = this.store.select(createConfigSelector('jobs.closedReasons'));
  jobClosedReason$ = this.store.select(selectJobClosedReason);

  public isJobStatusMenuVisible = false;
  public jobStatusItems: MenuItem[];

  constructor(
    public store: Store,
    private dialogService: DialogService,
    private freyaHelper: FreyaHelperService,
  ) {}

  ngOnInit(): void {

    this.subs.sink = this.job$
    .pipe(withLatestFrom(this.closedReasons$))
    .subscribe(([ job, closedReasons ]) => {
      this.updateJobStatusItems(job, closedReasons);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  updateJobStatusItems(
    job: FullJobFragmentWithFields,
    closedReasons: JobClosureReason[],
  ) {
    this.jobStatusItems = [
      job.stage === 'lead' && {
        label: 'Review Lead',
        icon: 'pi pi-eye',
        routerLink: ['/jobs', job.id, 'edit'],
      },
      this.createNextStageAction(job),
      this.createOpenAction(job),
      this.createCloseAction(job, closedReasons),
    ].filter(Boolean);
  }

  createNextStageAction(job: FullJobFragmentWithFields) {

    if (job.closedAt) { return; }

    const currentStage = JOB_STAGES[job.stage];

    if (!currentStage) { return; }

    const nextStage = Object.values(JOB_STAGES).find((s) => s.order === currentStage.order + 1);

    if (!nextStage) { return; }

    return {
      label: `Move to ${strToTitleCase(nextStage.name)}`,
      icon: 'pi pi-receipt',
      command: () => this.changeJobStage(nextStage.name),
    };
  }

  createOpenAction(
    job: FullJobFragmentWithFields,
  ) {

    const { showOpen } = this.freyaHelper.getJobCloseActionState(job);

    if (!showOpen) {
      return;
    }

    return {
      label: 'Open',
      icon: 'pi pi-circle',
      command: () => this.store.dispatch(JobToolActions.reopenJob({ jobId: job.id })),
    }
  }

  createCloseAction(
    job: FullJobFragmentWithFields,
    closedReasons: JobClosureReason[],
  ) {

    const { disableClose, reason, showClose } = this.freyaHelper.getJobCloseActionState(job);

    if (!showClose) {
      return;
    }

    return {
      label: 'Close Job',
      icon: 'pi pi-circle-fill',
      disabled: disableClose,
      tooltip: reason,
      items: closedReasons
        .filter((cr) => {
          if (!cr.stages?.length) { return true; }
          return cr.stages.includes(job.stage);
        })
        .map((r) => ({
          label: r.title,
          id: r.id,
          command: () => {
            this.store.dispatch(JobToolActions.closeJob({
              jobId: job.id,
              closedReason: r.id,
            }));
          }
        } as MenuItem)),
        command: () => this.store.dispatch(JobToolActions.closeJobButtonClicked({
          job,
        })),
    };
  }

  public toggleMenu($event: Event, menu: TieredMenu): void {
    this.isJobStatusMenuVisible = !this.isJobStatusMenuVisible;
    menu.toggle($event);
  }

  public changeJobStage(stage: string) {
    this.store.dispatch(JobToolActions.updateJobStage({ stage }));
  }
}
