import { on } from "@ngrx/store";

import { LoadingState } from "src/app/utilities/state.util";

import { environment } from '../../../../environments/environment';
import { BaseAssetFragment } from '../../../../generated/graphql.generated';
import { dayjs } from '../../../core/dayjs/dayjs';
import { ScheduleActions } from '../../../schedules/store/schedule.actions';
import { MS_ONE_SECOND } from '../../../time';
import { JobToolState } from "../../job-tool.reducer";

import { CalendarEventWithLockedAndInvoicedFlag } from "../../jobv2-create/jobv2-interfaces";

import { ScheduleEventsActions } from "./event-schedule.actions";

export const scheduleEventsReducers = [
    on(ScheduleEventsActions.openBookDialogButtonClicked, (state: JobToolState, res): JobToolState => {
        
        let selectedDate = res.event?.start;

        const jobTimeline = state.job.timeline;
        const jobTimelineDate = jobTimeline && dayjs(jobTimeline);
        const timelineDiffDays = jobTimelineDate?.diff(dayjs(), 'day');

        // only use job timelines in the next year
        if (!selectedDate && jobTimelineDate && timelineDiffDays >= 0 && timelineDiffDays < 365) {
            selectedDate = jobTimelineDate
                .set('hour', 8).set('minute', 0).set('second', 0)
                .unix();
        } else if (!selectedDate) {
            selectedDate = dayjs()
                .add(2, 'day')
                .set('hour', 8).set('minute', 0).set('second', 0)
                .unix();
        }

        const selectedAssets = (res.event?.assets || []).map((a) => a.id);

        let scheduleMode: 'view' | 'schedule' = 'view';
        const alreadyScheduled = ![ 'required', 'pending' ].includes(res.event.status);
        if (!alreadyScheduled) {
            scheduleMode = 'schedule';
        }

        const includeDockTravel = localStorage.getItem(environment.lskeys.includeDockTravel) !== 'false';
        
        return {
            ...state,
            bookEventDialogVisible: true,
            eventToBook: res.event,
            includeDockTravel,
            scheduleMode,
            alreadyScheduled,
            selectedDate: dayjs.tz(
                selectedDate * MS_ONE_SECOND,
                state.job.zone.timezone
            ).format('MM/DD/YYYY'),
            selectedStartTime: selectedDate,
            selectedAssets,
            availableAssets: [],
            loadingFindTime: true,
            isBookingRestrictionDisabled: false,
        };
    }),
    on(ScheduleEventsActions.dateSelected, (state: JobToolState, {date}): JobToolState => {
        // TODO: check if time and assets are available for the selected date
        // after it was changed

        const djsSelectedDate = date && dayjs.tz(
            date,
            state.job.zone.timezone
        );

        let selectedDate: string;
        if (date instanceof Date) {
            selectedDate = djsSelectedDate.format('MM/DD/YYYY')
        } else {
            selectedDate = date;
        }

        // convert selectedStartTime to the date of the new date
        let selectedStartTime = state.selectedStartTime;
        if (djsSelectedDate && selectedStartTime) {
            const djsSelectedStartTime = dayjs.tz(
                selectedStartTime * MS_ONE_SECOND,
                state.job.zone.timezone
            )

            selectedStartTime = djsSelectedStartTime.set('year', djsSelectedDate.get('year'))
                .set('month', djsSelectedDate.get('month'))
                .set('date', djsSelectedDate.get('date'))
                .unix();
        }

        
        return {
            ...state,
            selectedDate,
            selectedStartTime,
            loadingFindTime: true,

            // eventBookingData: {
            //     ...state?.eventBookingData,
            //     eventInput: {
            //         ...state.eventBookingData.eventInput,
            //         selectedDate: date,
            //         selectedStartTime: undefined,
            //         selectedAssets: [],
            //     },
            //     availability: {
            //         ...state.eventBookingData.availability,
            //         availableAssets: preselectedAssets?.length
            //             ? preselectedAssets
            //             : [],
            //         possibleWindows: [],
            //         startTimes: [],
            //         hasLockedWindows: false,
            //     },
            // },
            // calendarViewData: calculateDateRange(start, end),
        }
    }),
    on(ScheduleEventsActions.timeSelected, (state: JobToolState, res): JobToolState => {

        const selectedStartTime = res.time;
        const windows = (state.possibleWindows || [])
            .filter((w) => !selectedStartTime|| w.startTimes?.includes(selectedStartTime));

        let selectedAssets: string[] = state.selectedAssets;
        if (!state.isBookingRestrictionDisabled) {
            const availableAssetMap: {
                [ assetId: string ]: BaseAssetFragment;
            } = {};
            
            for (const window of windows) {
                for (const asset of window.assets) {
                    if (!availableAssetMap[asset.id]) {
                        availableAssetMap[asset.id] = asset;
                    }
                }
            }
    
            selectedAssets = (state.selectedAssets || [])
                .filter((asset) => availableAssetMap[asset]);
        }

        return {
            ...state,
            selectedStartTime,
            selectedAssets,
        }
    }),
    on(ScheduleEventsActions.findAvailabilitySuccess, (state: JobToolState, res): JobToolState => {        
        
        // TODO: show a warning from here

        // is our current startTime in the availability window?
        let selectedStartTime = state.selectedStartTime;
        let showTimeNotAvailableWarning = false;
        if (selectedStartTime) {
            const hasStartTime = res.startTimes.find((startTime) => startTime === selectedStartTime);
            if (!hasStartTime) {
                selectedStartTime = undefined;
                showTimeNotAvailableWarning = true;
            }
        }
        
        const windows = res.possibleWindows
            .filter((w) => !selectedStartTime || w.startTimes?.includes(selectedStartTime));

        const availableAssetMap: {
            [ assetId: string ]: BaseAssetFragment;
        } = {};
        
        for (const window of windows) {
            for (const asset of window.assets) {
                if (!availableAssetMap[asset.id]) {
                    availableAssetMap[asset.id] = asset;
                }
            }
        }

        const selectedAssets = (state.selectedAssets || [])
            .filter((asset) => availableAssetMap[asset]);

        const showAssetsNotAvailableWarning = state.selectedAssets?.length && !selectedAssets?.length;

        // TODO: if restrictions disabled then show ALL assets
        // are our selected assets in this window?

        return {
            ...state,
            availableTimes: res.startTimes,
            possibleWindows: res.possibleWindows,
            hasLockedWindows: res.hasLockedWindows,
            selectedStartTime,
            selectedAssets,
            availableAssets: Object.values(availableAssetMap),
            loadingFindTime: false,
            showTimeNotAvailableWarning,
            showAssetsNotAvailableWarning,
        }
    }),
    on(ScheduleEventsActions.includeDockTravelChecked, (state: JobToolState, res): JobToolState => {
        
        localStorage.setItem(environment.lskeys.includeDockTravel, `${ res.includeDockTravel }`);

        return {
            ...state,
            includeDockTravel: res.includeDockTravel,
        };
    }),
    // on(ScheduleEventsActions.eventInfoUpdated, (state: JobToolState, res): JobToolState => {
    //     return {
    //         ...state,
    //         eventBookingData: {
    //             ...state?.eventBookingData,
    //             eventInfo: {
    //                 ...state?.eventBookingData?.eventInfo,
    //                 totalLocationTime: res?.updatedEventInfo?.totalLocationTime,
    //                 totalTime: res?.updatedEventInfo?.totalTime,
    //                 totalTravelTime: res?.updatedEventInfo?.totalTravelTime,
    //             }
    //         }
    //     }
    // }),
    on(ScheduleEventsActions.availableAssetsSet, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            availableAssets: res.availableAssets,
        }
    }),
    on(ScheduleEventsActions.assetsSelected, (state: JobToolState, {
        assets,
    }): JobToolState => {
        return {
            ...state,
            selectedAssets: assets,
        }
    }),
    on(ScheduleEventsActions.bookButtonClicked, (state: JobToolState) => {
        return {
            ...state,
            callState: {
                ...state.callState,
                bookEvent: LoadingState.MUTATING,
            }
        }
    }),
    on(ScheduleEventsActions.bookEventSuccess, (state: JobToolState, { updatedEvent }): JobToolState => {
        const updatedEvents = state.job?.events.map(event =>
          event.id === updatedEvent.id
            ? {
                ...event,
                ...updatedEvent,
                // attendees: updatedEvent?.attendees,
                //TO DO - uncomment it when we return assets ans locations from LockWindow mutation
                //locations: updatedEvent?.locations,
                //assets: updatedEvent?.assets,
                // start: updatedEvent?.start,
                // end: updatedEvent?.start,
                // status: updatedEvent?.status,
            }
            : event
        );

        return {
          ...state,
          job: {
            ...state.job,
            events: updatedEvents as CalendarEventWithLockedAndInvoicedFlag[],
          },
          scheduleMode: 'view',
          alreadyScheduled: true,
          eventToBook: updatedEvent,
          callState: {
            ...state.callState,
            bookEvent: LoadingState.MUTATED,
        }
        };
      }),
    on(ScheduleEventsActions.bookEventError, (state: JobToolState, res) => {
        return {
            ...state,
            eventBookingData: {
                eventInput: {
                    minWindowLength: undefined,
                    selectedDate: undefined,
                    selectedStartTime: undefined,
                    selectedAssets: [],
                    includeStartDock: true,
                    includeEndDock: true,
                },
                eventInfo: undefined,
                availability: {
                    startTimes: [],
                    possibleWindows: [],
                    availableAssets: [],
                    hasLockedWindows: false,
                },
            },
            callState: {
                ...state.callState,
                bookEvent: {
                    error: res.error,
                }
            }
        };
    }),
    on(ScheduleActions.eventsLoaded, (state: JobToolState, res) => {
        if (!state.eventToBook) { return state; }

        // update eventToBook from new event
        const eventToBook = res.events?.find((e) => e.id === state.eventToBook?.id);
        if (!eventToBook) { return state; }

        return {
            ...state,
            eventToBook,
        }
    }),
    on(ScheduleEventsActions.rescheduleButtonClicked, (state: JobToolState): JobToolState => {
        return {
            ...state,
            scheduleMode: 'schedule',
        };
    }),
    on(ScheduleEventsActions.bookDialogClosed, (state: JobToolState): JobToolState => {
        return {
            ...state,
            eventToBook: undefined,
            bookEventDialogVisible: false,
            // eventBookingData: {
            //     eventInput: {
            //         minWindowLength: undefined,
            //         selectedDate: undefined,
            //         selectedStartTime: undefined,
            //         selectedAssets: [],
            //         includeStartDock: true,
            //         includeEndDock: true,
            //     },
            //     eventInfo: undefined,
            //     availability: {
            //         startTimes: [],
            //         possibleWindows: [],
            //         availableAssets: [],
            //         hasLockedWindows: false,
            //     },
            // }
        };
    }),
    on(ScheduleEventsActions.bookingRestrictionsChanged, (state: JobToolState, {isBookingRestrictionDisabled}): JobToolState => {
        
        if (isBookingRestrictionDisabled === null) {
            isBookingRestrictionDisabled = !state.isBookingRestrictionDisabled;
        }
        
        return {
            ...state,
            isBookingRestrictionDisabled: isBookingRestrictionDisabled,
        };
    }),
]