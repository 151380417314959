import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import {dayjs} from '@karve.it/core';
import { CalendarEvent } from '@karve.it/interfaces/calendarEvents';
import { ListGetConfigValuesOutput } from '@karve.it/interfaces/config';
import { Store } from '@ngrx/store';
import {QueryRef} from 'apollo-angular';

import { cloneDeep, partition } from 'lodash';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { skip, take, map, filter, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import {
  BaseCalendarEventFragment,
  CreateDocumentGQL,
  CreateDocumentMutationVariables,
  EditCalendarEventGQL,
  FullTransactionFragment,
  GetConfigValuesGQL,
  GetConfigValuesQuery,
  GetConfigValuesQueryVariables,
  GetJobDocumentsGQL,
  GetJobDocumentsQuery,
  GetJobDocumentsQueryVariables,
  JobPromotedGQL,
  JobPromotedOutput,
  ListCalendarEventsGQL,
  ListCalendarEventsQuery,
  ListCalendarEventsQueryVariables,
  PandadocArtifactFragment,
} from '../../../generated/graphql.generated';
import { JobToolActions } from '../../jobsv2/job-tool.actions';
import { strToTitleCase } from '../../js';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { EventHelperService } from '../../services/event-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';

export type DocumentType = 'invoice' | 'estimate' | 'receipt' | 'contract';
export type DocumentStatus = 'none' | 'draft' | 'pending' | 'sent' | 'failed';

export interface SendDocumentComponentInput {
  jobId: string;
  jobCode: string;
  userId?: string;
  preselectTemplateKey?: string;
  preselectEventType?: string;
  // Set these variables
  transactions?: FullTransactionFragment[];
  events?: CalendarEvent[];
  autogenerate?: boolean;
}

export interface CreatableDocument {
  key: string;
  type: DocumentType;
  name: string;
  templateId: string;
  validationSchema: any;
  // Matches validation schema but stores values
  variables: any;
  status: DocumentStatus;
  valid: boolean;
  // Link to view on pandadoc
  linkToView?: string;
  artifactId?: string;
}

export interface SendableDocument {
  artifact: PandadocArtifactFragment;
  status: DocumentStatus;
  message?: string;
}

export interface Step {
  name: string;
  content: TemplateRef<any>;
  contentMinimized: TemplateRef<any>;
  footer: TemplateRef<any>;
}

@Component({
  selector: 'app-send-documents',
  templateUrl: './send-documents.component.html',
  styleUrls: ['./send-documents.component.scss'],
})
export class SendDocumentsComponent implements AfterViewInit, OnDestroy {

  @ViewChild('createContent') createDocumentsContentRef: TemplateRef<any>;
  @ViewChild('createContentMinimized') createDocumentsContentMinimizedRef: TemplateRef<any>;
  @ViewChild('createFooter') createDocumentsFooterRef: TemplateRef<any>;
  @ViewChild('reviewAndSendContent') reviewAndSendContentRef: TemplateRef<any>;
  @ViewChild('reviewAndSendContentMinimized') reviewAndSendContentMinimizedRef: TemplateRef<any>;
  @ViewChild('reviewAndSendFooter') reviewAndSendFooterRef: TemplateRef<any>;

  subs = new SubSink();

  input: SendDocumentComponentInput;

  // All the documents that we have the ability to create
  documentTemplates: CreatableDocument[] = [];
  // The documents we have selected to create
  documentsToCreate: CreatableDocument[] = [];
  // All existing documents, whether they have been sent or not
  existingDocuments: SendableDocument[];
  // The documents we have selected to send
  documentsToSend: SendableDocument[];
  // The documents that have already been sent
  sentDocuments: SendableDocument[];

  // Stores the validity status of the selected documents, true if all are valid
  selectedDocumentsValid = false;
  // Stores whether or not we have active send, create, or Pandadoc queries
  selectedDocumentsPending = false;
  // Stores whether there are any documents that can be created, true if any of them can
  selectedDocumentsCreatable = false;
  // Stores whether any document artifacts are loading
  documentsLoading = false;
  preSelectEvent: string;

  // The Items for the overlay menu
  documentItems = [];

  // ADDITIONAL INFO
  events: BaseCalendarEventFragment[];
  // selectedEvents: CalendarEvent[] = [];
  // selectedTransaction: Transaction;

  documentQueryRef: QueryRef<GetConfigValuesQuery, GetConfigValuesQueryVariables>;
  artifactQueryRef: QueryRef<GetJobDocumentsQuery>;
  eventsQueryRef: QueryRef<ListCalendarEventsQuery, ListCalendarEventsQueryVariables>;

  steps: Step[];
  activeStep: Step;

  activeContent: TemplateRef<any>;
  activeFooter: TemplateRef<any>;

  activeContentMinimized: TemplateRef<any>;

  minimized$ = new BehaviorSubject<boolean>(false);

  documentCount = {
    valid: 0,
    invalid: 0,
    created: 0,
  };

  documentsPluralMapping = {
    '=0': 'No documents',
      '=1': '1 document',
      other: '# documents',
  };

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private localNotify: FreyaNotificationsService,
    private detailsHelper: DetailsHelperService,
    private getJobDocumentsGQL: GetJobDocumentsGQL,
    private cd: ChangeDetectorRef,
    private responsiveHelper: ResponsiveHelperService,
    private brandingService: BrandingService,
    public documentHelper: DocumentHelperService,
    private listCalendarEventsGQL: ListCalendarEventsGQL,
    private eventHelper: EventHelperService,
    private jobPromotedGQL: JobPromotedGQL,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private freyaMutate: FreyaMutateService,
    private createDocumentGQL: CreateDocumentGQL,
    private store: Store,
  ) { }

  ngAfterViewInit(): void {
    this.input = this.config.data?.input;
    this.setSteps();
    this.goToStep(this.config.data?.openToStep || 0);
    this.fetchExistingDocuments();
    this.getDocumentTemplates();
    this.cd.detectChanges();
    this.setPreselectEventType();
    this.handleMinimize();
    this.closeOnZoneChanges();
    this.fetchEvents();

    this.subs.sink = this.documentHelper.processingDocuments
      .subscribe((processing) => this.selectedDocumentsPending = processing);
  }

  closeOnZoneChanges() {
    this.subs.sink = this.brandingService.currentZone()
    .pipe(skip(1))
    .subscribe(() => {
      this.closeDialog();
    });
  }

  handleMinimize() {
    this.subs.sink = this.minimized$
    .pipe(skip(1))
    .subscribe((minimized) => {

      this.config.style = {
        position: minimized ? 'absolute' : 'relative',
        top: minimized ? '72px' : 'unset',
        right: minimized ? '20px' : 'unset',
        'max-width': minimized && !this.responsiveHelper.isSmallScreen ? '30rem' : 'unset',
      };

      this.config.contentStyle = {
        'overflow-y': minimized ? 'unset' : 'auto',
      };

      this.config.modal = !minimized;

    });

    this.subs.sink = this.documentHelper.sendDocumentsdialogMaximized
    .subscribe(() => this.minimized$.next(false));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setSteps() {
    this.steps = [
      {
        name: `Create Documents for ${this.input.jobCode}`,
        content: this.createDocumentsContentRef,
        contentMinimized: this.createDocumentsContentMinimizedRef,
        footer: this.createDocumentsFooterRef
      },
      {
        name: `Review and Send Documents for ${this.input.jobCode}`,
        content: this.reviewAndSendContentRef,
        contentMinimized: this.reviewAndSendContentMinimizedRef,
        footer: this.reviewAndSendFooterRef
      }
    ];
  }

  goToStep(stepIndex: number) {
    if (!this.steps.length || stepIndex >= this.steps.length) { return; }

    this.activeStep = this.steps[stepIndex];
    this.activeContent = this.activeStep.content;
    this.activeFooter = this.activeStep.footer;
    this.activeContentMinimized = this.activeStep.contentMinimized;
  }

  closeDialog() {
    this.ref.close();
  }

  // CREATE DOCUMENT FUNCTIONALITY

  /**
   * Retrieves a list of all the document templates that are configured to be sent in the system, and parses them out
   */
  getDocumentTemplates() {
    this.documentQueryRef = this.getConfigValuesGQL.watch({ keys: ['standard-documents.*'] }, { fetchPolicy: 'cache-and-network' });

    this.subs.sink = this.documentQueryRef.valueChanges.subscribe((res) => {

      if (res.loading) { return; }

      for (const document of res.data.getConfigValues) {

        const [ _namespace, docType ] = document.key.split('.');

        // Filter out invoices, these should be created through their own dialog
        if (docType === 'invoice') { continue; }

        // Parse the template values out of the configs
        let documentValue: Omit<CreatableDocument, 'key'>;
        try {
          documentValue = JSON.parse(document.value);
        } catch {
          console.warn(`${document.key} could not be parsed`);
          continue;
        }

        // Add new document template to the list
        const newDocument: CreatableDocument = {
          ...documentValue,
          key: document.key,
        };

        this.documentTemplates.push(newDocument);

        // Add a new menu item for the document template
        const newMenutem = {
          label: newDocument.name,
          id: newDocument.key,
          command: (values) => {
            this.selectDocument(values.item.id);
          }
        } as MenuItem;

        this.documentItems.push(newMenutem);
      }

      this.preselectDocuments();
    });
  }

  /**
   * If we are given preselected documents check if they exist then add them
   */
  preselectDocuments() {
    if (!this.input.preselectTemplateKey) { return; }

    // Check if we have a template matching that key
    const preselectedDocument = this.documentTemplates.find((dt) => dt.key === this.input.preselectTemplateKey);

    if (!preselectedDocument) {
      console.warn(`No document found with key ${this.input.preselectTemplateKey}`);
      return;
    }

    this.selectDocument(preselectedDocument.key);

    // Assign Values for preselected document if applicable
    const selectedDocument = this.documentsToCreate.find((doc) => doc.key === preselectedDocument.key);

    if (this.input.transactions) {
      this.setTransactionValues(selectedDocument, this.input.transactions);
    }

    if (this.input.events) {
      this.setEventValues(selectedDocument, this.input.events);
    }

    if (this.input.autogenerate) {
      this.createDraft(selectedDocument);
    }
  }

  setPreselectEventType() {
    if(!this.input.preselectEventType) {return ;}
    this.preSelectEvent = this.input.preselectEventType;
  }

  /**
   * Creates drafts for all selected documents.
   */
  async createDrafts() {
    const exclude = [ 'draft', 'sent', 'pending'];

    this.documentsToCreate.forEach((doc) => {
      if (!doc.valid || exclude.includes(doc.status)) { return; }
      this.runPreCreateChecks(doc);
    });
  }

  /**
   * Creates a draft for review and signing without sending it to the customer
   * based on a single (unsaved) document.
   *
   * @param document The (unsaved) document we want to use to create a draft.
   */
  async createDraft(document: CreatableDocument, successCb?: () => void) {

    // Set document status to creating
    document.status = 'pending';
    this.checkSelectedDocumentsStatus();

    // Try to create draft
    const createDocumentInput = this.generateCreateDocumentInput(document, false);

    this.createDocumentGQL.mutate(createDocumentInput)
      .subscribe((res) => {
        const { data: { createDocument: [ draft ] } } = res;

        document.status = 'draft';
        document.artifactId = draft.artifact.id;

        this.store.dispatch(JobToolActions.generateJobDocumentSuccess({ document: draft.artifact, jobId: this.input.jobId }));

        this.detailsHelper.pushUpdate({
          id: draft.artifact.id,
          type: 'Artifacts',
          action: 'create'
        });

        this.localNotify.success(`${strToTitleCase(document.type)} draft created and ready for review`);

        this.checkSelectedDocumentsStatus();

        this.fetchExistingDocuments();

        this.countDocumentsToCreate();

        if (successCb) {
          successCb();
        }

      }, (err) => {

        document.status = 'failed';

        console.error(err);
        this.localNotify.apolloError(`Failed to create ${document.type} draft.`, err);

        this.checkSelectedDocumentsStatus();
      });

  }

  /**
   * Generates the input required to send a document or create a draft.
   *
   * @param document The data for the document we want to create or send.
   * @param send Whether to send the document or just create a draft.
   */
  generateCreateDocumentInput(document: CreatableDocument, send: boolean): CreateDocumentMutationVariables {
    return {
      serviceToUse: 'pandadoc',
      variables: document.variables,
      templateIds: [document.templateId],
      saveArtifact: true,
      send
    };
  }

  /**
   * Add document to the list of selected documents, initialize it's values
   *
   * @param documentKey The config key for the document type
   */
  selectDocument(documentKey: string) {
    // Create a copy of the document and add it to the array
    const doc: CreatableDocument = {
      ...cloneDeep(this.documentTemplates.find((t) => t.key === documentKey)),
      status: 'none',
      variables: {
        jobId: this.input.jobId,
      }
    };

    this.documentsToCreate.push(doc);

    this.validateDocument(doc);
    this.checkSelectedDocumentsStatus();
    this.countDocumentsToCreate();
  }

  countDocumentsToCreate() {
    const newCount = {
      valid: 0,
      invalid: 0,
      created: 0,
    };

    for (const document of this.documentsToCreate) {
      if (document.status === 'draft') {
        newCount.created++;
      } else if (document.valid) {
        newCount.valid++;
      } else {
        newCount.invalid++;
      }
    }

    this.documentCount = newCount;
  }

  /**
   * Remove a document from the list of selected documents
   *
   * @param document The document to be removed
   */
  removeDocument(document: CreatableDocument) {
    const index = this.documentsToCreate.indexOf(document);

    this.documentsToCreate.splice(index, 1);

    this.checkSelectedDocumentsStatus();
    this.countDocumentsToCreate();
  }

  // VALIDATE DOCUMENTS

  /**
   * Checks if the docs have a variable matching the required property,
   * DOES NOT validate the data just checks it exists
   *
   * @param document Document to validate
   */
  validateDocument(document: CreatableDocument) {
    for (const property of document.validationSchema.required) {
      if (!document.variables[property]) {
        document.valid = false;
        return;
      }
    }

    document.valid = true;
  }

  /**
   * Checks the status of each selected document and updates relavant value holders accordingly:
   *
   * Sets selectedDocumentsValid true if all docs are valid.
   *
   * Sets selectedDocumentsPending true if some docs are still being sent or created.
   *
   * Sets selectedDocumentsCreatable true if some documents can be created.
   */
  checkSelectedDocumentsStatus() {

    const allDocuments = [...this.documentsToCreate, ...(this.existingDocuments || [])];

    if (!allDocuments.length) {
      this.selectedDocumentsValid = false;
      this.documentHelper.processingDocuments.next(false);
      this.selectedDocumentsCreatable = false;
      return;
    }

    let allValid = true;
    let somePending = false;
    let someCreatable = false;

    for (const doc of allDocuments) {

      const isCreatable = 'key' in doc;

      if (isCreatable && !doc.valid) {
        allValid = false;
      }

      switch (doc.status) {
        case 'pending':
          somePending = true;
          break;
        case 'none':
        case 'failed':
          someCreatable = true;
          break;
      }

    }

    this.selectedDocumentsValid = allValid;
    this.documentHelper.processingDocuments.next(somePending);
    this.selectedDocumentsCreatable = someCreatable;
    this.cd.detectChanges();
  }

  // SET PROPERTY VALUES

  /**
   * Sets the transaction property on the document variables
   *
   * @param document The document to set the transaction on
   * @param transactions The selected transactions
   */
  setTransactionValues(document: CreatableDocument, transactions: FullTransactionFragment[]) {
    if (!transactions?.length) { return; }

    document.variables.customerUserId = transactions[0].customer.id;
    document.variables.transactionIds = transactions.map((t) => t.id);

    this.validateDocument(document);
    this.checkSelectedDocumentsStatus();
  }

  /**
   * Sets the events property on the document variables based on the input
   *
   * @param document The document to set the events on
   * @param events The events to add to the variables
   */
  setEventValues(document: CreatableDocument, events: CalendarEvent[]) {
    if (!events?.length) { return; }

    document.variables.eventIds = events.map((event) => event.id);
  }

  setPropertyValue(document: CreatableDocument, key: string, value: any, type = 'string'){
    if (type === 'date'){
      value = dayjs(value).format('YYYY-MM-DD');
    }

    document.variables[key] = value;
  }

  // SEND DOCUMENTS FUNCTIONALITY

  fetchExistingDocuments() {
    /**
     * In theory we should not need to explicitly set loading to true
     * Apollo should be handling loading state, but for some reason,
     * `res.loading` is always coming back false
     */
    this.existingDocuments = [];
    this.documentsLoading = true;

    if (this.artifactQueryRef) {
      this.artifactQueryRef.refetch();
      return;
    }

    const getJobDocumentsVariables: GetJobDocumentsQueryVariables = {
      jobId: this.input.jobId,
      getPrivate: true,
      sortBy: 'createdAt:desc'
    };

    this.artifactQueryRef = this.getJobDocumentsGQL.watch(getJobDocumentsVariables);

    this.subs.sink = this.artifactQueryRef.valueChanges.subscribe((res) => {
      this.documentsLoading = res.loading;

      if (res.loading) { return; }

      this.existingDocuments = res.data.artifacts.artifacts
        // Filter out invoices, these should be sent through a different component
        .filter((a) => !a?.metadata?.invoiceId)
        .map((a) => ({ artifact: a, status: this.getDocumentStatus(a)}));

      this.sortExistingDocuments();
    });

  }

  getDocumentStatus(artifact: PandadocArtifactFragment): DocumentStatus {
    return artifact?.attributes?.includes('status::sent') ? 'sent' : 'draft';
  }

  /**
   * Sorts existing documents by status.
   */
  sortExistingDocuments() {
    const [ sent, draft ] = partition(this.existingDocuments, (ed) => ed.status === 'sent');

    this.sentDocuments = sent;
    this.documentsToSend = draft;
  }

  /**
   * Checks the status of all document to update the UI
   * (e.g. whether dialog navigation buttons are disabled),
   * and resorts documents by status.
   */
  handleStatusChange(status: DocumentStatus) {
    this.checkSelectedDocumentsStatus();

    // Do not resort if a document changes to pending
    if (status === 'pending') { return; }
    this.sortExistingDocuments();
  }

  fetchEvents() {
    if (!this.input.jobId) { return; }

    if (this.eventsQueryRef) {
      this.eventsQueryRef.refetch();
      return;
    }

    this.eventsQueryRef = this.listCalendarEventsGQL.watch({ filter: {
      jobId: this.input.jobId,
    }});

    this.subs.sink = this.eventsQueryRef.valueChanges.subscribe((res) => {
      if (res.loading) { return; }
      this.events = res.data.calendarEvents.events;
    });
  }

  runPreCreateChecks(document: CreatableDocument) {
    if (this.isInvoiceForJob(document)) {
      this.handleInvoice(document);
      return;
    }

    this.createDraft(document);
  }

  isInvoiceForJob(document: CreatableDocument) {

    const hasSelectedEvents = document.variables?.eventIds?.length > 0;

    return document.type === 'invoice' && !hasSelectedEvents;
  }

  async handleInvoice(document: CreatableDocument) {

    const prompt = 'You are trying to generate an invoice, but the following events have not been marked as complete:';

    const confirmationOutput = await this.eventHelper.confirmCompleteEvents(this.events, prompt);

    this.createDraft(document);

    if (confirmationOutput.confirmed) {
      // Wait for websocket event letting us know the job has been promoted
      this.jobPromotedGQL.subscribe().pipe(
        // We only care about the first event
        take(1),
        // Pluck data
        map((res) => res.data.jobPromoted),
        // Make sure output is not empty and cast to appropriate type
        filter((output): output is JobPromotedOutput => Boolean(output)),
        // Make sure output is for the correct job
        filter((output) => output.jobId === this.input.jobId),
        // On emit, complete events
      ).subscribe(() => this.eventHelper.markComplete(confirmationOutput.incompleteEvents));
    }
  }

  openCreateInvoiceDialog() {
    this.closeDialog();

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'invoice',
      additionalValues: [
        {
        property: 'jobId',
        value: this.input.jobId,
      }
      ],
    });
  }

}
