/* eslint-disable max-len */
import { gql } from 'graphql-tag';

import { ZoneDirection } from './common.gql';
import { Metadata } from './metadata';

//#region "Basic Types"
export interface Artifact {
    id: string;
    name: string;
    namespace: string;
    fileSize: number;
    contentType: string;
    public: boolean;
    author: string;
    gcsFileName: string;
    attributes: string[];
    signedPolicy: string;
    signedUrl: string;
    url: string;
    metadata: Metadata;
    createdAt: number;
    updatedOn: number;
    deletedAt: number;
}

export interface FileRestrictions {
  // Both in pixels
  minimumHeight?: number;
  minimumWidth?: number;
  // array of file types ex. ['image/png', 'image/jpg']
  allowedFileTypes: string[];
}
//#endregion "Basic Types"

//#region "IO Types"
export interface ArtifactInput {
    name: string;
    namespace: string;
    fileSize: number;
    contentType: string;
    public?: boolean;
    author?: string;
    attributes?: string[];
    relatedObjectIds?: string[];
}

export interface ListArtifactInput {
  filter?: ArtifactFilter;
  getPrivate?: boolean;
  sortBy?: string;
  skip?: number;
  limit?: number;
}

export interface ListArtifactOutput {
  artifacts: {
    artifacts: Artifact[];
    total: number;
    skipped: number;
    limited: number;
  };
}

export interface CreateArtifactOutput {
  createArtifacts: {
    total: number;
    artifacts: Artifact[];
  };
}

export interface ArtifactFilter extends ZoneDirection{
    ids?: string[];
    names?: string[];
    namespace?: string;
    contentType?: string;
    authors?: string[];
    tags?: string[];
    attributes?: string[];
    viewPrivateForUsers?: string[];
    relatedObjectIds?: string[];
    relatedObjectLabels?: string[];

}

export interface DeleteArtifactOutput {
  deleteArtifacts: boolean;
}
//#endregion "IO Types"

export const createArtifacts = gql`
mutation createArtifacts($artifacts: [ArtifactInput!]!) {
  createArtifacts(artifacts: $artifacts){
    total
    artifacts{
        id
        name
        namespace
        fileSize
        contentType
        public
        author
        gcsFileName
        attributes
        signedPolicy
        signedUrl
        url
        createdAt
        updatedAt
        deletedAt
    }
  }
}
`;

export const viewArtifacts = gql`
query artifacts($filter: ArtifactFilter, $getPrivate: Boolean, $sortBy: String, $skip: Int, $limit: Int) {
  artifacts(filter: $filter, getPrivate: $getPrivate, sortBy: $sortBy, skip: $skip, limit: $limit){
    artifacts{
        id
        name
        namespace
        fileSize
        contentType
        public
        author
        gcsFileName
        attributes
        signedPolicy
        signedUrl
        url
        createdAt
        updatedAt
        deletedAt
        metadata
    }
    total
    skipped
    limited
  }
}
`;

export interface ArtifactUsageInput{
  // ids of the authors
  authors?: string[];
  namespace?: string;
  // Leave blank to show both
  privacyType?: 'public' | 'private';
  createdBefore?: number;
  createdAfter?: number;
  contentType?: string;
  // Default false
  getSystemUsageBytes: boolean;
}

export interface ArtifactsUsageOutput {
  artifactsUsage: {
    systemUsageBytes: number;
    userUsageBytes: UserArtifactUsage[];
  };
}

export interface UserArtifactUsage {
  userId: string;
  usageBytes: number;
}

export const artifactUsageQuery = gql`
query artifactsUsage($authors: [String], $namespace: String, $privacyType: String, $createdBefore: Int, $createdAfter:Int, $contentType: String, $getSystemUsageBytes: Boolean){
  artifactsUsage(authors: $authors, namespace: $namespace, privacyType: $privacyType, createdBefore: $createdBefore, createdAfter: $createdAfter, contentType: $contentType, getSystemUsageBytes: $getSystemUsageBytes){
    systemUsageBytes
    userUsageBytes{
      userId
      usageBytes
    }
  }
}
`;

export const deleteArtifacts = gql`
mutation deleteArtifacts($artifactIds: [String!]!, $deleteResource: Boolean){
  deleteArtifacts(artifactIds: $artifactIds, deleteResource: $deleteResource)
}
`;
