import { uniqBy } from 'lodash';

import { environment } from '../../../../environments/environment';
import { BaseFieldFragment, BaseUserFragment, BaseZoneFragment, CommentWithRepliesFragment, FullJobFragment, RouteDistances } from '../../../../generated/graphql.generated';
import { JOB_ROLE_MAP } from '../../../global.constants';
import { safeParseJSON } from '../../../js';

import { QuickAccessState } from './quick-access-reducers';

export function getJobFromQuickAccess(
	jobId: string,
) {


	const quickAccessState: QuickAccessState = safeParseJSON(localStorage.getItem(environment.lskeys.quickAccessState), undefined);
	if (!quickAccessState) {
		return;
	}

	return [
		...quickAccessState.recentJobs,
		...quickAccessState.pinnedJobs,
		...quickAccessState.todaysJobs,
	].find((j) => j.id === jobId);
}


export type QuickAccessJob = FullJobFragment & {
	customer: BaseUserFragment;
	contextZone: BaseZoneFragment;

	fields: Partial<BaseFieldFragment>[],
	distances: RouteDistances;
	comments: CommentWithRepliesFragment[];
	totalComments: number;
};

export function parseQuickAccessJob(
	job: FullJobFragment,
	fields: Partial<BaseFieldFragment>[],
	comments: CommentWithRepliesFragment[],
	totalComments: number,
) {

	return {
		...job,
		customer: job.users.find((u) => u.role === JOB_ROLE_MAP.customerRole)?.user,
		contextZone: job.zone?.type === 'area' ? job.zone.parent : job.zone,
		fields,
		comments,
		totalComments,
	} as QuickAccessJob;

}

export function storeQuickAccessState(state: QuickAccessState) {
	localStorage.setItem(environment.lskeys.quickAccessState, JSON.stringify(state));

	return state;
}

export function updateRecentJobs<T extends {id: string}>(recentJobs: T[], job: T): T[] {
	const recentJobExistsIndex = recentJobs.findIndex((j) => j.id === job.id);
  
	if (recentJobExistsIndex < 0) {
	  // Only add the recent job if it doesn't already exist in recent jobs
	  recentJobs = uniqBy([job, ...recentJobs], (j) => j.id).slice(0, 3);
	} else {
	  // Update the job in place
	  recentJobs[recentJobExistsIndex] = job;
	}
  
	return recentJobs;
  }