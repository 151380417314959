import { cloneDeep } from 'lodash';

import { generateUUID } from '../../utilities/state.util';

import { InventoryItemDefinition, InventoryRoom, JobInventory } from './inventory.feature';

export function addItemDefinitionToRoom(stateRooms: InventoryRoom[], item: InventoryItemDefinition, roomId: string) {

	const rooms = cloneDeep(stateRooms);
	const room = rooms.find((r) => r.id === roomId);
	if (!room || !item) {
		return rooms;
	}

	room.inventory.push({
		...item,
		id: generateUUID(),
		name: item.reviewerName || item.label,
		generated: true,
		quantity: 1,
		volume: parseFloat(item.volume as any),
		weight: parseFloat(item.weight as any),
	});

	return rooms;
}


export function addCustomItemToRoom(stateRooms: InventoryRoom[], search: string, roomId: string) {

	const rooms = cloneDeep(stateRooms);
	const room = rooms.find((r) => r.id === roomId);
	if (!room || !search) {
		return rooms;
	}

	room.inventory.push({
		id: generateUUID(),
		name: search,
		quantity: 1,
		reviewerName: search,
		label: search,
		volume: 0,
		weight: 0,
		// isoCode1: "000",
		// isoCode2: "000",
		// isoCode3: "000",
		// isBulky: false,
		// isValuable: false,
		// isSpecialEquipment: false,
		// is3rdPartyServicing: false,
		// isProGear: false,
		// isAssembleDisassemble: false,
		generated: false,
	});

	return rooms;
}

export function recalculateInventory(
	rooms: InventoryRoom[],
	weightUnit: JobInventory['weightUnit'],
	volumeUnit: JobInventory['volumeUnit'],
) {
	const inventory: JobInventory = {
		rooms: cloneDeep(rooms),
		weightUnit,
		volumeUnit,
		specialtyItems: [],
		totalBoxes: 0,
		totalItems: 0,
		totalVolume: 0,
		totalWeight: 0,
	};

	for (const room of inventory.rooms) {
		room.totalItems = 0;
		room.totalBoxes = 0;
		room.totalVolume = 0;
		room.totalWeight = 0;

		for (const item of room.inventory) {
			item.weight = parseFloat(item.weight as any);
			item.volume = parseFloat(item.volume as any);
			item.quantity = parseFloat(item.quantity as any) || 0;
			item.totalWeight = item.weight * item.quantity;
			item.totalVolume = item.volume * item.quantity;
			room.totalWeight += item.totalWeight;
			room.totalVolume += item.totalVolume;
			room.totalItems += item.quantity;
		}

		inventory.totalWeight += room.totalWeight;
		inventory.totalVolume += room.totalVolume;
		inventory.totalItems += room.totalItems;
	}

	return inventory;
}
