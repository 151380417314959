<p-button
    *ngIf="action === 'create-close'"
    [outlined]="true"
    (click)="createClose()"
    label="Create & Close"
    [disabled]="buttonDisabled$ | async"
    [pTooltip]="buttonDisabledMessage$ | async">
</p-button>
<p-button
    *ngIf="action === 'create'"
    (click)="create()"
    label="Create Job"
    class="create-btn"
    [icon]="(jobMutating$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    [disabled]="buttonDisabled$ | async"
    [pTooltip]="buttonDisabledMessage$ | async"
></p-button>
<p-button
    *ngIf="action === 'update-close'"
    (click)="updateClose()"
    [outlined]="true"
    label="Close Job"
    [disabled]="buttonDisabled$ | async"
    [pTooltip]="buttonDisabledMessage$ | async">
</p-button>
<p-button
    *ngIf="action === 'update'"
    (click)="updateJob()"
    label="Update Job"
    class="update-btn"
    [icon]="(jobMutating$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    [disabled]="buttonDisabled$ | async"
    [pTooltip]="buttonDisabledMessage$ | async">
</p-button>
