<div class="header-with-button">
    <h4>
        <i [class]="icon"></i> Charge
        <app-object-state
            [object]="charge"
            objectType="charge"
            [colorful]="true"
        ></app-object-state>
    </h4>
    <span
        [pTooltip]="eventLockedTooltip"
        >
        <button pButton icon="pi pi-pencil"
            [disabled]="!charge || (charge?.calendarEvent | eventHasInvoice) || eventLocked || !charge.createdAt"
            (click)="editCharge(charge)"
            >
        </button>
    </span>
    <!-- <div>
        <button
            pButton
            pRipple
            label="Actions"
            *hasPermission="['charges.update']"
            (click)="chargeMenu.toggle($event)"
            icon="pi pi-angle-down"
            data-cy="price-actions-button"
        ></button>
        <p-menu
            #priceMenu
            [popup]="true"
            appendTo="body"
            [model]="chargeActions"
        ></p-menu>
    </div> -->
</div>

<div id="price-info" class="card">
    <p>
        Product:
        <a
            *ngIf="charge.product"
            class="clickable"
            (click)="detailsHelperSvc.open('product', charge.product)"
            >{{ charge.product.name }}</a
        >
        <span *ngIf="!charge.product">Custom</span>
    </p>
    <p>
        Price:
        <a
            *ngIf="charge.price"
            class="clickable"
            (click)="detailsHelperSvc.open('price', charge.price)"
            >{{ charge.price.name }}</a
        >
        <span *ngIf="!charge.price">Custom</span>
    </p>
    <!-- <p>
        Job:
        <a
            *ngIf="charge.job"
            class="clickable"
            (click)="detailsHelperSvc.open('job', charge.job)"
            >{{ charge.job.code }}</a
        >
        <span *ngIf="!charge.job">No Job</span>
    </p> -->
    <p>
        Event:
        <a
            *ngIf="charge.calendarEvent"
            class="clickable"
            (click)="detailsHelperSvc.open('calendar-event', charge.calendarEvent)"
            >{{ charge.calendarEvent.title }}</a
        >
        <span *ngIf="!charge.calendarEvent">{{ promisedChargeEvent }} (not booked)</span>
    </p>

    <hr />

    <p>
        Unit Amount:
        <span *ngIf="charge?.price?.priceType !== 'percentage'">
            {{ charge | chargeAmount | currency : charge?.job?.currency || 'USD' }}
        </span>
        <span *ngIf="charge?.price?.priceType === 'percentage'">
            {{ charge | chargeAmount }}%
        </span>
    </p>
    <p>Quantity: {{ charge.quantity }}</p>
    <p>
        Subtotal: {{ charge.chargeSubTotal | freyaCurrency : charge.currency }}
    </p>
    <p>
        Discount Total:
        {{ charge.discountTotal | freyaCurrency : charge.currency }}
    </p>
    <p>
        Discounted Sub Total:
        {{ charge.discountedSubTotal | freyaCurrency : charge.currency }}
    </p>
    <p>Tax Total: {{ charge.taxTotal | freyaCurrency : charge.currency }}</p>
    <p>Total: {{ charge.total | freyaCurrency : charge.currency }}</p>
    <p>Total cost: {{ charge.expenseTotal | freyaCurrency : charge.currency }}</p>

    <hr />

    <p>Created: {{ charge.createdAt ? (charge.createdAt | freyaDate) : 'Not Saved' }}</p>
</div>

<h5>Costs ({{ charge.expenseTotal | freyaCurrency : charge.currency }})</h5>
<div class="p-mb-2" >
    <p-accordion *ngIf="charge.expensesV2?.length; else noExpensesMessage" [multiple]="true">
        <p-accordionTab *ngFor="let expense of charge?.expensesV2" [header]="expense.expense.name">
            <p>
                {{ expense.expense.description}}
            </p>
            <p>
                Unit cost: {{ expense.unitCost / 100 | currency : charge.currency}}
            </p>
            <p>
                Total cost: {{ expense.amount / 100 | currency : charge.currency}}
            </p>
        </p-accordionTab>
    </p-accordion>
    <ng-template #noExpensesMessage>
        <p>No Costs Assigned</p>
    </ng-template>
</div>

<h5>Taxes</h5>
<div class="card">
    <div>
		<div *ngIf="charge.taxes?.length">
			<li *ngFor="let tax of charge.taxes">
				<a 
					class="clickable"
					(click)="openTax(tax)"
				>
					{{ tax.name }} ({{ tax.percentage }}%)
				</a>
			</li>
			<hr />
			<p>Charge Tax Total: {{ charge.taxTotal | freyaCurrency : charge.currency }}</p>
		</div>
        <p *ngIf="!charge.taxes?.length">No Taxes</p>
    </div>
</div>

