<main class="grid jobsv2-{{ colorScheme.colorScheme.value }}">
  <app-job-header></app-job-header>

  <app-karve-tabview
    [tabs]="tabsWithSlug"
    [baseUrl]="baseUrl"
    class="job-tabs"
  ></app-karve-tabview>

  <div class="tabView">
    <router-outlet></router-outlet>
  </div>

  <app-job-activity></app-job-activity>

  <p-dialog
    header="New Event"
    [modal]="true"
    [visible]="isDialogVisible$ | async"
    [closable]="false"
    [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
    [style]="{ width: '35vw' }"
    (onHide)="store.dispatch(JobToolActions.newEventDialogClosed())"
  >
    <ng-template pTemplate="headless">
      <app-timeline-create-event></app-timeline-create-event>
    </ng-template>
  </p-dialog>

</main>

<div
  *ngIf="hasUnsavedChanges$ | async"
  class="sticky-unsaved-changes-banner"
>
  <div>
    <i [ngClass]="(jobBeingUpdated$ | async)
            ? 'pi pi-spin pi-spinner'
            : 'pi pi-exclamation-triangle'">
    </i>
    <span>
      Unsaved changes
    </span>
  </div>
  <div class="buttons-container">
    <button
      pButton
      class="p-button-text p-m-1 save-notification-text-btn"
      label="Discard"
      tooltipPosition="left"
      (click)="cancelJobChanges()"
      pTooltip="Discard unsaved job changes"
    ></button>
    <button
      pButton
      class="p-button-text p-m-1 save-notification-text-btn"
      label="Save"
      (click)="saveModifiedCharges()"
      [pTooltip]="getSaveTooltipText()"
      [disabled]="(jobBeingUpdated$ | async) || (jobLoading$ | async) || !isOnline"
    ></button>
  </div>
</div>

<p-dialog
  header="Schedule Event"
  [modal]="true"
  [visible]="bookEventDialogVisible$ | async"
  [closable]="true"
  (onHide)="store.dispatch(ScheduleEventsActions.bookDialogClosed())"
  [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
  [style]="{ width: '90vw' }"
>
  <ng-template pTemplate="headless">
    <!-- [jobInDifferentTimezoneWarning]="jobInDifferentTimezoneWarning" -->
    <!-- [timezone]="timezone" -->

    <app-timeline-book-event *ngIf="bookEventDialogVisible$ | async"></app-timeline-book-event>
  </ng-template>
</p-dialog>